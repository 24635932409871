import { firstLetter as _firstLetter } from "common/helpers";


const firstLetter = ({ name }) => _firstLetter(name).toUpperCase();


const country = {
  firstLetter,
};

export default country;
