import { dbEnums } from "common/enums";
import { trimStr } from "common/helpers";
import { doMutation, getTagIds } from "client/store/helpers";
import { UPDATE_ALUMNUS_HOBBY } from "client/store/alumnus";


const tagEntity = dbEnums.tagEntity.HOBBY;


const normalizeRecord = async (client, { tags: rawTags, hobby: rawHobby, id, ...data }) => {
  const hobby = trimStr(rawHobby, true);
  const tags = await getTagIds(tagEntity, rawTags, client);

  return {
    ...data,
    id,
    hobby,
    tags,
  };
};


const saveHobby = async (_root, { record }, { client }) => {
  const { id, ...changes } = await normalizeRecord(client, record);

  await doMutation(client, UPDATE_ALUMNUS_HOBBY, {
    id,
    changes,
  });
};

export default saveHobby;
