import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { Form, AdminVerification } from "components/form";
import { Rows } from "components/listPages";
import { ListContext } from "./ListPage";


const Content = ({ adminVerification, multiSelect, ...props }) => {

  const { account, verified, rows, page, setPage: _setPage, ...restContext } = useContext(ListContext);


  const [localPage, setLocalPage] = useState(page);


  const setPage = useCallback(page => setLocalPage(page), []);


  const onEnter = useCallback(() => _setPage(localPage), [_setPage, localPage]);


  const onUpdateFinished = useCallback(() => page !== localPage && setLocalPage(page), [localPage, page]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.listPages.updateFinished, onUpdateFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.listPages.updateFinished, onUpdateFinished);
    }
  }, [onUpdateFinished]);


  return (
    <div className="h-full">
      {Boolean(adminVerification && !verified) && (
        <Form className="max-w-md md:max-w-lg my-20 mx-24"
              records={[account]}
        >
          <Grid item xs={12}>
            <AdminVerification {...restContext}
                               account={account}
            />
          </Grid>
        </Form>
      )}
      <Rows {...restContext}
            {...props}
            rows={rows}
            page={localPage}
            setPage={setPage}
            onEnter={onEnter}
            onBlur={onEnter}
            listClassName="px-24 pt-8 pb-16"
            enableRipple={Boolean(multiSelect)}
      />
    </div>
  );
};

export default React.memo(Content);
