import SingletonRouter, { Router } from "next/router"
import { useCallback, useContext, useEffect } from "react";
import { safeFnOrPromise } from "common/helpers";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const useConfirmRouteChange = ({ confirm, disabled }) => {

  const { dialog: { open } } = useContext(UiStateContext);


  const doChangeRoute = useCallback(url => SingletonRouter.push(url), []);


  // TODO: temporary workaround until next.js doesn't yet provide a nice way to abort page loading:
  // https://github.com/zeit/next.js/issues/2476#issuecomment-612483261
  const change = useCallback((...args) => {
    const asPath = args[2];

    if (open || disabled || !asPath) return Router.prototype.change.apply(SingletonRouter.router, args);

    safeFnOrPromise(confirm)(doChangeRoute, asPath);

    return new Promise(resolve => resolve(false));
  }, [confirm, disabled, doChangeRoute, open]);


  useEffect(() => {
    SingletonRouter.router.change = change;

    return () => {
      delete SingletonRouter.router.change
    }
  }, [change]);

};

export default useConfirmRouteChange;
