import React from "react";
import { Grid } from "@material-ui/core";
import ExportButtons from "./ExportButtons";


const Toolbar = () => (
  <Grid container justify="space-between" alignItems="center" className="w-full px-16">
    <Grid item container spacing={1} alignItems="center">
      <ExportButtons/>
    </Grid>
  </Grid>
);

export default React.memo(Toolbar);
