import Bottleneck from "bottleneck";
import produce from "immer";
import { GET_OCTOPUS_STATE } from "../queries";
import { defaultCache } from "../defaultCache";
import { mutationStatuses, requestsPerSecond, safeMargin, stopMutationErrorMessage } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";


export const subStates = {
  init: "init",
  status: "status",
  sync: "sync",
};


export const getOctopusState = client => client.readQuery({ query: GET_OCTOPUS_STATE });


const writeOctopusState = (client, updatedState) => client.writeQuery({
  query: GET_OCTOPUS_STATE,
  data: { octopusState: updatedState },
});


const updateHelper = (client, producerCb) => {
  const { octopusState } = getOctopusState(client);
  const updatedState = produce(octopusState, draftState => {
    producerCb(draftState, octopusState)
  });
  writeOctopusState(client, updatedState);
};


const updateOctopusState = (client, subState, updates) => {
  if (!subState || !Object.values(subStates).includes(subState)) return;
  if (!Object.keys(defaultCache.octopusState).includes(subState)) return;

  const producerCb = (draftState, octopusState) => Object.entries(updates || {}).forEach(([key, value]) => {
    if (Object.keys(octopusState[subState]).includes(key)) draftState[subState][key] = value;
  });

  updateHelper(client, producerCb);
};


export const onStart = (client, subState, updates) => {
  const updatesOnStart = {
    ...updates,
    startTime: Date.now(),
    endTime: null,
    status: mutationStatuses.started,
    error: null,
    inProgress: true,
  };
  updateOctopusState(client, subState, updatesOnStart);
};


export const onEnd = (client, subState, updates) => {
  const updatesOnEnd = {
    ...updates,
    endTime: Date.now(),
    status: updates.error
      ? mutationStatuses.failed
      : updates.aborted
        ? mutationStatuses.aborted
        : mutationStatuses.completed,
    inProgress: false,
  };
  updateOctopusState(client, subState, updatesOnEnd);
};


export const onTaskProcessed = (client, failed) => {
  const producerCb = ({ sync = {} }, { sync: { failedTasks = 0, completedTasks = 0, numTasks = 0 } = {} }) => {
    if (failed) {
      sync.failedTasks = failedTasks + 1;
    } else {
      sync.completedTasks = completedTasks + 1;
    }
    const percent = numTasks
      ? (failedTasks + completedTasks) / numTasks
      : 0;
    sync.percent = Math.floor(percent * 100);
  };
  updateHelper(client, producerCb);
};


export const updateNumTasks = (client, numTasks) => {
  const producerCb = ({ sync = {} }) => {
    sync.numTasks = numTasks || 0;
  };
  updateHelper(client, producerCb);
};


export const createLimiter = client => {
  try {
    const safeMultiplier = safeMargin / 100 + 1;
    const secondInMs = 1000;
    const minTime = secondInMs / (requestsPerSecond || 1) * safeMultiplier;
    const limiter = new Bottleneck({
      reservoir: requestsPerSecond,
      reservoirIncreaseAmount: requestsPerSecond,
      reservoirIncreaseInterval: secondInMs,
      maxConcurrent: requestsPerSecond,
      minTime,
    });

    if (!limiter || !limiter.stop) {
      errorLogStore("octopus", "createLimiter", null, "Failed instantize Bottleneck limiter");
      return null;
    }

    const producerCb = ({ sync = {} }) => {
      sync.stop = limiter.stop.bind(limiter);
    };
    updateHelper(client, producerCb);
    return limiter;

  } catch (e) {
    errorLogStore("octopus", "createLimiter", e);
    return null;
  }
};


export const stopLimiter = async (client) => {
  const { octopusState: { sync: { stop } = {} } = {} } = getOctopusState(client);
  if (typeof stop === "function") {
    await stop({
      dropErrorMessage: stopMutationErrorMessage,
    });
  }
};
