import React from "react";
import clsx from "clsx";
import { Scrollbars } from "components/layout/common";


const FusePageSimpleSidebarContent = props => {
  const classes = props.classes;

  return (
    <Scrollbars className="h-full">
      {props.header && (
        <div
          className={clsx(classes.sidebarHeader, props.variant, props.sidebarInner && classes.sidebarHeaderInnerSidebar)}>
          {props.header}
        </div>
      )}

      {props.content && (
        <div className={classes.sidebarContent}>
          {props.content}
        </div>
      )}
    </Scrollbars>
  );
};

export default React.memo(FusePageSimpleSidebarContent);
