import { errorLogStore } from "client/lib/errorLog";
import { GET_INSERTED_TAG_IDS } from "./alumnus";


const getReturnings = (data, wrapperAlias = "results", returningAlias = "returning") => {
  const wrapper = data && data[wrapperAlias];
  return (wrapper && wrapper[returningAlias]) || [];
};


const getRecordId = (returning, idFieldName = "id") => returning && idFieldName && returning[idFieldName];


const updateFragment = (fragment, fragmentName, updateFragmentCb, returning, recordId, cache) => {
  try {
    const fragmentId = `${fragmentName}:${recordId}`;
    const fragmentData = cache.readFragment({ fragmentName, fragment, id: fragmentId }) || {};

    const updates = updateFragmentCb(returning, fragmentData) || {};
    const updatedFragmentData = {
      __typename: fragmentName,
      ...fragmentData,
      ...updates,
    };
    cache.writeFragment({ fragmentName, fragment, id: fragmentId, data: updatedFragmentData });

  } catch (e) {
    errorLogStore("helpers", "updateFragment", e);
  }
};


export const getUpdateHelper = (fragment, fragmentName, updateFragmentCb, wrapperAlias, returningAlias, idFieldName) => (cache, { data }) => {
  if (typeof updateFragmentCb !== "function") return null;

  const returnings = getReturnings(data, wrapperAlias, returningAlias);
  returnings && returnings.forEach(returning => {
    const recordId = getRecordId(returning, idFieldName);
    if (!recordId) return null;

    updateFragment(fragment, fragmentName, updateFragmentCb, returning, recordId, cache);
  });
};


export const removeMetaKeys = data => Object.fromEntries(Object.entries(data || {}).filter(([key]) => key.substr(0, 1) !== "_"));


export const doMutation = async (client, mutation, variables) => {
  const { error } = await client.mutate({
    mutation,
    variables,
  });

  if (error) throw new Error(error);
};


export const getTagIds = async (entity, rawTags, client) => {
  const { error, data: { getInsertedTagIds } = {} } = await client.mutate({
    mutation: GET_INSERTED_TAG_IDS,
    variables: {
      rawTags,
      entity,
    },
  });

  if (error) throw new Error(error);

  return getInsertedTagIds || [];
};
