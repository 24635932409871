import React, { useContext } from "react";
import Link from "next/link";
import { Typography } from "@material-ui/core";
import { accent } from "common/helpers";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { getHref } from "./config";
import ErrorBase from "./ErrorBase";


const Error = ({ serverError }) => {

  const { envVars: { supportEmail, supportEmailUri } } = useContext(SessionContext);


  const description = serverError
    ? "A szerver nem elérhető"
    : "Hiba lépett fel";


  const issue = serverError
    ? "a szerver nem elérhető"
    : "hiba lépett fel";


  const href = getHref(issue, supportEmailUri);


  return (
    <ErrorBase title={500}>

      <Typography variant="h5" color="textSecondary" className="w-full mb-24">
        {description}
      </Typography>

      <Typography variant="subtitle1" color="textSecondary" className="w-full mt-40">
        Kérjük, próbálkozz újra! Ha újra ezt a hibaüzenetet látod, írj {accent(supportEmail)}&nbsp;
        <Link href={href} prefetch={false}>
          <a rel="noopener noreferrer" className="my-1">{supportEmail}</a>
        </Link>
        &nbsp;email címre! Üzenetedben, kérjük, jelezd, hogy melyik oldalon, milyen műveletet végeztél, mielőtt ezzel a
        hibaüzenettel találkoztál!
      </Typography>

    </ErrorBase>
  );
};

export default React.memo(Error);
