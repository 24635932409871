import memoizee from "memoizee";
import clsx from "clsx";
import { subscriptionStatuses, contactStatuses } from "server/lib/octopus/config";


const getContactEmail = memoizee(({ email_address: email }) => email);


const checkOrphan = (contact, member) => {
  if (!member) return {
    email: getContactEmail(contact),
    status: subscriptionStatuses.orphan.code,
  };
};


const isContactSubscribed = memoizee(({ status }) => status === contactStatuses.subscribed);


const isAlumnusSubscribed = memoizee(({ nls: { s: alumnusSubscribed } = {} }) => Boolean(alumnusSubscribed));


const getAlumnusId = memoizee(({ id }) => id);


const checkUnsubscribedInOctopus = (contact, member) => {
  if (!isContactSubscribed(contact)) return {
    email: getContactEmail(contact),
    id: getAlumnusId(member),
    status: !isAlumnusSubscribed(member)
      ? subscriptionStatuses.unsubscribedSynced.code
      : subscriptionStatuses.unsubscribedUnsynced.code,
  };
};


const checkUnsubscribedInApp = (contact, member) => {
  if (isContactSubscribed(contact) && !isAlumnusSubscribed(member)) return {
    email: getContactEmail(contact),
    id: getAlumnusId(member),
    status: subscriptionStatuses.subscribedUnsynced.code,
  };
};


const isNameUnsynced = (contactFirst, contactLast, alumnusFirst, alumnusLast) =>
  clsx(contactFirst).trim() !== clsx(alumnusFirst).trim()
  || clsx(contactLast).trim() !== clsx(alumnusLast).trim();


const checkNameUnsynced = (contact, member) => {
  const { fields: { FirstName: contactFirst, LastName: contactLast } } = contact;
  const { name: { first: alumnusFirst, last: alumnusLast } } = member;
  const unsynced = isNameUnsynced(contactFirst, contactLast, alumnusFirst, alumnusLast);
  if (unsynced && isContactSubscribed(contact) && isAlumnusSubscribed(member)) return {
    email: getContactEmail(contact),
    id: getAlumnusId(member),
    status: subscriptionStatuses.subscribedNameUnsynced.code,
    first: alumnusFirst,
    last: alumnusLast,
  };
};


const handleSubscribed = (contact, member) => ({
  email: getContactEmail(contact),
  id: getAlumnusId(member),
  status: subscriptionStatuses.subscribedSynced.code,
});


const analyzeContact = (contact, membersObject) => {
  const { email_address: email } = contact;
  const member = membersObject && membersObject[email];

  const isOrphan = checkOrphan(contact, member);
  if (isOrphan) return isOrphan;

  const isUnsubscribedInOctopus = checkUnsubscribedInOctopus(contact, member);
  if (isUnsubscribedInOctopus) return isUnsubscribedInOctopus;

  const isUnsubscribedInApp = checkUnsubscribedInApp(contact, member);
  if (isUnsubscribedInApp) return isUnsubscribedInApp;

  const isNameUnsynced = checkNameUnsynced(contact, member);
  if (isNameUnsynced) return isNameUnsynced;

  return handleSubscribed(contact, member);
};

export default analyzeContact;
