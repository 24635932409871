import Router from "next/router";
import { redirects } from "config/routing";
import { devConsoleDebug } from "common/helpers";


export const methods = {
  push: "push",
  replace: "replace",
};


const redirectTo = async (pathname, { method = methods.push } = {}) => new Promise(resolve => {
  devConsoleDebug("redirect to:", pathname, `(${method})`);
  Router[method]({ pathname }).then(resolve);
});


export const redirect = {
  onAuthenticated: (method = methods.replace) => redirectTo(redirects.authenticated(), { method }),
  onUnauthenticated: (method = methods.replace) => redirectTo(redirects.unauthenticated, { method }),
  toRegistration: () => redirectTo(redirects.registration),
};
