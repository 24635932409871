import React, { useContext } from "react";
import clsx from "clsx";
import { Icon, IconButton } from "@material-ui/core";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const NavbarMobileToggleButton = ({ children, className }) => {

  const { navbar: { toggleMobileNavbar } } = useContext(UiStateContext);

  return (
    <IconButton className={clsx(className)} onClick={toggleMobileNavbar} color="inherit" disableRipple>
      {children}
    </IconButton>
  );
};

NavbarMobileToggleButton.defaultProps = {
  children: <Icon>menu</Icon>
};

export default React.memo(NavbarMobileToggleButton);
