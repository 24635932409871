import React, { useCallback, useContext } from "react";
import nextTick from "next-tick";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { accent, safeFnOrPromise } from "common/helpers";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { DEL_REG_CODE } from "client/store/regCode/mutations";
import { ListContext } from "components/listPages";
import { useConfirm } from "components/common";


const getConfirmOptions = ({ name }) => ({
  title: "Egyedi regisztrációs kód törlése",
  content: (<span>Biztosan törlöd {accent(name)} <b>{name}</b> részére kiállított regisztrációs kódot?</span>),
  OKButton: "Igen, törlöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
});


const DelButton = regCode => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { refetch, resetFilters } = useContext(ListContext);


  const [doDelete] = useMutation(DEL_REG_CODE);


  const confirm = useConfirm({
    confirmOptions: getConfirmOptions(regCode),
  });


  const deleteRegCode = useCallback(async () => {
    const { error } = await doDelete({ variables: { id: regCode.id } });

    if (!error) {
      await safeFnOrPromise(refetch)();
      resetFilters();
    }

    nextTick(() => showMessage(error ? UiMessages.form.submit.saveError : UiMessages.regCodes.deleted));
  }, [doDelete, regCode.id, showMessage, refetch, resetFilters]);


  const onDelete = useCallback(() => confirm(deleteRegCode), [confirm, deleteRegCode]);


  return (
    <Tooltip title="Kód törlése">
      <span>
        <IconButton size="small"
                    onClick={onDelete}
                    disabled={regCode.used}
                    className="text-red"
        >
          <Icon>delete</Icon>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default React.memo(DelButton);
