import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { requirementMessages } from "../config";


const useVerificationField = ({ banned, codeAlphabet, codeLength }) => {

  const codeRegexp = useMemo(() => codeAlphabet && new RegExp(`^[${codeAlphabet}]+$`), [codeAlphabet]);


  const placeholder = useMemo(() => codeLength ? Array(codeLength).fill("_").join(" ") : "", [codeLength]);


  const validateCodeLength = useCallback(code => {
    if (!codeLength) return true;

    const valid = clsx(code).trim().length === codeLength;
    return valid || requirementMessages.verification.invalidVerificationCodeLength;
  }, [codeLength]);


  const validateCodeFormat = useCallback(code => {
    if (!codeRegexp) return true;

    const valid = codeRegexp && codeRegexp.test(clsx(code).trim());
    return Boolean(valid) || requirementMessages.verification.invalidVerificationCodeFormat;
  }, [codeRegexp]);


  const validateCodeVerified = useCallback(value => !value || requirementMessages.verification.failedVerification, []);


  const validateBanned = useCallback(() => Boolean(!banned) || requirementMessages.verification.failedVerification, [banned]);


  const validated = useCallback(code =>
    validateCodeLength(code) === true && validateCodeFormat(code) === true && validateBanned() === true
    , [validateBanned, validateCodeFormat, validateCodeLength]);


  const fieldRequirements = useMemo(() => ({
    required: requirementMessages.verification.missingVerficationCode,
    validate: {
      validateCodeLength,
      validateCodeFormat,
      validateCodeVerified,
      validateBanned,
    },
  }), [validateBanned, validateCodeFormat, validateCodeLength, validateCodeVerified]);


  return {
    placeholder,
    validated,
    fieldRequirements,
  };

};

export default useVerificationField;
