import gql from "graphql-tag";


export const fragmentSetting = gql`
  fragment setting on setting {
    id
    value
  }
`;


export const GET_SETTINGS = gql`
  query GetSettings($ids: [String!]) {
    settings: setting(where: {id: {_in: $ids}}) {
      ...setting
    }
  }

  ${fragmentSetting}
`;
