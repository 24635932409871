import React from "react";
import { Typography } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";


const MyAccount = () => {

  const forbidden = useForbidden([dbEnums.accountRoles.ALUMNUS]);

  return (
    <>
      <Typography className="mb-20">
        Ezen a lapon <b>felhasználói fiókod</b> bejelentkezési felhasználónevét tudod megtekinteni, valamint jelszavadat
        megváltoztatni.
      </Typography>
      <Typography className="my-20">
        A <span className="text-blue">bejelentkezési felhasználónév</span>{!forbidden && (
        <span>&nbsp;mindig <b>az aktuálisan megadott kapcsolattartási email címeddel</b> azonos. (Utóbbi az adatlapodon
          módosítható.) A felhasználónév</span>)}&nbsp;&ndash; a mellette lévő gombra kattintva &ndash; <b>a vágólapra
        másolható</b>.
      </Typography>
      <Typography className="my-20">
        Ahhoz, hogy új <span className="text-blue">jelszót</span> állíthass be, először <b>meg kell adnod a jelenlegi
        jelszavadat</b>. Ezt a rendszer csak az űrlap mentését követően ellenőrzi, így az esetleges elírást is ekkor
        jelzi
        vissza, az erre vonatkozó hibaüzenettel.
      </Typography>
      <Typography className="my-20">
        Az új jelszóra vonatkozó <b>valamennyi formai követelménynek teljesülnie kell</b>: a <i>minimum 8 karakter
        hosszú</i> jelszónak legalább <i>2 kisbetűt</i>, <i>2 nagybetűt</i>, <i>1 számjegyet</i>, valamint <i>1 szóközt
        vagy írásjelet</i> kell tartalmaznia. Ezek teljesülésére <b>az alumnusok adatainak biztonsága érdekében</b> van
        szükség. Az egyes követelmények teljesülése &ndash; az űrlap alján lévő blokkban &ndash; külön-külön is
        ellenőrizhető.
      </Typography>
      <Typography className="mt-20">
        Az újonnan beállítandó jelszót &ndash; egymás alatt &ndash; <b>kétszer kell megadni</b>. A megerősítésre az
        elírások elkerülése végett van szükség.
      </Typography>
    </>
  );
}

export default React.memo(MyAccount);
