import React from "react";


const className = "splash-screen";

const id = className;

const Splash = () => (
  <div id={id} className={className}>
    <div className="center">
      <div className="logo">
        <img width="360" src="/images/logo.png" alt="logo"/>
      </div>
      <div className="spinner-wrapper">
        <div className="spinner">
          <div className="inner">
            <div className="gap"/>
            <div className="left">
              <div className="half-circle"/>
            </div>
            <div className="right">
              <div className="half-circle"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default React.memo(Splash);
