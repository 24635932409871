import { Check, Report } from "@material-ui/icons";
import { dateStr } from "common/helpers";


const termsAdminProps = {
  notRequired: {
    label: "Elfogadta",
    color: "success",
    avatar: Check,
  },
  required: {
    label: "Még nem fogadta el",
    color: "danger",
    avatar: Report,
  },
};


const termsStatus = ({ lastTerms, requiredTerms }) => {
  const adminProps = requiredTerms ? termsAdminProps.required : termsAdminProps.notRequired;

  const normalizedLastTerms = dateStr(lastTerms) || "Még sosem fogadta el.";

  return {
    ...adminProps,
    lastTerms: normalizedLastTerms,
  };
};


const view_account_resctricted_manager = {
  termsStatus,
};

export default view_account_resctricted_manager;
