import React from "react"
import { AppBar, Grid } from "@material-ui/core";
import { Copyright } from "components/layout";


const Footer = () => (
  <Grid item xs={12}>
    <AppBar id="fuse-footer" className="relative z-0 px-16 py-16 sm:py-4 min-h-48 flex justify-center" color="default">
      <Copyright/>
    </AppBar>
  </Grid>
);

export default React.memo(Footer);
