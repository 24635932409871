import { makeStyles } from "@material-ui/core/styles";


export const useSidebarStyles = makeStyles(theme => ({
  listItem: {
    color: "inherit!important",
    textDecoration: "none!important",
    height: 36,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingLeft: 24,
    paddingRight: 12,
    paddingTop: 2,
    paddingBottom: 2,
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + "!important",
      pointerEvents: "none",
      "& .list-item-icon": {
        color: "inherit"
      },
    },
    "&.filled": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + "!important",
      "& .list-item-icon": {
        color: "inherit"
      },
    },
    "& .list-item-icon": {
      fontSize: 16,
      width: 16,
      height: 16,
      marginRight: 16
    },
  },
}));
