import React from "react";
import Link from "next/link";
import { Grid, Icon, Typography } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";


const BackButton = ({ from, fromAs, fromName, onClick }) => {

  const content = (
    <a rel="noopener noreferrer" role="button" onClick={onClick} className="cursor-pointer">
      <FuseAnimate animation="transition.slideRightIn" delay={300}>
        <Grid container alignItems="center">
          <Grid item>
            <Icon className="mr-4 text-white text-20">arrow_back</Icon>
          </Grid>
          <Grid item className="min-h-24">
            <Typography className="text-14 text-white">Vissza {fromName || "az előző oldalra"}</Typography>
          </Grid>
        </Grid>
      </FuseAnimate>
    </a>
  );

  return Boolean(from) && (
    typeof onClick === "function"
      ? content
      : (
        <Link href={from} as={fromAs || from} prefetch={false}>
          {content}
        </Link>
      )
  );
};

export default React.memo(BackButton);
