import { getOctopusState, onEnd, onStart, subStates, updateNumTasks } from "./octopusState";
import processTasks from "./processTasks";
import refreshStatus from "./refreshStatus";
import { mutationStatuses } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { decoratedError, errors } from "common/errors";


const getTasks = client => {
  const { error, octopusState: { status: { status, tasks = [] } = {} } = {} } = getOctopusState(client);
  if (error) return errors.client.octopus.ON_GET_TASKS;
  return status !== mutationStatuses.completed
    ? errors.client.octopus.UNCOMPLETED_STATUS
    : { tasks };
};


const getSyncUpdates = async client => {
  try {
    const { error, tasks } = getTasks(client);
    if (error) return { error };

    updateNumTasks(client, tasks.length);

    return await processTasks(client, tasks);

  } catch (e) {
    const message = errorLogStore("octopus", "getSyncUpdates", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};


const refresh = client => refreshStatus(null, null, { client });


const octopusSync = async (_root, _args, { client }) => {
  const updatesOnStart = {
    numTasks: 0,
    completedTasks: 0,
    failedTasks: 0,
    percent: 0,
  };
  onStart(client, subStates.sync, updatesOnStart);
  const updates = await getSyncUpdates(client);
  const updatesOnEnd = {
    ...updates,
    stop: null,
  };
  onEnd(client, subStates.sync, updatesOnEnd);
  await refresh(client);
  return null;
};

export default octopusSync;
