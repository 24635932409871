import React, { useContext } from "react";
import { Header as ListHeader, TextFilter, ListContext } from "components/listPages";


const Header = props => {

  const { setFilter, filterValues } = useContext(ListContext);

  return (
    <ListHeader {...props}>
      <TextFilter filterId="toFilter"
                  filterValue={filterValues.toFilter}
                  setFilter={setFilter}
                  disableUnderline
                  className="w-full"
                  fullWidth
      />
    </ListHeader>
  );
};

export default React.memo(Header);
