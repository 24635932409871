import React, { useCallback, useRef, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Chip, Grid } from "@material-ui/core";
import { GET_REVISION_INVOLVED } from "client/store/revisionRequest";
import { CheckBox, DateField, Input } from "components/form";


export const __typename = "revision_request";


export const _updateFields = {
  required: "required",
  lastRevision: "lastRevision",
  note: "note",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.required, _updateFields.lastRevision],
};


const _fieldAliases = {
  [_updateFields.lastRevision]: "last_revision",
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


const RevisionRequest = ({ isNew, involved = 0, ...record }) => {

  const fetched = useRef(false);


  const [_involved, _setInvolved] = useState(involved);


  const onCompleted = useCallback(({ involved } = {}) => {
    const [wrapper] = involved || [];
    const { cnt } = wrapper || {};
    _setInvolved(cnt || 0);
    fetched.current = true;
  }, []);


  const { refetch } = useQuery(GET_REVISION_INVOLVED, {
    skip: !isNew,
    variables: {
      lastRevision: record[_updateFields.lastRevision],
    },
    onCompleted,
  });


  const onDateChange = useCallback(date => isNew && refetch({
    lastRevision: date,
  }), [isNew, refetch]);


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm="auto">
        <DateField record={record}
                   field={_updateFields.lastRevision}
                   onChangeCb={onDateChange}
                   disabled={!isNew}
                   label="Utolsó figyelembe vett adatellenőrzés"
                   helperText={`Azokat ${isNew ? "fogja érinteni" : "érinti"}, akik e dátum óta nem ellenőrizték adataikat`}
                   pickerClassName="w-full"
                   className="mb-16"
                   disableFuture
                   required
        />
      </Grid>
      <Grid item xs={12} sm="auto">
        {Boolean(fetched.current) && (
          <Chip label={`${isNew ? _involved : involved} alumnust ${isNew ? "fog érinteni" : "érint"}`}
                className="sm:mt-10 mb-16"
                color="primary"
                variant="outlined"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <CheckBox record={record}
                  field={_updateFields.required}
                  title="Kötelező adatellenőrzés az érintettektől (bejelentkezést követően)"
                  className="mb-16"
        />
      </Grid>
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.note}
               className="mb-8"
               label="Megjegyzés"
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(RevisionRequest);
