import { dbEnums } from "common/enums";
import { pathNames } from "./routing";


const userMenuItems = [
  {
    id: 1,
    href: pathNames.alumnus.href,
    as: pathNames.alumnus.as("me"),
    allowedRoles: [dbEnums.accountRoles.ALUMNUS],
    title: "Saját adatlapom",
    icon: "account_circle",
  },
  {
    id: 2,
    href: pathNames.alumnus.href,
    as: `${pathNames.alumnus.as("me")}/edit`,
    allowedRoles: [dbEnums.accountRoles.ALUMNUS],
    title: "Adataim módosítása",
    icon: "edit",
  },
  {
    id: 3,
    href: pathNames.myAccount.href,
    title: "Jelszóváltoztatás",
    icon: "security",
  },
];

export default userMenuItems;
