import moment from "moment";
import nextTick from "next-tick";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { GET_ALUMNUS_W_GROUPMATES } from "client/store/alumnus";
import {
  emitUiStateEventNextTick,
  UiStateContext,
  uiStateEvents,
  uiStateEventEmitter
} from "client/lib/providers/UiStateProvider";


// tolerance of difference of update and last submit timestamps in milliseconds
const diffUpdateLastSubmitToleranceInMs = 2 * 1000;


const useAlumnus = ({ alumnusId, editMode, getLastSubmit, submitLoading, forbidden }) => {

  const { message: { showMessage } } = useContext(UiStateContext);


  const [alumnusLoading, setAlumnusLoading] = useState(false);

  const [disabledReloadMessage, setDisabledReloadMessage] = useState(false);

  const [fetched, setFetched] = useState(false);

  const [error, setError] = useState(false);

  const [alumnus, setAlumnus] = useState();


  const loadedIds = useRef([]);


  const loading = useMemo(() => !fetched || alumnusLoading || !alumnusId || submitLoading
    , [alumnusId, alumnusLoading, fetched, submitLoading]);

  const notFound = useMemo(() => fetched && !alumnusLoading && (!alumnus || !alumnus.id), [alumnusLoading, fetched, alumnus]);


  const client = useApolloClient();


  const doFetch = useCallback(async forced => {
    const { error, data: { alumnus } = {} } = await client.query({
      query: GET_ALUMNUS_W_GROUPMATES,
      fetchPolicy: "no-cache",
      variables: {
        id: alumnusId,
        forcedRefetch: forced || !loadedIds.current.includes(alumnusId),
      },
    });

    loadedIds.current = [...loadedIds.current, alumnusId];

    return { error, alumnus };
  }, [alumnusId, client]);


  const fetchData = useCallback(async forced => {
    setAlumnusLoading(true);

    const { error, alumnus } = await doFetch(forced);

    setError(Boolean(error));
    setAlumnus(alumnus);
    setFetched(true);

    nextTick(() => setAlumnusLoading(false));

    if (forced) emitUiStateEventNextTick(uiStateEvents.form.dataRefetched);
  }, [doFetch]);


  const startLoading = useCallback(() => {
    setAlumnusLoading(true);
    setDisabledReloadMessage(true);
  }, []);


  const stopLoading = useCallback(() => {
    setAlumnusLoading(false);
    setDisabledReloadMessage(false);
  }, []);


  const forcedReload = useCallback(async () => await fetchData(true), [fetchData]);


  const onMemberUpdated = useCallback(async (updatedAlumnusId, updated) => {
    if (updatedAlumnusId !== alumnusId || submitLoading) return;

    const diffUpdateLastSubmitInMs = moment(updated).diff(moment(getLastSubmit()), "milliseconds");

    if (diffUpdateLastSubmitInMs > diffUpdateLastSubmitToleranceInMs) {
      await forcedReload();
      if (editMode) {
        if (!disabledReloadMessage) showMessage(UiMessages.alumnus.reloaded);
        setDisabledReloadMessage(false);
      }
    }
  }, [alumnusId, submitLoading, getLastSubmit, forcedReload, editMode, disabledReloadMessage, showMessage]);


  const onSubmitFinished = useCallback(({ success }) => {
    if (success) forcedReload()
  }, [forcedReload]);


  useEffect(() => {
    if (!forbidden) {
      setFetched(false);
      fetchData();
    }
  }, [alumnusId, fetchData, forbidden]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.alumnus.memberUpdated, onMemberUpdated);
    uiStateEventEmitter.on(uiStateEvents.form.submitFinished, onSubmitFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.alumnus.memberUpdated, onMemberUpdated);
      uiStateEventEmitter.off(uiStateEvents.form.submitFinished, onSubmitFinished);
    }
  }, [onSubmitFinished, onMemberUpdated]);


  return {
    loading,
    error,
    notFound,
    alumnus,
    startLoading,
    stopLoading,
    forcedReload,
  };

};

export default useAlumnus;
