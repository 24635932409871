import React from "react";
import clsx from "clsx";
import { Typography, Chip, ListItem, ListItemAvatar, ListItemText, Avatar, Grid } from "@material-ui/core";
import { Work as WorkIcon, School as SchoolIcon } from "@material-ui/icons";
import FlagIcon from "components/alumnus/FlagIcon";
import { ExternalLink } from "components/common";


const Professional = ({ study, job }) => {
  const entity = study || job;
  if (!entity) return null;

  const primary = (
    <Grid container spacing={1} className="mb-4">

      <Grid item xs={12} container spacing={1}>
        <Grid item xs="auto">
          <Typography>{entity.degree || entity.job}</Typography>
        </Grid>
        {entity.current && (
          <Grid item xs="auto">
            <Chip label="aktuális" size="small" color="secondary"/>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography color="primary">
          <span className="mr-8">{entity.institutionFull || entity.companyFull}</span>
          <FlagIcon code={entity.country} disableHun/>
        </Typography>
      </Grid>

      <Grid item xs={12} container spacing={1}>
        {entity.sector && (
          <Grid item xs="auto">
            <Typography variant="caption">{entity.sector}</Typography>
          </Grid>
        )}
        {entity.web && (
          <Grid item xs="auto">
            <Typography variant="caption" color="primary">
              <ExternalLink href={entity.web}>{entity.web}</ExternalLink>
            </Typography>
          </Grid>
        )}
      </Grid>

      {entity.note && (
        <Grid item xs={12}>
          <Typography className="text-13 mb-2 whitespace-pre-line">{entity.note}</Typography>
        </Grid>
      )}

    </Grid>
  );

  const yearFrom = clsx(entity.from);
  const yearTo = clsx(entity.to);
  const dateRange = (
    <>
      {yearFrom}
      {(yearFrom || yearTo) && <span>&nbsp;&ndash;&nbsp;</span>}
      {yearTo}
    </>
  );

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          {study
            ? (
              <SchoolIcon/>
            )
            : (
              <WorkIcon/>
            )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={dateRange}/>
    </ListItem>
  );
};

export default React.memo(Professional);
