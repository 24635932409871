import React from "react";
import clsx from "clsx";
import { Icon } from "@material-ui/core";


const ExternalLink = ({ href, noIcon, role, className, children }) => (
  <>
    {!noIcon && (
      <Icon className="text-14 mr-1" color="action">launch</Icon>
    )}
    <a href={href}
       target="_blank"
       rel="noopener noreferrer"
       className={clsx("cursor-pointer", className)}
       role={role}>
      {children}
    </a>
  </>
);

export default React.memo(ExternalLink);
