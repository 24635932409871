import gql from "graphql-tag";


export const GET_SPECIAL_ACCOUNTS = gql`
  query GetSpecialAccounts {
    accounts: view_special_account(order_by: {updated: desc_nulls_last}) {
      id
      created
      updated
      username
      roles
      status
      lstTerms: last_terms
      requiredTerms: required_terms
      lastLogin: last_login
      ip
      banned
      banExpiry: expiry
      bannedServices: banned_services
      note
      role @client
      toFilter @client
    }
  }
`;
