import React from "react";
import { Grid } from "@material-ui/core";
import AddButton from "./AddButton";


const Toolbar = () => (
  <Grid container justify="space-between" alignItems="center" className="w-full px-16">
    <Grid item container spacing={1} alignItems="center">
      <Grid item>
        <AddButton/>
      </Grid>
    </Grid>
    <Grid item>
    </Grid>
  </Grid>
);

export default React.memo(Toolbar);
