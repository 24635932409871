import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { MenuItem, ListItemIcon, Icon, ListItemText, Button, Menu, Tooltip } from "@material-ui/core";


const ButtonSelect = ({
                        value, onChange, name, options, tooltip, color, variant = "contained", defaultIcon,
                        className, buttonClassName, buttonProps, iconClassName, textClassName, size
                      }) => {

  const [anchorEl, setAnchorEl] = useState(null);


  const option = useMemo(() => options.find((option, index) => option && (option.value || index) === value), [options, value]);

  const icon = useMemo(() => (option && option.icon) || defaultIcon, [defaultIcon, option]);

  const label = useMemo(() => option && (option.label || option.title), [option]);

  const labelColor = useMemo(() => option && option.color, [option]);

  const labelClassName = useMemo(() => option && option.className, [option]);


  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), []);

  const handleClose = useCallback(() => setAnchorEl(null), []);


  const items = useMemo(() => Array.isArray(options) && options.map((option, index) => {
    const { value, label, title, icon, disabled } = option || {};

    const onClick = () => {
      onChange && onChange(value || index);
      setAnchorEl(null);
    };

    return (
      <MenuItem key={value || index} onClick={onClick} disabled={disabled}>
        <ListItemIcon className="min-w-0 sm:min-w-24 sm:mr-16">
          <Icon classes={{
            root: "hidden sm:block"
          }}>{icon || defaultIcon}</Icon>
        </ListItemIcon>
        <ListItemText primary={label || title}/>
      </MenuItem>
    );
  }), [defaultIcon, onChange, options]);


  const button = useMemo(() => (
    <Button {...buttonProps}
            className={clsx("normal-case", buttonClassName)}
            variant={variant}
            color={color}
            aria-label={name}
            onClick={handleClick}
            size={size || "medium"}
    >
      {icon && (
        <ListItemIcon className="min-w-0 sm:min-w-24 sm:mr-12">
          <Icon classes={{
            root: clsx("hidden sm:block", labelClassName, iconClassName)
          }} color={labelColor}>
            {icon}
          </Icon>
        </ListItemIcon>
      )}
      <ListItemText classes={{
        root: "my-0",
        primary: clsx("flex flex-row items-center text-left", labelClassName, textClassName)
      }} primaryTypographyProps={{
        color: labelColor,
      }}>
        {label}
        <Icon classes={{
          root: "ml-4"
        }} color={labelColor}>
          arrow_drop_down
        </Icon>
      </ListItemText>
    </Button>
  ), [buttonClassName, buttonProps, color, handleClick, icon, iconClassName, label, labelClassName, labelColor, name, size, textClassName, variant]);


  return (
    <div className={className}>
      {tooltip
        ? (
          <Tooltip title={tooltip} aria-label={name}>
            {button}
          </Tooltip>
        )
        : button
      }
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items}
      </Menu>
    </div>
  );

};

export default React.memo(ButtonSelect);
