import React, { useContext, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { Suspense } from "components/common";
import { SetPassword } from "components/form";


const statementId = "changedPassword";


const PswcStep = ({ doStatement, revokeStatement, untouched, submitLoading, disableSubmit, enableSubmit, startSubmit, registerSubmitCb }) => {

  const { account: { id: accountId } } = useContext(SessionContext);


  useEffect(() => {
    if (typeof registerSubmitCb === "function") registerSubmitCb(startSubmit);
  }, [registerSubmitCb, startSubmit]);


  useEffect(() => {
    if (untouched) {
      revokeStatement(statementId);
    } else {
      doStatement(statementId);
    }
  }, [doStatement, revokeStatement, untouched]);


  return (
    <Suspense loading={submitLoading}
              className="py-32 md:py-48 lg:py-64"
    >
      <div className="w-full">

        <Typography className="my-16">
          A jelszó, amellyel az imént bejelentkeztél, nem felel meg a biztonsági követelményeknek. <i>Kérjük, állíts be
          új jelszót!</i> A formai követelményeket (és azok teljesülését) a beviteli mezők alatt ellenőrizheted.
        </Typography>

        <Typography className="mt-16 mb-32">
          A következő bejelentkezéskor már az itt beállított új jelszót add meg!
        </Typography>

        <SetPassword accountId={accountId}
                     enableSubmit={enableSubmit}
                     disableSubmit={disableSubmit}
                     loading={submitLoading}
                     requiredCurrent
                     noMessage
                     noStopLoading
                     issue
                     className="mt-32 mb-32"
        />

      </div>
    </Suspense>
  );
};

export default React.memo(PswcStep);
