import gql from "graphql-tag";


export const GET_BANNED_IPS_W_COUNTRIES = gql`
  query GetBannedIpsWCountries {
    bannedIpsWCountries @client
  }
`;


export const GET_BANNED_IPS = gql`
  query GetBannedIps {
    ips: view_banned_ip_inc_services(order_by: {expiry: asc_nulls_last, ip: asc_nulls_last}) {
      ip
      expiry
    }
  }
`;
