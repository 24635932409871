import React from "react";


const FusePageCardedHeader = props => {
  return (
    <div className={props.classes.header}>
      {props.header}
    </div>
  );
};

export default React.memo(FusePageCardedHeader);
