import getInsertedTagIds from "./getInsertedTagIds";
import saveAvatar from "./saveAvatar";
import saveCooperation from "./saveCooperation";
import saveEmails from "./saveEmails";
import saveHobby from "./saveHobby";
import saveJobs from "./saveJobs";
import saveCards from "./saveCards";
import savePhones from "./savePhones";
import saveSocials from "./saveSocials";
import saveStudies from "./saveStudies";


export const Mutation = {
  getInsertedTagIds,
  saveAvatar,
  saveCooperation,
  saveEmails,
  saveHobby,
  saveJobs,
  saveCards,
  savePhones,
  saveSocials,
  saveStudies,
};
