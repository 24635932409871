import React, { useCallback, useContext, useMemo } from "react";
import { customAlphabet } from "nanoid";
import { nolookalikes } from "nanoid-dictionary";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { FormContext, Input } from "components/form";
import { getPrefixedId, serializeFieldName } from "../helpers";
import { __typename, _updateFields } from "./RegCode";


const randomCodeLength = 10;


export const getRandomCode = () => customAlphabet(nolookalikes, randomCodeLength)();


const Code = record => {

  const { setValue } = useContext(FormContext);


  const prefixedId = useMemo(() => getPrefixedId(record && record.id), [record]);


  const fieldName = useMemo(() => serializeFieldName(__typename, prefixedId, _updateFields.code), [prefixedId]);


  const setRandomCode = useCallback(() => setValue(fieldName, getRandomCode()), [fieldName, setValue]);


  return (
    <div className="flex flex-row items-center">
      <Input record={record}
             disabled={Boolean(record.used)}
             field={_updateFields.code}
             minLength={5}
             label="Egyedi regisztrációs kód"
             className="mb-16 mr-8"
             required
      />
      <Tooltip title="Véletlenszerű kód létrehozása"
               classes={{
                 popper: "z-9999",
               }}
      >
        <span>
          <IconButton onClick={setRandomCode}
                      disabled={Boolean(record.used)}
                      className="mb-16"
          >
            <Icon>autorenew</Icon>
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default React.memo(Code);
