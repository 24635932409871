import { useCallback, useContext, useEffect, useRef, useState } from "react";
import nextTick from "next-tick";
import Router from "next/router";
import { UiMessages } from "config/messages";
import {
  UiStateContext,
  uiStateEvents,
  uiStateEventEmitter,
  emitUiStateEvent
} from "client/lib/providers/UiStateProvider";
import { useConfirm, useConfirmRouteChange } from "components/common";


export const confirmOptions = {
  title: "Biztosan félbehagyod az űrlapkitöltést?",
  content: "Az űrlapon váloztatásokat hajtottál végre, de azokat még nem mentetted el. Az űrlap elhagyásával ezek elvesznek.",
  OKButton: "Elhagyom az űrlapot",
  classNameOK: "text-red",
  cancelButton: "Folytatom a kitöltést",
  colorCancel: "secondary",
};


const useSubmit = confirmExit => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const [enabled, setEnabled] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const lastSubmit = useRef(new Date());


  const confirm = useConfirm({
    confirmOptions,
    disabled: !confirmExit || !enabled,
  });


  useConfirmRouteChange({
    confirm,
    disabled: !confirmExit || !enabled,
  });


  const getLastSubmit = useCallback(() => lastSubmit.current, []);


  const showNotification = useCallback((success, invalid, message) => {
    const defaultMessage = invalid
      ? UiMessages.form.submit.invalidForm
      : success
        ? UiMessages.form.submit.saved
        : UiMessages.form.submit.saveError;

    if (message || defaultMessage) showMessage(message || defaultMessage);
  }, [showMessage]);


  const startSubmit = useCallback(() => enabled && emitUiStateEvent(uiStateEvents.form.submitStarted), [enabled]);


  const handleSubmitFinished = useCallback(({ ignore, success, invalid, noMessage, message, noStopLoading }) => {
    if (success) lastSubmit.current = new Date();

    if (invalid || !ignore) {
      if (!noMessage) showNotification(success, invalid, message);
      if (!noStopLoading) nextTick(() => setSubmitLoading(false));
    }
  }, [showNotification]);


  const disableSubmit = useCallback(() => setEnabled(false), []);


  const enableSubmit = useCallback(() => setEnabled(true), []);


  const onLoadingStarted = useCallback(() => setSubmitLoading(true), []);


  const stopSubmitLoading = useCallback(() => setSubmitLoading(false), []);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitFinished, handleSubmitFinished);
    uiStateEventEmitter.on(uiStateEvents.form.loadingStarted, onLoadingStarted);
    Router.events.on("routeChangeStart", disableSubmit);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitFinished, handleSubmitFinished);
      uiStateEventEmitter.off(uiStateEvents.form.loadingStarted, onLoadingStarted);
      Router.events.off("routeChangeStart", disableSubmit);
    }
  }, [disableSubmit, handleSubmitFinished, onLoadingStarted]);


  return {
    submitLoading,
    untouched: !enabled,
    disableSubmit,
    enableSubmit,
    getLastSubmit,
    startSubmit,
    stopSubmitLoading,
  };
};

export default useSubmit;
