import React from "react";
import clsx from "clsx";
import { Hidden } from "@material-ui/core";
import { ApiError } from "components/error";
import { Help } from "components/help";
import { FuseMessage } from "components/fuse";
import { Dialog, SettingsPanel } from "../common";
import PublicMenu from "./PublicMenu";


const UnauthenticatedLayout = ({ classes, WrapperComponent, settings, children, noMenu, ...props }) => (
  <div className={clsx(classes.root, "scroll-content")}>
    <div className="flex flex-1 flex-col overflow-hidden relative h-full w-full flex-grow flex-shrink items-stretch ">

      <WrapperComponent {...props}
                        header={!noMenu && (
                          <Hidden smUp>
                            <PublicMenu reduced/>
                          </Hidden>
                        )}
                        footer={!noMenu && (
                          <Hidden smUp>
                            <PublicMenu copyright/>
                          </Hidden>
                        )}
      >
        {children}
      </WrapperComponent>
      {!noMenu && (
        <Hidden xsDown>
          <PublicMenu copyright/>
        </Hidden>
      )}

      {Boolean(settings) && (
        <SettingsPanel {...props}>
          {settings}
        </SettingsPanel>
      )}

      <Dialog/>
      <Help/>

    </div>

    <FuseMessage/>

    <ApiError/>

  </div>
);

export default React.memo(UnauthenticatedLayout);
