import moment from "moment";
import { errorLogStore } from "client/lib/errorLog";
import { getSession, handleExpiredSession, isAuthenticated, validateMe } from "client/lib/session";
import { uiStateEvents, emitUiStateEvent } from "client/lib/providers/UiStateProvider";
import { SUBSCRIPTION_ALUMNUS_UPDATED } from "client/store/alumnus";


const pipeResetInterval = 60 * 1000;

const cache = {
  intervalId: null,
  client: null,
  subscription: null,
  updatedMembers: {},
};


const alreadyUpdated = (id, updated) => cache.updatedMembers[id] >= updated;


const updateMember = (id, updated, _cache = cache) => {
  // the difference between server time and client time in milliseconds
  const { serverTimeGapInMs } = getSession();

  const updateDate = moment(updated).add(serverTimeGapInMs * -1, "milliseconds").toDate();

  if (!alreadyUpdated(id, updateDate)) {
    _cache.updatedMembers[id] = updateDate;
    emitUiStateEvent(uiStateEvents.alumnus.memberUpdated, id, updateDate);
  }
};


const onUpdate = ({ data }) => {
  try {
    const { members } = data || {};
    if (members) {
      members.forEach(({ id, updated }) => updateMember(id, updated));
    }

  } catch (e) {
    errorLogStore("createStore", "onUpdate", e);
  }
};


const resetPipe = (_cache = cache) => {
  if (_cache.subscription) {
    _cache.subscription.unsubscribe();
    _cache.subscription = null;
  }
  _cache.updatedMembers = {};
};


const validateAuthenticated = async (_cache = cache) => {
  if (!_cache.client) return false;

  if (!await validateMe() && isAuthenticated()) return false;

  const { banned } = getSession();
  if (banned) return false;

  handleExpiredSession().then();

  return true;
};


const resubscribe = async (_cache = cache) => {
  try {
    // in order to terminate Apollo websocket link on unauthorized state
    if (await validateAuthenticated()) return;

    resetPipe();

    const _observable = _cache.client.subscribe({
      query: SUBSCRIPTION_ALUMNUS_UPDATED,
      variables: {
        startDate: new Date(),
      },
    });

    _cache.subscription = _observable.subscribe({ next: onUpdate });

  } catch (e) {
    errorLogStore("createStore", "resubscribe", e);
  }
};


const anyIssues = () => {
  const { issues } = getSession();
  return Boolean(Object.values(issues || {}).find(issue => issue));
};


export const subscribeToAlumnusUpdated = client => {
  if (!client || anyIssues()) return null;

  cache.client = client;

  // renew subscription periodically in order
  //   1) to avoid large payload sizes which have all of alumnus ids updated since subscription's timestamp and
  //   2) to terminate Apollo websocket link on unauthorized state
  cache.intervalId = setInterval(resubscribe, pipeResetInterval);
  resubscribe().then();
};


export const unsubscribe = () => {
  if (cache.intervalId) clearInterval(cache.intervalId);
  resetPipe();
};
