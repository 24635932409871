import { useCallback, useState } from "react";


const defaultValues = {
  folded: false,
  foldedOpen: false,
  mobileOpen: false,
};


const useUiNavbar = () => {

  const [folded, setFolded] = useState(defaultValues.folded);

  const [foldedOpen, setFoldedOpen] = useState(defaultValues.foldedOpen);

  const [mobileOpen, setMobileOpen] = useState(defaultValues.mobileOpen);


  const reset = useCallback(() => {
    setFolded(defaultValues.folded);
    setFoldedOpen(defaultValues.foldedOpen);
    setMobileOpen(defaultValues.mobileOpen);
  }, []);

  const toggleMobileNavbar = useCallback(() => setMobileOpen(mobileOpen => !mobileOpen), []);

  const closeMobileNavbar = useCallback(() => setMobileOpen(false), []);

  const toggleFoldedNavbar = useCallback(() => setFolded(folded => !folded), []);

  const openFoldedNavbar = useCallback(() => setFoldedOpen(true), []);

  const closeFoldedNavbar = useCallback(() => setFoldedOpen(false), []);


  return {
    navbar: {
      folded,
      foldedOpen,
      mobileOpen,
      toggleMobileNavbar,
      closeMobileNavbar,
      toggleFoldedNavbar,
      openFoldedNavbar,
      closeFoldedNavbar,
    },
    reset,
  };
};

export default useUiNavbar;
