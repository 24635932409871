import clsx from "clsx";
import { normalizeStr } from "common/helpers";
import { labels } from "common/enums";
import { safeGroup, fullName } from "client/store/alumnus/resolvers/getters";


const toFilter = async ({ code, name, mem, grp, formTeacher, note, alumnus }) => {
  const alumnusName = alumnus && alumnus.name && fullName(alumnus.name);

  const membership = labels.membership[mem];

  const group = safeGroup({ group: grp })

  const raw = clsx(code, name, membership, group, formTeacher, note, alumnusName);
  return normalizeStr(raw, true);
};

export default toFilter;
