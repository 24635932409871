import React, { useCallback, useContext, useEffect } from "react";
import clsx from "clsx";
import nextTick from "next-tick";
import { useApolloClient } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/uiState/events";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { UPDATE_SPECIAL_ACCOUNT } from "client/store/specialAccount/mutations";
import { Form, SpecialAccount } from "components/form";
import { requiredFields, updateFields, __typename } from "components/form/specialAccount";
import { ListContext } from "components/listPages";


const EditSpecialAccount = ({ isNew, ...specialAccount }) => {

  const { dialog: { closeDialog, enableOKButton }, message: { showMessage } } = useContext(UiStateContext);

  const { refetch, resetFilters } = useContext(ListContext)


  const client = useApolloClient();


  const saveCb = useCallback(async ({ jobs, jobsCount }) => {
    if (!jobsCount) return;

    const job = jobs[__typename][0];

    const { error } = isNew
      ? await backendFetch(backendServices.account.createSpecialAccount, job)
      : await client.mutate({
        mutation: UPDATE_SPECIAL_ACCOUNT,
        variables: {
          id: job.id,
          ...(job.note ? { note: clsx(job.note) } : {})
        },
      });

    if (!error) {
      await refetch();
      resetFilters();

    } else {
      showMessage(UiMessages.form.submit.saveError);
    }

    return {
      error,
      noMessage: true,
    };
  }, [client, isNew, refetch, resetFilters, showMessage]);


  const onSubmitFinished = useCallback(({ success }) => {
    if (!success) return enableOKButton()

    closeDialog();
    nextTick(() => showMessage(UiMessages.form.submit.saved));
  }, [closeDialog, enableOKButton, showMessage]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitFinished, onSubmitFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitFinished, onSubmitFinished);
    };
  }, [onSubmitFinished]);


  return (
    <Form className="w-full md:min-w-lg"
          records={[specialAccount]}
          updateFields={updateFields}
          requiredFields={requiredFields}
          saveCb={saveCb}
          enableSubmit={enableOKButton}
    >
      <SpecialAccount isNew={isNew}
                      {...specialAccount}
      />
    </Form>
  );
};

export default React.memo(EditSpecialAccount);
