import React, { useMemo } from "react";
import clsx from "clsx";
import Link from "next/link";
import { Grid, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { FuseAnimate } from "components/fuse";
import { Scrollbars, useStyles } from "components/layout";


const ErrorBase = ({ title = 500, noStartPage, children }) => {

  const startPageHref = useMemo(() => pathNames.startPage.href(), []);

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, "h-full")}>
      <Scrollbars thumbClassName="sm:bg-black sm:opacity-25"
                  center
      >

        <div className="h-full flex flex-col items-center">
          <FuseAnimate animation="transition.expandIn" delay={200}>

            <Grid container
                  alignItems="center"
                  justify="center"
                  className="w-full h-full max-w-lg p-16"
            >
              <Grid item xs={12}
                    className="flex flex-col flex-auto flex-shrink-0 items-center justify-center text-center">

                <Typography variant="h1" color="inherit" className="font-medium mb-16">{title}</Typography>

                {Boolean(children) && (
                  <FuseAnimate delay={500}>
                    <>
                      {children}

                      {!noStartPage && (
                        <div className="mt-48">
                          <Link href={startPageHref} as={startPageHref}>
                            <a className="font-medium" rel="noopener noreferrer">Ugrás a kezdőlapra</a>
                          </Link>
                        </div>
                      )}
                    </>
                  </FuseAnimate>
                )}

              </Grid>
            </Grid>

          </FuseAnimate>
        </div>

      </Scrollbars>
    </div>
  );
};

export default React.memo(ErrorBase);
