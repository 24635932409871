import React, { useCallback, useContext } from "react";
import { Chip, Grid, Hidden, Button, Icon, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { SearchContext } from "client/lib/providers/SearchProvider";
import { helps } from "components/help";
import SortFilter from "./SortFilter";


const Toolbar = () => {

  const { help: { openHelp } } = useContext(UiStateContext);

  const { count, numPages } = useContext(SearchContext);

  const theme = useTheme();

  const screenMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const onHelpClick = useCallback(() => openHelp(helps.search), [openHelp]);

  return (
    <Grid container spacing={1} justify="space-between" alignItems="center" className="w-full pl-24 pr-8">
      <Hidden xsDown>
        {Boolean(count) && (
          <Grid item>
            <Chip label={`${count} találat ${numPages} oldalon`}
                  color="primary"
            />
          </Grid>
        )}
      </Hidden>
      <Grid item>
        <Tooltip title="Súgó a kereséssel kapcsolatos tudnivalókról" placement="bottom">
          <div>
            <Hidden xsDown>
              <Button variant="outlined"
                      color="primary"
                      size="small"
                      onClick={onHelpClick}
                      startIcon={<Icon className="text-blue-dark">help_outline</Icon>}
              >
                {screenMdUp ? "Hogyan keressek?" : "Súgó"}
              </Button>
            </Hidden>
          </div>
        </Tooltip>
      </Grid>
      <Grid item>
        <SortFilter/>
      </Grid>
    </Grid>
  );
};

export default React.memo(Toolbar);
