import { subscriptionStatuses } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { decoratedError, errors } from "common/errors";


const getIrrelevantContacts = list => {
  const unsubscribedContacts = (list && list[subscriptionStatuses.unsubscribedUnsynced.code]) || [];
  const nameChangedContacts = (list && list[subscriptionStatuses.subscribedNameUnsynced.code]) || [];
  const syncedContacts = (list && list[subscriptionStatuses.subscribedSynced.code]) || [];
  return [].concat(unsubscribedContacts, nameChangedContacts, syncedContacts);
};


const getIrrelevantEmails = irrelevantContacts => (irrelevantContacts && irrelevantContacts.map(({ email }) => email)) || [];


const getRelevantSubscriptions = ({ members = [] } = {}, irrelevantEmails = []) => members.filter(({ nls: { e: email, s: status } = {} }) => status && email && !irrelevantEmails.includes(email));


const normalizeSubscriptions = relevantSubscriptions => (relevantSubscriptions && relevantSubscriptions.map(({ id, nls: { e: email }, name: { first, last } = {} }) => ({
  email,
  id,
  first,
  last
}))) || [];


const getMissingSubcriptions = (client, subscriptions, { primaryList, secondaryList }) => {
  try {
    const inPrimary = getIrrelevantContacts(primaryList);
    const inSecondary = getIrrelevantContacts(secondaryList);
    const irrelevantContacts = [].concat(inPrimary, inSecondary);

    const irrelevantEmails = getIrrelevantEmails(irrelevantContacts);
    const relevantSubscriptions = getRelevantSubscriptions(subscriptions, irrelevantEmails);
    const normalizedSubscriptions = normalizeSubscriptions(relevantSubscriptions);

    return {
      appSubscriptions: {
        [subscriptionStatuses.missing.code]: normalizedSubscriptions,
      }
    }

  } catch (e) {
    const message = errorLogStore("octopus", "getMissingSubcriptions", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};

export default getMissingSubcriptions;
