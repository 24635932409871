import React, { useCallback, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { UiMessages } from "config/messages";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { Label } from "components/form";


const Username = ({ username }) => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const onCopy = useCallback(() => showMessage(UiMessages.password.copiedToClipboard), [showMessage]);

  return (
    <Grid item xs={12}>
      <Label label="Bejelentkezési felhasználóneved:"
             className="mb-16"
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography>{username}</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Felhasználónév másolása a vágólapra">
              <CopyToClipboard text={username} onCopy={onCopy}>
                <IconButton size="small"><FileCopy/></IconButton>
              </CopyToClipboard>
            </Tooltip>
          </Grid>
        </Grid>
      </Label>
    </Grid>
  );
};

export default React.memo(Username);
