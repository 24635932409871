import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { dateStr, safeFnOrPromise } from "common/helpers";
import { Controller, useField } from "components/form";
import { getDefaultValueProp } from "../helpers";


const DateField = ({
                     record, field, requirements, required, componentRequired = required, defaultValue, helperText,
                     className, pickerClassName, label, openToYearOnUntouched, onChangeCb, variant = "outlined", ...restProps
                   }) => {


  const [isDirty, setIsDirty] = useState(Boolean(record[field]));


  const fieldRequirements = useMemo(() => !required
    ? requirements
    : {
      ...requirements,
      required,
    }, [required, requirements]);


  const { error, name, helperTextOrError, rules } = useField({
    field,
    record,
    helperText,
    fieldRequirements,
  });


  const onChange = useCallback(([rawDate]) => {
    const date = dateStr(rawDate, "YYYY-MM-DD");

    setIsDirty(Boolean(date));

    if (onChangeCb) safeFnOrPromise(onChangeCb)(date);

    return date;
  }, [onChangeCb]);


  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}>
        <Controller
          {...restProps}
          {...getDefaultValueProp(defaultValue)}
          as={<DatePicker className={pickerClassName}/>}
          name={name}
          rules={rules}
          onChange={onChange}
          label={label}
          format="L"
          autoOk
          clearable
          clearLabel="Törlés"
          cancelLabel="Mégsem"
          todayLabel="Ma"
          variant="modal"
          inputVariant={variant}
          helperText={helperTextOrError}
          required={Boolean(componentRequired)}
          error={error}
          openTo={!openToYearOnUntouched || isDirty ? "date" : "year"}
          views={["year", "month", "date"]}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default React.memo(DateField);
