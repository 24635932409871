import { errorLogStore } from "client/lib/errorLog";
import { DELELE_REG_CODE } from "./mutations";
import { GET_REG_CODES } from "./queries";


const delRegCode = async (_root, { id }, { client }) => {
  const { error } = await client.mutate({
    mutation: DELELE_REG_CODE,
    variables: {
      id,
    },
  });
  if (error) throw new Error(error);

  try {
    const { regCodes: cachedRegCodes } = client.readQuery({ query: GET_REG_CODES });
    client.writeQuery({
      query: GET_REG_CODES,
      data: {
        regCodes: (cachedRegCodes || []).filter(({ id: cachedId }) => cachedId !== id)
      },
    });

  } catch (e) {
    errorLogStore("regCodes", "delRegCode", e);
    throw e;
  }
};

export default delRegCode;
