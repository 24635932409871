import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import { useApolloClient } from "@apollo/react-hooks";
import { maxRecordsCounts } from "config/form";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { SAVE_PHONES } from "client/store/alumnus";
import { AlumnusContext } from "components/alumnus";
import FlagIcon from "components/alumnus/FlagIcon";
import { Phone, Form } from "components/form";
import { requiredFields, updateFields, fieldAliases, newRecordValues, __typename } from "components/form/phone";


const PanelContents = {
  [__typename]: Phone,
};


export const captionCb = ({ expanded, isPrimary, prefixedId, getValue }) => {
  const phone = getValue(__typename, prefixedId, updateFields[__typename].phone);

  return expanded
    ? phone ? clsx("Telefonszám szerkesztése") : "Add meg az új telefonszámot!"
    : isPrimary ? "Kapcsolattartási telefonszám" : "További telefonszám"
};


export const titleCb = ({ prefixedId, getValue }) => clsx(getValue(__typename, prefixedId, updateFields[__typename].phone));


export const getTitleComponentCb = ({ prefixedId, getValue }) => {
  const phone = getValue(__typename, prefixedId, updateFields[__typename].phone);
  const parsed = parsePhoneNumberFromString(clsx(phone));

  return Boolean(parsed && parsed.isValid()) && (
    <div className="min-w-20 mr-8">
      <FlagIcon code={parsed.country}/>
    </div>
  );
};


const headerAlertText = "Az Alumnival való kapcsolattartás telefonszámán felül további telefonszámokat vehetsz fel a + gombbal. Mindegyik láthatóságát külön-külön állíthatod.";


const EditPhone = () => {

  const { personal: { privacy } } = useContext(SessionContext);

  const { submitLoading, disableSubmit, enableSubmit, alumnus: { id: alumnusId, phones, primaryPhone: { id: primaryPhoneId } } } = useContext(AlumnusContext);


  const client = useApolloClient();


  const newRecordValuesWPrivacy = useMemo(() => ({
    ...newRecordValues,
    [updateFields[__typename].privacy]: privacy || 0,
  }), [privacy]);


  const saveCb = useCallback(async ({ jobs, deletedIds }) => await client.mutate({
    mutation: SAVE_PHONES,
    variables: {
      phones: jobs[__typename],
      deletedIds,
      primaryPhoneId,
      alumnusId,
    },
  }), [alumnusId, client, primaryPhoneId]);


  return (
    <Form className="max-w-full"
          records={phones}
          updateFields={updateFields}
          requiredFields={requiredFields}
          fieldAliases={fieldAliases}
          disabled={submitLoading}
          disableSubmit={disableSubmit}
          enableSubmit={enableSubmit}
          saveCb={saveCb}
          newRecordTypename={__typename}
          newRecordValues={newRecordValuesWPrivacy}
          maxRecordsCount={maxRecordsCounts.phone}
          newRecordEntityStr="telefonszám"
          onErrorEntityStr="telefonszámot"
          primaryId={primaryPhoneId}
          PanelContents={PanelContents}
          captionWidth={5}
          captionCb={captionCb}
          titleCb={titleCb}
          getTitleComponentCb={getTitleComponentCb}
          HeaderAlert={headerAlertText}
    />
  );
};

export default React.memo(EditPhone);
