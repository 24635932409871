import React, { useContext, useMemo } from "react";
import Link from "next/link"
import { Typography } from "@material-ui/core";
import { accent } from "common/helpers";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { FuseAnimate } from "components/fuse";
import { getHref } from "./config";


const BannedInfo = ({ bannedIp, permanent }) => {

  const { envVars: { supportEmail, supportEmailUri } } = useContext(SessionContext);


  const href = useMemo(() => getHref("Kitiltás", supportEmailUri), [supportEmailUri]);


  return Boolean(supportEmail) && (
    <FuseAnimate delay={500}>
      <>
        <Typography variant="caption" color="textSecondary" className="w-full">
          A kitiltás azért történt, mert valamely általad&nbsp;&mdash;&nbsp;vagy a nevedben / IP címedről&nbsp;&mdash;
          &nbsp;elvégzett művelet megsértette a biztonsági protokollt (pl. ismételten hibásan megadott jelszó).
          Többszöri újbóli végrehajtás esetén a kitiltás ideje progresszíven nő, végül pedig határozatlan idejű
          kitiltás következik.
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" className="w-full mt-40">
          {permanent
            ? "A kitiltás törlését az Alumnitól kérheted,"
            : "A kitiltás a fenti időpont után feloldásra kerül. Ezután kattints az Újraellenőrzés gombra! A kitiltás törlését az Alumnitól is kérheted,"
          }
          &nbsp;{accent(supportEmail)}&nbsp;
          <Link href={href} prefetch={false}>
            <a rel="noopener noreferrer" className="my-1">{supportEmail}</a>
          </Link>
          &nbsp;email címen. Üzenetedben, kérjük,&nbsp;
          {bannedIp
            ? `mindenképpen tüntesd fel a kitiltott IP címet (${bannedIp})!`
            : "jelezd, hogy a kitiltást megelőzően melyik oldalon, milyen műveletet végeztél!"
          }
        </Typography>
      </>
    </FuseAnimate>
  );
};

export default React.memo(BannedInfo);
