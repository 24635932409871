import React, { useMemo } from "react";
import clsx from "clsx";
import { Stepper as MuiStepper, Step, StepLabel, Hidden, Typography } from "@material-ui/core";


const Stepper = ({ steps, activeStep, autoCompleteLast, className, stepperClassName }) => {

  const stepLabel = useMemo(() => steps[activeStep] && steps[activeStep].label, [activeStep, steps]);


  const smaller = useMemo(() => steps.length > 5, [steps.length]);


  const safeActiveStep = useMemo(() => autoCompleteLast && activeStep === steps.length - 1
    ? activeStep + 1
    : activeStep
    , [activeStep, autoCompleteLast, steps.length]);


  return (
    <div className={clsx("w-full mt-8 mb-24", !clsx(className).includes("sm:mb-") && "sm:mb-32", className)}>
      <MuiStepper activeStep={safeActiveStep} alternativeLabel className={clsx("p-0", stepperClassName)}>
        {steps.map(({ id, label }) => (
          <Step key={id} className="px-0">
            <StepLabel>
              <Hidden xsDown smDown={smaller}>
                <span>{label}
                </span>
              </Hidden>
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>

      <Hidden mdUp smUp={!smaller}>
        <Typography className="mt-32 text-left" variant="h6">{stepLabel}</Typography>
      </Hidden>
    </div>
  );
}

export default React.memo(Stepper);
