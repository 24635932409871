import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { apiPaths, noSSL } from "config/routing";
import { getSecurityHeaders } from "client/lib/session";


const authLink = setContext(() => ({ headers: getSecurityHeaders(true) }));


const createWsLink = (uri = apiPaths.graphqlWsUri) => new SubscriptionClient(uri,
  {
    lazy: true,
    reconnect: true,
    connectionParams: () => ({
      headers: getSecurityHeaders(),
    }),
  },
);


const createHttpLink = (uri = apiPaths.graphqlHttpUri) => {
  const credentials = noSSL ? "include" : "same-origin";
  return new HttpLink({
    uri,
    credentials,
  });
};


export const createRemoteLinks = () => {
  const wsLink = createWsLink();

  const links = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    ApolloLink.from([
      authLink,
      createHttpLink(),
    ]),
  );

  return { links, wsLink };
};
