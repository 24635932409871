import clsx from "clsx";
import slashes from "remove-trailing-slash";
import { avatarMinSize } from "config/image";
import { apiPaths } from "config/routing";


const fileExtension = "jpg";


const getURL = fileId => {
  const normalizedFileId = clsx(fileId).trim();
  if (!normalizedFileId) return null;

  const filename = `${fileId}.${fileExtension}`;
  const woTrailingSlash = slashes(apiPaths.mediaUrl);
  return `${woTrailingSlash}/download/${filename}`;
};


const prefetchImage = src => {
  if (src && process.browser) {
    const wrapper = {};
    wrapper.img = new Image();
    wrapper.img.src = `${src}?size=${avatarMinSize}`;
    delete wrapper.img;
  }
};


const src = ({ fileId }) => {
  if (!fileId) return null;
  const url = getURL(fileId) || null;
  prefetchImage(url);
  return url;
};


const alumnus_picture = {
  src,
};

export default alumnus_picture;
