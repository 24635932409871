import React from "react";
import { Form } from "components/form";
import * as addressForm from "components/form/address";
import * as emailForm from "components/form/email";
import * as phoneForm from "components/form/phone";
import * as editAddress from "components/alumnus/edit/contactTab/EditAddress";
import * as editEmail from "components/alumnus/edit/contactTab/EditEmail";
import * as editPhone from "components/alumnus/edit/contactTab/EditPhone";


const gridProps = {
  sm: 12,
  md: 12,
  className: "max-w-full",
};


const PanelContents = {
  [emailForm.__typename]: emailForm.Email,
  [phoneForm.__typename]: phoneForm.Phone,
  [addressForm.typenames.address]: addressForm.Address,
};


const updateFields = {
  ...emailForm.updateFields,
  ...phoneForm.updateFields,
  [addressForm.typenames.address]: addressForm.updateFields[addressForm.typenames.address],
};


const requiredFields = {
  ...emailForm.requiredFields,
  ...phoneForm.requiredFields,
  [addressForm.typenames.address]: addressForm.requiredFields[addressForm.typenames.address],
};


const fieldAliases = {
  ...emailForm.fieldAliases,
  ...phoneForm.fieldAliases,
  ...addressForm.fieldAliases,
};


const captionCb = ({ __typename, ...args }) => {
  if (__typename === emailForm.__typename) return editEmail.captionCb({ __typename, ...args });
  if (__typename === phoneForm.__typename) return editPhone.captionCb({ __typename, ...args });
  if (__typename === addressForm.typenames.address) return editAddress.captionCb({ __typename, ...args });
};


export const titleCbFactory = countries => ({ __typename, ...args }) => {
  if (__typename === emailForm.__typename) return editEmail.titleCb({ __typename, ...args });
  if (__typename === phoneForm.__typename) return editPhone.titleCb({ __typename, ...args });
  if (__typename === addressForm.typenames.address) return editAddress.titleCb(countries)({ __typename, ...args });
};


export const getTitleComponentCb = ({ __typename, ...args }) => {
  if (__typename === emailForm.__typename) return editEmail.getTitleComponentCb({ __typename, ...args });
  if (__typename === phoneForm.__typename) return editPhone.getTitleComponentCb({ __typename, ...args });
  if (__typename === addressForm.typenames.address) return editAddress.getTitleComponentCb({ __typename, ...args });
};


const EditContact = ({ countries, address, primaryEmail, primaryPhone, saveCb, loading, disableSubmit, enableSubmit }) => (
  <Form className="max-w-md md:max-w-lg lg:max-w-xl"
        records={[primaryEmail, primaryPhone, address]}
        updateFields={updateFields}
        requiredFields={requiredFields}
        fieldAliases={fieldAliases}
        disabled={loading}
        disableSubmit={disableSubmit}
        enableSubmit={enableSubmit}
        saveCb={saveCb}
        PanelContents={PanelContents}
        captionWidth={5}
        captionCb={captionCb}
        titleCb={titleCbFactory(countries)}
        getTitleComponentCb={getTitleComponentCb}
        gridProps={gridProps}
        issue
  />
);

export default React.memo(EditContact);
