import gql from "graphql-tag";


export const GET_STUDY_INSTITUTIONS = gql`
  query GetStudyInstitutions {
    options: view_study_institution(order_by: {occurrences: desc_nulls_last, institution: asc_nulls_last}) {
      value: institution
      cnt: occurrences
    }
  }
`;


export const GET_STUDY_DEGREES = gql`
  query GetStudyDegrees {
    options: view_study_degree(order_by: {occurrences: desc_nulls_last, degree: asc_nulls_last}) {
      value: degree
      cnt: occurrences
    }
  }
`;
