import React, { useContext, useMemo } from "react";
import dynamic from "next/dynamic";
import { dbEnums } from "common/enums";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { AlumnusContext } from "components/alumnus";
import ProfileTab from "./profileTab/ProfileTab";

const AlumniTab = dynamic(() => import("./alumniTab/AlumniTab"));
const LogsTab = dynamic(() => import("./accountTab/AccountTab"));


const ProfileContent = () => {

  const { account: { roles } } = useContext(SessionContext);

  const { alumnus, myProfile, page: selectedTab } = useContext(AlumnusContext);


  const adminMode = useMemo(() => roles.includes(dbEnums.accountRoles.MANAGER), [roles]);


  return (
    <div className="p-16 sm:p-24">
      {selectedTab === 0 &&
      (
        <ProfileTab alumnus={alumnus}/>
      )}
      {selectedTab === 1 && (adminMode || myProfile) &&
      (
        <AlumniTab alumnus={alumnus}/>
      )}
      {selectedTab === 2 && adminMode &&
      (
        <LogsTab alumnus={alumnus}/>
      )}
    </div>
  );
};

export default React.memo(ProfileContent);
