import { useCallback, useState } from "react";


const useUiScroll = () => {

  const [scrollbarRef, setScrollbarRef] = useState(null);


  const refCb = useCallback(scrollbar => setScrollbarRef(scrollbar), []);

  const scrollTo = useCallback((left = 0, top = 0) => {
    const { view } = scrollbarRef || {};

    if (view.scrollTo) view.scrollTo(left, top);
  }, [scrollbarRef]);


  return {
    scroll: {
      refCb,
      scrollTo,
    },
  };
};

export default useUiScroll;
