import React, { useMemo } from "react";
import moment from "moment";
import Link from "next/link";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { MiniChip } from "components/common";
import RowControl from "./RowControl";


const Row = revisionRequest => {

  const { created, required, alumnusId, fullName, lastRevision, note, involved, completed, revised, notRevised } = revisionRequest;

  const completedPercent = useMemo(() => ((completed || 0) * 100).toFixed(0), [completed]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <RowControl {...revisionRequest}/>
      </Grid>
      <Grid item>
        <Typography variant="caption" className="sm:min-w-64">
          Létrehozva: {moment(created).format("L")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" className="sm:min-w-256">
          Célcsoport: akik {moment(lastRevision).format("L")} óta nem ellenőrizték adataikat
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <MiniChip label={required ? "Kötelező" : "Opcionális"}
                      colorClassName={required ? "bg-red" : "bg-blue"}
            />
          </Grid>
          <Grid item>
            <MiniChip label={`${involved} érintett`}
                      colorClassName="bg-blue"
            />
          </Grid>
          <Grid item>
            <MiniChip label={`${notRevised} függőben`}
                      colorClassName={notRevised ? "bg-orange" : "bg-green"}
            />
          </Grid>
          <Grid item>
            <MiniChip label={`${revised} teljesült (${completedPercent}%)`}
                      colorClassName={revised ? "bg-green" : "bg-orange"}
            />
          </Grid>
        </Grid>
      </Grid>
      {Boolean(alumnusId) && (
        <Grid item>
          <Typography variant="caption">
            <Link href={pathNames.alumnus.href} as={pathNames.alumnus.as(alumnusId)}>
              <Tooltip title="Az adategyeztetési kört létrehozó felhasználó">
                <a className="cursor-pointer">{fullName}</a>
              </Tooltip>
            </Link>
          </Typography>
        </Grid>
      )}
      {Boolean(note) && (
        <Grid item>
          <Typography variant="caption">{note}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Row);
