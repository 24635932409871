import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import Link from "next/link";
import * as PropTypes from "prop-types";
import { useRouter } from "next/router"
import { Icon, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import FuseNavBadge from "./FuseNavBadge";


const useStyles = makeStyles(theme => ({
  item: {
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingRight: 12,
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + "!important",
      pointerEvents: "none",
      transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
      "& .list-item-text-primary": {
        color: "inherit"
      },
      "& .list-item-icon": {
        color: "inherit"
      }
    },
    "& .list-item-icon": {},
    "& .list-item-text": {},
    color: theme.palette.text.primary,
    cursor: "pointer",
    textDecoration: "none!important"
  }
}));


const FuseNavVerticalItem = props => {

  const { item, nestedLevel } = props;

  const { pathname, asPath, query: { from } = {} } = useRouter() || {};

  const { navbar: { closeMobileNavbar } } = useContext(UiStateContext);


  const isActive = useMemo(() => {
    const itemPath = item && (item.as || item.href);
    const regex = item.isActiveRegex || new RegExp(`^${itemPath}[/]?`);

    if (!regex || !itemPath || item.href !== pathname) return false;

    return Boolean([asPath, from].some(path => regex.test(path)));
  }, [item, pathname, asPath, from]);


  const classes = useStyles(props);


  let paddingValue = 40 + (nestedLevel * 16);
  const listItemPadding = nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-24";

  return !item.disallowed && (
    <Link href={item.href} as={item.as || item.href}>
      <a rel="noopener noreferrer" role="button">
        <ListItem
          button
          className={clsx(classes.item, listItemPadding, "list-item", clsx(isActive && "active"))}
          onClick={closeMobileNavbar}
        >
          {item.icon && (
            <Icon className="list-item-icon text-16 flex-shrink-0 mr-16" color="action">{item.icon}</Icon>
          )}
          <ListItemText className="list-item-text" primary={item.title}
                        classes={{ primary: "text-14 list-item-text-primary" }}/>
          {Boolean(item.badge) && (
            <FuseNavBadge badge={item.badge}/>
          )}
        </ListItem>
      </a>
    </Link>
  );
};

FuseNavVerticalItem.propTypes = {
  item: PropTypes.shape(
    {
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      icon: PropTypes.string,
      url: PropTypes.string,
    })
};

FuseNavVerticalItem.defaultProps = {};

export default React.memo(FuseNavVerticalItem);
