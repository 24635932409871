import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useDebounce } from "use-debounce";
import { FormControl, Icon, IconButton, Input } from "@material-ui/core";


const defaultInitValue = "";


const TextFilter = ({
                      filterId, filterValue, setFilter, className, textClassName, Component = Input, inputClassName,
                      initValue = defaultInitValue, disableEndAndorment, debounce = 700, placeholder = "Szűrés", ...restProps
                    }) => {


  const [localValue, setLocalValue] = useState(filterValue || defaultInitValue);

  const [debouncedValue] = useDebounce(localValue, debounce || 0);


  const onChange = useCallback(event => {
    const value = event.target.value || defaultInitValue;
    setLocalValue(value);
  }, []);


  const reset = useCallback(() => setLocalValue(initValue), [initValue]);


  const endAdornment = useMemo(() => (
    <IconButton onClick={reset}>
      <Icon>clear</Icon>
    </IconButton>
  ), [reset]);


  useEffect(() => {
    setFilter(filterId, debouncedValue);
  }, [setFilter, filterId, debouncedValue]);


  return (
    <FormControl className={className}>
      <Component
        {...restProps}
        {...(!disableEndAndorment && filterValue ? { endAdornment } : {})}
        value={localValue}
        onChange={onChange}
        className={clsx(textClassName)}
        placeholder={placeholder}
        inputProps={{
          className: clsx(!clsx(inputClassName).includes("py") && "py-6", inputClassName),
        }}
      />
    </FormControl>
  );
};

export default React.memo(TextFilter);
