import gql from "graphql-tag";


export const GET_SEARCH_IDS = gql`
  query GetSearchIds($searchText: String = "", $category: search_category = "ALL", $yearFrom: Int = 0, $yearTo: Int = 9999, $orderBy: [_sr_order_by!]) {
    ids: alumni_search_ids(args: {search_string: $searchText, category: $category, year_from: $yearFrom, year_to: $yearTo}, order_by: $orderBy) {
      id
      ng: in_name_group
      ft: in_form_teacher
      c: in_contact
      h: in_hobby
      x: in_discount
      p: in_professional
      r: relevance
    }
  }
`;


export const GET_EMPTY_SEARCH = gql`
  query GetEmptySearch($yearFrom: Int = 0, $yearTo: Int = 9999, $orderBy: [_es_order_by!], $discount: Boolean = false) {
    ids: alumni_empty_search(args: {year_from: $yearFrom, year_to: $yearTo, only_discount: $discount}, order_by: $orderBy) {
      id
    }
  }
`;
