import { dbEnums } from "common/enums";
import { withCapital } from "common/helpers";
import { profileHelps } from "components/help/helps/profile";
import texts from "./texts";


export const meAId = "me";

export const modes = {
  profile: "profile",
  edit: "edit",
};

export const basePath = "/alumnus";

export const defaultPage = 0;

export const defaultTab = 0;


export const editModeAllowedRoles = [dbEnums.accountRoles.MANAGER];


export const skipOnlyForMeRoles = [dbEnums.accountRoles.STUDENT, dbEnums.accountRoles.MANAGER];


export const alumnusRouteConfig = {
  [modes.profile]: [
    {
      title: "Adatlap",
    },
    {
      title: "Alumni",
      allowedOnlyForMe: true,
    },
    {
      title: "Fiók",
      allowedRoles: [dbEnums.accountRoles.MANAGER],
    },
  ],
  [modes.edit]: [
    {
      title: "Elérhetőségek",
      icon: "email",
      tabs: [
        {
          title: "Email",
          help: profileHelps.email,
        },
        {
          title: "Telefon",
          help: profileHelps.phone,
        },
        {
          title: "Cím",
          help: profileHelps.address,
        },
        {
          title: "Web",
          help: profileHelps.social,
        },
      ],
    },
    {
      title: "Név és profilkép",
      icon: "account_circle",
      tabs: [
        {
          title: "Név",
        },
        {
          title: "Profilkép",
          help: profileHelps.avatar,
        },
      ],
    },
    {
      title: "Kapcsolatépítés",
      icon: "people_outline",
      tabs: [
        {
          title: "Munka",
          help: profileHelps.job,
        },
        {
          title: "Képzés",
          help: profileHelps.study,
        },
        {
          title: "Hobbi",
          help: profileHelps.hobby,
        },
        {
          title: "Együttműködés",
          help: profileHelps.cooperation,
        },
      ],
    },
    {
      title: "Feliratkozások",
      icon: "announcement",
      tabs: [
        {
          title: "Feliratkozások",
          help: profileHelps.news,
        },
      ],
    },
    {
      title: "Alumni",
      icon: "contact_mail",
      allowedRoles: [dbEnums.accountRoles.MANAGER],
      tabs: [
        {
          title: "Hivatalos",
          help: profileHelps.org,
        },
        {
          title: withCapital(texts.cardName),
          help: profileHelps.card,
        },
        {
          title: "Fiók",
          hiddenSubmit: true,
          help: profileHelps.account,
        },
      ],
    },
  ],
};
