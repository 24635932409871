import clsx from "clsx";
import { removeMetaKeys, doMutation } from "client/store/helpers";
import { UPSERT_SOCIALS, DELETE_SOCIALS } from "client/store/alumnus";


const normalizeRecord = async (client, { account: rawAccount, id, ...data }, { alumnusId }) => {
  const account = clsx(rawAccount).trim();

  return {
    alumnus__id: alumnusId,
    ...(id ? { id } : {}),
    ...data,
    account,
  };
};


const normalizeRecords = async (client, { socials, ...args }) => {
  const normalizedRecords = [];

  for (let social of (socials || [])) {
    const normalizedRecord = await normalizeRecord(client, social, args);
    normalizedRecords.push(normalizedRecord);
  }

  return normalizedRecords;
};


const getRecords = (records, deletedIds) => (records || [])
  .filter(({ id }) => !(deletedIds || []).includes(id))
  .map(removeMetaKeys);


const saveSocials = async (_root, { deletedIds, ...args }, { client }) => {
  const normalizedRecords = await normalizeRecords(client, args);

  const records = getRecords(normalizedRecords, deletedIds);
  if (records && records.length) await doMutation(client, UPSERT_SOCIALS, {
    records
  });

  if (deletedIds && deletedIds.length) await doMutation(client, DELETE_SOCIALS, {
    ids: deletedIds
  });
};

export default saveSocials;
