import React from "react";
import { Typography } from "@material-ui/core";
import { maxRecordsCounts } from "config/form";


const Email = () => (
  <>
    <Typography className="mb-20">
      Az Alumni az itt megadott <b>kapcsolattartási email címen</b> tudja felvenni veled a kapcsolatot. Egyúttal <b>ezzel
      az email címmel tudsz bejelentkezni</b> az Alumni Online! felületére.
    </Typography>
    <Typography className="my-20">
      Annak ellenőrzése végett, hogy biztosan nem történt elírás, a <i>kapcsolattartási
      email cím</i> megadása/változtatása email-ben kiküldött visszaigazoló kódokkal történik. A kód
      (újbóli) kiküldését a gombra kattintva kezdeményezheted. Az így kiküldött kódot <b>írd be az email cím alatti
      kód mezőbe</b>! A helyesen beírt vagy bemásolt kód <span className="text-blue">azonnal, automatikusan ellenőrzésre kerül</span>.
      Ha a beírt/bemásolt kód helyes, akkor a kód szövegmező <span className="text-blue">magától eltűnik</span>. Ha nem
      megfelelő a megadott kód, akkor a kód szövegmező alatt megjelenik az erre vonatkozó hibaüzenet.
    </Typography>
    <Typography className="my-20">
      A kapcsolattartási email címen kívül <b>további email címeket</b> is megoszthatsz az alumnusokkal
      (legfeljebb {maxRecordsCounts.email} további email cím hozható létre). Ezek megadásakor/változtatásakor nincs
      szükség megerősítő kódra. Az adott további email címeket a <span className="text-blue">Törlés</span> gombra
      kattintva törölheted. (A kapcsolattartási email cím nem törölhető).
    </Typography>
    <Typography className="my-20">
      Az email címekhez <b>megjegyzést írhatsz, valamint kulcsszavakat is megadhatsz</b>, ezzel közölve az email-es
      kapcsolattartásra vonatkozó kéréseidet, körülményeket, tudnivalókat.
    </Typography>
    <Typography className="my-20">
      Az egyes email címek mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Az adott adatvédelmi beállítás értelemszerűen csak a megfelelő email címre
      vonatkozik. Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
    <Typography className="my-20">
      Egy-egy email címet a <span className="text-blue">Törlés</span> gombra kattintva <b>törölhetsz</b> (kivéve a
      kapcsolattartási email címet, amely nem törölhető).
    </Typography>
    <Typography className="mt-20">
      A <span className="text-blue">hírlevél kézbesítési címe</span> ezen a lapon csak tájékoztatásként
      szerepel. Az <span className="text-blue">Ugrás a hírlevél beállításaihoz</span> gombra kattintva
      a <i>feliratkozásokhoz</i> navigálhatsz, ahol &ndash; többek között &ndash; a kézbesítési címet is
      megadhatod.&nbsp;<b>Amikor például megváltoztatod a kapcsolattartási email címedet, ellenőrizd, hogy nem
      szeretnéd-e egyúttal a hírlevél kézbesítési email címedet is megváltoztatni</b>!
    </Typography>
  </>
);

export default React.memo(Email);
