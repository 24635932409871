import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { VelocityTransitionGroup } from "velocity-react";
import { isClientSide } from "common/helpers";


const enterAnimationDefaults = {
  animation: "transition.fadeIn",
  stagger: 50,
  duration: 200,
  display: null,
  visibility: "visible",
  delay: 0
};

const leaveAnimationDefaults = {
  stagger: 50,
  duration: 200,
  display: null,
  visibility: "visible",
  delay: 0
};

const FuseAnimateGroup = props => {
  useEffect(() => {
    if (isClientSide()) require("velocity-animate/velocity.ui");
  });

  const newProps = {
    ...props,
    enter: enterAnimationDefaults,
    leave: leaveAnimationDefaults,
  };

  return (
    <VelocityTransitionGroup {...newProps}>
      {props.children}
    </VelocityTransitionGroup>
  );
};

FuseAnimateGroup.propTypes = {
  children: PropTypes.any
};

FuseAnimateGroup.defaultProps = {
  enter: enterAnimationDefaults,
  leave: leaveAnimationDefaults,
  easing: [0.4, 0.0, 0.2, 1],
  runOnMount: true,
  enterHideStyle: {
    visibility: "visible"
  },
  enterShowStyle: {
    visibility: "hidden"
  }
};

export default React.memo(FuseAnimateGroup);
