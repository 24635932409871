import React, { useCallback, useContext } from "react";
import { logout } from "client/lib/session";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { useConfirm } from "components/common";
import { CenterCardWrapper, UnauthenticatedLayout, useStyles } from "components/layout";
import { FuseAnimate } from "components/fuse";
import { confirmOptions } from "./config";
import useIssues from "./useIssues";
import Stepper from "./Stepper";
import Bar from "./Bar";


const IssuesLayout = props => {

  const { anyIssues } = useContext(SessionContext);


  const { steps, step, activeStep, bar, submitLoading, ...restSteps } = useIssues();


  const confirm = useConfirm({ confirmOptions });


  const onExit = useCallback(() => confirm(logout), [confirm]);


  const classes = useStyles();


  return anyIssues && (
    <UnauthenticatedLayout {...props}
                           WrapperComponent={CenterCardWrapper}
                           wrapperClassName="max-w-full sm:max-w-2xl lg:max-w-3xl xl:max-w-4xl"
                           loading={submitLoading}
                           authenticated
                           classes={classes}
                           noMenu
    >

      {steps.length > 1 && (
        <Stepper steps={steps}
                 activeStep={activeStep}
        />
      )}

      {Boolean(step && step.ContentComponent) && (
        <FuseAnimate animation="transition.expandIn">
          <step.ContentComponent submitLoading={submitLoading}
                                 {...restSteps}
          />
        </FuseAnimate>
      )}

      <Bar {...bar}
           stepsCount={steps.length}
           onExit={onExit}
      />

    </UnauthenticatedLayout>
  );
};

export default React.memo(IssuesLayout);
