import ApolloClient from "apollo-client";
import { uiStateEvents, emitUiStateEvent } from "client/lib/providers/UiStateProvider";
import { createLink } from "./link";
import { getCache, loadDefaultCache } from "./cache";
import { subscribeToAlumnusUpdated, unsubscribe } from "./update";


const resources = {
  client: null,
  cache: null,
  resolvers: null,
  fetch: null,
  wsLink: null,
};


const clearClient = () => {
  delete resources.client;
  resources.client = null;
};


export const destroyStore = (_resources = resources) => {
  unsubscribe();

  if (_resources.wsLink) _resources.wsLink.close();

  if (_resources.client) {
    _resources.client.stop();
    _resources.client.clearStore()
      .then(clearClient)
      .catch(clearClient);
  }
};


const getResources = (_resources = resources) => {
  if (!_resources.cache) _resources.cache = getCache();

  // cannot import at top level of module because of circular reference
  if (!_resources.resolvers) {
    const { default: resolvers } = require("./resolvers");
    if (resolvers) _resources.resolvers = resolvers;
  }

  // import fetch polyfill only at client side
  if (!_resources.fetch) {
    const { fetch: fetchPolyfill } = require("whatwg-fetch");
    _resources.fetch = fetchPolyfill;
  }
};


const getClient = (_resources = resources) => {
  const { link, wsLink } = createLink();

  const client = new ApolloClient({
    ssrMode: false,
    assumeImmutableResults: true,
    link,
    cache: _resources.cache,
    resolvers: _resources.resolvers,
    fetch: _resources.fetch,
  });

  _resources.client = client;
  _resources.wsLink = wsLink;

  return client;
};


export const createStore = () => {
  destroyStore();

  getResources();

  emitUiStateEvent(uiStateEvents.session.uiStateReseted);
  emitUiStateEvent(uiStateEvents.search.reseted);

  const client = getClient();

  loadDefaultCache();

  subscribeToAlumnusUpdated(client);

  return client;
};
