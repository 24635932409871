import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import { accent } from "common/helpers";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { VerificationHelpEmailLink } from "components/form/verification";


const Verification = ({ phone }) => {

  const { envVars: { supportEmail } } = useContext(SessionContext);

  const entity = phone ? "telefonszám" : "email cím";
  const aAn = phone ? "a" : "az";
  const AAn = phone ? "A" : "Az";
  const subject = phone ? "telefonszámot" : "email címet";
  const withAnother = phone ? "telefonszámával" : "email címével";
  const transfer = phone ? "SMS" : "email";
  const byTransfer = phone ? "SMS-ben" : "email-ben";

  return (
    <>
      <Typography>
        A hivatalos Alumni kapcsolattartási {entity} megerősítésére az alábbiak miatt van szükség:
      </Typography>
      <div>
        <ul className="my-10">
          <li className="mb-8">Megerősíti, hogy {aAn} {subject} helyesen adtad meg, ezáltal a kapcsolattartást nem
            nehezítik majd véletlen elírások.
          </li>
          <li className="mb-8">Segít megbizonyosodni arról, hogy olyan {subject} adtál meg, amelyet valóban Te
            használsz.
          </li>
          <li className="mb-8">Segít azon visszaélések ellen, amikor valaki más {withAnother} szeretne regisztrálni,
            vagy később az adatlapján azt feltüntetni.
          </li>
          <li className="mb-8">A regisztrációnál - többfaktoros megerősítésként - csökkenti a fiktív adatokkal történő
            regisztráció esélyét.
          </li>
        </ul>
      </div>
      <Typography className="my-20">
        {AAn} {entity} megerősítése <b>{byTransfer} kiküldött kód</b> segítségével történik. A kód
        (újbóli) kiküldését a gombra kattintva kezdeményezheted. Az így kiküldött kódot <b>írd be {aAn} {entity} alatti
        kód mezőbe</b>! A helyesen beírt vagy bemásolt kód <span className="text-blue">azonnal, automatikusan ellenőrzésre kerül</span>.
        Ha a beírt/bemásolt kód helyes, akkor a kód szövegmező <span className="text-blue">magától eltűnik</span>. Ha
        nem megfelelő a megadott kód, akkor a kód szövegmező alatt megjelenik az erre vonatkozó hibaüzenet.
      </Typography>
      <Typography className="my-20">
        Az <i>adatlap szerkesztésénél</i> <b>{aAn} {entity} megerősítése azonban még nem jelenti az űrlap
        mentését</b>.&nbsp;{AAn} {entity} tényleges mentéséhez, a megerősítést - és az űrlap egyéb
        mezőinek szerkesztését -
        követően még a <i>Mentés</i> gombra is rá kell kattintani.
      </Typography>
      <Typography className="mt-20">
        Előfordulhat, hogy a megerősítő kódot tartalmazó {transfer} csak pár perc késéssel, rosszabb esetben egyáltalán
        nem kerül kézbesítésre. Amennyiben ezt tapasztalod:
      </Typography>
      <div>
        <ol className="my-10">
          <li className="mb-8">Először ellenőrizd, hogy nem történt-e elírás {aAn} {entity} megadásakor!</li>
          {!phone && (
            <li className="mb-8">Ellenőrizd, hogy a levél nem került-e az email fiókod spam/levélszemét vagy promóciók
              mappájába!</li>
          )}
          <li className="mb-8">Kérjük, várj türelemmel! Ha néhány percen belül nem érkezik meg az {transfer}, kérj új
            kódot!
          </li>
          <li className="mb-8">Ha a 2. próbálkozás után sem kaptad meg az {transfer}-t, próbálkozz újra néhány órával
            később! Kérjük, ne kezdeményezz sorozatos kiküldést! Az email/SMS számos szerveren keresztül érkezik a
            postfiókodba/telefonodra; ezek bármelyikében előfordulhat átmeneti késleltetés. Ilyenkor az újbóli kiküldés
            nem célravezető.
          </li>
          <li>Ha a kézbesítés mindezek után sem történik meg, jelezd {accent(supportEmail)}&nbsp;
            <VerificationHelpEmailLink/>&nbsp;címen!
          </li>
        </ol>
      </div>
    </>
  );
};

export default React.memo(Verification);
