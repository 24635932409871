import React from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { ApiError } from "components/error";
import { Help } from "components/help";
import { Suspense } from "components/common";
import { FuseMessage } from "components/fuse";
import { Dialog, SettingsPanel } from "../common";
import { Navbar } from "./navbar";
import ToolbarLayout from "./ToolbarLayout";
import Footer from "./Footer";


const AuthenticatedLayout = ({ classes, loading, WrapperComponent, settings, children, ...props }) => (
  <div className={clsx(classes.root, "scroll-content")}>
    <div className="flex flex-1 flex-col overflow-hidden relative">

      <div className={classes.wrapper}>

        <Navbar/>

        <div className={classes.contentWrapper}>

          <ToolbarLayout/>

          <Suspense loading={Boolean(loading)}>
            <div className="flex flex-1 flex-col items-stretch overflow-hidden relative h-full">
              <WrapperComponent {...props}>
                {children}

                <Grid item xs={12}>
                  <Footer/>
                </Grid>
              </WrapperComponent>
            </div>
          </Suspense>

          {Boolean(settings) && (
            <SettingsPanel {...props}>
              {settings}
            </SettingsPanel>
          )}

          <Dialog/>
          <Help/>

        </div>

      </div>
    </div>

    <FuseMessage/>
    <ApiError/>

  </div>
);

export default React.memo(AuthenticatedLayout);
