import React, { useMemo } from "react";
import { Divider, List } from "@material-ui/core";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import FuseNavVerticalGroup from "./FuseNavVerticalGroup";
import FuseNavVerticalCollapse from "./FuseNavVerticalCollapse";
import FuseNavVerticalItem from "./FuseNavVerticalItem";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
  navigation: {},
  verticalNavigation: {
    "&.active-square-list": {
      "& .list-item, & .active.list-item": {
        width: "100%",
        borderRadius: "0"
      },
    },
    "&.dense": {
      "& .list-item": {
        paddingTop: 0,
        paddingBottom: 0,
        height: 32
      },
    },
  },
  "@global": {
    ".popper-navigation-list": {
      "& .list-item": {
        padding: "8px 12px 8px 12px",
        height: 40,
        minHeight: 40,
        "& .list-item-text": {
          padding: "0 0 0 8px"
        },
      },
      "&.dense": {
        "& .list-item": {
          minHeight: 32,
          height: 32,
          "& .list-item-text": {
            padding: "0 0 0 8px"
          },
        },
      },
    },
  },
}));


export const minGroupCnt = 2;


const FuseNavigation = props => {

  const { navigation, active, dense, className } = props;

  const classes = useStyles(props);


  const groupCnt = useMemo(() => (navigation || []).filter(item => item.type === "group").length, [navigation]);


  return Boolean(navigation.length) && (
    <List
      className={clsx("navigation whitespace-no-wrap", classes.navigation, classes.verticalNavigation, `active-${active}-list`, dense && "dense", className)}>
      {
        navigation.map(item => !item.disallowed && (

          <React.Fragment key={item.id}>

            {item.type === "group" && (
              <FuseNavVerticalGroup item={item} nestedLevel={0} groupCnt={groupCnt}/>
            )}

            {item.type === "collapse" && (
              <FuseNavVerticalCollapse item={item} nestedLevel={0}/>
            )}

            {(!item.type || item.type === "item") && (
              <FuseNavVerticalItem item={item} nestedLevel={0}/>
            )}

            {item.type === "divider" && (
              <Divider className="my-16"/>
            )}
          </React.Fragment>
        ))
      }
    </List>
  );
};

FuseNavigation.propTypes = {
  navigation: PropTypes.array.isRequired
};

export default React.memo(FuseNavigation);
