import React, { useMemo, useState, useEffect } from "react";
import Bowser from "bowser";
import { infoURL, platformInfo } from "./platforms";


const OutdatedBrowser = () => {

  const [description, setDescription] = useState(null);

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    const osName = browser.getOSName();
    const description = browserName && osName && `${browserName} ${browser.getBrowserVersion()} (${osName} ${browser.getOSVersion()})`;
    setDescription(description);
  }, []);

  const requirements = useMemo(() => Object.entries(platformInfo || {}).reduce((acc, [key, { name, min }]) => {
    if (acc.names.includes(name)) return acc;
    const item = (
      <li key={key}><b>{name}</b>: <b className="text-red">{min}</b> vagy újabb</li>
    );
    return {
      rs: [...acc.rs, item],
      names: [...acc.names, name]
    };
  }, { rs: [], names: [] }).rs, []);

  return (
    <div className="flex flex-col flex-wrap m-16 text-14">
      <div className="mb-16">
        Az <i>Alumni Online!</i> alkalmazás ezen az eszközön e böngésző verzióval nem használható. Kérjük, frissítsd a
        böngészőt, vagy próbálkozz másik böngészőből vagy eszközről.
      </div>
      {requirements && (
        <div>
          <div>A böngésző verziójára vonatkozó követelmények:</div>
          <ul>
            {requirements}
          </ul>
        </div>
      )}
      {description && (
        <>
          <div className="mt-8 mb-2">Észlelt böngésző:</div>
          <div><i>{description}</i></div>
        </>
      )}
      <div className="mt-12">
        További információ: <a href={infoURL} rel="noopener noreferrer" target="_blank">{infoURL}</a>
      </div>
    </div>
  );
};

export default React.memo(OutdatedBrowser);
