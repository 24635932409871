import React, { useMemo } from "react";
import clsx from "clsx";
import { countries } from "i18n-iso-countries/langs/hu";
import { Tooltip } from "@material-ui/core";


const Flagicon = ({ code, className, disableHun, disableTooltip }) => {

  const lowerCode = useMemo(() => clsx(code).toLowerCase(), [code]);

  const upperCode = useMemo(() => clsx(code).toUpperCase(), [code]);


  const icon = useMemo(() => (
    <span className={clsx("flag-icon", `flag-icon-${lowerCode}`, className)}/>
  ), [className, lowerCode]);


  const tooltip = useMemo(() => upperCode && countries[upperCode], [upperCode]);


  if (!upperCode || (disableHun && upperCode === "HU")) return null;


  return !disableTooltip && tooltip
    ? (
      <Tooltip title={tooltip}>
        {icon}
      </Tooltip>
    )
    : icon;
};

export default React.memo(Flagicon);
