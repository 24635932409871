import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { Button, Icon, Typography, Grid } from "@material-ui/core";
import { alumnusRouteConfig, modes } from "config/profile";
import { useFilterByRoles } from "client/lib/session";
import { FuseAnimate } from "components/fuse";
import { AlumnusAvatar } from "components/alumnus/common";
import { ButtonSelect } from "components/form";
import { AlumnusContext } from "components/alumnus";
import HeaderName from "../HeaderName";


const EditHeader = () => {

  const { alumnus, setRoute, page, setPage, tabConfig, untouched, startSubmit } = useContext(AlumnusContext);


  const editPages = useFilterByRoles({
    config: alumnusRouteConfig[modes.edit],
    childrenKey: "tabs",
  });


  const buttonOptions = useMemo(() => editPages.filter(({ disallowed }) => !disallowed), [editPages]);


  const goToProfile = useCallback(() => setRoute({
    editMode: false,
  }), [setRoute]);


  const onMenuClick = useCallback(value => value !== page && setPage(value), [setPage, page]);


  return (
    <Grid container spacing={2} alignItems="center" className="my-8 sm:my-12">

      <Grid item xs={12}>
        <a rel="noopener noreferrer" role="button" className="cursor-pointer" onClick={goToProfile}>
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <div className="flex flex-row items-center text-white">
              <Icon className="mr-4 text-20">arrow_back</Icon>
              <div>
                Adatlap megtekintése
              </div>
            </div>
          </FuseAnimate>
        </a>
      </Grid>

      <Grid item xs={12} container spacing={1} alignItems="center" wrap="nowrap">
        {alumnus.img && alumnus.img.src && (
          <Grid item>
            <AlumnusAvatar alumnus={alumnus} rounded={4} avatarClassName="mr-16" size={72} noButton/>
          </Grid>
        )}
        <HeaderName alumnus={alumnus}>
          <div className="hidden sm:block">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography variant="caption">Profil szerkesztése &ndash; {clsx(tabConfig.title)}</Typography>
            </FuseAnimate>
          </div>
        </HeaderName>
      </Grid>

      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <ButtonSelect options={buttonOptions} value={page} onChange={onMenuClick}/>
          </FuseAnimate>
        </Grid>
        {!tabConfig.hiddenSubmit && (
          <Grid item>
            <FuseAnimate animation="transition.slideRightIn" delay={300}>
              <Button
                className="whitespace-no-wrap"
                variant="contained"
                color="secondary"
                disabled={untouched}
                onClick={startSubmit}
              >
                Mentés
              </Button>
            </FuseAnimate>
          </Grid>
        )}
      </Grid>

    </Grid>
  );
};

export default React.memo(EditHeader);
