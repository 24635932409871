import React, { useContext } from "react";
import clsx from "clsx";
import { useRouter } from "next/router";
import { FusePageSimple } from "components/fuse";
import { AlumnusContext } from "components/alumnus";
import Tabs from "../Tabs";
import ProfileHeader from "./ProfileHeader";
import ProfileContent from "./ProfileContent";


const ProfilePage = () => {

  const { editable } = useContext(AlumnusContext);

  const { query: { from } } = useRouter();

  return (
    <FusePageSimple
      classes={{
        header: clsx("py-4", editable
          ? from ? "min-h-216 h-216 sm:min-h-148 sm:h-148" : "min-h-184 h-184 sm:min-h-112 sm:h-112 sm:pt-6"
          : from ? "min-h-148 h-148" : "min-h-112 h-112 sm:pt-6",
        ),
        toolbar: "px-16 sm:px-24",
      }}
      header={(
        <ProfileHeader/>
      )}
      contentToolbar={editable && (
        <Tabs/>
      )}
      content={(
        <ProfileContent/>
      )}
    />
  );
};

export default React.memo(ProfilePage);
