import React, { useCallback, useContext } from "react";
import Router from "next/router";
import { Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UiMessages } from "config/messages";
import { pathNames } from "config/routing";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import EditButton from "./EditButton";


const RowControl = card => {

  const { message: { showMessage } } = useContext(UiStateContext);


  const readOnly = useForbidden([dbEnums.accountRoles.MANAGER]);


  const onCopy = useCallback(() => showMessage(UiMessages.meta.copiedToClipboard("A kártyatulajdonos neve")), [showMessage]);


  const navigateToProfileEdit = useCallback(() => Router.push(pathNames.alumnus.href, `${pathNames.alumnus.as(card.alumnusId)}/edit/4/1`)
    , [card.alumnusId]);


  return (
    <Grid container spacing={1} alignItems="center" className={readOnly ? "sm:min-w-40" : "sm:min-w-128"}>
      {!readOnly && (
        <>
          <Grid item>
            <EditButton {...card}/>
          </Grid>
          <Grid item>
            <Tooltip title="Alumnus adatlap szerkesztése">
              <IconButton size="small"
                          onClick={navigateToProfileEdit}
              >
                <Icon>account_circle</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      )}
      <Grid item>
        <CopyToClipboard text={card.name}
                         onCopy={onCopy}
        >
          <Tooltip title="Név másolása a vágólapra">
            <IconButton size="small">
              <Icon>file_copy</Icon>
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      </Grid>
    </Grid>
  );
};

export default React.memo(RowControl);
