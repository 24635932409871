import clsx from "clsx";
import socialUrl from "social-url";
import skypeRegex from "skype-regex";
import validator from "validator";
import { useCallback, useContext, useMemo, useState } from "react";
import { dbEnums, labels } from "common/enums";
import { resolvers } from "client/store/alumnus";
import { useField, FormContext } from "components/form";
import { requirementMessages } from "../config";
import { updateFields, __typename } from "./Social";


const maxLength = 512;


const siteFieldRequirements = {
  required: true,
};


const invalidUrlError = "Teljes webes hivatkozást (URL) adj meg! További információk a súgóban.";


const getIcon = values => values && values.site !== dbEnums.socialSite.UNKNOWN && resolvers.social.icon(values);


const useSocialEntry = ({ record, accountField, siteField, requirements, required, helperText }) => {


  const { setValue, validateUniqueness } = useContext(FormContext);


  const [icon, setIcon] = useState(() => getIcon(record));


  const parseSocialUrl = useCallback(value => {
    const parsed = socialUrl.parse(value);
    const network = clsx(parsed && parsed.network).toUpperCase();

    const parsedEntry = network && Object.entries(labels.socialSite).find(entry => clsx(entry[1]).toUpperCase() === network);
    return parsedEntry && parsedEntry[0];
  }, []);


  const isSkype = useCallback(value => skypeRegex({ exact: true }).test(value), []);


  const parseSkype = useCallback(value => isSkype(value) && dbEnums.socialSite.SKYPE, [isSkype]);


  const parseSite = useCallback(value => parseSocialUrl(value) || parseSkype(value) || dbEnums.socialSite.UNKNOWN
    , [parseSkype, parseSocialUrl]);


  const validateUniquePerAlumnus = useCallback(value =>
    !validateUniqueness(__typename, updateFields[__typename].account, value, record.id) || requirementMessages.social.notUniquePerAlumnus
    , [record, validateUniqueness]);


  const validateFormat = useCallback(url => {
    if (!url || isSkype(url)) return true;

    if (/[\s]+/.test(url)) return requirementMessages.url.whitespace;

    return validator.isURL(url, {
      protocols: ["http", "https"],
      require_tld: true,
      require_protocol: true,
      disallow_auth: true,
    }) || invalidUrlError;
  }, [isSkype]);


  const onlyUpperCase = useCallback(value => !clsx(value).trim()
    || clsx(value) !== clsx(value).toUpperCase()
    || requirementMessages.onlyUpperCase, []);


  const accountFieldRequirements = useMemo(() => ({
    ...requirements,
    required: required || true,
    maxLength: {
      value: maxLength,
      message: requirementMessages.social.tooLong(maxLength),
    },
    validate: {
      ...(requirements && requirements.validate),
      validateUniquePerAlumnus,
      validateFormat,
      onlyUpperCase,
    },
  }), [onlyUpperCase, required, requirements, validateFormat, validateUniquePerAlumnus]);


  const { name, rules, error, helperTextOrError } = useField({
    record,
    field: accountField,
    helperText,
    fieldRequirements: accountFieldRequirements,
  });


  const { name: siteFieldName } = useField({
    record,
    field: siteField,
    fieldRequirements: siteFieldRequirements,
  });


  const setSite = useCallback(async value => {
    const site = parseSite(value) || dbEnums.socialSite.UNKNOWN;
    setValue(siteFieldName, site);

    const icon = getIcon({ site });
    setIcon(icon);
  }, [parseSite, setValue, siteFieldName]);


  const onChange = useCallback(([{ target: { value } = {} }]) => {
    setSite(clsx(value));

    return clsx(value);
  }, [setSite]);


  return {
    name,
    rules,
    onChange,
    helperTextOrError,
    error,
    icon,
    siteFieldName,
  };

};

export default useSocialEntry;
