import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Icon, List, ListItem, ListItemText, ListSubheader, Button } from "@material-ui/core";
import { categories } from "config/search";
import { SearchContext } from "client/lib/providers/SearchProvider";
import { emitUiStateEventNextTick, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { FuseAnimate } from "components/fuse";
import { useSidebarStyles } from "components/listPages";
import YearFilter from "./YearFilter";


const Sidebar = () => {

  const { emptyFilters, isLoading, startLoading, clearFilters, category, setCategory, setYearFrom, setYearTo, yearFrom, yearTo } = useContext(SearchContext);


  const onResetClick = useCallback(() => {
    clearFilters();
    emitUiStateEventNextTick(uiStateEvents.search.updateStarted);
  }, [clearFilters]);


  const onCategoryClick = useCallback(category => {
    startLoading();
    setCategory(category);
    emitUiStateEventNextTick(uiStateEvents.search.updateStarted);
  }, [setCategory, startLoading]);


  const onApplyClick = useCallback(() => emitUiStateEventNextTick(uiStateEvents.search.updateStarted), []);


  const classes = useSidebarStyles();


  return (
    <FuseAnimate animation="transition.slideUpIn" delay={400}>

      <div className="flex-auto border-0 border-l-1 border-solid mt-16">

        <List>
          <ListItem
            button
            disabled={isLoading}
            onClick={onResetClick}
            className={clsx(classes.listItem, emptyFilters && "active")}
          >
            <Icon className="list-item-icon" color="primary">view_headline</Icon>
            <ListItemText primary="Összes alumnus" disableTypography/>
          </ListItem>
        </List>

        <List>
          <ListItem className={clsx(classes.listItem, yearFrom && "filled")}>
            <Icon className="w-16 h-16 text-16 mr-12" color="primary">first_page</Icon>
            <ListItemText disableTypography
                          primary={
                            <YearFilter value={yearFrom}
                                        onChange={setYearFrom}
                                        disabled={isLoading}
                                        from
                            />}
            />
          </ListItem>
          <ListItem className={clsx(classes.listItem, yearTo && "filled")}>
            <Icon className="w-16 h-16 text-16 mr-12" color="primary">last_page</Icon>
            <ListItemText disableTypography
                          primary={
                            <YearFilter value={yearTo}
                                        onChange={setYearTo}
                                        disabled={isLoading}
                            />}
            />
          </ListItem>
        </List>


        <div className="px-24 pt-8 pb-8">
          <Button
            onClick={onApplyClick}
            disabled={isLoading}
            variant="contained"
            color="primary"
            className="w-full normal-case"
          >
            Évszűrők alkalmazása
          </Button>
        </div>

        <List>
          <ListSubheader disableSticky>MIT KERESSÜNK?</ListSubheader>
          {categories.map(({ id, label, colorClassName }) => (
            <ListItem
              button
              disabled={isLoading}
              key={id}
              onClick={() => onCategoryClick(id)}
              className={clsx(classes.listItem, category === id && "active")}
            >
              <Icon className={clsx("w-16 h-16 text-16 mr-12", colorClassName)} color="action">label</Icon>
              <ListItemText primary={label} disableTypography className="text-13 sm:text-14"/>
            </ListItem>
          ))}
        </List>
      </div>
    </FuseAnimate>
  );
};

export default React.memo(Sidebar);
