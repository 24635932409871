import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { getDefaultValueProp } from "../helpers";
import ButtonSelect from "./ButtonSelect";
import Controller from "./Controller"
import useField from "./useField";


const EnumField = ({
                     options, record, field, requirements, required, defaultValue, onChangeCb, buttonClassName,
                     textClassName, tooltip, className, helperText, size, buttonProps, disabled, ...props
                   }) => {


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    required: required || true,
  }), [required, requirements]);


  const { name, rules } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const onChange = useCallback(([value]) => {
    if (typeof onChangeCb === "function") onChangeCb(value);

    return value;
  }, [onChangeCb]);


  return (
    <Controller
      {...props}
      {...getDefaultValueProp(defaultValue)}
      name={name}
      as={<ButtonSelect/>}
      rules={rules}
      options={options}
      onChange={onChange}
      className={clsx(!clsx(className).includes("mb-") && "mb-32", className)}
      buttonClassName={clsx("bg-transparent hover:bg-transparent", buttonClassName)}
      textClassName={`my-0 ${textClassName}`}
      size={size || "large"}
      buttonProps={{
        size: "medium",
        disabled: Boolean(disabled),
        ...buttonProps,
      }}
      tooltip={tooltip}
    />
  );
};

export default React.memo(EnumField);
