import memoizee from "memoizee";
import { errorLogStore } from "client/lib/errorLog";
import { getSearchFilters, getSearchPages } from "client/lib/providers/uiState";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { GET_ALUMNUS_GROUP } from "client/store/alumnus";


const getAlumnusQueryOptions = (idsArray, offset, limit) => {
  const ids = limit > 0
    ? idsArray.slice(offset, offset + limit)
    : idsArray.slice(offset);
  return {
    query: GET_ALUMNUS_GROUP,
    variables: { ids }
  };
};


const fetchMembers = async (options, client) => {
  try {
    const { variables: { ids } } = options;
    if (!ids) return null;

    const { data: { members: fetchedMembers } } = await client.query(options);
    const membersWIds = fetchedMembers.reduce((members, member) => ({
      ...members,
      [member.id]: member
    }), {});

    // sort members array by Id
    return ids.reduce((members, id) => members.concat([membersWIds[id]]), []);

  } catch (e) {
    errorLogStore("search", "fetchMembers", e);
  }
};


const decorateMembers = (members, relevances, searchText) => {
  if (!members) return null;

  if (!searchText) return members;

  return members.map(alumnus => ({
    ...alumnus,
    relevances: relevances[alumnus.id] || {},
  }));
};


const fetchPage = async (searchHash, idsArray, relevances, client) => {
  const { searchText, pageSize } = getSearchFilters();
  const { page } = getSearchPages();

  const offset = pageSize * (page - 1);
  const options = getAlumnusQueryOptions(idsArray, offset, pageSize);

  const members = await fetchMembers(options, client);
  return decorateMembers(members, relevances, searchText);
};


const prefetchNextPage = (idsArray, client) => {
  try {
    const { pageSize } = getSearchFilters();
    const { page, prefetchedPages, numPages } = getSearchPages();

    const prefetchedPage = page + 1;
    if (prefetchedPages.includes(prefetchedPage) || prefetchedPage > numPages) return null;

    const offset = pageSize * (prefetchedPage - 1);
    const options = getAlumnusQueryOptions(idsArray, offset, pageSize);
    client.query(options);


    emitUiStateEvent(uiStateEvents.search.pagePrefetched, prefetchedPage);

  } catch (e) {
    errorLogStore("search", "prefetchNextPage", e);
  }
};


const idsToArray = rawIds => rawIds.reduce((ids, { id }) => ids.concat([id]), []);


const parseRelevances = rawIds => rawIds.reduce((relevances, { id, ...rest }) => ({
  ...relevances,
  [id]: {
    ...rest,
  },
}), {});


const fetchResults = memoizee(async (searchHash, rawIds, client) => {
  const idsArray = idsToArray(rawIds);
  const relevances = parseRelevances(rawIds);

  const results = await fetchPage(searchHash, idsArray, relevances, client);

  // only starting to fetch next page's members, not waiting for promise's return value
  prefetchNextPage(idsArray, client);

  return results;
}, { length: 1 });

export default fetchResults;
