import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { errors, isSameAppError } from "common/errors";
import { UiMessages } from "config/messages";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { ConfirmInput, Form } from "components/form";
import Password from "./Password";
import PswRequirements from "./PswRequirements";
import Username from "./Username";


const __typename = "__mock_account";

const currentField = "__currentPsw";

const newField = "__newPsw";

const updateFields = {
  [__typename]: {
    [currentField]: currentField,
    [newField]: newField,
  },
};

const requiredFields = {
  [__typename]: [currentField, newField],
};


const SetPassword = ({
                       accountId, username, token, fetch, requiredCurrent, disableSubmit, enableSubmit, loading,
                       noMessage, noStopLoading, issue, className, children
                     }) => {

  const [currentPassword, setCurrentPassword] = useState();

  const [password, setPassword] = useState();

  const [showPassword, setShowPassword] = useState(false);


  const mockRecord = useMemo(() => ({
    id: accountId,
    [currentField]: "",
    [newField]: "",
    __typename,
  }), [accountId]);


  const onChange = useCallback(value => {
    setPassword(value);
    enableSubmit();
  }, [enableSubmit]);


  const backendService = useMemo(() => token
    ? backendServices.pswrLink.pswReset
    : issue
      ? backendServices.account.changePasswordIssue
      : backendServices.account.changePassword
    , [issue, token]);


  const fetchMethod = useMemo(() => fetch || backendFetch, [fetch]);


  const getErrorMessage = useCallback(error => {
    if (error.same) return UiMessages.password.sameAsCurrent;
    if (isSameAppError({ error }, errors.server.general.INVALID)) return UiMessages.password.wrongPassword;

    return UiMessages.password.failedPasswordChange;
  }, []);


  const saveCb = useCallback(async ({ jobs }) => {
    const { id, [currentField]: currentPassword, [newField]: password } = (jobs[__typename] || [])[0] || {};
    if (requiredCurrent ? !id : !token) return;

    const { error } = await fetchMethod(backendService, {
      password,
      ...(requiredCurrent
        ? { id, currentPassword }
        : { token }),
    });

    return {
      error,
      message: error && getErrorMessage(error),
      noMessage: !error && noMessage,
      noStopLoading: !error && noStopLoading,
    };
  }, [backendService, fetchMethod, getErrorMessage, noMessage, noStopLoading, requiredCurrent, token]);


  return Boolean(accountId) && (
    <Form className={clsx("max-w-md", className)}
          records={[mockRecord]}
          saveCb={saveCb}
          updateFields={updateFields}
          requiredFields={requiredFields}
          disableSubmit={disableSubmit}
    >
      <Grid item container spacing={2}>
        {Boolean(username) && (
          <Username username={username}/>
        )}
        {Boolean(requiredCurrent) && (
          <Grid item xs={12}>
            <Password record={mockRecord}
                      field={currentField}
                      onChangeCb={setCurrentPassword}
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                      disabled={loading}
                      className="mb-20"
                      label="Jelenlegi jelszavad"
                      autoComplete="current-password"
                      required
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Password record={mockRecord}
                    field={newField}
                    currentPassword={currentPassword}
                    onChangeCb={onChange}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    disabled={loading}
                    className="mb-20"
                    label="Új jelszó"
                    autoComplete="new-password"
                    required
                    strong
          />
        </Grid>
        <Grid item xs={12}>
          <ConfirmInput record={mockRecord}
                        field={newField}
                        confirmValue={password}
                        InputComponent={Password}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        disabled={loading}
                        className="mb-16"
                        entity="jelszó"
                        label="Új jelszó megerősítése"
                        autoComplete="new-password"
          />
        </Grid>
        {Boolean(children) && (
          <Grid item xs={12}>
            {children}
          </Grid>
        )}
        <Grid item xs={12}>
          <PswRequirements value={password}/>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(SetPassword);
