import texts from "config/texts";
import * as dbEnums from "./db";


export const membership = {
  [dbEnums.membership.GRADUATED]: "A Gimnáziumban érettségizett",
  [dbEnums.membership.NONGRADUATED]: "A Gimnáziumban tanult, de máshol érettségizett",
  [dbEnums.membership.TEACHER]: "A Gimnázium tanára",
  [dbEnums.membership.HONORARY]: "Tiszteletbeli tag",
};


export const accountStatus = {
  [dbEnums.accountStatus.ACTIVE]: "Aktív fiók",
  [dbEnums.accountStatus.BLOCKED]: "Letiltott fiók",
  [dbEnums.accountStatus.DELETED]: "Törölt fiók",
};


export const accountRoles = {
  [dbEnums.accountRoles.ALUMNUS]: "Alumnus felhasználó",
  [dbEnums.accountRoles.MANAGER]: "Admin felhasználó",
  [dbEnums.accountRoles.RETAILER]: texts.retailerAccount,
  [dbEnums.accountRoles.STUDENT]: texts.studentAccount,
};


export const cardStatus = {
  [dbEnums.cardStatus.REQUESTED]: "Igényelt",
  [dbEnums.cardStatus.PREPARATION]: "Előkészítés alatt",
  [dbEnums.cardStatus.ACTIVE]: "Aktív",
  [dbEnums.cardStatus.REVOKED]: "Visszavont",
};


export const socialSite = {
  [dbEnums.socialSite.UNKNOWN]: null,
  [dbEnums.socialSite.FACEBOOK]: "Facebook",
  [dbEnums.socialSite.INSTAGRAM]: "Instagram",
  [dbEnums.socialSite.LINKEDIN]: "LinkedIn",
  [dbEnums.socialSite.PINTEREST]: "Pinterest",
  [dbEnums.socialSite.SKYPE]: "Skype",
  [dbEnums.socialSite.YOUTUBE]: "YouTube",
  [dbEnums.socialSite.TWITTER]: "Twitter",
};


export const action = {
  [dbEnums.action.REVISION]: "Adatmegerősítő lap beküldése",
  [dbEnums.action.UPDATE]: "Adatmódosítás",
  [dbEnums.action.LOGIN]: "Bejelentkezés a felületre",
  [dbEnums.action.REGISTRATION]: "Regisztráció",
  [dbEnums.action.PSW_CHANGE]: "Jelszóváltoztatás",
  [dbEnums.action.PSW_CHANGE_W_TOKEN]: "Jelszócsere linken keresztül",
  [dbEnums.action.PSW_RESET]: "Jelszóváltoztatási link kérése",
  [dbEnums.action.ACCOUNT_ST_CHANGE]: "Fiókstátusz megváltozása",
  [dbEnums.action.BANS_DELETED]: "Kitiltások törlése",
};
