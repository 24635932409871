import React from "react";
import { Grid } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { ChipSelect, Input, PrivacyPolicy, useTags } from "components/form";


export const tagEntity = dbEnums.tagEntity.HOBBY;

export const __typename = "alumnus_hobby";


const maxLength = 1000;

const rowsCount = 5;

const rowsMaxCount = 10;


const _updateFields = {
  privacy: "privacy",
  hobby: "hobby",
  tags: "tags",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.privacy],
};


const _fieldAliases = {
  [_updateFields.tags]: "tags",
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


const tagsHelperText = (
  <span>Pl. <span className="text-blue">foci</span>. A lenyíló listában a leggyakrabban megadott kulcsszavak közül is választhatsz.
    Az itt felvett kulcsszavak alapján is megtalálható leszel az <i className="text-blue">Alumnus keresőben</i>.</span>
);


const hobbyHelperText = (
  <span>
    Legfeljebb {maxLength} karakterben. Az itt leírtak alapján is megtalálható leszel a keresőben.<br/><br/> Ahhoz, hogy
    az adatlapon a <i>webes hivatkozások</i> kattinthatók legyenek, mindig teljes URL-t adj meg! Pl. a <span
    className="text-blue">https://example.com</span> megfelelő, a <span className="text-red">example.com</span> viszont nem.
  </span>
);


const Hobby = ({ record }) => {

  const { loading, tags } = useTags(tagEntity);

  if (loading) return null;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <PrivacyPolicy record={record}
                       field={_updateFields.privacy}
                       tooltip="Az érdeklődési kör leírására és a kulcsszavaira"/>
      </Grid>
      <Grid item xs={12}>
        <ChipSelect record={record}
                    field={_updateFields.tags}
                    label="Érdeklődési köröd kulcsszavakban"
                    options={tags}
                    helperText={tagsHelperText}/>
      </Grid>
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.hobby}
               label="Részletezés, webes hivatkozásokkal"
               helperText={hobbyHelperText}
               className="mb-8"
               maxLength={maxLength}
               rows={rowsCount}
               rowsMax={rowsMaxCount}
               multiline
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Hobby);
