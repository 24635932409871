import React from "react";
import { Typography } from "@material-ui/core";
import { maxRecordsCounts } from "config/form";
import texts from "config/texts";


const Card = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon az alumnushoz rendelt <b>{texts.cardsName}</b> adminisztrációját végezheted.
    </Typography>
    <Typography className="my-20">
      Az alumnushoz rendelt egy-egy kártyát egy-egy bejegyzés létrehozásával adhatsz meg
      (legfeljebb {maxRecordsCounts.cards} kártyabejegyzés hozható létre).
    </Typography>
    <Typography className="my-20">
      Ha az alumnus a regisztrációkor &ndash; az erre vonatkozó jelölőnégyzettel &ndash; {texts.cardName} igénylésére
      vonatkozó nyilatkozatot tett, akkor <b>automatikusan létrejön egy {texts.cardName} bejegyzés</b>.
    </Typography>
    <Typography className="my-20">
      A <span className="text-blue">kártya státusza</span> négyféle lehet. A regisztrációs során
      igényelt {texts.cardName} bejegyzését a rendszer <span
      className="text-blue">&quot;Igényelt&quot;</span> státusszal hozza létre. Ezt követően, amikor a kártyával
      kapcsolatos ügyintézés kezdetét veszi, akkor az <span
      className="text-blue">&quot;Előkészítés alatt&quot;</span> státuszt, a kártya elkészültekor pedig az <span
      className="text-blue">&quot;Aktív&quot;</span> státuszt állítsd be! Ha egy kártya <b>elveszik vagy hatályát
      veszti</b>, akkor a <span className="text-blue">&quot;Visszavont&quot;</span> státuszt állítsd be!
    </Typography>
    <Typography className="my-20">
      A <span className="text-blue">kártya sorszámát</span> és <span
      className="text-blue">kiállításának dátumát</span>&nbsp;<i>Aktív</i> és <i>Visszavont</i> státuszok esetén
      kötelező megadni.
    </Typography>
    <Typography className="my-20">
      A <span className="text-blue">lejárat/visszavonás dátumát</span> csak <i>Visszavont</i> státusz esetén adható meg,
      és ekkor kötelező is megadni.
    </Typography>
    <Typography className="mt-20">
      Egy-egy kártyabejegyzést a <span className="text-blue">Törlés</span> gombra kattintva <b>törölhetsz</b>. Fontos,
      hogy <b>a kártya törlése nem azonos a kártya visszavonásával</b>. <span
      className="text-blue">Kártya törlésekor</span> arról döntesz, hogy a kártyát az Alumni többé már <i>nem kívánja
      nyilvántartani</i>. (Ezt követően a partner szervezetek sem látják.) <span className="text-blue">Kártya visszavonásakor</span> pedig
      arról, hogy az Alumni továbbra is nyilvántartja, de immár <i>nem érvényesként</i>.
    </Typography>
  </>
);

export default React.memo(Card);
