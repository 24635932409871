import React from "react";
import { Typography, Chip, Icon, Tooltip, Grid } from "@material-ui/core";
import Link from "next/link";
import { withAccent } from "common/helpers";
import FlagIcon from "components/alumnus/FlagIcon";


const EmailOrPhone = ({ email, phone, noIcon }) => {
  if (!email && !phone) return null;
  if (email) phone = null;

  const { email: em, href, parsed: { country, normalized } = {}, primary, note, tagsW } = email || phone;
  const { tags } = tagsW || {};

  return (
    <div className="flex items-start">
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <div className="flex flex-row">
            {!noIcon && (
              <Icon className="text-16 mt-1 mr-8" color="action">{email ? "alternate_email" : "phone"}</Icon>
            )}
            {email && (
              <Link href={href} prefetch={false}>
                <a className="mt-1" rel="noopener noreferrer">{em}</a>
              </Link>
            )}
            {phone && (
              <Typography className="mt-1">
                {normalized}
                <FlagIcon className="ml-8" code={country} disableHun/>
              </Typography>
            )}
          </div>
        </Grid>


        {primary && (
          <Grid item xs="auto">
            <Chip label={`Alumni kapcsolattartási ${email ? "email" : "telefonszám"}`} size="small" color="primary"/>
          </Grid>
        )}
        {Boolean(tags && tags.length) && tags.map(({ value, label }) => (
          <Grid key={value} item xs="auto">
            <Chip label={label} size="small" color="secondary"/>
          </Grid>
        ))}

        {note && (
          <Grid item xs="auto">
            <Grid container alignItems="center">
              <Grid item xs="auto">
                <Tooltip
                  title={`${email ? `${withAccent(em, true)} email címre` : `A ${normalized} telefonszámra`} vonatkozik`}
                  placement="bottom" disableTouchListener disableFocusListener>
                  <Icon className="text-16 mr-4 mt-1" color="primary">info</Icon>
                </Tooltip>
              </Grid>
              <Grid item xs="auto">
                <Typography variant="body1" display="block">{note}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

      </Grid>
    </div>
  );
};

export default React.memo(EmailOrPhone);
