import octopusInit from "./resolvers/init";
import octopusRefreshStatus from "./resolvers/refreshStatus";
import octopusSync from "./resolvers/sync";
import octopusStop from "./resolvers/stop";


export const resolvers = {
  Mutation: {
    octopusInit,
    octopusRefreshStatus,
    octopusSync,
    octopusStop,
  },
};
