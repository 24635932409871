import React, { useContext, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const HelpButton = () => {

  const { help: { config, toAnimate, openHelp } } = useContext(UiStateContext);

  const ref = useRef(null);

  useEffect(() => {
    const { current } = ref || {};
    const { runAnimation } = current || {};
    if (runAnimation) runAnimation();
  }, [toAnimate]);

  const tip = useMemo(() => config
    ? "Súgó megnyitása"
    : "Ehhez az oldalhoz nem tartozik súgó", [config]);

  return (
    <FuseAnimate animation="transition.flipBounceXIn" duration={2000} runOnMount={false} ref={ref}>
      <Tooltip title={tip} placement="bottom">
        <div>
          <IconButton className="w-64 h-64" disabled={!config} onClick={openHelp}>
            <Icon className={clsx(config && "text-blue-dark")}>help_outline</Icon>
          </IconButton>
        </div>
      </Tooltip>
    </FuseAnimate>
  );
};

export default React.memo(HelpButton);
