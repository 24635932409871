import React from "react";
import clsx from "clsx";
import { Icon, Link } from "@material-ui/core";
import { Terms, EffectiveDate } from "components/terms";


const _Expander = ({ visibleTerms, toggleVisibleTerms, expanderClassName, noCurrent }) => (
  <div className={clsx("w-full flex flex-row flex-no-wrap items-center", expanderClassName)}>
    <Icon color="action" className="mr-4">
      {visibleTerms ? "keyboard_arrow_up" : "keyboard_arrow_down"}
    </Icon>
    <Link onClick={toggleVisibleTerms} className="cursor-pointer" underline="none" role="button" color="secondary">
      {noCurrent ? "Az" : "A hatályos"}&nbsp;Adatkezelési Tájékoztató {visibleTerms ? "elrejtése" : "megjelenítése"}
    </Link>
  </div>
);


const Expander = React.memo(_Expander);


const TermsReader = ({ visibleTerms, toggleVisibleTerms, ...expanderProps }) => (
  <>
    <Expander visibleTerms={visibleTerms} toggleVisibleTerms={toggleVisibleTerms} {...expanderProps}/>

    {Boolean(visibleTerms) && (
      <>
        <EffectiveDate className="pt-32 py-8"/>

        <Terms className="py-16 sm:pb-0 lg:pb-8"/>

        <Expander visibleTerms={visibleTerms} toggleVisibleTerms={toggleVisibleTerms} {...expanderProps}/>
      </>
    )}
  </>
);

export default React.memo(TermsReader);
