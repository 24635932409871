import { safeGroup } from "client/store/alumnus/resolvers/getters";
import toFilter from "./toFilter";
import delRegCode from "./delRegCode";
import saveRegCode from "./saveRegCode";


const used = ({ alumnusId, regDate }) => Boolean(alumnusId || regDate);


export const resolvers = {
  reg_code: {
    safeGroup,
    toFilter,
    used,
  },
  Mutation: {
    delRegCode,
    saveRegCode,
  },
};
