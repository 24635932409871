import React from "react";
import { Grid } from "@material-ui/core";
import { getPrefixedId } from "components/form/helpers";
import { _updateFields, CardEntry, CardStatus, useCard } from "components/form/card";


const Card = record => {

  const { status, onStatusChange } = useCard({
    record: record,
    recordId: record.id,
    prefixedId: getPrefixedId(record.id),
    dialog: true,
  });

  return (
    <Grid container spacing={2} item>
      <Grid item xs={12}>
        <CardStatus record={record}
                    field={_updateFields.status}
                    defaultValue={record[_updateFields.status]}
                    onChangeCb={onStatusChange}
                    tooltip="Kártya státuszának állítása"
        />
      </Grid>
      <CardEntry status={status}
                 record={record}
                 _updateFields={_updateFields}
      />
    </Grid>
  );
};

export default React.memo(Card);
