import React, { useContext } from "react"
import clsx from "clsx";
import { SessionContext } from "client/lib/providers/SessionProvider";
import Layout from "components/layout";
import { FusePageSimple } from "components/fuse";
import EffectiveDate from "./EffectiveDate";
import Terms from "./Terms";


const TermsPage = () => {

  const { authenticated } = useContext(SessionContext);

  const content = (
    <>
      <EffectiveDate className="py-8" small/>
      <Terms className="py-16 sm:pb-0 lg:pb-8"/>
    </>
  );

  return (
    <Layout wrapper={clsx(!authenticated && "center")}
            wrapperClassName="max-w-full sm:max-w-2xl lg:max-w-3xl xl:max-w-4xl">

      {!authenticated
        ? content
        : (
          <FusePageSimple
            classes={{
              content: "max-w-3xl lg:max-w-5xl p-24",
            }}
            content={content}
          />
        )}

    </Layout>
  );
};

export default React.memo(TermsPage);
