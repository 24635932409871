import React from "react";
import { Icon } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";


const SidebarHeader = ({ icon, title }) => (
  <div className="flex flex-col justify-center h-full pt-28 lg:pt-0 px-24">
    <div className="flex items-center flex-1">
      <FuseAnimate animation="transition.expandIn" delay={300}>
        <Icon className="text-32 mr-16">{icon}</Icon>
      </FuseAnimate>
      <FuseAnimate animation="transition.slideLeftIn" delay={300}>
        <span className="text-24">{title}</span>
      </FuseAnimate>
    </div>
  </div>
);

export default React.memo(SidebarHeader);
