import React from "react";
import { Typography } from "@material-ui/core";
import { withAccent } from "common/helpers";
import { productName } from "components/layout";
import MultiStatement from "../MultiStatement";
import useTermsStep from "./useTermsStep";
import TermsReader from "./TermsReader";
import { statementsConfig } from "./config";


const TermsStep = ({ registerSubmitCb, stepStatements, toggleStatement, statementClassName, statementColor }) => {

  const { visibleTerms, toggleVisibleTerms } = useTermsStep({
    registerSubmitCb,
    stepStatements,
  });

  return (
    <div className="w-full">
      <Typography className="my-16 mb-24">
        {withAccent(productName, true)} felületére történő legutóbbi bejelentkezésed óta új Adatkezelési Tájékoztató
        lépett hatályba. A felületre való továbblépés feltétele, hogy a hatályos Adatkezelési Tájékoztatóban írtakat
        tudomásul vedd. <i>A szükséges nyilatkozatokat az alábbi jelölőnégyzetek bekapcsolásával teheted meg.</i>
      </Typography>

      <TermsReader visibleTerms={visibleTerms} toggleVisibleTerms={toggleVisibleTerms}/>

      <MultiStatement statementsConfig={statementsConfig}
                      stepStatements={stepStatements}
                      toggleStatement={toggleStatement}
                      className={statementClassName}
                      color={statementColor}
                      title="Nyilatkozatok az adatkezelésről"
      />
    </div>
  );
};

export default React.memo(TermsStep);
