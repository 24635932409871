import { requestMethods } from "server/lib/api/config";


export const contentTypes = {
  json: "application/json",
  octet: "application/octet-stream",
};


const headerNames = {
  contentType: "Content-Type",
  accept: "Accept",
};


const normalizeTypes = (acceptContentType, contentType) => {
  const safeAcceptContentType = acceptContentType || contentTypes.json;
  const safeContentType = contentType || contentTypes.json;

  return { safeAcceptContentType, safeContentType };
};


const getSettings = (safeAcceptContentType, safeContentType) => ({
  method: requestMethods.POST,
  credentials: "include",
  headers: {
    [headerNames.accept]: safeAcceptContentType,
    [headerNames.contentType]: safeContentType,
  },
});


export const getService = (path, { acceptContentType, contentType, ...options } = {}) => {
  const { safeAcceptContentType, safeContentType } = normalizeTypes(acceptContentType, contentType);
  const settings = getSettings(safeAcceptContentType, safeContentType);

  return {
    ...options,
    settings,
    contentJson: safeContentType === contentTypes.json,
    acceptJson: safeAcceptContentType === contentTypes.json,
    acceptBlob: safeAcceptContentType === contentTypes.octet,
    path,
  };
};
