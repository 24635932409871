import moment from "moment";
import { useCallback, useRef, useState } from "react";
import { backendFetch } from "client/lib/backendApi";


const useBackendService = () => {

  const _banned = useRef(false);

  const _expiry = useRef(null);

  const _expiryFormatted = useRef("");


  const [loading, setLoading] = useState(false);

  const [banned, setBanned] = useState(false);

  const [banExpiry, setBanExpiry] = useState(null);

  const [banExpiryFormatted, setBanExpiryFormatted] = useState("");


  const setExpiry = useCallback(expiry => {
    const safeExpiry = (expiry && moment(expiry).startOf("m").add(1, "m").toDate()) || null;
    _expiry.current = safeExpiry;
    setBanExpiry(safeExpiry);
  }, []);


  const setFormatted = useCallback(() => {
    if (_expiry.current) {
      const format = moment(_expiry.current).dayOfYear() === moment().dayOfYear() ? "HH:mm[ (UTC]Z[)]" : "L HH:mm[ (UTC]Z[)]";
      const formatted = moment(_expiry.current).format(format);

      _expiryFormatted.current = formatted;
      setBanExpiryFormatted(formatted);

    } else {
      _expiryFormatted.current = "";
      setBanExpiryFormatted("");
    }
  }, []);


  const scheduleUnban = useCallback(() => {
    const msToExpiry = moment(_expiry.current).diff(moment());

    if (msToExpiry > 0) setTimeout(() => {
      _banned.current = false;
      setBanned(false);
    }, msToExpiry);
  }, []);


  const setBan = useCallback(({ banned, expiry } = {}) => {
    _banned.current = Boolean(banned);
    setBanned(_banned.current);

    setExpiry(expiry);
    setFormatted();
    scheduleUnban();

    return {
      banned: _banned.current,
      expiry: _expiry.current,
      banExpiryFormatted: _expiryFormatted.current,
    };
  }, [scheduleUnban, setExpiry, setFormatted]);


  const handleResponse = useCallback(({ error, data }) => {
    const { serviceBan: errorBan } = error || {};
    const { serviceBan: dataBan, ...safeData } = data || {};
    const serviceBan = errorBan || dataBan || {};

    const ban = setBan(serviceBan);

    setLoading(false);

    return {
      ...ban,
      error,
      data: safeData,
    };
  }, [setBan]);


  const fetch = useCallback(async (service, body) => {
    if (_banned.current) return {
      banned: true,
      error: {},
      data: {},
    };

    setLoading(true);

    const response = await backendFetch(service, body);

    return handleResponse(response);
  }, [handleResponse]);


  return {
    banExpiry,
    banExpiryFormatted,
    banned,
    loading,
    fetch,
  };

};

export default useBackendService;
