import React, { useMemo } from "react";
import clsx from "clsx";
import {
  AppBar,
  Card,
  CardContent,
  Divider,
  List,
  Toolbar,
  Typography
} from "@material-ui/core";
import Professional from "./Professional";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
  CardContent: {
    "&:last-child": {
      paddingBottom: 12,
    },
  },
  List: {
    "& ul": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& li.MuiListItem-gutters": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "& li.MuiDivider-inset": {
      marginLeft: 56,
    },
  },
}));


const ProfessionalCard = ({ alumnus }) => {
  const classes = useStyles();

  const getNodes = (items, type, lastDivider) => {
    if (!items || !items.length) return null;
    return items && items.map((item, index) => {
      const props = {
        key: `${type}_${item.id}`,
        [type]: item,
      };
      return (
        <div key={`${type}_${item.id}_wrapper`}>
          <Professional {...props}/>
          {(lastDivider || (index < items.length - 1)) && (
            <Divider key={`${type}_${item.id}_divider`} variant="inset" component="li"/>
          )}
        </div>
      );
    });
  };

  const jobs = useMemo(() => getNodes(alumnus.jobs, "job", true), [alumnus.jobs]);
  const studies = useMemo(() => getNodes(alumnus.studies, "study", false), [alumnus.studies]);

  return (
    <Card className="w-full pt-0 pb-0 mb-16">
      <AppBar position="static" elevation={0}>
        <Toolbar className="pl-16 pr-8">
          <Typography variant="subtitle1" color="inherit" className="flex-1">
            Munkahelyek és tanulmányok
          </Typography>
        </Toolbar>
      </AppBar>

      <CardContent className={clsx("py-8", classes.CardContent)}>
        <List className={clsx("py-0", classes.List)}>
          {jobs}
          {studies}
        </List>
      </CardContent>
    </Card>
  );
};

export default React.memo(ProfessionalCard);
