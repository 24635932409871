import path from "path";
import moment from "moment";
import clsx from "clsx";
import { logFolder, rotatedFileTransportDefaults as rfDefs } from "./config"


// only on server side
const winston = typeof window === "undefined" ? require("winston") : {};
const { format } = winston || {};
if (typeof window === "undefined") require("winston-daily-rotate-file")


export const getFormattedCurrentTS = () => moment().format("L HH:mm:ss");


const consoleError = (fn, { message: errorMessage, stack: errorStack } = {}) =>
  !process.env.TEST_MODE && console.error(`${getFormattedCurrentTS()} [error] In ${fn}@server/lib/log${clsx(errorMessage && `\n  Error: ${errorMessage}`)}${clsx(errorStack && `\n  ${errorStack}`)}`);


export const getDefaultFileOptions = serviceName => ({
  filename: `${serviceName}.%DATE%.log`,
  subfolder: serviceName,
  format: format && format.combine(
    format.splat(),
    format.json(),
  ),
});


const createDailyRotateFileTransport = (name, {
  filename, subfolder = "", datePattern = rfDefs.datePattern, zippedArchive = rfDefs.zipped, maxSize = rfDefs.maxSize, maxFiles = rfDefs.maxFiles, utc = rfDefs.utcInFilename
}) =>
  new (winston.transports.DailyRotateFile)({
    dirname: path.join(logFolder, subfolder),
    filename,
    datePattern,
    zippedArchive,
    maxSize,
    maxFiles,
    utc,
    createSymlink: false,
    auditFile: path.join(logFolder, subfolder, `${name}.audit.json`),
  });


const createTransports = (name, consoleOptions, fileOptions) => {
  try {
    const transports = [];
    if (consoleOptions && !process.env.TEST_MODE) transports.push(new winston.transports.Console(consoleOptions));
    if (fileOptions) transports.push(createDailyRotateFileTransport(name, fileOptions));

    return transports;

  } catch (e) {
    consoleError("createTransports", e);
    return null;
  }
};


export const wrapLogger = (logger, log) => ({
  error: (...args) => log && log(logger, "error", ...args),
  warn: (...args) => log && log(logger, "warn", ...args),
  info: (...args) => log && log(logger, "info", ...args),
  verbose: (...args) => log && log(logger, "verbose", ...args),
  debug: (...args) => log && log(logger, "debug", ...args),
  silly: (...args) => log && log(logger, "silly", ...args),
});


export const createLogger = (name, { consoleOptions, fileOptions, level = "debug" }, log) => {
  try {
    if (!name || typeof log !== "function") return null;

    if (typeof window !== "undefined") return wrapLogger();

    const transports = createTransports(name, consoleOptions, fileOptions);
    if (!transports) return null;

    winston.loggers.add(name, {
      level,
      transports,
      exitOnError: false,
      defaultMeta: {
        service: name,
      },
    });

    const logger = winston.loggers.get(name);
    return wrapLogger(logger, log);

  } catch (e) {
    consoleError("createLogger", e);
    return null;
  }
};
