import memoizee from "memoizee";
import hash from "object-hash";
import { getSearchFilters, getSearchPages } from "client/lib/providers/uiState";


const hashObject = memoizee(objectToHash => hash(objectToHash));


export const getSearchHash = () => {
  const filters = getSearchFilters();
  const { page } = getSearchPages();

  const objectToHash = {
    ...filters,
    page,
  };

  return hashObject(objectToHash);
};
