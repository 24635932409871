import React, { useCallback, useContext, useMemo } from "react";
import nextTick from "next-tick";
import clsx from "clsx";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { UiMessages } from "config/messages";
import { dbEnums } from "common/enums";
import { accent, safeFnOrPromise } from "common/helpers";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { ListContext } from "components/listPages";
import { useConfirm } from "components/common";


const getConfirmOptions = (title, isActive, { username }) => ({
  title,
  content: (
    <span>Biztosan {isActive ? "letiltod" : "feloldod"} {accent(username)} <b>{username}</b> felhasználónévhez tartozó fiókot?</span>
  ),
  OKButton: `Igen, ${isActive ? "letiltom" : "feloldom"}`,
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
});


const StatusButton = specialAccount => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { refetch, resetFilters } = useContext(ListContext);


  const isActive = useMemo(() => specialAccount.status === dbEnums.accountStatus.ACTIVE, [specialAccount.status]);


  const title = useMemo(() => `Speciális felhasználói fiók ${isActive ? "letiltása" : "tiltásának feloldása"}`
    , [isActive]);


  const confirm = useConfirm({
    confirmOptions: getConfirmOptions(title, isActive, specialAccount),
  });


  const changeStatus = useCallback(async () => {
    const { error } = await backendFetch(backendServices.account.changeStatus, {
      id: specialAccount.id,
      status: isActive ? dbEnums.accountStatus.BLOCKED : dbEnums.accountStatus.ACTIVE,
    });

    if (!error) {
      await safeFnOrPromise(refetch)();
      resetFilters();
    }

    nextTick(() => showMessage(error
      ? UiMessages.form.submit.saveError
      : isActive ? UiMessages.specialAccounts.disabled : UiMessages.specialAccounts.enabled));
  }, [specialAccount.id, isActive, showMessage, refetch, resetFilters]);


  const onClick = useCallback(() => confirm(changeStatus), [confirm, changeStatus]);


  return (
    <Tooltip title={title}>
      <span>
        <IconButton size="small"
                    onClick={onClick}
        >
          <Icon color="secondary"
                className={clsx(isActive && "text-red")}>
            {isActive ? "lock" : "lock_open"}
          </Icon>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default React.memo(StatusButton);
