import React, { useCallback, useContext } from "react";
import nextTick from "next-tick";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { UiMessages } from "config/messages";
import { accent, safeFnOrPromise } from "common/helpers";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { useConfirm } from "components/common";
import { ListContext } from "components/listPages";


const getConfirmOptions = username => ({
  title: "Kitiltások és korlátozások törlése",
  content: (
    <span>Biztosan feloldod {accent(username)}&nbsp;<b>{username}</b> felhasználónévhez tartozó kitiltásokat és korlátozásokat?</span>
  ),
  OKButton: "Igen, feloldom",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
});


const UnbanButton = ({ id, banned, bannedServices, username }) => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { refetch, resetFilters } = useContext(ListContext);


  const confirm = useConfirm({
    confirmOptions: getConfirmOptions(username),
  });


  const unban = useCallback(async () => {
    const { error } = await backendFetch(backendServices.account.deleteAccountBan, {
      id: id,
    });

    if (!error) {
      await safeFnOrPromise(refetch)();
      resetFilters();
    }

    nextTick(() => showMessage(error ? UiMessages.form.submit.saveError : UiMessages.form.account.bansDeleted));
  }, [id, refetch, resetFilters, showMessage]);


  const onClick = useCallback(() => confirm(unban), [confirm, unban]);


  return (
    <Tooltip title="Kitiltások és korlátozások törlése">
      <span>
        <IconButton size="small"
                    color="secondary"
                    onClick={onClick}
                    disabled={!banned && !bannedServices}
        >
          <Icon>check_circle</Icon>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default React.memo(UnbanButton);
