import React, { useMemo } from "react";
import { Dialog } from "@material-ui/core";


const AlumnusPictureModal = ({ src, alumnus, open, onClose }) => {

  const safeSrc = useMemo(() => {
    if (src) return src;

    const { img } = alumnus || {};
    const { src: _src } = img || {};
    return _src;
  }, [alumnus, src]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" classes={{
      paper: "h-full w-auto rounded-none bg-transparent shadow-none",
    }}>
      <img src={safeSrc}
           alt={alumnus.name.fullName}
           className="h-full w-auto object-contain rounded-8"/>
    </Dialog>
  );
};

export default React.memo(AlumnusPictureModal);
