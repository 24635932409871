import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { FormControl, FormHelperText, FormControlLabel, FormLabel, Checkbox } from "@material-ui/core";
import { requirementMessages } from "../config";
import { getDefaultValueProp } from "../helpers";
import Controller from "./Controller";
import useField from "./useField";


const CheckBox = ({
                    record, field, defaultValue, required, onChangeCb, helperText, title, label, className,
                    inputProps, ...restProps
                  }) => {


  const fieldRequirements = useMemo(() => required && {
    validate: value => value || (typeof required === "string" ? required : requirementMessages.required),
  }, [required]);


  const { name, rules, error, helperTextOrError } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const onChange = useCallback(([{ target: { checked } = {} }]) => {
    if (typeof onChangeCb === "function") onChangeCb(Boolean(checked));

    return Boolean(checked);
  }, [onChangeCb]);


  return (
    <FormControl required={Boolean(required)} error={error}
                 className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}>
      {Boolean(label) && (
        <FormLabel component="legend">{label}</FormLabel>
      )}
      <FormControlLabel
        label={title}
        control={
          <Controller
            {...restProps}
            {...getDefaultValueProp(defaultValue)}
            name={name}
            as={<Checkbox/>}
            onChange={onChange}
            rules={rules}
            type="checkbox"
            inputProps={inputProps}
          />
        }
      />
      {Boolean(helperTextOrError) && (
        <FormHelperText className="pl-16">{helperTextOrError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(CheckBox);
