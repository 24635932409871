import React, { useCallback } from "react";
import clsx from "clsx";
import { Autocomplete } from "components/form";


const CountrySelect = ({ required, countries, helperText = !required && "Az országot üresen hagyva magyarországi címet feltételezünk.", ...props }) => {

  const groupBy = useCallback(option => clsx(option && option.firstLetter), []);

  const getOptionValue = useCallback(option => clsx(option && option.code), []);

  const getOptionTitle = useCallback(option => clsx(option && `${clsx(option.name)} (${clsx(option.code)})`), []);

  const getOptionLabel = useCallback(option => clsx(option && option.name), []);

  const getOptionCountryCode = useCallback(option => clsx(option && option.code), []);

  const getOptionSelected = useCallback((option, value) => Boolean(option && option.code === value), []);

  const getValueOption = useCallback(value => (countries || []).find(option => option && option.code === value), [countries]);

  const getValueLabel = useCallback(value => getOptionLabel(getValueOption(value)), [getOptionLabel, getValueOption]);

  return (
    <Autocomplete
      {...props}
      required={required}
      options={countries}
      groupBy={groupBy}
      getOptionValue={getOptionValue}
      getOptionTitle={getOptionTitle}
      getOptionSelected={getOptionSelected}
      getValueLabel={getValueLabel}
      getOptionCountryCode={getOptionCountryCode}
      helperText={helperText}
      openText="Országok listája"
    />
  );
};

export default React.memo(CountrySelect);
