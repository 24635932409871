import { clientErrors as client } from "./client";
import { serverErrors as server } from "./server";

export const errors = {
  client,
  server,
};

export * from "./auth";
export * from "./decorators";
export * from "./status";
