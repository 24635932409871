import clsx from "clsx";
import { dbEnums } from "common/enums";
import { extendedCards } from "./card";
import { stats } from "./stats"


export const residency_typename = "residency";


const special = ({ group }) => group === dbEnums.membership.TEACHER || group === dbEnums.membership.HONORARY;


export const safeGroup = ({ group }) => {
  if (group === dbEnums.membership.TEACHER) return "A Gimnázium tanára";
  if (group === dbEnums.membership.HONORARY) return "Tiszteletbeli tag";
  return group;
};


const accountStatus = ({ account: { status } }) => status || null;


const hiddenAccount = ({ account: { status } }) => status === dbEnums.accountStatus.DELETED;


const _isSecondaryFilled = ({ sAddress }) => Boolean(sAddress && (sAddress.city || sAddress.country));


const _fromSecondary = ({ sAddress }) => Boolean(_isSecondaryFilled({ sAddress }) && sAddress.isResidence);


const _getAddress = ({ address, sAddress }) => _fromSecondary({ sAddress }) ? sAddress : address || {};


const _country = alumnus => _getAddress(alumnus).country || null;


const _privacy = alumnus => _getAddress(alumnus).privacy || 0;


const _cityAndCountry = alumnus => {
  const { country, city, cName } = _getAddress(alumnus);

  const { n: countryName = "" } = cName || {};

  const normalizedCountryCode = clsx(country).trim().toUpperCase();
  if (!normalizedCountryCode || normalizedCountryCode === "HU") return clsx(city).trim() || null;

  const cityWComma = city && `${city},`;
  const normalizedCountryName = clsx(countryName).trim() || normalizedCountryCode;

  return [cityWComma, normalizedCountryName].join(" ").trim();
};


const residency = alumnus => ({
  id: alumnus.id,
  country: _country(alumnus),
  cityAndCountry: _cityAndCountry(alumnus),
  privacy: _privacy(alumnus),
  fromSecondary: _fromSecondary(alumnus),
  secondaryFilled: _isSecondaryFilled(alumnus),
  __typename: residency_typename,
});


const primaryEmail = ({ emails }) => (emails && emails.find(({ primary }) => primary)) || null;


const primaryPhone = ({ phones }) => (phones && phones.find(({ primary }) => primary)) || null;


const alumnus = {
  special,
  safeGroup,
  accountStatus,
  hiddenAccount,
  stats,
  extendedCards,
  residency,
  primaryEmail,
  primaryPhone,
};

export default alumnus;
