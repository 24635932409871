import gql from "graphql-tag";


export const DOWNLOAD_CSV = gql`
  mutation DownloadGroupmatesCSV {
    returning: downloadGroupmatesCSV @client {
      error
    }
  }
`;


export const DOWNLOAD_XLSX = gql`
  mutation DownloadGroupmatesXLSX {
    returning: downloadGroupmatesXLSX @client {
      error
    }
  }
`;
