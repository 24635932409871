import React from "react";
import navigationConfig from "config/navigation";
import { useFilterByRoles } from "client/lib/session";
import { FuseNavigation } from "components/fuse";


const Navigation = props => {

  const navigation = useFilterByRoles({
    config: navigationConfig,
    childrenKey: "children",
  });

  return Boolean(navigation.length) && (
    <FuseNavigation {...props}
                    navigation={navigation}
    />
  );
};

export default React.memo(Navigation);
