import nextTick from "next-tick";
import { useCallback, useContext } from "react";
import { isFnOrPromise, safeFnOrPromise } from "common/helpers";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const useConfirm = ({ confirmOptions, disabled }) => {

  const { dialog: { openDialog } } = useContext(UiStateContext);


  const onExitConfirmation = useCallback((handler, args) => safeFnOrPromise(handler)(...args), []);


  const open = useCallback((handler, args) => nextTick(() => {
    const safeConfirmOptions = typeof confirmOptions === "function"
      ? confirmOptions() || {}
      : confirmOptions || {};

    openDialog({
      ...safeConfirmOptions,
      onOK: onExitConfirmation.bind(null, handler, args),
    });
  }), [confirmOptions, onExitConfirmation, openDialog]);


  return useCallback(async (handler, ...args) => {
    if (!isFnOrPromise(handler)) return;

    if (!disabled) {
      open(handler, args);

    } else {
      await handler(...args);
    }
  }, [disabled, open]);

};

export default useConfirm;
