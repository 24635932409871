import React from "react";
import { AppBar, Card, CardContent, Toolbar, Typography, Grid } from "@material-ui/core";
import EmailOrPhone from "./EmailOrPhone";
import Address from "./Address"
import SocialMedia from "./SocialMedia";


const ContactCard = ({ alumnus }) => {

  const { socials, stats } = alumnus;

  return (
    <Card className="w-full mb-16">
      <AppBar position="static" elevation={0}>
        <Toolbar className="pl-16 pr-8">
          <Typography variant="subtitle1" color="inherit" className="flex-1">
            Elérhetőségek
          </Typography>
        </Toolbar>
      </AppBar>

      <CardContent>
        <Grid container spacing={3}>

          {Boolean(stats.emails) && (
            <Grid item xs={12}>
              <Typography className="font-bold mb-6 text-15">Email</Typography>
              <Grid container spacing={1}>
                {alumnus.emails.map(email => (
                  <Grid key={email.id} item xs={12}>
                    <EmailOrPhone email={email}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {Boolean(stats.phones) && (
            <Grid item xs={12}>
              <Typography className="font-bold mb-6 text-15">Telefon</Typography>
              <Grid container spacing={1}>
                {alumnus.phones.map(phone => (
                  <Grid key={phone.id} item xs={12}>
                    <EmailOrPhone phone={phone}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {Boolean(stats.address) && (
            <Grid item xs={12}>
              <Typography className="font-bold mb-6 text-15">Levelezési cím</Typography>
              <Address address={alumnus.address}/>
            </Grid>
          )}

          {Boolean(stats.sAddress) && (
            <Grid item xs={12}>
              <Typography className="font-bold mb-6 text-15">Másodlagos cím</Typography>
              <Address address={alumnus.sAddress}/>
            </Grid>
          )}

          {Boolean(stats.socials) && (
            <Grid item xs={12}>
              <Typography className="font-bold mb-6 text-15">Közösségi média, weboldal</Typography>
              <Grid container spacing={1}>
                {socials.map(item => (
                  <Grid key={item.id} item xs="auto">
                    <SocialMedia social={item}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

        </Grid>
      </CardContent>
    </Card>
  );
};

export default React.memo(ContactCard);
