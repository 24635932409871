import React, { useCallback, useContext, useEffect } from "react";
import nextTick from "next-tick";
import { useApolloClient } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/uiState/events";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { SAVE_REG_CODE } from "client/store/regCode/mutations";
import { Form, RegCode } from "components/form";
import { fieldAliases, requiredFields, updateFields, __typename } from "components/form/regCode";
import { ListContext } from "components/listPages";


const EditRegCode = ({ isNew, ...regCode }) => {

  const { dialog: { closeDialog, enableOKButton }, message: { showMessage } } = useContext(UiStateContext);

  const { refetch, resetFilters } = useContext(ListContext)


  const client = useApolloClient();


  const saveCb = useCallback(async ({ jobs, jobsCount }) => {
    if (!jobsCount) return;

    const { error } = await client.mutate({
      mutation: SAVE_REG_CODE,
      variables: {
        record: jobs[__typename][0],
      },
    });

    if (!error) {
      await refetch();
      resetFilters();

    } else {
      showMessage(UiMessages.form.submit.saveError);
    }

    return {
      error,
      noMessage: true,
    };
  }, [client, refetch, resetFilters, showMessage]);


  const onSubmitFinished = useCallback(({ success }) => {
    if (!success) return enableOKButton()

    closeDialog();
    nextTick(() => showMessage(UiMessages.form.submit.saved));
  }, [closeDialog, enableOKButton, showMessage]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitFinished, onSubmitFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitFinished, onSubmitFinished);
    };
  }, [onSubmitFinished]);


  return (
    <Form className="w-full md:min-w-lg"
          records={[regCode]}
          updateFields={updateFields}
          requiredFields={requiredFields}
          fieldAliases={fieldAliases}
          saveCb={saveCb}
          enableSubmit={enableOKButton}
    >
      <RegCode isNew={isNew}
               {...regCode}
      />
    </Form>
  );
};

export default React.memo(EditRegCode);
