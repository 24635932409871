import clsx from "clsx";
import validator from "validator";
import slashes from "remove-trailing-slash";
import { serverLogger } from "server/lib/log";


const getValidEmail = (envVar, email) => {
  if (email && !validator.isEmail(email)) {
    serverLogger.error({
      library: "envVars",
      fn: "getValidEmail",
      description: `Invalid email in ${envVar} envvar`
    });
    return null;
  }
  return email;
};


const getValidUrl = (envVar, url, validateOptions) => {
  const normalized = url && slashes(url);
  if (normalized && !validator.isURL(normalized, validateOptions)) {
    serverLogger.error({
      library: "envVars",
      fn: "getValidUrl",
      description: `Invalid url in ${envVar} envvar`
    });
    return null;
  }
  return normalized;
};


const getRawValue = (envVar, { isEmail, isUrl, validateOptions, noLog } = {}) => {
  if (!envVar) return null;

  const rawValue = process && process.env && process.env[envVar];
  if (!rawValue) {
    if (!noLog) serverLogger.error({
      library: "envVars",
      fn: "getRawValue",
      description: `Missing ${envVar} envvar`
    });
    return null;
  }

  const normalized = rawValue.toString().trim();
  if (isEmail) return getValidEmail(envVar, normalized);
  if (isUrl) return getValidUrl(envVar, normalized, validateOptions);
  return normalized;
};


export const senderEmail = getRawValue("SENDER_EMAIL", { isEmail: true });


export const smsapicomAccessToken = getRawValue("SMSAPICOM_ACCESS_TOKEN");


const supportEmail = getRawValue("SUPPORT_EMAIL", { isEmail: true });


const publicSiteUrl = getRawValue("PUBLIC_SITE_URL", { isUrl: true });


const metaPanels = getRawValue("META_PANELS");


export const clientEnvVars = {
  supportEmail,
  publicSiteUrl,
  supportEmailUri: clsx(supportEmail && `mailto:${supportEmail}`),
  metaPanels,
};
