import React, { useMemo } from "react";
import clsx from "clsx";
import { TextField, InputAdornment, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "components/form";
import { getDefaultValueProp } from "../helpers";
import useSocialEntry from "./useSocialEntry";


const SocialEntry = ({
                       record, accountField, siteField, requirements, required, defaultValue, className,
                       textFieldClassName, helperText, maxLength, variant = "outlined", ...restProps
                     }) => {


  const { name, rules, onChange, helperTextOrError, error, icon, siteFieldName } = useSocialEntry({
    record,
    accountField,
    siteField,
    requirements,
    required,
    helperText,
  });


  const InputProps = useMemo(() => ({
    startAdornment: Boolean(icon) && (
      <InputAdornment position="start">
        <FontAwesomeIcon icon={icon} className="text-14 mr-4"/>
      </InputAdornment>
    ),
  }), [icon]);


  return (
    <Grid container spacing={1} alignItems="flex-start"
          className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}>
      <Grid item xs={12}>
        <Controller
          {...restProps}
          {...getDefaultValueProp(defaultValue)}
          name={name}
          onChange={onChange}
          as={<TextField/>}
          rules={rules}
          inputProps={{
            maxLength: maxLength || 150,
          }}
          classes={{
            root: clsx("w-full", textFieldClassName),
          }}
          variant={variant}
          helperText={helperTextOrError}
          required={Boolean(required)}
          error={error}
          InputProps={InputProps}
        />
        <Controller
          name={siteFieldName}
          as={<div className="hidden"/>}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(SocialEntry);
