import React from "react";
import { Grid } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";
import NameTitle from "./NameTitle";


const HeaderName = ({ alumnus, children }) => (
  <Grid item container spacing={1}>
    <Grid item xs={12}><FuseAnimate animation="transition.slideLeftIn" delay={300}>
      <NameTitle alumnus={alumnus} variant="h6"/>
    </FuseAnimate>
    </Grid>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);

export default React.memo(HeaderName);
