import React, { useCallback, useContext, useEffect, useMemo } from "react";
import nextTick from "next-tick";
import { useApolloClient } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { UPSERT_REVISION_REQUESTS } from "client/store/revisionRequest";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/uiState/events";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { ListContext } from "components/listPages";
import { Form } from "components/form";
import Card, { __typename, fieldAliases, requiredFields, updateFields } from "./RevisionRequest";


const EditRevisionRequest = revisionRequest => {

  const { dialog: { closeDialog, enableOKButton }, message: { showMessage } } = useContext(UiStateContext);

  const { refetch } = useContext(ListContext)


  const client = useApolloClient();


  const record = useMemo(() => ({
    ...revisionRequest,
    __typename,
  }), [revisionRequest]);


  const saveCb = useCallback(async ({ jobs, jobsCount }) => {
    if (!jobsCount) return;

    const { error } = await client.mutate({
      mutation: UPSERT_REVISION_REQUESTS,
      variables: {
        records: jobs[__typename],
      },
    });

    if (!error) {
      await refetch();
    } else {
      showMessage(UiMessages.form.submit.saveError);
    }

    return {
      error,
      noMessage: true,
    };
  }, [client, refetch, showMessage]);


  const onSubmitFinished = useCallback(({ success }) => {
    if (!success) return enableOKButton()

    closeDialog();
    nextTick(() => showMessage(UiMessages.form.submit.saved));
  }, [closeDialog, enableOKButton, showMessage]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitFinished, onSubmitFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitFinished, onSubmitFinished);
    };
  }, [onSubmitFinished]);


  return (
    <Form className="w-full md:min-w-lg"
          records={[record]}
          updateFields={updateFields}
          requiredFields={requiredFields}
          fieldAliases={fieldAliases}
          saveCb={saveCb}
          enableSubmit={enableOKButton}
    >
      <Card {...record}/>
    </Form>
  );
};

export default React.memo(EditRevisionRequest);
