import gql from "graphql-tag";


export const SAVE_COOPERATION = gql`
  mutation SaveCooperation($record: jsonb!) {
    saveCooperation(record: $record) @client
  }
`;


export const UPDATE_ALUMNUS_COOPERATION = gql`
  mutation UpdateAlumnusCooperation($id: Int!, $changes: alumnus_cooperation_set_input) {
    results: update_alumnus_cooperation(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;
