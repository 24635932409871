import React from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { DOWNLOAD_CSV, DOWNLOAD_XLSX } from "client/store/revisionRequest";
import DelButton from "./DelButton";
import EditButton from "./EditButton";


const RowControl = revisionRequest => {

  const [downloadCSV] = useMutation(DOWNLOAD_CSV, {
    variables: { id: revisionRequest.id },
  });

  const [downloadXLSX] = useMutation(DOWNLOAD_XLSX, {
    variables: { id: revisionRequest.id },
  });

  return (
    <Grid container spacing={1} alignItems="center" className="sm:min-w-96">
      <Grid item>
        <EditButton {...revisionRequest}/>
      </Grid>
      <Grid item>
        <div className="min-w-28">
          <Tooltip title="Függőben lévők letöltése Excel táblázatként (.xslx)">
            <IconButton size="small"
                        onClick={downloadXLSX}
            >
              <FontAwesomeIcon className="text-24" icon={faFileExcel} color="rgba(0, 0, 0, 0.4)"/>
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
      <Grid item>
        <div className="min-w-24">
          <Tooltip title="Függőben lévők letöltése szövegfájlként (.csv)">
            <IconButton size="small"
                        onClick={downloadCSV}
            >
              <FontAwesomeIcon className="text-24" icon={faFileCsv} color="rgba(0, 0, 0, 0.4)"/>
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
      <Grid item>
        <DelButton {...revisionRequest}/>
      </Grid>
    </Grid>
  );
};

export default React.memo(RowControl);
