import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { GET_LOG_IP_ADRESSES } from "client/store/alumnus";


const parseIps = ips => ips.map(({ ip }) => ({ ip }));


export const extendIps = async ips => {
  const parsedIps = parseIps(ips);

  const { error, data: { data } } = await backendFetch(backendServices.geoip, { ips: parsedIps });
  if (error) return ips;

  return ips && ips.reduce((items, item) => {
    const { ip } = item || {};
    const country = ip
      ? data[ip]
      : null;
    return country
      ? { ...items, [ip]: country }
      : items;
  }, {});
};


export const ipsWCountries = async (_root, _args, { client }) => {
  try {
    const { data: { ips } = {} } = await client.query({ query: GET_LOG_IP_ADRESSES });
    if (!(ips && ips.length > 0)) return [];

    return await extendIps(ips);

  } catch (e) {
    errorLogStore("alumnus", "ipsWCountries", e, "on fetch geoip");
    return [];
  }
};

export default ipsWCountries;
