const tagsString = ({ tagsW }) => (tagsW && tagsW.str) || null;


const tags = ({ tagsW }) => (tagsW && tagsW.tags) || null;


const tag = {
  tags,
  tagsString,
};

export default tag;
