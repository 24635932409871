import React from "react";
import { useUiSearch } from "./uiState";


export const SearchContext = React.createContext(null);


const SearchProvider = props => {

  const search = useUiSearch();

  return (
    <SearchContext.Provider value={search}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default React.memo(SearchProvider);
