import React from "react";
import { Typography } from "@material-ui/core";


const Hobby = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon <b>érdeklődési körödet, hobbijaidat</b> oszthatod meg. Az itt megadott információk kereshetővé
      válnak az <span className="text-blue">Alumnus keresőben</span>, lehetőséget biztosítva arra, hogy a hasonló
      érdeklődési körrel bíró alumnusok könnyebben megtalálhassák egymást.
    </Typography>
    <Typography className="my-20">
      Az érdeklődési körödet, hobbijaidat <b>kulcsszavakkal</b> is megadhatod. Ezek az adatlapodon címkékkel jelennek
      meg.
    </Typography>
    <Typography className="my-20">
      <b>Részletesebb bemutatáshoz</b> az erre vonatkozó, többsoros szövegmező kitöltésével nyílik lehetőség. Ebben
      célszerű lehet webes hivatkozásokat is elhelyezni. Ahhoz, hogy az adatlapon e hivatkozások kattinthatók legyenek,
      mindig teljes URL-t adj meg! (Pl. a <span className="text-blue">https://example.com</span> kattintható lesz,
      a <span className="text-red">example.com</span> viszont nem.)
    </Typography>
    <Typography className="mt-20">
      Az itt megadott kulcsszavak és a bemutatkozás mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
  </>
);

export default React.memo(Hobby);
