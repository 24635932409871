import clsx from "clsx";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";


const parsed = ({ phone }) => {
  const normalizedPhone = clsx(phone).trim();
  const wCountryCode = normalizedPhone.substr(0, 1) !== "+"
    ? `+${normalizedPhone}`
    : normalizedPhone;
  const parsedNumber = parsePhoneNumberFromString(wCountryCode);
  if (parsedNumber) {
    const { country } = parsedNumber;
    return {
      normalized: parsedNumber.formatInternational() || wCountryCode,
      country
    };
  } else {
    return { normalized: wCountryCode }
  }
};


const phone = {
  parsed,
};

export default phone;
