import { dbEnums } from "common/enums";
import toFilter from "./toFilter";
import delSpecialAccount from "./delSpecialAccount";


const role = ({ roles }) => (roles || []).includes(dbEnums.accountRoles.STUDENT) ? dbEnums.accountRoles.STUDENT : dbEnums.accountRoles.RETAILER;


export const resolvers = {
  view_special_account: {
    toFilter,
    role,
  },
  Mutation: {
    delSpecialAccount,
  },
};
