import React from "react";
import clsx from "clsx";


const FusePageCardedSidebarContent = props => {
  const classes = props.classes;

  return (
    <React.Fragment>
      {props.header && (
        <div className={clsx(classes.sidebarHeader, props.variant)}>
          {props.header}
        </div>
      )}

      {props.content && (
        <div className={clsx(classes.sidebarContent, "h-full")}>
          {props.content}
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(FusePageCardedSidebarContent);
