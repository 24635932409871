import gql from "graphql-tag";


export const UPDATE_ALUMNUS_NEWSLETTER = gql`
  mutation UpdateAlumnusNewsletter($id: Int!, $changes: alumnus_newsletter_set_input) {
    results: update_alumnus_newsletter(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;
