import React, { useState, useCallback } from "react";


export const ModuleLoaderContext = React.createContext(null);


const modules = {
  session: false,
};


const ModuleLoader = ({ children }) => {

  const [loadedModules, setLoadedModules] = useState(modules);
  const [splashScreen, setSplashScreen] = useState(true);


  const checkModules = useCallback(modules => {
    if (splashScreen) {
      const allLoaded = Object.values(modules || {}).every(module => module);
      if (allLoaded) setSplashScreen(false);
    }
  }, [splashScreen]);


  const moduleLoaded = useCallback(module => {
    if (splashScreen && module && loadedModules) {
      setLoadedModules(previous => {
        const current = {
          ...previous,
          [module]: true,
        };
        checkModules(current);
        return current;
      });
    }
  }, [checkModules, loadedModules, splashScreen]);


  const contextValue = {
    splashScreen,
    moduleLoaded,
  };


  return (
    <ModuleLoaderContext.Provider value={contextValue}>
      {children}
    </ModuleLoaderContext.Provider>
  );
};

export default React.memo(ModuleLoader);
