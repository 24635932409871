import React from "react";
import { Typography, Grid } from "@material-ui/core";
import Chip from "components/alumnus/Chip";


const AccountStatus = ({ account: { statusProps }, children }) => (
  <Grid container spacing={4}>
    <Grid item>
      <Chip label={statusProps.label}
            color={statusProps.color}
            AvatarComponent={statusProps.avatar}
      />
    </Grid>
    {Boolean(statusProps.expiry) && (
      <Grid item container>
        <Grid item>
          <Typography className="mr-4">Tényleges adattörlés időpontja: </Typography>
        </Grid>
        <Grid item>
          <Typography color="textSecondary">{statusProps.expiry}</Typography>
        </Grid>
      </Grid>
    )}
    {children}
  </Grid>
);

export default React.memo(AccountStatus);
