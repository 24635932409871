import { requestMethods } from "server/lib/api/config";


export const primaryListName = "Alumni Online 1";
export const secondaryListName = "Alumni Online 2";

export const octopusSettingKey = "octopus";

export const primaryListLimit = 2490;

export const requestsPerSecond = 5;
export const safeMargin = 10; // in %
export const stopMutationErrorMessage = "STOP_MUTATION";

export const remoteBaseURL = "https://emailoctopus.com";
export const remoteVersionPath = "/api/1.5";
export const maxLimit = 100;

export const contactStatuses = {
  subscribed: "SUBSCRIBED",
  unsubscribed: "UNSUBSCRIBED",
  pending: "PENDING",
};

export const subscriptionStatuses = {
  orphan: {
    code: "orphan",
    // contact's status: subscribed
    // member is missing
    // no task
  },
  unsubscribedUnsynced: {
    code: "unsubscribedUnsynced",
    // contact's status: unsubscribed
    // member's status: subscribed
    // tasks: tasks.unsubcribeAlumnus + tasks.deleteContact
  },
  unsubscribedSynced: {
    code: "unsubscribedSynced",
    // contact's status: unsubscribed
    // member's status: unsubscribed
    // task: deleteContact
  },
  subscribedUnsynced: {
    code: "subscribedUnsynced",
    // contact's status: subscribed
    // member's status: unsubscribed
    // task: deleteContact
  },
  subscribedNameUnsynced: {
    code: "subscribedNameUnsynced",
    // contact's status: subscribed
    // member's status: subscribed
    // tasks updateContact
  },
  subscribedSynced: {
    code: "subscribedSynced",
    // contact's status: subscribed
    // member's status: subscribed
    // no task
  },
  missing: {
    code: "missing",
    // contact is missing
    // member's status: subscribed
    // task: createContact
  }
};

export const taskTypes = {
  unsubcribeAlumnus: "unsubcribeAlumnus",
  deleteContact: "deleteContact",
  updateContact: "updateContact",
  createContact: "createContact",
};

export const statusTasks = [
  {
    task: taskTypes.unsubcribeAlumnus,
    statuses: [subscriptionStatuses.unsubscribedUnsynced],
  },
  {
    task: taskTypes.deleteContact,
    statuses: [subscriptionStatuses.unsubscribedUnsynced, subscriptionStatuses.unsubscribedSynced, subscriptionStatuses.subscribedUnsynced],
  },
  {
    task: taskTypes.updateContact,
    statuses: [subscriptionStatuses.subscribedNameUnsynced],
  },
  {
    task: taskTypes.createContact,
    statuses: [subscriptionStatuses.missing],
  },
];

export const mutationStatuses = {
  uninitialized: "uninitialized",
  started: "started",
  completed: "completed",
  failed: "failed",
  aborted: "aborted",
};

export const fetchSettings = {
  GET: {
    method: requestMethods.GET,
    headers: {},
  },
  POST: {
    method: requestMethods.POST,
    headers: {
      "Content-Type": "application/json",
    },
  },
  PUT: {
    method: requestMethods.PUT,
    headers: {
      "Content-Type": "application/json",
    },
  },
  DELETE: {
    method: requestMethods.DELETE,
    headers: {
      "Content-Type": "application/json",
    },
  },
};
