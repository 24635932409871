import React from "react";
import clsx from "clsx";
import { GET_JOB_COMPANIES, GET_JOB_SECTORS } from "client/store/alumnus";
import JobBase, { _fieldAliases, _newRecordValues, _updateFields as __updateFields } from "./JobBase";


export const __typename = "job";


const _updateFields = {
  ...__updateFields,
  company: "company",
  job: "job",
  sector: "sector",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.privacy, _updateFields.company, _updateFields.job, _updateFields.current],
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


export const newRecordValues = {
  ..._newRecordValues,
  [_updateFields.company]: "",
  [_updateFields.job]: "",
  [_updateFields.sector]: null,
};


const getSectorHelperText = autocomplete => (
  <span>A munkahelyre/projektre vonatkozó, nagyvonalú besorolás (pl. <span className="text-blue">ICT</span> vagy <span
    className="text-blue">közigazgatás</span>){clsx(autocomplete && ".")}</span>
);


const Job = () => (
  <JobBase
    __typename={__typename}
    projectField={_updateFields.company}
    projectQuery={GET_JOB_COMPANIES}
    roleField={_updateFields.job}
    sectorField={_updateFields.sector}
    sectorQuery={GET_JOB_SECTORS}
    projectLabel="Munkahely / projekt"
    roleLabel="Beosztás, munkakör"
    sectorLabel="Iparág, szektor"
    sectorHelperText={getSectorHelperText(false)}
    sectorHelperTextAutocomplete={getSectorHelperText(true)}
    currentTitle="Jelenleg is ezen a munkahelyen/projekten dolgozom"
    confirmEntity="a munkahelyet/projektet"
    privacyTooltip="Erre a munkahelyre vonatkozó"
  />
);

export default React.memo(Job);
