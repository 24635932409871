import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import FlagIcon from "components/alumnus/FlagIcon";
import { Controller } from "components/form";
import { getDefaultValueProp } from "../helpers";
import useField from "./useField";


export const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});


const Autocomplete = ({
                        record, field, options, label, helperText, placeholder, className, textFieldProps, defaultValue,
                        requirements, required, autoSelect, groupBy, getOptionValue, getOptionTitle, getOptionSelected,
                        getOptionCountryCode, getValueLabel, noOptionsText = "Nem található ilyen érték",
                        openText = "Leggyakoribbak", variant = "outlined", ...restProps
                      }) => {


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    required,
  }), [required, requirements]);


  const { error, name, helperTextOrError, rules } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const _getOptionValue = useCallback(option => typeof getOptionValue === "function"
    ? getOptionValue(option) || null
    : clsx(option) || null,
    [getOptionValue]);


  const _getOptionTitle = useCallback(option => typeof getOptionTitle === "function"
    ? getOptionTitle(option)
    : clsx(option),
    [getOptionTitle]);


  const _getOptionSelected = useCallback((option, value) => typeof getOptionSelected === "function"
    ? getOptionSelected(option, value)
    : option === value,
    [getOptionSelected]);


  const _getOptionCountryCode = useCallback(option => typeof getOptionCountryCode === "function"
    ? getOptionCountryCode(option)
    : "",
    [getOptionCountryCode]);


  const _getValueLabel = useCallback(value => {
      // some workaround needed because of on first render Autocomplete doesn't call the value renderer function (_getOptionValue) defined by its getOptionLabel attribute
      const safeValue = (value && options && options[0] && typeof value === typeof options[0])
        ? _getOptionValue(value)
        : value;

      return typeof getValueLabel === "function"
        ? clsx(getValueLabel(safeValue))
        : clsx(safeValue)
    },
    [_getOptionValue, getValueLabel, options]);


  const onChange = useCallback(args => _getOptionValue(args[1]), [_getOptionValue]);


  const renderOption = useCallback((option, { inputValue }) => {
    const title = _getOptionTitle(option);
    const matches = match(title, clsx(inputValue));
    const parts = parse(title, matches);

    const countryCode = _getOptionCountryCode(option);

    return (
      <>
        {Boolean(countryCode) && (
          <FlagIcon className="h-16 ml-8 my-0 py-0" code={countryCode}/>
        )}
        <div>
          {parts.map((part, index) => (
            <span key={index} className={{ fontWeight: part.highlight ? "font-700" : "font-400" }}>
                    {part.text}
                  </span>
          ))}
        </div>
      </>
    );
  }, [_getOptionCountryCode, _getOptionTitle]);


  const renderInput = useCallback(params => (
    <TextField
      {...params}
      {...textFieldProps}
      error={error}
      required={Boolean(required)}
      label={label}
      variant={variant}
      fullWidth
      inputProps={{
        ...params.inputProps,
        autoComplete: "disabled",
      }}
    />
  ), [error, label, required, textFieldProps, variant]);


  const classes = useStyles();


  return (
    <FormControl variant={variant} error={error}
                 className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}>
      <Controller
        {...restProps}
        {...getDefaultValueProp(defaultValue)}
        name={name}
        as={(
          <MuiAutocomplete renderInput={renderInput}
                           options={options || []}
          />
        )}
        rules={rules}
        onChange={onChange}
        id={name}
        placeholder={clsx(placeholder)}
        groupBy={groupBy}
        autoSelect={autoSelect}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionSelected={_getOptionSelected}
        getOptionLabel={_getValueLabel}
        noOptionsText={noOptionsText}
        renderOption={renderOption}
        renderInput={renderInput}
        closeText="Bezár"
        clearText="Törlés"
        loadingText="Lista betöltése..."
        openText={openText}
      />
      {Boolean(helperTextOrError) && (
        <FormHelperText>{helperTextOrError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(Autocomplete);
