import gql from "graphql-tag";


export const UPDATE_SPECIAL_ACCOUNT = gql`
  mutation UpdateSpecialAccount($id: Int!, $note: String = null) {
    results: update_account(where: {special: {_eq: true}, id: {_eq: $id}}, _set: {note: $note}) {
      affected_rows
    }
  }
`;
