import gql from "graphql-tag";


export const SAVE_CARDS = gql`
  mutation SaveCards($cards: jsonb!, $deletedIds: [Int!], $alumnusId: Int!) {
    saveCards(cards: $cards, deletedIds: $deletedIds, alumnusId: $alumnusId) @client
  }
`;


export const UPSERT_CARDS = gql`
  mutation UpsertCards($records: [card_insert_input!]!) {
    results: insert_card(objects: $records, on_conflict: {constraint: card__pkey, update_columns: [status, card_number, date_of_issue, expiry]}) {
      affected_rows
    }
  }
`;


export const DELETE_CARDS = gql`
  mutation DeleteCards($ids: [Int!]!) {
    results: delete_card(where: {id: {_in: $ids}}) {
      affected_rows
    }
  }
`;
