import React from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { maxSize, minSize } from "components/form/avatar/useFileUpload";


const minFileSize = clsx(Math.trunc(minSize / 1024), "kB");
const maxFileSize = clsx(Math.trunc(maxSize / 1024 / 1024), "MB");


const Avatar = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon az adatlapodon megjelenő <b>profilképet</b> állíthatod be.
    </Typography>
    <Typography className="my-20">
      Új profilkép feltöltéséhez <b>húzd az erre kijelölt területre</b> a feltölteni kívánt képfájlt, vagy kattints
      a <i>Fájl kiválasztása</i> gombra, és jelöld ki a képfájlt. Engedélyezett <i>fájltípusok</i>: <b>.jpg, .jpeg,
      .png, .apng, .svg, .svgz és .webp</b>. A <i>képméret</i> <b>legalább {minFileSize},
      legfeljebb {maxFileSize}</b> lehet.
    </Typography>
    <Typography className="my-20">
      A profilképnek 1:1 méretarányúnak (négyzetalakúnak) kell lennie. Ha a feltöltésre kijelölt fájl képaránya ettől
      eltér, akkor az erre szolgáló <span className="text-blue">vágókeret eszközzel</span> jelölheted ki a feltöltésre
      szánt képterületet. Alapértelmezetten a kisebbik oldalhoz igazodva jelenik meg a vágókeret. Ennek mérete a
      sarkainál kijelölve változtatható, valamint megragadva eltolható.
    </Typography>
    <Typography className="my-20">
      Egyidejűleg egy profilkép feltöltésére van lehetőség. <b>Új profilkép beállításához először törölni kell a meglévő
      profilképet</b>, majd ezt követően feltölteni az újat.
    </Typography>
    <Typography className="my-20">
      A <b>meglévő profilkép</b> természetesen anélkül is <b>törölhető</b>, hogy utána újat vinnél fel.
    </Typography>
    <Typography className="mt-20">
      A feltöltött profilkép mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
  </>
);

export default React.memo(Avatar);
