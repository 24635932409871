import React, { useContext, useMemo } from "react";
import moment from "moment";
import { Grid, Typography, Checkbox } from "@material-ui/core";
import { MiniChip } from "components/common";
import FlagIcon from "components/alumnus/FlagIcon";
import { onCheckboxClick, ListContext } from "components/listPages";


const hunCode = "HU";


const Row = ({ isChecked, onCheck, ip, expiry }) => {

  const { bannedIpsWCountries } = useContext(ListContext);


  const label = useMemo(() => expiry
    ? `${moment(expiry).format("L HH:mm")}-ig`
    : "Határozatlan idejű"
    , [expiry]);


  const colorClassName = useMemo(() => expiry ? "bg-orange" : "bg-red", [expiry]);


  const code = useMemo(() => bannedIpsWCountries && bannedIpsWCountries[ip], [bannedIpsWCountries, ip]);


  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <Checkbox
          tabIndex={-1}
          disableRipple
          checked={isChecked()}
          onChange={onCheck}
          onClick={onCheckboxClick}
        />
      </Grid>
      {Boolean(code && code !== hunCode) && (
        <Grid item>
          <FlagIcon code={code} disableHun/>
        </Grid>
      )}
      <Grid item>
        <Typography className="min-w-112">{ip}</Typography>
      </Grid>
      <Grid item>
        <MiniChip label={label}
                  colorClassName={colorClassName}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Row);
