import { InMemoryCache } from "apollo-cache-inmemory";
import defaultData from "./defaultCache";


let cache;


export const loadDefaultCache = () => cache && cache.writeData({ data: defaultData });


export const getCache = () => {
  if (cache) return cache;

  cache = new InMemoryCache({
    freezeResults: true,
  });

  loadDefaultCache();

  return cache;
};
