import clsx from "clsx";


const countryName = ({ cName }) => (cName && cName.n) || null;


const buildInstitution = (institution, city, country = "") => [institution, city, country].join(" ").trim() || null;


const institutionFull = ({ country, institution, city, cName }) => {
  const { n: countryName = "" } = cName || {};
  const normalizedCountryCode = clsx(country).trim().toUpperCase();
  const normalizedCountry = clsx(countryName).trim() || normalizedCountryCode;
  const normalizedCity = clsx(city).trim();
  const domestic = (normalizedCountryCode === "HU");

  const institutionWComma = (city || (normalizedCountryCode && !domestic))
    ? `${clsx(institution).trim()},`
    : clsx(institution).trim();
  if (domestic) return buildInstitution(institutionWComma, normalizedCity);

  const cityWComma = normalizedCountryCode
    ? `${normalizedCity},`
    : normalizedCity;
  return buildInstitution(institutionWComma, cityWComma, normalizedCountry);
};


const study = {
  countryName,
  institutionFull,
};

export default study;
