import React, { useMemo } from "react";
import clsx from "clsx";
import { Chip, Grid, Typography } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";


const NameTitle = ({ alumnus: { name, account, accountStatus }, className, variant = "body1" }) => {

  const forbidden = useForbidden([dbEnums.accountRoles.MANAGER]);

  const nonActive = useMemo(() => accountStatus !== dbEnums.accountStatus.ACTIVE, [accountStatus]);

  const deleted = useMemo(() => Boolean(accountStatus === dbEnums.accountStatus.DELETED), [accountStatus]);

  const shortLabel = useMemo(() => clsx(account && account.statusProps && account.statusProps.shortLabel), [account]);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Typography variant={variant} className={clsx(!clsx(className).includes("text-") && "text-16 sm:text-20")}>
          {name && name.fullName}
        </Typography>
      </Grid>
      {Boolean(!forbidden && nonActive && shortLabel) && (
        <Grid item>
          <Chip label={shortLabel} size="small" color="secondary" className={deleted ? "bg-red" : "bg-orange"}/>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(NameTitle);
