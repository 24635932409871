import gql from "graphql-tag";


export const GET_LOG_IP_ADRESSES = gql`
  query GetLogIpAdresses {
    ips: view_action_log(distinct_on: ip) {
      ip
    }
  }
`;


export const GET_LOG_IP_ADRESSES_W_COUNTRIES = gql`
  query GetLogIpAdressesWCountries {
    ipsWCountries @client
  }
`;
