import React, { useContext, useMemo } from "react";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { NotAllowed, Error } from "components/error";
import { Splash } from "components/common";
import { AuthenticatedLayout } from "./authenticated";
import { UnauthenticatedLayout } from "./unauthenticated";
import { useStyles } from "./styles";
import useLayout from "./useLayout";


const Layout = ({ error, notFound, forbidden, help, wrapper, ...props }) => {

  const { authenticated } = useContext(SessionContext);


  const { routerLoading, WrapperComponent } = useLayout({
    help,
    wrapper,
  });


  const LayoutComponent = useMemo(() => !authenticated
    ? UnauthenticatedLayout
    : AuthenticatedLayout
    , [authenticated]);


  const classes = useStyles();


  if (routerLoading) return (<Splash/>);

  if (notFound || forbidden) return (<NotAllowed notFound={notFound}/>);

  if (error) return (<Error/>);

  return (
    <LayoutComponent {...props}
                     authenticated={authenticated}
                     classes={classes}
                     WrapperComponent={WrapperComponent}
    />
  );
};

export default React.memo(Layout);
