import { useCallback, useRef, useState } from "react";
import { emitUiStateEventNextTick, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { getIds, filterRows, parseRows } from "../helpers";
import usePage from "./usePage";
import useCheck from "./useCheck";
import useFilter from "./useFilter";


const useList = ({ idField = "id", filters, defaultPage, defaultPageSize, pageSizes } = {}) => {

  const _rawRows = useRef();

  const _filteredIds = useRef([]);


  const [sidebarOpened, _setSidebarOpened] = useState(false);

  const [rows, _setRows] = useState([]);

  const [count, _setCount] = useState(0);


  const toggleSidebar = useCallback(() => _setSidebarOpened(state => !state), []);


  const _getFilteredIds = useCallback(() => _filteredIds.current, []);


  const _updateRows = useCallback((page, pageSize) => {
    _setRows(filterRows(_rawRows.current, idField, page, pageSize, _filteredIds.current));
    emitUiStateEventNextTick(uiStateEvents.listPages.updateFinished);
  }, [idField]);


  const { _updatePages, pageSize, ...page } = usePage({
    _getFilteredIds,
    _updateRows,
    defaultPage,
    defaultPageSize,
    pageSizes,
  });


  const { _onFilterChecked, _onFilterUnchecked, _onFiltersChanged, _updateChecked, checkedIds, ...check } = useCheck({
    _getFilteredIds,
    rows,
  });


  const _setFilteredIds = useCallback(filterFn => {
    const filteredRows = (_rawRows.current || []).filter(filterFn);

    const ids = getIds(filteredRows, idField);

    _filteredIds.current = ids;

    return ids;
  }, [idField]);


  const _update = useCallback(filters => {
    const ids = _setFilteredIds(rawRow => Object.values(filters || {}).every(({ testFn, value }) => {
      return typeof testFn !== "function" || Boolean(testFn(value, rawRow))
    }));

    _updatePages(ids);

    _updateChecked();

    _onFiltersChanged();
  }, [_setFilteredIds, _updatePages, _updateChecked, _onFiltersChanged]);


  const { resetFilters, ...filter } = useFilter({
    _update,
    _onFiltersChanged,
    filters,
  });


  const filterChecked = useCallback(() => {
    _filteredIds.current = checkedIds;

    _updatePages(checkedIds);

    resetFilters(true);
    _onFilterChecked();
  }, [checkedIds, _onFilterChecked, resetFilters, _updatePages]);


  const filterUnchecked = useCallback(() => {
    const ids = _setFilteredIds(rawRow => !checkedIds.includes(rawRow[idField]));

    _updatePages(ids);

    resetFilters(true);
    _onFilterUnchecked();
  }, [checkedIds, idField, _onFilterUnchecked, resetFilters, _setFilteredIds, _updatePages]);


  const reload = useCallback(data => {
    if (data) {
      const parsedRows = parseRows(data);
      _rawRows.current = parsedRows;

      _setCount(parsedRows.length);

      _update();
    }
  }, [_update]);


  return {
    ...page,
    ...check,
    ...filter,
    pageSize,
    checkedIds,
    resetFilters,
    filterChecked,
    filterUnchecked,
    sidebarOpened,
    toggleSidebar,
    rows,
    count,
    reload,
  };
};

export default useList;
