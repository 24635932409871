import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import {
  resolvers,
  GET_COUNTRIES,
  UPDATE_ALUMNUS_ADDRESS,
  UPDATE_ALUMNUS_SECONDARY_ADDRESS
} from "client/store/alumnus";
import FlagIcon from "components/alumnus/FlagIcon";
import { Form, Address, getSaveCb } from "components/form";
import { AlumnusContext } from "components/alumnus";
import { updateFields, requiredFields, fieldAliases, typenames, Residency } from "components/form/address";


const gridProps = {
  sm: 12,
  md: 12,
  className: "max-w-2xl",
};


const PanelContents = {
  [typenames.address]: Address,
  [typenames.sAddress]: Address,
  [typenames.residency]: Residency,
};


const mutations = {
  [typenames.address]: UPDATE_ALUMNUS_ADDRESS,
  [typenames.sAddress]: UPDATE_ALUMNUS_SECONDARY_ADDRESS,
};


export const captionCb = ({ __typename }) => __typename === typenames.sAddress
  ? "Másodlagos cím"
  : __typename === typenames.residency
    ? "Ahol élsz"
    : "Levelezési cím";


const getCountryName = countries => code => ((countries || []).find(({ code: c }) => c === code) || {}).name;


export const titleCb = countries => ({ __typename, prefixedId, getItemValues }) => {
  const values = getItemValues(__typename, prefixedId);

  const countryName = getCountryName(countries)(values[updateFields[__typename].country]);

  return __typename === typenames.residency
    ? clsx(values[updateFields[__typename].cityAndCountry], values[updateFields[__typename].fromSecondary]
      ? "(a másodlagos cím alapján)"
      : "(a levelezési cím alapján)")
    : resolvers.alumnus_address.full({
    name: values.addressName, // on registration form
    ...values,
    countryName,
  }) || null;
};


export const getTitleComponentCb = ({ __typename, prefixedId, getItemValues }) => {
  const { country, zip, city, cityAndCountry } = getItemValues(__typename, prefixedId, updateFields[__typename].country);

  return Boolean(country && (zip || city || cityAndCountry)) && (
    <div className="min-w-20 mr-8">
      <FlagIcon code={country}/>
    </div>
  );
};


const headerAlertText = "Az Alumni a levelezési címeden tudja felvenni a kapcsolatot. Ha nem itt élsz, vagy ha egy másik címet osztanál meg a diáktársakkal, akkor ezt a másodlagos cím kitöltésével adhatod meg.";


const EditAddress = () => {

  const { panelKey, submitLoading, disableSubmit, enableSubmit, alumnus: { residency, address, sAddress } } = useContext(AlumnusContext);


  const client = useApolloClient();


  const { loading, error, data: { countries } = {} } = useQuery(GET_COUNTRIES);


  const saveCb = useCallback(async ({ jobs }) => await getSaveCb(client, mutations)(jobs), [client]);


  return Boolean(!loading && !error && countries) && (
    <Form className="max-w-md md:max-w-lg lg:max-w-xl"
          records={[address, sAddress, residency]}
          updateFields={updateFields}
          requiredFields={requiredFields}
          fieldAliases={fieldAliases}
          disabled={submitLoading}
          disableSubmit={disableSubmit}
          enableSubmit={enableSubmit}
          saveCb={saveCb}
          onErrorEntityStr="címet"
          PanelContents={PanelContents}
          panelKey={panelKey}
          gridProps={gridProps}
          captionWidth={3}
          captionCb={captionCb}
          titleCb={titleCb(countries)}
          getTitleComponentCb={getTitleComponentCb}
          HeaderAlert={headerAlertText}
    />
  );
};

export default React.memo(EditAddress);
