import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import { Icon, IconButton, makeStyles, SwipeableDrawer, Typography } from "@material-ui/core";
import { emptyFn } from "common/helpers";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { Scrollbars } from "components/layout";


const defaultTitle = "Súgó";


const useStyles = makeStyles(theme => ({
  root: {
    "& a:not([role=button])": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
    },
    "& a:not([role=button]):hover": {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
    }
  },
}));


const Help = () => {

  const { help: { opened, config, closeHelp } } = useContext(UiStateContext);

  const classes = useStyles();

  const title = useMemo(() => (config && config.title) || defaultTitle, [config]);

  const helpProps = useMemo(() => (config && config.props) || {}, [config]);

  const HelpContent = useMemo(() => config && config.content, [config]);

  return Boolean(HelpContent) && (
    <SwipeableDrawer
      anchor="right"
      open={opened}
      onClose={closeHelp}
      onOpen={emptyFn}
    >
      <div
        className="w-288 sm:w-384 md:w-md lg:w-lg xl:w-xl h-full"
        role="presentation"
        onClick={closeHelp}
        onKeyDown={closeHelp}
      >
        <Scrollbars>
          <div className={clsx(classes.root, "pt-24 md:pt-28 pb-32 md:pb-36 pl-32 md:pl-36 pr-20 md:pr-24")}>
            <div className="flex flex-row items-center justify-between mb-28">
              <Typography variant="h6">{title}</Typography>
              <IconButton onClick={closeHelp}>
                <Icon>close</Icon>
              </IconButton>
            </div>
            <div className="mr-12">
              <HelpContent {...helpProps}/>
            </div>
          </div>
        </Scrollbars>
      </div>
    </SwipeableDrawer>
  );
};

export default React.memo(Help);
