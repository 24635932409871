import gql from "graphql-tag";


export const GET_COUNTRIES = gql`
  query GetCountries {
    countries: country(order_by: {name: asc}) {
      code
      name
      firstLetter @client
    }
  }
`;
