import { safeFnOrPromise } from "common/helpers";
import { downloadFile } from "client/lib/backendApi";


const emptyReturningDownloadFile = {
  error: false,
  __typename: "download",
};


const download = async (getData, client, service, args) => {
  const data = await safeFnOrPromise(getData)(client, args);
  if (data && data.error) return data;

  const { fileName, ...body } = data;

  const { error } = await downloadFile(service, body, fileName);

  return !error
    ? emptyReturningDownloadFile
    : {
      ...emptyReturningDownloadFile,
      error,
    };
};

export default download;
