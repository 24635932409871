import React, { useCallback } from "react";
import { Grid, Hidden, Icon, IconButton, TextField } from "@material-ui/core";
import { logActionDetails } from "client/store/alumnus/resolvers/getters/view_action_log";
import { useList, SelectFilter, TextFilter, Rows, ListContext } from "components/listPages";
import { getFilterTestFnObj } from "components/listPages/helpers";
import Row from "./Row";


const defaultPageSize = 10;

const fields = {
  id: "id",
  action: "action",
  ip: "ip",
};


const filters = {
  [fields.ip]: getFilterTestFnObj(fields.ip),
  [fields.action]: getFilterTestFnObj(fields.action),
};


const actions = Object.entries(logActionDetails).map(([key, { actionStr: value, icon, iconColor }]) => ({
  key,
  value,
  icon,
  iconColor
}));


const List = ({ data, ipsWCountries }) => {

  const { setFilter, filterValues, ...list } = useList({
    data,
    filters,
    defaultPageSize,
  });


  const resetTextFilter = useCallback(() => setFilter(fields.ip), [setFilter]);


  const contextValue = {
    ...list,
    actions,
    ipsWCountries,
  };


  return (
    <ListContext.Provider value={contextValue}>
      <Grid container spacing={2} wrap="wrap-reverse" alignItems="center" justify="space-between"
            className="mb-16 pl-2 pr-1">
        <Grid item xs={12} sm={6} xl={5}>
          <SelectFilter filterId={fields.action}
                        filterValue={filterValues[fields.action]}
                        setFilter={setFilter}
                        items={actions}
                        label="Szűrés típus szerint"
                        className="w-full"
                        inputClassName="py-8"
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={7}>
          <Grid container alignItems="center" justify="space-between" wrap="nowrap">
            <Grid item xs={12} sm={11}>
              <TextFilter filterId={fields.ip}
                          filterValue={filterValues[fields.ip]}
                          setFilter={setFilter}
                          Component={TextField}
                          debounce={0}
                          label="Szűrés IP cím szerint"
                          className="w-full"
                          placeholder=""
                          inputClassName="py-8"
                          disableEndAndorment
              />
            </Grid>
            <Hidden xsDown>
              <Grid item>
                <IconButton
                  onClick={resetTextFilter}
                  aria-label="Szűrő törlése"
                  size="small"
                  className="mt-16"
                >
                  <Icon>clear</Icon>
                </IconButton>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      <Rows {...contextValue}
            RowComponent={Row}
            listItemClassName="py-16 lg:py-12"
            noResultsClassName="text-14 md:text-20"
            noPageSizeField
      />
    </ListContext.Provider>
  );
};

export default React.memo(List);
