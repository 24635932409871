import { ApolloLink } from "apollo-link";
import { logErrorLink } from "./links/logError";
import { unauthorizedLink } from "./links/unauthorized";
import { createRemoteLinks } from "./links/remote";
import { reauthenticateLink } from "./links/reauthenticate";


export const createLink = () => {
  const { links, wsLink } = createRemoteLinks();

  const link = ApolloLink.from([
    logErrorLink,
    unauthorizedLink,
    reauthenticateLink,
    links,
  ]);

  return { link, wsLink };
};
