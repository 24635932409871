import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { InputAdornment, Grid, Icon, Tooltip } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { EmailAddress, useBackendService } from "components/form";
import { modes, Verification } from "components/form/verification";
import usePrimaryEmailAddress from "./usePrimaryEmailAddress";


const PrimaryEmailAddress = ({
                               record, field, requirements, required, accountId, label = "Kapcsolattartási email cím",
                               textFieldClassName, registration, ...restProps
                             }) => {


  // SessionContext doesn't exist on EditContact registration step
  const { account: { roles } = {} } = useContext(SessionContext);


  const [needToVerify, setNeedToVerify] = useState(false);


  const { loading, banned, banExpiryFormatted, fetch } = useBackendService();


  const { onChangeCb, errorType, fieldRequirements, verifiedToolTip, verificationArgs } = usePrimaryEmailAddress({
    record,
    field,
    accountId,
    requirements,
    fetch,
    registration,
  });


  const adminMode = useMemo(() => (roles || []).includes(dbEnums.accountRoles.MANAGER), [roles]);


  const disableError = useMemo(() => Boolean(needToVerify || loading), [loading, needToVerify]);


  const safeVerifiedTooltip = useMemo(() => Boolean(!loading && !adminMode && verifiedToolTip)
    , [adminMode, loading, verifiedToolTip]);


  const getEndAdornment = useCallback((errorType, errorMessage) => Boolean((!loading && errorType) || safeVerifiedTooltip) && (
    <InputAdornment position="end">
      <Tooltip title={(errorType && errorMessage) || "Megerősített email cím"}>
        <Icon
          className={errorType ? "text-red" : "text-green"}>{errorType ? "error_outline" : "check_circle_outline"}</Icon>
      </Tooltip>
    </InputAdornment>
  ), [loading, safeVerifiedTooltip]);


  useEffect(() => {
    if (!loading) {
      setNeedToVerify(errorType === "validateVerification");
    }
  }, [errorType, loading]);


  return (
    <EmailAddress {...restProps}
                  record={record}
                  field={field}
                  requirements={fieldRequirements}
                  required={required || true}
                  onChangeCb={onChangeCb}
                  getEndAdornment={getEndAdornment}
                  errorEndAdornment
                  loading={loading}
                  disableError={disableError}
                  spacing={2}
                  alignItems="center"
                  textFieldClassName={clsx(needToVerify && "mb-12", textFieldClassName)}
                  label={label}
    >
      {Boolean(needToVerify) && (
        <Grid item xs={12}>
          <Verification {...verificationArgs}
                        mode={modes.email}
                        record={record}
                        parentLoading={loading}
                        banned={banned}
                        banExpiryFormatted={banExpiryFormatted}
                        fetch={fetch}
                        registration={registration}
                        className={clsx(registration && "mb-0")}
          />
        </Grid>
      )}
    </EmailAddress>
  );
};

export default React.memo(PrimaryEmailAddress);
