import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { GET_CONTACTS } from "client/store/contacts";
import { useList, ListPage, ListContext } from "components/listPages";
import { getFilterTestFnObj } from "components/listPages/helpers";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Row from "./Row";


const fields = {
  fullName: "fullName",
  group: "group",
  email: "email",
  phone: "phone",
  address: "address",
  nwsEmail: "nwsEmail",
  statusLabel: "statusLabel",
  note: "note",
};


export const filterIds = {
  inName: "inName",
  inContact: "inContact",
};


const filters = {
  [filterIds.inName]: getFilterTestFnObj([fields.fullName, fields.group]),
  [filterIds.inContact]: getFilterTestFnObj([fields.email, fields.phone, fields.nwsEmail, fields.statusLabel, fields.note]),
};


const normalizeContacts = contacts => (contacts || []).map(({ id, student, manager }) => ({ id, ...student, ...manager }))


const ContactsList = () => {

  const [allDetails, setAllDetails] = useState(false);


  const forbidden = useForbidden([dbEnums.accountRoles.MANAGER, dbEnums.accountRoles.STUDENT]);


  const { reload, ...list } = useList({ filters });


  const { refetch: refetchContacts, error, loading } = useQuery(GET_CONTACTS, {
    onCompleted: ({ contacts } = {}) => contacts && reload(normalizeContacts(contacts)),
  });


  const toggleAllDetails = useCallback(() => setAllDetails(allDetails => !allDetails), []);


  const refetch = useCallback(async () => {
    const { data: { contacts } = {} } = await refetchContacts();
    if (contacts) reload(normalizeContacts(contacts));
  }, [refetchContacts, reload]);


  const contextValue = {
    ...list,
    refetch,
    allDetails,
    toggleAllDetails,
  };


  return (
    <ListContext.Provider value={contextValue}>
      <ListPage forbidden={forbidden}
                loading={loading}
                error={error}
                title="Tagság"
                icon="folder_shared"
                HeaderComponent={Header}
                ToolbarComponent={Toolbar}
                RowComponent={Row}
                headerHeight={300}
      />
    </ListContext.Provider>
  );
};

export default React.memo(ContactsList);
