import nextTick from "next-tick";
import { useCallback, useState } from "react";
import { safeFnOrPromise } from "common/helpers";


const defaultValues = {
  open: false,
  disabled: false,
  options: {
    title: "",
    content: "",
    ContentComponent: null,
    OKButton: "Bezár",
    cancelButton: "",
    classNameOK: "",
    classNameCancel: "",
    colorOK: "primary",
    colorCancel: "primary",
    onCancel: null,
    onOK: null,
    disabledOKButton: false,
    maxWidth: "sm",
    fullScreen: false,
    fullWidth: false,
    disableBackdropClick: false,
    disableEscapeKeyDown: false,
  },
};


const useUiDialog = () => {

  const [open, setOpen] = useState(defaultValues.open);

  const [disabled, setDisabled] = useState(defaultValues.disabled);

  const [options, setOptions] = useState(defaultValues.options);


  const cbOK = useCallback(async () => await safeFnOrPromise(options.onOK)() || {}, [options]);


  const cbCancel = useCallback(async () => await safeFnOrPromise(options.onCancel)() || {}, [options]);


  const reset = useCallback(() => {
    setOpen(defaultValues.open);
    setDisabled(defaultValues.disabled);
    setOptions(defaultValues.options);
  }, []);


  const openDialog = useCallback(options => {
    setOpen(true);
    setDisabled(options.disabledOKButton || false);
    setOptions({
      ...defaultValues.options,
      ...options,
    });
  }, []);


  const closeDialog = useCallback(() => {
    setOpen(false);
    nextTick(() => setOptions(defaultValues.options));
  }, []);


  const onCancel = useCallback(async () => {
    const { stayOpened } = await cbCancel();
    if (!stayOpened) {
      closeDialog();
    }
  }, [cbCancel, closeDialog]);


  const onOK = useCallback(async () => {
    setDisabled(true);

    const { stayOpened } = await cbOK();
    if (!stayOpened) {
      closeDialog();
      setDisabled(false);
    }
  }, [cbOK, closeDialog]);


  const enableOKButton = useCallback(() => setDisabled(false), []);


  return {
    dialog: {
      open,
      disabled,
      ...options,
      openDialog,
      closeDialog,
      onCancel,
      onOK,
      enableOKButton,
    },
    reset,
  };
};

export default useUiDialog;
