import { getFormattedCurrentDate } from "common/helpers";


const useLogComponentError = (componentName, error) => {
  if (process.env.DEV_MODE && componentName && error) {
    console.error(`[${getFormattedCurrentDate()}] [component error] @ ${componentName}`, error);
  }
  return Boolean(error);
};

export default useLogComponentError;
