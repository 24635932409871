import clsx from "clsx";
import moment from "moment";
import texts from "config/texts";
import { dbEnums, labels } from "common/enums";
import { dateStr } from "common/helpers";
import { errorLogStore } from "client/lib/errorLog";
import { getSessionRoles } from "client/lib/session";
import { GET_CARDS } from "./queries";


const filterCards = cards => cards.filter(({ nr }) => nr);


const fetchCards = async client => {
  try {

    const roles = getSessionRoles();
    const admin = roles.includes(dbEnums.accountRoles.MANAGER);

    const { data: { cards = [] } = {} } = await client.query({ query: GET_CARDS });

    return admin ? cards : filterCards(cards);

  } catch (e) {
    errorLogStore("card", "fetchCards", e);
  }
};


const headers = {
  nr: "Kártyaszám",
  name: "Név",
  group: "Osztály",
  status: "Kártyastátusz",
  issueDate: "Kiállítva",
  exp: "Érvényesség vége",
  dup: "Duplikált kártyaszám",
};


const headersArray = [headers.nr, headers.name, headers.group, headers.status, headers.issueDate, headers.exp, headers.dup];


const mapRawCards = cards => (cards || []).map(({ nr, status, issueDate, exp, dup, name, group }) => ({
  [headers.nr]: clsx(nr),
  [headers.name]: clsx(name),
  [headers.group]: clsx(group),
  [headers.status]: labels.cardStatus[status],
  [headers.issueDate]: dateStr(issueDate),
  [headers.exp]: dateStr(exp),
  [headers.dup]: clsx(dup && "Duplikált kártyaszám!"),
}));


const getTitle = () => {
  const date = moment().format("YYYY-MM-DD");
  const title = `${texts.cardsName} export`;
  return clsx(title, date);
};


const exportData = async client => {
  const fetchedCards = await fetchCards(client);

  const rows = mapRawCards(fetchedCards);
  const title = getTitle();

  return {
    rows,
    fields: headersArray,
    title,
    fileName: title,
  };
};

export default exportData;
