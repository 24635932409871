import { makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core";


export const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    "&.boxed": {
      maxWidth: 1280,
      margin: "0 auto",
      boxShadow: theme.shadows[3]
    },
    "&.scroll-body": {
      "& $wrapper": {
        height: "auto",
        flex: "0 0 auto",
        overflow: "auto"
      },
      "& $contentWrapper": {},
      "& $content": {}
    },
    "&.scroll-content": {
      "& $wrapper": {},
      "& $contentWrapper": {},
      "& $content": {}
    },
    "& .navigation": {
      "& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon": {
        transition: theme.transitions.create("opacity", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut
        })
      },
    },
    "& code:not([class*=\"language-\"])": {
      color: theme.palette.secondary.dark,
      backgroundColor: "#F5F5F5",
      padding: "2px 3px",
      borderRadius: 2,
      lineHeight: 1.7
    },
    "& table.simple tbody tr td": {
      borderColor: theme.palette.divider
    },
    "& table.simple thead tr th": {
      borderColor: theme.palette.divider
    },
    "& a:not([role=button])": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    "& [class^=\"border-\"]": {
      borderColor: theme.palette.divider
    },
    "& [class*=\"border-\"]": {
      borderColor: theme.palette.divider
    },
  },
  publicRoot: {
    background: "linear-gradient(to right, " + theme.palette.primary.dark + " 0%, " + darken(theme.palette.primary.dark, 0.5) + " 100%)",
    color: theme.palette.primary.contrastText,
    height: "100%",
  },
  wrapper: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    flex: "1 1 auto",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 3,
    overflow: "hidden",
    flex: "1 1 auto"
  },
  content: {
    position: "relative",
    display: "flex",
    overflow: "auto",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
    "-webkit-overflow-scrolling": "touch",
    zIndex: 2
  },
}));
