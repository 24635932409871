import React, { useCallback, useContext } from "react";
import { Grid, Button, Tooltip, Typography } from "@material-ui/core";
import { useConfirm } from "components/common";
import { AlumnusContext } from "components/alumnus";
import { jump } from "components/form/newsletter";


const Email = () => {

  const { setRoute, untouched, alumnus: { news: { email: nwsEmail } } } = useContext(AlumnusContext);


  const confirm = useConfirm({
    confirmOptions: jump.confirmOptions,
    disabled: untouched,
  });


  const navigateToNewsletter = useCallback(() => setRoute({
    disableConfirm: true,
    editMode: true,
    page: jump.page,
    tab: jump.tab,
  }), [setRoute]);


  const onNewsletter = useCallback(() => confirm(navigateToNewsletter), [confirm, navigateToNewsletter]);


  return (
    <Grid container spacing={2} item>
      <Grid item xs={12} className="mb-8">
        <Typography color="textSecondary" className="mb-8 text-13">
          Az Alumni <i>email hírlevelének</i> kézbesítési címét a hírlevél beállítások űrlapján adtad meg. A hírlevél
          kézbesítési címe:
        </Typography>
        <Typography>{nwsEmail}</Typography>
        <Tooltip title="Ugrás a hírlevél beállításaihoz">
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="mt-24"
            onClick={onNewsletter}
          >
            Ugrás a hírlevél beállításaihoz
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default React.memo(Email);
