import clsx from "clsx";
import validator from "validator";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useRouter } from "next/router"
import { dbEnums } from "common/enums";
import { meAId, modes, defaultTab, alumnusRouteConfig, editModeAllowedRoles } from "config/profile";
import { useFilterByRoles, useForbidden } from "client/lib/session";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { useConfirm } from "components/common";
import { confirmOptions } from "components/form";
import { normalizePage, normalizeTab, serializePath } from "./helpers";


const defaultPanelKey = "";


const useAlumnusRoute = ({ untouched }) => {

  const { help: { setHelp } } = useContext(UiStateContext);

  const { account: { roles }, personal: { alumnusId: sessionAlumnusId } } = useContext(SessionContext);


  const router = useRouter();

  const [aId, _mode, _page, _tab, _panelKey] = (router.query && router.query.aRoute) || [];


  const alumnusId = useMemo(() => aId === meAId
    ? sessionAlumnusId || null
    : (aId && validator.isNumeric(clsx(aId)) && parseInt(aId)) || null
    , [aId, sessionAlumnusId]);


  const myProfile = useMemo(() => alumnusId === sessionAlumnusId, [alumnusId, sessionAlumnusId]);


  const mode = useMemo(() => _mode === modes.edit ? modes.edit : modes.profile, [_mode]);


  const editMode = useMemo(() => _mode === modes.edit, [_mode]);


  const page = useMemo(() => normalizePage(_page), [_page]);


  const tab = useMemo(() => normalizeTab(_tab), [_tab]);


  const panelKey = useMemo(() => _panelKey ? clsx(_panelKey) : defaultPanelKey, [_panelKey]);


  const config = useMemo(() => alumnusRouteConfig[mode] || [], [mode]);


  const rawPageConfig = useMemo(() => config[page] || {}, [config, page]);


  const rawTabConfig = useMemo(() => (rawPageConfig.tabs || [])[tab] || {}, [rawPageConfig, tab]);


  const { disallowed: pageDisallowed, ...pageConfig } = useFilterByRoles({
    config: rawPageConfig,
    myProfile,
  });


  const { disallowed: tabDisallowed, ...tabConfig } = useFilterByRoles({
    config: rawTabConfig,
    myProfile,
  });


  const forbiddenPage = useForbidden([dbEnums.accountRoles.ALUMNUS]);


  const editable = useMemo(() => Boolean(myProfile || editModeAllowedRoles.find(role => (roles || []).includes(role)))
    , [myProfile, roles]);


  const forbidden = useMemo(() => Boolean(
    forbiddenPage
    || pageDisallowed
    || tabDisallowed
    || (editMode && !editable)
    || page >= config.length
    || (rawPageConfig.tabs && tab >= rawPageConfig.tabs.length),
  ), [config.length, editMode, editable, forbiddenPage, page, pageDisallowed, rawPageConfig.tabs, tab, tabDisallowed]);


  const confirm = useConfirm({
    confirmOptions,
    disabled: untouched,
  });


  const confirmedPush = useCallback((disableConfirm, editMode, page, tab, panelKey) => {
    const route = serializePath(
      aId,
      editMode,
      normalizePage(page),
      normalizeTab(tab || defaultTab),
      panelKey || defaultPanelKey,
    );

    return disableConfirm ? router.push(router.pathname, route) : confirm(router.push, router.pathname, route);
  }, [aId, confirm, router]);


  const setRoute = useCallback(({ disableConfirm, editMode, page, tab, panelKey }) => confirmedPush(disableConfirm, editMode, page, tab, panelKey)
    , [confirmedPush]);


  const setPage = useCallback((page, disableConfirm) => confirmedPush(disableConfirm, editMode, page)
    , [confirmedPush, editMode]);


  const setTab = useCallback((tab, disableConfirm) => confirmedPush(disableConfirm, editMode, page.toString(), tab)
    , [confirmedPush, editMode, page]);


  useEffect(() => {
    setHelp(tabConfig.help);
  }, [setHelp, tabConfig.help]);


  return {
    alumnusId,
    myProfile,
    editMode,
    page,
    tab,
    panelKey,
    config,
    pageConfig,
    forbidden,
    tabConfig,
    editable,
    setRoute,
    setPage,
    setTab,
  };

};

export default useAlumnusRoute;
