import clsx from "clsx";
import { getService } from "./helpers";


export const auth = {
  init: getService("auth/init", { ignoreBan: true }),
  login: getService("auth/login"),
  logout: getService("auth/logout"),
  whoAmI: getService("auth/whoAmI"),
};


const authApiRegex = /^auth\/(.*)$/;


export const isAuthService = service => Boolean(authApiRegex.test(clsx(service.path)));
