import React, { useMemo } from "react";
import { Button, Grid } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";


const Bar = ({
               onBack, onNext, last, disabledBack, disabledNext, stepsCount, onExit, noExit, noExitOnLast, noBackOnLast,
               noLast, backButtonLabel = "Vissza", nextButtonLabel = "Tovább", lastButtonLabelSingle = "Mentés",
               lastButtonLabelMultiple = "Befejezés", exitButtonLabel = "Kijelentkezés",
             }) => {


  const visibleBack = useMemo(() => !last || !noBackOnLast, [last, noBackOnLast]);


  const visibleNext = useMemo(() => !last || !noLast, [last, noLast]);


  const visibleExit = useMemo(() => !noExit && (!last || !noExitOnLast), [last, noExit, noExitOnLast]);


  const spacing = useMemo(() => visibleExit ? 1 : 0, [visibleExit]);


  return (
    <Grid container spacing={spacing} alignItems="center" justify="space-between" className="w-full pb-24 sm:pb-16 md:pb-8 lg:pb-0">

      <Grid item>
        <Grid container spacing={spacing} alignItems="center">
          {Boolean(visibleBack) && (
            <Grid item>
              <Button disabled={disabledBack}
                      onClick={onBack}
                      disableFocusRipple
                      className="mr-16"
              >
                {backButtonLabel}
              </Button>
            </Grid>
          )}

          {Boolean(visibleNext) && (
            <Grid item>
              <Button disabled={disabledNext}
                      onClick={onNext}
                      disableFocusRipple
                      variant="contained"
                      color="primary"
              >
                {!last
                  ? nextButtonLabel
                  : stepsCount > 1
                    ? lastButtonLabelMultiple
                    : lastButtonLabelSingle
                }
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {Boolean(visibleExit) && (
        <Grid item>
          <Button onClick={onExit}
                  endIcon={<ExitToAppIcon color="action"/>}
                  disableFocusRipple
          >
            {exitButtonLabel}
          </Button>
        </Grid>
      )}

    </Grid>
  );
}

export default React.memo(Bar);
