import React from "react";
import { FuseAnimate } from "components/fuse";


const Logo = () => (
  <FuseAnimate delay={200}>
    <div className="flex items-center mr-8 py-4 pr-8">
      <img src="/images/logo.png"
           alt="Alumni Online!"
           height="14"
      />
    </div>
  </FuseAnimate>
);

export default React.memo(Logo);
