import { updateSettings } from "./settings";
import initLists from "./initLists";
import { onEnd, onStart, subStates } from "./octopusState";
import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { decoratedError, errors } from "common/errors";


const getLists = async () => {
  try {
    const { error, data: { data: lists = {} } } = await backendFetch(backendServices.octopus.getLists);
    return error ? { error } : { lists };

  } catch (e) {
    const message = errorLogStore("octopus", "getLists", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};


const getInitUpdates = async (client) => {
  try {
    const lists = await getLists();
    if (lists.error) return lists;

    const listInfo = await initLists(lists);
    if (listInfo.error) return listInfo;

    const updatedSettings = await updateSettings(client, listInfo);
    if (updatedSettings.error) return updatedSettings;

    return { listInfo };

  } catch (e) {
    const message = errorLogStore("octopus", "getInitUpdates", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};


const octopusInit = async (_root, _args, { client }) => {
  onStart(client, subStates.init);
  const updates = await getInitUpdates(client);
  onEnd(client, subStates.init, updates);
  return null;
};

export default octopusInit;
