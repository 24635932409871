import React, { useCallback, useEffect } from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import { uiStateEventEmitter, uiStateEvents } from "./uiState/events";
import { useUiApiErrorMessage, useUiDialog, useUiHelp, useUiMessage, useUiNavbar, useUiScroll } from "./uiState"


export * from "./uiState/events"

export const UiStateContext = React.createContext(null);


moment.locale("hu");
momentTz.locale("hu");


const UiStateProvider = ({ children }) => {

  const { reset: resetApiErrorMessage, ...apiErrorMessage } = useUiApiErrorMessage();
  const { reset: resetDialog, ...dialog } = useUiDialog();
  const { reset: resetHelp, ...help } = useUiHelp();
  const { reset: resetMessage, ...message } = useUiMessage();
  const { reset: resetNavbar, ...navbar } = useUiNavbar();
  const { ...scroll } = useUiScroll();


  const resetUiState = useCallback(() => {
    resetApiErrorMessage();
    resetDialog();
    resetHelp();
    resetMessage();
    resetNavbar();
  }, [resetApiErrorMessage, resetDialog, resetHelp, resetMessage, resetNavbar]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.session.uiStateReseted, resetUiState);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.session.uiStateReseted, resetUiState);
    }
  }, [resetUiState]);


  const contextValue = {
    ...apiErrorMessage,
    ...dialog,
    ...help,
    ...message,
    ...navbar,
    ...scroll,
  };


  return (
    <UiStateContext.Provider value={contextValue}>
      {children}
    </UiStateContext.Provider>
  );
};

export default React.memo(UiStateProvider);
