import { useCallback, useContext, useMemo } from "react";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { skipOnlyForMeRoles } from "config/profile";


const useFilterByRoles = ({ config, childrenKey, myProfile }) => {

  const { account: { roles }, personal: { special } } = useContext(SessionContext);


  const isAllowedRole = useCallback(item => {
    const { allowedRoles } = item || {};

    return !allowedRoles || !allowedRoles.length || allowedRoles.find(role => (roles || []).includes(role));
  }, [roles]);


  const isAllowedMembership = useCallback(item => {
    const { disallowedSpecial } = item || {};

    return !disallowedSpecial || !special;
  }, [special]);


  const isAllowedOnlyForMe = useCallback(item => {
    const { allowedOnlyForMe } = item || {};

    return !allowedOnlyForMe || myProfile || skipOnlyForMeRoles.find(role => (roles || []).includes(role));
  }, [myProfile, roles]);


  const mapItem = useCallback(({ [childrenKey]: children, ...item }) => {
    const allowedRole = isAllowedRole(item);
    const allowedMembership = isAllowedMembership(item);
    const allowedForMe = isAllowedOnlyForMe(item);

    const disallowed = Boolean(!allowedRole || !allowedMembership || !allowedForMe);

    const mappedChildren = childrenKey && children && children.map(child => mapItem(child));

    return {
      ...item,
      ...(childrenKey ? { [childrenKey]: mappedChildren } : {}),
      disallowed,
    };
  }, [childrenKey, isAllowedMembership, isAllowedOnlyForMe, isAllowedRole]);


  return useMemo(() => Array.isArray(config)
    ? config.map(mapItem)
    : mapItem(config)
    , [config, mapItem]);

};

export default useFilterByRoles;
