import * as auth from "server/lib/auth";


export const requestMethods = {
  DELETE: "DELETE",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
};


export const authMethods = {
  anonymous: auth.anonymous,
  authorization: auth.authorization,
  init: auth.init,
  issue: auth.issue,
  login: auth.login,
  logout: auth.logout,
  none: auth.withoutAuthorization,
  media: auth.media,
  whoAmI: auth.whoAmI,
};


export const methodsDataHandlerEnabledOn = [
  authMethods.anonymous,
  authMethods.authorization,
  authMethods.issue,
  authMethods.media,
  authMethods.none,
];


export const authServices = {
  approveTerms: auth.approveTerms,
  changePsw: auth.changePsw,
  deleteSessions: auth.deleteSessions,
  encryptPsw: auth.encryptPsw,
  hydrateSessions: auth.hydrateSessions,
  revision: auth.revision,
  setPsw: auth.setPsw,
};


export const maxBodyBufferSizeInBytes = 10 * 1024 * 1024;
