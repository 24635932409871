import gql from "graphql-tag";


export const GET_JOB_COMPANIES = gql`
  query GetJobCompanies {
    options: view_job_company(order_by: {occurrences: desc_nulls_last, company: asc_nulls_last}) {
      value: company
      cnt: occurrences
    }
  }
`;


export const GET_JOB_SECTORS = gql`
  query GetJobSectors {
    options: view_job_sector(order_by: {occurrences: desc_nulls_last, sector: asc_nulls_last}) {
      value: sector
      cnt: occurrences
    }
  }
`;
