import React from "react";
import { AppBar, Card, CardContent, makeStyles, Toolbar, Typography } from "@material-ui/core";
import CityAndCountry from "components/alumnus/CityAndCountry";


const useStyles = makeStyles(() => ({
  CardContent: {
    "&:last-child": {
      paddingBottom: 20,
    },
  },
}));


const CityAndCountryCard = ({ alumnus }) => {

  const { residency: { cityAndCountry } } = alumnus;

  const classes = useStyles();

  return Boolean(cityAndCountry) && (
    <Card className="w-full mb-16">
      <AppBar position="static" elevation={0}>
        <Toolbar className="pl-16 pr-8">
          <Typography variant="subtitle1" color="inherit" className="flex-1">
            Város, ország, ahol él
          </Typography>
        </Toolbar>
      </AppBar>

      <CardContent className={classes.CardContent}>
        <CityAndCountry alumnus={alumnus}/>
      </CardContent>
    </Card>
  );
};

export default React.memo(CityAndCountryCard);
