import gql from "graphql-tag";


export const SAVE_JOBS = gql`
  mutation SaveJobs($jobs: jsonb!, $deletedIds: [Int!], $alumnusId: Int!) {
    saveJobs(jobs: $jobs, deletedIds: $deletedIds, alumnusId: $alumnusId) @client
  }
`;


export const UPSERT_JOBS = gql`
  mutation UpsertJobs($records: [job_insert_input!]!) {
    results: insert_job(objects: $records, on_conflict: {constraint: job__pkey, update_columns: [privacy, company, job, current, year_from, year_to, country_code, city, sector, web, note]}) {
      affected_rows
    }
  }
`;


export const DELETE_JOBS = gql`
  mutation DeleteJobs($ids: [Int!]!) {
    results: delete_job(where: {id: {_in: $ids}}) {
      affected_rows
    }
  }
`;
