import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import texts from "config/texts";
import { withAccent } from "common/helpers";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { ExternalLink } from "components/common";
import { FuseAnimate } from "components/fuse";


const Welcome = () => {

  const { envVars: { publicSiteUrl } } = useContext(SessionContext);

  return (
    <>
      <FuseAnimate animation="transition.expandIn">
        <img className="w-256 sm:w-360 mb-16 mt-16 md:mt-0" src="/images/logo.png" alt="logo"/>
      </FuseAnimate>

      <FuseAnimate delay={400}>
        <Typography variant="subtitle1" color="inherit" className="max-w-512 mt-24">
          Köszöntünk {withAccent(texts.organization)} közösségi felületén.
        </Typography>
      </FuseAnimate>

      <FuseAnimate delay={400}>
        <Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16 mb-24 md:mb-16">
          Az oldalra a sikeres regisztrációt követően,&nbsp;az Alumni tagjaként jelentkezhetsz be.
          További információért látogasd meg az Alumni&nbsp;
          <ExternalLink href={publicSiteUrl} noIcon>weboldalát</ExternalLink>!
        </Typography>
      </FuseAnimate>
    </>
  );
};

export default React.memo(Welcome);
