import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Icon, List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";
import { useSidebarStyles } from "../styles";
import { ListContext } from "components/listPages";


const Sidebar = () => {

  const { count, checked, resetFilters, emptyFilters, filterChecked, filterUnchecked, checkedFilter, uncheckedFilter } = useContext(ListContext);


  const onResetClick = useCallback(() => resetFilters(), [resetFilters]);


  const unchecked = (count || 0) - (checked || 0);


  const classes = useSidebarStyles();


  return (
    <FuseAnimate animation="transition.slideUpIn" delay={400}>

      <div className="flex-auto border-0 border-l-1 border-solid mt-16">

        <List>
          <ListItem
            button
            onClick={onResetClick}
            className={clsx(classes.listItem, emptyFilters && "active")}
          >
            <Icon className="list-item-icon" color="primary">view_headline</Icon>
            <ListItemText primary="Összes elem" disableTypography/>
          </ListItem>
        </List>

        <List>
          <ListSubheader disableSticky>KIJELÖLÉS SZERINT</ListSubheader>
          <ListItem
            button
            onClick={filterUnchecked}
            className={clsx(classes.listItem, uncheckedFilter && "active")}
          >
            <Icon className="list-item-icon" color="action">check_box_outline_blank</Icon>
            <ListItemText primary={`${unchecked} kijelöletlen elem`} disableTypography/>
          </ListItem>
          {Boolean(checked) && (
            <ListItem
              button
              onClick={filterChecked}
              className={clsx(classes.listItem, checkedFilter && "active")}
            >
              <Icon className="list-item-icon" color="secondary">check_box</Icon>
              <ListItemText primary={`${checked} kijelölt elem`} disableTypography/>
            </ListItem>
          )}
        </List>
      </div>
    </FuseAnimate>
  );
};

export default React.memo(Sidebar);
