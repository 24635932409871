import clsx from "clsx";
import moment from "moment";
import texts from "config/texts";
import { dbEnums } from "common/enums";
import { dateStr } from "common/helpers";
import { errorLogStore } from "client/lib/errorLog";
import { getSessionRoles } from "client/lib/session";
import { GET_CONTACTS_TO_EXPORT } from "./queries";


const isAdmin = () => getSessionRoles().includes(dbEnums.accountRoles.MANAGER);


const parseContact = (admin, { id, student, manager }) => admin ? ({ id, ...student, ...manager }) : ({ id, ...student });


const normalizeContacts = (contacts, admin) => (contacts || []).map(parseContact.bind(null, admin))


const fetchContacts = async (client, admin = isAdmin()) => {
  try {
    const { data: { contacts = [] } = {} } = await client.query({ query: GET_CONTACTS_TO_EXPORT });

    return normalizeContacts(contacts, admin);

  } catch (e) {
    errorLogStore("contacts", "fetchContacts", e);
  }
};


const headers = {
  id: "Azonosító",
  pre: "Név előtag",
  last: "Családi név",
  first: "Utónév",
  suf: "Név utótag",
  nick: "Becenév",
  fullName: "Teljes név",
  group: "Osztály",
  formTeacher: "Osztályfőnök(ök)",
  email: "Email",
  phone: "Telefon",
  address: "Levelezési cím",
  nws: "Hírlevél",
  nwsEmail: "Hírlevél email",
  magazine: texts.magazineName,
  note: "Tagsági megjegyzés",
  regDate: "Regisztráció",
  lastRevision: "Utolsó adatellenőrzés",
  lastUpdate: "Utolsó adatmódosítás",
  lastLogin: "Utolsó bejelentkezés",
  lastTerms: "Adatkezelési feltételek elfogadása",
  status: "Fiók státusza",
  exp: "Fiók törlésének dátuma",
};


const adminHeaders = [
  headers.id, headers.pre, headers.last, headers.first, headers.suf, headers.nick, headers.fullName, headers.group,
  headers.formTeacher, headers.email, headers.phone, headers.address, headers.nws, headers.nwsEmail, headers.magazine,
  headers.note, headers.regDate, headers.lastRevision, headers.lastUpdate, headers.lastLogin, headers.lastTerms,
  headers.status, headers.exp
];


const studentHeaders = [
  headers.id, headers.pre, headers.last, headers.first, headers.suf, headers.nick, headers.fullName, headers.group,
  headers.formTeacher, headers.email, headers.phone, headers.address, headers.nws, headers.nwsEmail, headers.magazine,
  headers.lastRevision, headers.status, headers.exp
];


const mapRawContacts = (contacts, admin = isAdmin()) => (contacts || [])
  .map(({
          id, pre, last, first, suf, nick, fullName, group, formTeacher, email, phone, address, nws, nwsEmail, magazine,
          note, regDate, lastRevision, lastUpdate, lastLogin, lastTerms, status, exp
        }) =>
    ({
      [headers.id]: id,
      [headers.pre]: clsx(pre),
      [headers.last]: clsx(last),
      [headers.first]: clsx(first),
      [headers.suf]: clsx(suf),
      [headers.nick]: clsx(nick),
      [headers.fullName]: clsx(fullName),
      [headers.group]: clsx(group),
      [headers.formTeacher]: clsx(formTeacher),
      [headers.email]: clsx(email),
      [headers.phone]: clsx(phone),
      [headers.address]: clsx(address),
      [headers.nws]: nws ? "Kér" : "Nem kér",
      [headers.nwsEmail]: clsx(nwsEmail),
      [headers.magazine]: magazine ? "Kér" : "Nem kér",
      ...(!admin ? {} : {
        [headers.note]: clsx(note),
        [headers.regDate]: dateStr(regDate),
      }),
      [headers.lastRevision]: dateStr(lastRevision),
      ...(!admin ? {} : {
        [headers.lastUpdate]: dateStr(lastUpdate),
        [headers.lastLogin]: dateStr(lastLogin),
        [headers.lastTerms]: dateStr(lastTerms),
      }),
      [headers.status]: clsx(status),
      [headers.exp]: dateStr(exp),
    }));


const getTitle = () => {
  const date = moment().format("YYYY-MM-DD");
  const title = "Alumni adatexport";
  return clsx(title, date);
};


const getHeadersArray = (admin = isAdmin()) => admin ? adminHeaders : studentHeaders;


const exportData = async client => {
  const fetchedContacts = await fetchContacts(client);

  const rows = mapRawContacts(fetchedContacts);
  const title = getTitle();
  const fields = getHeadersArray();

  return {
    rows,
    fields,
    title,
    fileName: title,
  };
};

export default exportData;
