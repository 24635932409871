import EventEmitter from "events";
import nextTick from "next-tick";
import { devConsoleDebug } from "common/helpers";


export const uiStateEvents = {
  message: {
    showMessage: "message.showMessage",
  },
  alumnus: {
    memberUpdated: "alumnus.memberUpdated",
  },
  backend: {
    apiError: "backend.apiError",
  },
  form: {
    loadingStarted: "form.loadingStarted",
    dataRefetched: "form.dataRefetched",
    submitStarted: "form.submitStarted",
    submitFinished: "form.submitFinished",
  },
  listPages: {
    updateFinished: "listPages.updateFinished",
  },
  search: {
    loadingStarted: "search.loadingStarted",
    numPagesChanged: "search.numPagesChanged",
    pagePrefetched: "search.pagePrefetched",
    reseted: "search.reseted",
    updateStarted: "search.updateStarted",
    updateFinished: "search.updateFinished",
  },
  session: {
    uiStateReseted: "session.uiStateReseted",
    showExpiredSessionMessage: "session.showExpiredSessionMessage",
  },
  registration: {
    onModeSelect: "registration.onModeSelect",
  },
  revision: {
    onDirty: "revision.onDirty",
  },
};


export const uiStateEventEmitter = new EventEmitter();


export const emitUiStateEvent = (eventName, ...args) => {
  devConsoleDebug("uiStateEvent:", eventName, args);
  uiStateEventEmitter.emit(eventName, ...args);
};


export const emitUiStateEventNextTick = (eventName, ...args) => nextTick(() => emitUiStateEvent(eventName, ...args));
