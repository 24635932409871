import React from "react";
import clsx from "clsx";
import { Paper, Hidden, IconButton, Icon, Grid } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";


const Header = ({ disableSidebar, toggleSidebar, title, icon, noSearchIcon, noFilters, noPaper, titleClassName, children, className }) => {

  const shortTitle = clsx(title).length < 20;

  const md = shortTitle ? 4 : 6;

  const lg = shortTitle ? 4 : 5;

  const xl = shortTitle ? 3 : 4;

  return (
    <Grid container spacing={0} alignItems="center" className="w-full">
      {Boolean(title) && (
        <Hidden lgUp={!disableSidebar}>
          <Grid item
                xs={12}
                md={md}
                lg={lg}
                xl={xl}
                className={clsx(!noFilters && !clsx(titleClassName).includes("mb-") && "mb-24 sm:mb-32",
                  disableSidebar && "md:mb-0", titleClassName)}
          >
            <div className="flex items-center flex-1">
              <Hidden xsDown>
                <FuseAnimate animation="transition.expandIn" delay={300}>
                  <Icon className="text-32 mr-16">{icon}</Icon>
                </FuseAnimate>
              </Hidden>
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <span className="text-20 sm:text-24">{title}</span>
              </FuseAnimate>
            </div>
          </Grid>
        </Hidden>
      )}

      {!noFilters && (
        <Grid item xs={12}
              md={disableSidebar ? 12 - md : 12}
              lg={disableSidebar ? 12 - lg : 12}
              xl={disableSidebar ? 12 - xl : 12}
        >
          {noPaper
            ? children
            : (
              <Paper className={clsx("flex items-center h-48 sm:h-56 rounded-8", className)}
                     elevation={1}
              >
                {!disableSidebar && (
                  <Hidden lgUp>
                    <IconButton
                      onClick={toggleSidebar}
                      aria-label="szűrők"
                    >
                      <Icon>menu</Icon>
                    </IconButton>
                  </Hidden>
                )}
                {!noSearchIcon && (
                  <Hidden xsDown>
                    <Icon color="action" className="mr-16">search</Icon>
                  </Hidden>
                )}
                {children}
              </Paper>
            )}
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(Header);
