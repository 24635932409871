import { useCallback, useState } from "react";
import { calcNumPages } from "client/store/search";
import { defaultPageSize as _defaultPageSize, defaultPageSizes } from "../config";
import { normalizePage } from "../helpers";


const usePage = ({ _getFilteredIds, _updateRows, defaultPage = 1, defaultPageSize = _defaultPageSize, pageSizes = defaultPageSizes }) => {

  const [numPages, setNumPages] = useState(0);

  const [pageSize, _setPageSize] = useState(defaultPageSize);

  const [page, _setPage] = useState(defaultPage);


  const setPageSize = useCallback(pageSize => {
    const safePageSize = pageSize || defaultPageSize;
    _setPageSize(safePageSize);

    const filteredIds = _getFilteredIds();
    setNumPages(calcNumPages(safePageSize, filteredIds));

    _setPage(defaultPage);

    _updateRows(defaultPage, safePageSize);
  }, [_getFilteredIds, defaultPage, defaultPageSize, _updateRows]);


  const setPage = useCallback(page => {
    const normalizedPage = normalizePage(page || defaultPage, numPages || defaultPage);
    _setPage(normalizedPage);

    _updateRows(normalizedPage, pageSize);
  }, [defaultPage, numPages, pageSize, _updateRows]);


  const pageDown = useCallback(() => page > 1 && setPage(page - 1), [page, setPage]);


  const pageUp = useCallback(() => page < numPages && setPage(page + 1), [numPages, page, setPage]);


  const goToFirstPage = useCallback(() => setPage(defaultPage), [defaultPage, setPage]);


  const goToLastPage = useCallback(() => setPage(numPages), [numPages, setPage]);


  const _updatePages = useCallback(filteredIds => {
    setNumPages(calcNumPages(pageSize, filteredIds));

    _setPage(defaultPage);

    _updateRows(defaultPage, pageSize);
  }, [pageSize, defaultPage, _updateRows]);


  return {
    _updatePages,
    numPages,
    pageSizes,
    pageSize,
    setPageSize,
    page,
    setPage,
    pageDown,
    pageUp,
    goToFirstPage,
    goToLastPage,
  };
};

export default usePage;
