import clsx from "clsx";
import momentTz from "moment-timezone";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { UiMessages } from "config/messages";
import { backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { useField, FormContext } from "components/form";
import { __typename as emailTypename, updateFields as emailUpdateFields } from "components/form/email";
import { __typename as phoneTypename, updateFields as phoneUpdateFields } from "components/form/phone";
import { requirementMessages } from "../config";
import { getPrefixedId } from "../helpers";
import { modes } from "./Verification";
import useVerificationField from "./useVerificationField";


const useVerification = ({ mode, record, field, codeReqs, onVerified, setCodeSent, banned, fetch, registration, helperText }) => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { getValue, triggerValidation, setValue } = useContext(FormContext);


  const codeCache = useRef({});

  const codeIds = useRef({});


  const [loading, setLoading] = useState(false);


  const codeAlphabet = useMemo(() => codeReqs && codeReqs.codeAlphabet, [codeReqs]);

  const codeLength = useMemo(() => codeReqs && codeReqs.codeLength, [codeReqs]);

  const sendingService = useMemo(() => registration ? backendServices.registration[mode].sendCode : backendServices.verify[mode].send
    , [mode, registration]);


  const verificationService = useMemo(() => !registration ? backendServices.verify[mode].verify : backendServices.registration[mode].verifyCode
    , [mode, registration]);


  const { placeholder, validated, fieldRequirements } = useVerificationField({
    banned,
    codeAlphabet,
    codeLength,
  });


  const getCodeId = useCallback(toValue => toValue && (codeIds.current || {})[toValue], []);


  const { error, name, helperTextOrError, rules } = useField({
    record,
    field,
    helperText: helperText || requirementMessages.verification.missingVerficationCode,
    fieldRequirements,
  });


  const getToValue = useCallback(() => {
    if (mode === modes.phone) return getValue(phoneTypename, getPrefixedId(record.id), phoneUpdateFields[phoneTypename].phone);
    if (mode === modes.email) return getValue(emailTypename, getPrefixedId(record.id), emailUpdateFields[emailTypename].email);
    return true;
  }, [getValue, mode, record.id]);


  const setEmptyFieldValue = useCallback(() => setValue(name, "", true), [name, setValue]);


  const handleSendResponse = useCallback((toValue, error, { codeId }) => {
    const ids = codeIds.current || {};

    if (ids[toValue]) setEmptyFieldValue();

    codeIds.current = {
      ...ids,
      [toValue]: !error && codeId,
    };

    setLoading(false);
    if (!error) setCodeSent(true);
  }, [setCodeSent, setEmptyFieldValue]);


  const sendCode = useCallback(async () => {
    const toValue = getToValue();
    if (!toValue) return;

    setLoading(true);

    const { error, data } = await fetch(sendingService, {
      ...(mode !== modes.admin ? {
        [mode]: toValue,
      } : {}),
      tz: momentTz.tz.guess(true),
    });

    handleSendResponse(toValue, error, data);
  }, [fetch, getToValue, handleSendResponse, mode, sendingService]);


  const cacheCode = useCallback((codeId, code) => {
    const cache = codeCache.current || {};
    const safeCodeId = clsx(codeId);

    codeCache.current = {
      ...cache,
      [safeCodeId]: [
        ...(cache[safeCodeId] || []),
        clsx(code),
      ],
    };
  }, []);


  const checkCachedCode = useCallback((codeId, code) => {
    const cache = codeCache.current || {};
    const safeCodeId = clsx(codeId);

    return Boolean(cache[safeCodeId] && cache[safeCodeId].includes(clsx(code)));
  }, []);


  const handleVerifyResponse = useCallback((codeId, code, error, { verified }) => {
    if (!error && verified) {
      showMessage(UiMessages.form.verify.success);
      onVerified(getToValue());

    } else {
      cacheCode(codeId, code);
      triggerValidation();

      // shouldn't refresh the state when the component has already been unmounted
      setLoading(false);
    }
  }, [cacheCode, getToValue, onVerified, showMessage, triggerValidation]);


  const fetchVerify = useCallback(async (codeId, code) => {
    if (!codeId) return;

    setLoading(true);

    const { banned, error, data } = await fetch(verificationService, {
      codeId,
      code,
    });

    if (!banned) {
      handleVerifyResponse(codeId, code, error, data);
    } else {
      setLoading(false);
    }
  }, [fetch, handleVerifyResponse, verificationService]);


  const verifyCode = useCallback(async (codeId, code) => codeId && code && !banned && !checkCachedCode(codeId, code) && await fetchVerify(codeId, code)
    , [fetchVerify, banned, checkCachedCode]);


  const onChange = useCallback(([{ target: { value } = {} }]) => {
    const toValue = getToValue();
    const codeId = getCodeId(toValue);
    const code = clsx(value).trim();

    if (validated(code)) verifyCode(codeId, code);

    return code;
  }, [getCodeId, getToValue, validated, verifyCode]);


  return {
    name,
    onChange,
    sendCode,
    placeholder,
    rules,
    error,
    verificationLoading: loading,
    helperTextOrError,
  };

};

export default useVerification;
