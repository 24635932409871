import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { Tab, Tabs as MuiTabs } from "@material-ui/core";
import { useFilterByRoles } from "client/lib/session";
import { AlumnusContext } from "components/alumnus";


const Tabs = () => {

  const { myProfile, editMode, page, setPage, tab: _tab, setTab, config, pageConfig } = useContext(AlumnusContext);


  const tabs = useFilterByRoles({
    config: editMode ? pageConfig.tabs : config,
    myProfile,
  });


  const filteredTabs = useMemo(() => tabs.filter(({ disallowed }) => !disallowed), [tabs]);


  const tab = useMemo(() => editMode ? _tab : page, [_tab, editMode, page]);


  const tabsClasses = useMemo(() => ({
    root: "h-64 w-full border-solid border-0 border-b-1",
    scrollButtons: clsx(editMode && "text-gray-600"),
  }), [editMode]);


  const tabClasses = useMemo(() => ({
    root: editMode ? "h-64 min-w-32 sm:min-w-128" : "h-64",
  }), [editMode]);


  const scrollButtons = useMemo(() => editMode ? "auto" : "off", [editMode]);


  const onChange = useCallback((event, value) => editMode
    ? setTab(value)
    : setPage(value)
    , [editMode, setPage, setTab]);


  return Boolean(editMode || filteredTabs.length > 1) && (
    <MuiTabs
      value={tab}
      onChange={onChange}
      indicatorColor="secondary"
      textColor="secondary"
      variant="scrollable"
      scrollButtons={scrollButtons}
      classes={tabsClasses}
    >
      {filteredTabs.map(({ title }) => (
        <Tab
          key={title}
          label={title}
          classes={tabClasses}
        />
      ))}
    </MuiTabs>
  );
};

export default React.memo(Tabs);
