import React from "react";
import { Grid } from "@material-ui/core";
import { dbEnums, labels } from "common/enums";
import { EnumField, Input } from "components/form";
import RandomPsw from "./RandomPsw";


export const __typename = "view_special_account";


export const _updateFields = {
  username: "username",
  password: "password",
  role: "role",
  note: "note",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.username, _updateFields.password, _updateFields.role],
};


const roleOtions = [
  {
    value: dbEnums.accountRoles.STUDENT,
    label: labels.accountRoles[dbEnums.accountRoles.STUDENT],
    icon: "laptop",
    color: "primary",
  },
  {
    value: dbEnums.accountRoles.RETAILER,
    label: labels.accountRoles[dbEnums.accountRoles.RETAILER],
    icon: "shopping_cart",
    color: "primary",
  },
];


const SpecialAccount = ({ isNew, ...record }) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={isNew ? 6 : 12}>
      <Input record={record}
             field={_updateFields.username}
             label="Bejelentkezési email cím"
             disabled={!isNew}
             className="mb-16 sm:w-full"
             required
      />
    </Grid>
    {Boolean(isNew) && (
      <Grid item xs={12} sm={6}>
        <RandomPsw {...record}/>
      </Grid>
    )}
    <Grid item xs={12}>
      <EnumField record={record}
                 field={_updateFields.role}
                 options={roleOtions}
                 disabled={!isNew}
                 label="Fiók típusa"
                 className="mb-16"
      />
    </Grid>
    <Grid item xs={12}>
      <Input record={record}
             field={_updateFields.note}
             className="mb-8"
             label="Megjegyzés"
      />
    </Grid>
  </Grid>
);

export default React.memo(SpecialAccount);
