import React from "react";
import { Grid } from "@material-ui/core";
import { Input } from "components/form";
import clsx from "clsx";


export const __typename = "alumnus_name";


const _updateFields = {
  first: "first",
  last: "last",
  pre: "pre",
  suf: "suf",
  nick: "nick",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.first, _updateFields.last],
};


const _fieldAliases = {
  [_updateFields.first]: "first_name",
  [_updateFields.last]: "last_name",
  [_updateFields.pre]: "name_prefix",
  [_updateFields.suf]: "name_suffix",
  [_updateFields.nick]: "nickname",
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


const prefixHelperText = (
  <span>Pl. <span className="text-blue">Dr.</span>, <span className="text-blue">Ifj.</span>,
    <span className="text-blue">Id.</span> stb.</span>
);


const suffixHelperText = (
  <span>Pl. <span className="text-blue">PhD</span>, <span className="text-blue">OSB</span> stb.</span>
);


const Name = ({ record, registration }) => (
  <Grid item container spacing={2}>
    <Grid item xs={12} md={registration ? 6 : 12}>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={12} sm={4}>
          <Input record={record}
                 field={_updateFields.pre}
                 label="Név előtag"
                 className={clsx(registration && "mb-24")}
                 helperText={prefixHelperText}
                 maxLength={15}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Input record={record}
                 field={_updateFields.last}
                 label="Családi név"
                 className={clsx(registration && "mb-24")}
                 required
                 noOnlyLowerCase
                 noOnlyUpperCase
                 maxLength={50}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={registration ? 6 : 12}>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={12} sm={8}>
          <Input record={record}
                 field={_updateFields.first}
                 label="Utónév / utónevek"
                 className={clsx(registration && "mb-24")}
                 required
                 noOnlyLowerCase
                 noOnlyUpperCase
                 maxLength={50}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input record={record}
                 field={_updateFields.suf}
                 label="Név utótag"
                 className={clsx(registration && "mb-24")}
                 helperText={suffixHelperText}
                 maxLength={15}
          />
        </Grid>
      </Grid>
    </Grid>
    {!registration && (
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.nick}
               label="Becenév"
               className="mb-8"
               helperText="Az adatlapodon a neved után, zárójelben fog megjelenni"
               maxLength={50}
        />
      </Grid>
    )}
  </Grid>
);

export default React.memo(Name);
