import clsx from "clsx";
import urlParse from "url-parse";
import slashes from "remove-trailing-slash";
import { dbEnums } from "common/enums";
import { isClientSide } from "common/helpers";
import envVars from "./envVars";


const rootPageHref = "/";


const baseUrl = slashes(isClientSide() ? urlParse("/").href : envVars.baseUrl);

const apiBaseUrl = slashes(envVars.apiBaseUrl || baseUrl);

const apiBaseHost = urlParse(apiBaseUrl, {}).host;

export const noSSL = urlParse(apiBaseUrl, {}).protocol !== "https:";


export const apiPaths = {
  graphqlClientHealthcheckUri: `${apiBaseUrl}/api/hasura/healthz`,
  graphqlHttpUri: `${apiBaseUrl}/api/hasura/v1/graphql`,
  graphqlWsUri: `${noSSL ? "ws:" : "wss:"}//${apiBaseHost}/api/hasura/v1/graphql`,
  mediaUrl: `${baseUrl}/api/media`,
};


const getStartPage = () => {
  //only on client side
  const { getSessionRoles } = isClientSide() ? require("client/lib/session") : {};
  const sessionRoles = getSessionRoles ? getSessionRoles() : [];

  if (sessionRoles.includes(dbEnums.accountRoles.RETAILER)) return pathNames.cards.href;
  if (sessionRoles.includes(dbEnums.accountRoles.STUDENT)) return pathNames.contacts.href;

  return rootPageHref;
};


export const pathNames = {
  error404: {
    href: "/404",
  },
  contacts: {
    href: "/contacts",
  },
  adminRegistration: {
    href: "/adminregistration",
  },
  alumnus: {
    href: "/alumnus/[...aRoute]",
    as: id => `/alumnus${clsx(id && `/${id}`)}`,
  },
  bannedIps: {
    href: "/bannedips",
  },
  changelog: {
    href: "/changelog",
  },
  dashboard: {
    href: rootPageHref,
  },
  startPage: {
    href: getStartPage,
  },
  emailoctopus: {
    href: "/emailoctopus",
  },
  faq: {
    href: "/faq/[faqId]",
    as: faqId => `/faq/${clsx(faqId) || "all"}`,
  },
  group: {
    href: "/group",
  },
  login: {
    href: "/login",
  },
  myAccount: {
    href: "/myaccount",
  },
  cards: {
    href: "/cards",
  },
  terms: {
    href: "/terms",
  },
  pswr: {
    href: "/pswr",
  },
  regCodes: {
    href: "/regcodes",
  },
  registration: {
    href: "/registration",
  },
  revision: {
    href: "/revision",
  },
  revisionRequests: {
    href: "/revisionrequests",
  },
  specialAccounts: {
    href: "/specialaccounts",
  },
  search: {
    href: "/search",
  },
};


export const anonymousPages = [
  pathNames.error404.href,
  pathNames.login.href,
  pathNames.terms.href,
  pathNames.registration.href,
  pathNames.pswr.href,
];


export const onlyAnonymousPages = [
  pathNames.login.href,
  pathNames.registration.href,
  pathNames.pswr.href,
];


export const redirects = {
  authenticated: pathNames.startPage.href,
  unauthenticated: pathNames.login.href,
  registration: pathNames.registration.href,
};
