import gql from "graphql-tag";


const fragmentViewActionLog = gql`
  fragment view_action_log on view_action_log {
    id
    time: action_timestamp
    action
    ip
    byAdmin: by_admin
    byAccountId: by_account__id
    byAlumnusId: by_alumnus__id
    byFirst: by_first_name
    byLast: by_last_name
    byPre: by_name_prefix
    bySuf: by_name_suffix
    details @client
  }
`;


export const GET_ACTION_LOGS = gql`
  query GetActionLogs($accountId: Int!) {
    logs: view_action_log(where: {account__id: {_eq: $accountId}}) {
      ...view_action_log
    }
  }

  ${fragmentViewActionLog}
`;
