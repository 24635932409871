import React, { useCallback, useMemo } from "react";
import Link from "next/link"
import clsx from "clsx";
import Avatar from "react-avatar";
import { ButtonBase } from "@material-ui/core";
import { avatarMinSize } from "config/image";
import { pathNames } from "config/routing";
import { normalizeStr } from "common/helpers";


const avatarSettings = {
  textSizeRatio: 2.2,
  color: "#bdbdbd",
  fgColor: "#fafafa",
};


const AlumnusAvatar = ({ hidden, alumnus, avatarClassName, size, rounded, link, meLink, linkParams, noButton, onClick, onlyLetters, defaultAvatar }) => {

  const safeSize = useMemo(() => Math.max(avatarMinSize, size || 0), [size]);


  const src = useMemo(() => {
    if (onlyLetters) return;

    const { img } = alumnus || {};
    const { src } = img || {};
    return src
      ? safeSize
        ? `${src}?size=${safeSize}`
        : src
      : defaultAvatar && "/images/defaultAvatar.png";
  }, [alumnus, defaultAvatar, onlyLetters, safeSize]);


  const round = useMemo(() => rounded === true
    ? true
    : rounded ? `.${parseInt(rounded)}rem` : false
    , [rounded]);


  const firstAndLastName = useMemo(() => {
    const { name } = alumnus || {};
    const { last, first } = name || {};

    const full = [last, first].join(" ");
    return normalizeStr(full) || null;
  }, [alumnus]);


  const CustomAvatar = useMemo(() => Boolean(alumnus) && (
    <Avatar
      name={firstAndLastName}
      className={clsx(avatarClassName)}
      src={src}
      size={size}
      color={avatarSettings.color}
      fgColor={avatarSettings.fgColor}
      textSizeRatio={avatarSettings.textSizeRatio}
      round={round}
    />
  ), [alumnus, firstAndLastName, avatarClassName, src, size, round]);


  const hrefParams = useMemo(() => clsx(linkParams && Object.entries(linkParams).reduce((hrefParams, [key, value]) => hrefParams + `&${key}=${value}`, ""))
    , [linkParams]);


  const href = useMemo(() => `${pathNames.alumnus.href}${hrefParams}`, [hrefParams]);


  const as = useMemo(() => pathNames.alumnus.as(meLink ? "me" : alumnus.id), [alumnus, meLink]);


  const safeOnClick = useCallback(() => onClick && onClick(), [onClick]);


  const WrappedAvatar = useMemo(() => Boolean(alumnus) && (
    (link || meLink)
      ? (
        <Link href={href} as={as || href}>
          <a rel="noopener noreferrer" role="button">
            {CustomAvatar}
          </a>
        </Link>
      ) : CustomAvatar
  ), [CustomAvatar, alumnus, as, href, link, meLink]);


  const Button = useMemo(() => Boolean(WrappedAvatar) && (
    <ButtonBase onClick={safeOnClick}>
      {WrappedAvatar}
    </ButtonBase>
  ), [WrappedAvatar, safeOnClick]);


  if (hidden || !alumnus) return null;


  return (
    <>
      {
        noButton
          ? WrappedAvatar
          : Button
      }
    </>
  );
};

export default React.memo(AlumnusAvatar);
