import React from "react";
import clsx from "clsx";
import { TextField } from "@material-ui/core";
import { dbEnums, labels } from "common/enums";
import { EnumField, Label } from "components/form";


const options = [
  {
    value: dbEnums.membership.GRADUATED,
    label: labels.membership[dbEnums.membership.GRADUATED],
    icon: "school",
    color: "primary",
  },
  {
    value: dbEnums.membership.NONGRADUATED,
    label: labels.membership[dbEnums.membership.NONGRADUATED],
    icon: "exit_to_app",
    color: "primary",
  },
];


const regCodeOptions = [
  {
    value: dbEnums.membership.NONGRADUATED,
    label: labels.membership[dbEnums.membership.NONGRADUATED],
    icon: "exit_to_app",
    color: "primary",
  },
  {
    value: dbEnums.membership.TEACHER,
    label: labels.membership[dbEnums.membership.TEACHER],
    icon: "group",
    color: "primary",
  },
  {
    value: dbEnums.membership.HONORARY,
    label: labels.membership[dbEnums.membership.HONORARY],
    icon: "star_rate",
    color: "primary",
  },
];


const Membership = ({
                      record, special, label, specialLabel, className, enumClassName, helperText, regCode,
                      variant = "outlined", ...props
                    }) => {

  const { membershipStr } = record;

  return (
    <Label label={clsx(!special && label)}
           className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}
           helperText={helperText}
    >
      {special && !regCode
        ? (
          <TextField
            disabled
            label={specialLabel}
            defaultValue={membershipStr}
            variant={variant}
          />
        )
        : (
          <EnumField
            {...props}
            className={clsx(!clsx(enumClassName).includes("mb-") && "mb-4", enumClassName)}
            record={record}
            options={regCode ? regCodeOptions : options}
          />
        )}
    </Label>
  );
};

export default React.memo(Membership);
