import React from "react";
import { Typography } from "@material-ui/core";


const Address = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon két postai címet adhatsz meg: <b>az Alumni számára megadott levelezési címedet</b> és egy <b>másodlagos
      címet</b>.
    </Typography>
    <Typography className="my-20">
      <b>Levelezési címet kötelező megadni (ország, irányítószám, település és cím).</b> Az Alumni az itt megadott <span
      className="text-blue">levelezési címre</span> fogja küldeni leveleit, kiadványait. (Az <span
      className="text-blue">Ugrás a kiadványokhoz</span> gombra kattintva egyből
      a <i>feliratkozásokhoz</i>&nbsp;navigálhatsz.)</Typography>
    <Typography className="mt-20">
      Az opcionálisan megadható <span className="text-blue">másodlagos címet</span> jellemzően két okból lehet célszerű
      megadni:
    </Typography>
    <div>
      <ul>
        <li className="mb-12">Ha az alumnusok számára <b>egy másik levélcímet</b> szeretnél láthatóvá tenni, amely
          különbözik az Alumni kiadványok számára megadott <i>levelezési címtől</i>.
          (A két cím láthatósága külön-külön állítható.)
        </li>
        <li className="mb-12">Ha a <b>tartózkodási helyedet</b> szeretnéd megosztani, amely különbözik a <i>levelezési
          címtől</i>. Mivel a másodlagos címnél bármelyik adat üresen hagyható, ezért lehetőséged van pl. csak a
          tartózkodási országot és/vagy várost megosztani.
        </li>
      </ul>
    </div>
    <Typography className="my-20">
      Az adatlapodon mindkét megadott cím szerepelni fog, ugyanakkor az egyik &ndash; alapértelmezetten
      a <i>levelezési cím</i> &ndash; a <span className="text-blue">&quot;Város, ország, ahol él&quot;</span> blokkban
      kiemelten is megjelenik. Ha azt szeretnéd, hogy ebben a blokkban a <i>másodlagos cím</i> szerepeljen, akkor
      ezt <b>a másodlagos cím szerkesztésénél szereplő</b>&nbsp;<span
      className="text-blue">&quot;Itt élek&quot;</span>&nbsp;jelölőnégyzet <b>bekapcsolásával</b>&nbsp;állíthatod be.
      Az <span className="text-blue">Alumnus keresőben</span>, a keresési találat kártyáin szintén az így beállított
      ország és/vagy város jelenik meg &ndash; amennyiben megadtad ezeket, és az adatvédelmi beállítás a keresést végző
      felhasználó számára láthatóvá teszi ezeket.
    </Typography>
    <Typography className="my-20">
      A két cím mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Az adott adatvédelmi beállítás értelemszerűen csak a megfelelő címre vonatkozik.
      Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
    <Typography className="mt-20">
      Az adatlapod <span className="text-blue">&quot;Város, ország, ahol él&quot;</span> blokkjában megjelenő adatok
      láthatóságáról a megfelelő cím (levelezési vagy másodlagos cím) adatvédelmi beállításával rendelkezhetsz.
    </Typography>
  </>
);

export default React.memo(Address);
