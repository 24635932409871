import clsx from "clsx";
import { trimStr } from "common/helpers";
import { removeMetaKeys, doMutation } from "client/store/helpers";
import { UPSERT_STUDIES, DELETE_STUDIES } from "client/store/alumnus";


const normalizeRecord = async (client, { id, institution: rawInstitution, degree: rawDegree, note: rawNote, ...data }, { alumnusId }) => {
  const institution = clsx(rawInstitution).trim();
  const degree = clsx(rawDegree).trim();
  const note = trimStr(rawNote, true);

  return {
    alumnus__id: alumnusId,
    ...data,
    ...(id ? { id } : {}),
    institution,
    degree,
    note,
  };
};


const normalizeRecords = async (client, { studies, ...args }) => {
  const normalizedRecords = [];

  for (let study of (studies || [])) {
    const normalizedRecord = await normalizeRecord(client, study, args);
    normalizedRecords.push(normalizedRecord);
  }

  return normalizedRecords;
};


const getRecords = (records, deletedIds) => (records || [])
  .filter(({ id }) => !(deletedIds || []).includes(id))
  .map(removeMetaKeys);


const saveStudies = async (_root, { deletedIds, ...args }, { client }) => {
  const normalizedRecords = await normalizeRecords(client, args);

  const records = getRecords(normalizedRecords, deletedIds);
  if (records && records.length) await doMutation(client, UPSERT_STUDIES, {
    records
  });

  if (deletedIds && deletedIds.length) await doMutation(client, DELETE_STUDIES, {
    ids: deletedIds
  });
};

export default saveStudies;
