import { primaryListLimit } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { decoratedError, errors } from "common/errors";


const fetchList = async listId => {
  const { error, data: { data = {} } } = await backendFetch(backendServices.octopus.getList, { listId });
  return error ? { error } : data;
};


const fetchPrimaryList = async ({ primaryList: { id: listId } }) => fetchList(listId);


const fetchSecondaryList = async ({ secondaryList: { id: listId } }) => fetchList(listId);


const calcCount = ({ subscribed = 0, unsubscribed = 0, pending = 0 } = {}, { deleteContact = [] } = {}) => subscribed + unsubscribed + pending - deleteContact.length;


const getCapacity = async (settings, { tasks }) => {
  try {
    const { error: primaryError, counts: primaryCounts } = await fetchPrimaryList(settings);
    if (primaryError) return { error: primaryError };

    const { error: secondaryError, counts: secondaryCounts } = await fetchSecondaryList(settings);
    if (secondaryError) return { error: secondaryError };

    const primaryCount = calcCount(primaryCounts, tasks);
    const secondaryCount = calcCount(secondaryCounts, tasks);
    const capacity = primaryListLimit - primaryCount;

    return {
      primaryCount,
      secondaryCount,
      capacity,
    }

  } catch (e) {
    const message = errorLogStore("octopus", "getCapacity", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};

export default getCapacity;
