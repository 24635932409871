import { v1 as uuidv1 } from "uuid";
import { uploadPicture } from "client/lib/media";
import { doMutation } from "client/store/helpers";
import { UPDATE_ALUMNUS_PICTURE } from "client/store/alumnus";


const saveRecord = async (client, recordId, item, deleted, fileId) => {
  const { id, ...changes } = {
    ...item,
    id: recordId,
  };

  if (fileId || deleted) changes.file_id = fileId;

  return await doMutation(client, UPDATE_ALUMNUS_PICTURE, {
    id,
    changes,
  });
};


const saveAvatar = async (_root, { id, item, deleted, file, crop }, { client }) => {
  const fileId = file ? uuidv1() : null;

  if (file) {
    const { error: uploadError } = await uploadPicture(fileId, file, crop) || {};
    if (uploadError) throw new Error();
  }

  const { error } = await saveRecord(client, id, item, deleted, fileId) || {};
  if (error) throw new Error(error);
};

export default saveAvatar;
