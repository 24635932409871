import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import Link from "next/link";
import { AppBar, Hidden, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { pathNames } from "config/routing";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { Scrollbars } from "components/layout";
import UserNavbarHeader from "./UserNavbarHeader";
import NavbarFoldedToggleButton from "./NavbarFoldedToggleButton";
import NavbarMobileToggleButton from "./NavbarMobileToggleButton";
import Navigation from "./Navigation";
import Logo from "./Logo";


const useStyles = makeStyles({
  content: {
    overflowX: "hidden",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch",
    background: "linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 40px, 100% 10px",
    backgroundAttachment: "local, scroll"
  },
  appBar: {
    height: 29,
  },
});


const NavbarLayout = props => {

  const { navbar: { folded, foldedOpen, closeMobileNavbar } } = useContext(UiStateContext);

  const navbarFolded = useMemo(() => folded && !foldedOpen, [folded, foldedOpen]);

  const classes = useStyles();

  return (
    <div className={clsx("flex flex-col overflow-hidden h-full", props.className)}>

      <AppBar
        color="primary"
        position="static"
        elevation={0}
        className={clsx("flex flex-row items-center flex-shrink", navbarFolded && classes.appBar, navbarFolded ? "justify-center" : "h-64 min-h-64 pl-24 pr-12")}
      >

        {!navbarFolded && (
          <div className="flex flex-1 pr-8">
            <Link href={pathNames.startPage.href()}>
              <a rel="noopener noreferrer" role="button" onClick={closeMobileNavbar}>
                <Logo navbarFolded={navbarFolded}/>
              </a>
            </Link>
          </div>
        )}

        <Hidden mdDown>
          <div className={clsx(navbarFolded && "mt-32")}>
            <NavbarFoldedToggleButton className="w-40 h-40 p-0"/>
          </div>
        </Hidden>

        <Hidden lgUp>
          <NavbarMobileToggleButton className="w-40 h-40 p-0">
            <Icon>arrow_back</Icon>
          </NavbarMobileToggleButton>
        </Hidden>
      </AppBar>

      <Scrollbars>
        <UserNavbarHeader navbarFolded={navbarFolded}/>
        <Navigation layout="vertical" active={true} dense={false}/>
      </Scrollbars>

    </div>
  );
};

export default React.memo(NavbarLayout);
