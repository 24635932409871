import urlParse from "url-parse";
import * as faIcons from "@fortawesome/free-brands-svg-icons";
import { dbEnums, labels } from "common/enums";


const icons = {
  [dbEnums.socialSite.UNKNOWN]: faIcons.faSlackHash,
  [dbEnums.socialSite.FACEBOOK]: faIcons.faFacebookF,
  [dbEnums.socialSite.INSTAGRAM]: faIcons.faInstagram,
  [dbEnums.socialSite.LINKEDIN]: faIcons.faLinkedinIn,
  [dbEnums.socialSite.PINTEREST]: faIcons.faPinterest,
  [dbEnums.socialSite.SKYPE]: faIcons.faSkype,
  [dbEnums.socialSite.YOUTUBE]: faIcons.faYoutubeSquare,
  [dbEnums.socialSite.TWITTER]: faIcons.faTwitter,
};


const title = ({ account, site }) => {
  const type = site && labels.socialSite[site];
  if (type) return type;

  const parsed = account && urlParse(account);
  const { hostname } = parsed || {};

  return hostname === "localhost"
    ? null
    : hostname || null;
};


const icon = ({ site }) => (site && icons[site]) || icons[dbEnums.socialSite.UNKNOWN];


const social = {
  title,
  icon,
};

export default social;
