import React, { useCallback, useContext, useMemo } from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@material-ui/icons";
import { dbEnums } from "common/enums";
import { UiMessages } from "config/messages";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { useConfirm } from "components/common";
import { Label } from "components/form";
import { AlumnusContext } from "components/alumnus";
import AccountStatus from "components/alumnus/profile/accountTab/AccountStatus";


export const tooltipDisabled = "Előbb erősítsd meg jogosultságodat!";


const getConfirmOptions = inactivate => ({
  title: inactivate ? "Fiók letiltása" : "Letiltott fiók feloldása",
  content: inactivate
    ? "Biztosan letiltod a fiókot? Az alumnus ettől kezdve nem fog tudni bejelentkezni."
    : "Biztosan feloldod a letiltott fiókot? Az alumnus ettől kezdve be tud majd jelentkezni.",
  OKButton: `Igen, ${inactivate ? "letiltom a fiókot" : "feloldom a tiltást"}`,
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
});


const Status = ({ account, deleted, verified, checkVerified }) => {

  const { id, status } = account;

  const { message: { showMessage } } = useContext(UiStateContext);

  const { startLoading, stopLoading } = useContext(AlumnusContext);


  const confirmActivate = useConfirm({
    confirmOptions: getConfirmOptions(false),
  });

  const confirmInactivate = useConfirm({
    confirmOptions: getConfirmOptions(true),
  });


  const activateTooltip = useMemo(() => verified ? "Letiltott fiók feloldása" : tooltipDisabled, [verified]);


  const inactivateTooltip = useMemo(() => verified ? "Fiók letiltása" : tooltipDisabled, [verified]);


  const changeStatus = useCallback(async (status, uiMessage) => {
    if (!await checkVerified()) return;

    startLoading();

    const { error } = await backendFetch(backendServices.account.changeStatus, {
      id,
      status,
    });

    if (error) {
      stopLoading();
      showMessage(UiMessages.form.submit.saveError);
    } else {
      showMessage(uiMessage);
    }
  }, [checkVerified, startLoading, id, showMessage, stopLoading]);


  const activate = useCallback(() => confirmActivate(changeStatus, dbEnums.accountStatus.ACTIVE, UiMessages.form.account.activated)
    , [changeStatus, confirmActivate]);


  const inactivate = useCallback(() => confirmInactivate(changeStatus, dbEnums.accountStatus.BLOCKED, UiMessages.form.account.inactivated)
    , [changeStatus, confirmInactivate]);


  return (
    <Label label="Fiók státusza"
           helperText="Letiltott és törölt státusz esetén az alumnus nem fog tudni bejelentkezni"
    >
      <AccountStatus account={account}>
        {!deleted && (
          status === dbEnums.accountStatus.ACTIVE
            ? (
              <Tooltip title={inactivateTooltip}>
                <Grid item>
                  <Button
                    variant="outlined"
                    className="text-red"
                    startIcon={<LockIcon color={verified ? "error" : "disabled"}/>}
                    disabled={!verified}
                    onClick={inactivate}
                  >
                    Letiltom
                  </Button>
                </Grid>
              </Tooltip>
            )
            : (
              <Tooltip title={activateTooltip}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<LockOpenIcon color={verified ? "secondary" : "disabled"}/>}
                    disabled={!verified}
                    onClick={activate}
                  >
                    Feloldom a tiltást
                  </Button>
                </Grid>
              </Tooltip>
            )
        )}
      </AccountStatus>
    </Label>
  );
};

export default React.memo(Status);
