import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { parsePrefixedId } from "../helpers";
import { newRecordIdSuffix } from "../config";


const useDeleteRecords = ({ primaryId, enableSubmit }) => {

  const [deletedPrefixedIds, setDeletedPrefixedIds] = useState([]);
  const [deletedStoredIds, setDeletedStoredIds] = useState([]);


  const deleteRecord = useCallback(prefixedId => {
    const recordId = parsePrefixedId(prefixedId);

    if (!recordId || recordId === clsx(primaryId)) return;

    setDeletedPrefixedIds(deletedPrefixedIds => [...deletedPrefixedIds, prefixedId]);

    const newRecord = recordId.includes(newRecordIdSuffix);
    if (!newRecord) setDeletedStoredIds(deletedStoredIds => [...deletedStoredIds, recordId]);

    if (typeof enableSubmit === "function") enableSubmit();
  }, [enableSubmit, primaryId]);


  const reload = useCallback(() => {
    setDeletedStoredIds([]);
    setDeletedPrefixedIds([]);
  }, []);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.dataRefetched, reload);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.dataRefetched, reload);
    }
  }, [reload]);


  return {
    deletedStoredIds,
    deletedPrefixedIds,
    deleteRecord,
  };

};

export default useDeleteRecords;
