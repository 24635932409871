import React from "react";
import { Fab, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExternalLink } from "components/common";


const SocialMedia = ({ social }) => {

  const { title, icon, account } = social || {};

  return Boolean(account) && (
    <ExternalLink href={account} noIcon>
      <Tooltip title={title || account} placement="bottom" disableTouchListener disableFocusListener>
        <Fab color="secondary" aria-label={title || account} className="mt-8 mr-16 text-22" size="small">
          <FontAwesomeIcon icon={icon} className="text-17"/>
        </Fab>
      </Tooltip>
    </ExternalLink>
  );
};

export default React.memo(SocialMedia);
