import { useCallback, useContext, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { GET_ALUMNUS_GROUP, GET_COUNTRIES } from "client/store/alumnus/queries";
import useSaveContact from "./useSaveContact";


const statementId = "confirmedContact";


const useContactStep = ({ stepStatements, toggleStatement, registerSubmitCb, ignoredSaveCbReturn }) => {

  const { personal: { alumnusId } } = useContext(SessionContext);


  const { loading: countriesLoading, error: countriesError, data: { countries } = {} } = useQuery(GET_COUNTRIES);

  const { loading: alumnusLoading, error: alumnusError, data: { members } = {} } = useQuery(GET_ALUMNUS_GROUP, {
    skip: !alumnusId,
    variables: {
      ids: [alumnusId]
    },
  });


  const queryLoading = useMemo(() => Boolean(alumnusLoading || countriesLoading)
    , [alumnusLoading, countriesLoading]);


  const { address, emails, phones, primaryEmail: { id: primaryEmailId, email: previousEmail } = {}, primaryPhone: { id: primaryPhoneId } = {}, status: { revision: { lastRevision } = {} } = {} } =
    useMemo(() => (!alumnusLoading && !alumnusError && members && members[0]) || {}
      , [alumnusError, alumnusLoading, members]);


  const primaryEmail = useMemo(() => (primaryEmailId && emails.find(({ id }) => id === primaryEmailId)) || {}
    , [emails, primaryEmailId]);


  const primaryPhone = useMemo(() => (primaryPhoneId && phones.find(({ id }) => id === primaryPhoneId)) || {}
    , [phones, primaryPhoneId]);


  const error = useMemo(() => Boolean(alumnusError || countriesError || !address || !primaryEmailId || !primaryPhone)
    , [address, alumnusError, countriesError, primaryEmailId, primaryPhone]);


  const statement = useMemo(() => Boolean(stepStatements && stepStatements.includes(statementId))
    , [stepStatements]);


  const saveCb = useSaveContact({
    alumnusId,
    primaryEmailId,
    primaryPhoneId,
    previousEmail,
    ignoredSaveCbReturn,
  });


  const toggleApprove = useCallback(() => toggleStatement(statementId), [toggleStatement]);


  const onSubmit = useCallback(() => emitUiStateEvent(uiStateEvents.form.submitStarted), []);


  useEffect(() => {
    if (typeof registerSubmitCb === "function") registerSubmitCb(onSubmit);
  }, [registerSubmitCb, onSubmit]);


  return {
    queryLoading,
    error,
    lastRevision,
    countries,
    address,
    primaryEmail,
    primaryPhone,
    saveCb,
    statement,
    toggleApprove,
  };
};

export default useContactStep;
