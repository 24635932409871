import clsx from "clsx";
import { useCallback, useContext, useMemo } from "react";
import { getPrefixedId, getSafeRequirements, serializeFieldName } from "../helpers";
import { FormContext } from "../formControl";


const useField = ({ record, field, fieldRequirements, helperText }) => {

  const { clearError, formErrors, register } = useContext(FormContext);


  const safeRecord = useMemo(() => record || {}, [record]);

  const safeRecordId = useMemo(() => clsx(safeRecord.id), [safeRecord.id]);

  const safePrefixedId = useMemo(() => getPrefixedId(safeRecordId), [safeRecordId]);

  const __typename = useMemo(() => clsx(safeRecord.__typename), [safeRecord.__typename]);


  const safeField = useMemo(() => clsx(field), [field]);


  const name = useMemo(() => serializeFieldName(__typename, safePrefixedId, safeField), [__typename, safeField, safePrefixedId]);


  const required = useMemo(() => Boolean(fieldRequirements && fieldRequirements.required), [fieldRequirements]);

  const rules = useMemo(() => getSafeRequirements(fieldRequirements), [fieldRequirements]);

  const safeRegister = useCallback(() => register(rules), [register, rules]);


  const safeClearError = useCallback(() => clearError(name), [clearError, name]);


  const recordErrors = formErrors && formErrors[__typename] && formErrors[__typename][safePrefixedId];

  const error = recordErrors && recordErrors[safeField];

  const errorMessage = error && error.message;

  const errorType = error && error.type;


  const helperTextOrError = error ? clsx(errorMessage) : helperText;


  return {
    __typename,
    clearError: safeClearError,
    error: Boolean(error),
    errorType,
    errorMessage,
    helperTextOrError,
    name,
    prefixedId: safePrefixedId,
    register: safeRegister,
    recordId: safeRecordId,
    required,
    rules,
  };
};

export default useField;
