import { createLimiter } from "./octopusState";
import processTask from "./processTask";
import { stopMutationErrorMessage } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { decoratedError, errors } from "common/errors";


const getLimiter = client => {
  const limiter = createLimiter(client);
  return limiter ? { limiter } : errors.client.octopus.BOTTLENECK_LIMITER;
};


const getTaskFns = (limiter, client, tasks) => tasks && tasks.map(task => limiter.wrap(processTask.bind(null, client, task)));


const executeTaskFns = async (taskFns) => {
  try {

    await Promise.all(taskFns.map(taskFn => taskFn()));

  } catch (e) {
    if (e.message === stopMutationErrorMessage) return { aborted: true };

    const message = errorLogStore("octopus", "executeTaskFns", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};


const processTasks = async (client, tasks) => {
  const { error: limiterError, limiter } = getLimiter(client);
  if (limiterError) return { error: limiterError };

  const taskFns = getTaskFns(limiter, client, tasks);
  if (taskFns) {
    const failed = await executeTaskFns(taskFns);
    if (failed) return failed;
  }

  return {};
};

export default processTasks;
