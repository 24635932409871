import React, { useContext } from "react";
import clsx from "clsx";
import { Grid, IconButton, Icon, Tooltip } from "@material-ui/core";
import { ListContext } from "components/listPages";
import ExportButtons from "./ExportButtons";


const Toolbar = () => {

  const { allDetails, toggleAllDetails } = useContext(ListContext)

  return (
    <Grid container justify="space-between" alignItems="center" className="w-full px-16">
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <div className="min-w-32">
            <Tooltip title={clsx("További információk", allDetails ? "elrejtése" : "megjelenítése", "minden sornál")}>
              <IconButton size="small"
                          onClick={toggleAllDetails}
              >
                <Icon>{allDetails ? "more_horiz" : "more_vert"}</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <ExportButtons/>
      </Grid>
    </Grid>
  );
};

export default React.memo(Toolbar);
