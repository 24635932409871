import React, { useCallback, useState } from "react";
import moment from "moment";
import { Grid, TextField } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { DateField, Input } from "components/form";
import Membership from "./Membership";


export const __typename = "alumnus_org";


const maxLength = 2000;

const rowsCount = 5;

const rowsMaxCount = 10;


const _updateFields = {
  mem: "mem",
  placeOfBirth: "placeOfBirth",
  dateOfBirth: "dateOfBirth",
  mothersName: "mothersName",
  formTeacher: "formTeacher",
  gradName: "gradName",
  note: "note",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.mem, _updateFields.placeOfBirth, _updateFields.dateOfBirth, _updateFields.mothersName, _updateFields.gradName],
};


const _fieldAliases = {
  [_updateFields.mem]: "membership",
  [_updateFields.placeOfBirth]: "place_of_birth",
  [_updateFields.dateOfBirth]: "date_of_birth",
  [_updateFields.mothersName]: "mothers_name",
  [_updateFields.gradName]: "grad_name",
  [_updateFields.note]: "admin_note",
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


const Org = ({ record, special, safeGroup, regDate, issue, registration, variant = "outlined" }) => {

  const [mem, setMem] = useState(record[_updateFields.mem]);

  const onChangeCb = useCallback(mem => setMem(mem), []);

  return (
    <Grid item container spacing={2} className="mt-4">
      <Grid item xs={12} md={registration ? 4 : 12}>
        <Input record={record}
               field={_updateFields.placeOfBirth}
               maxLength={70}
               label="Születési hely"
               helperText="Nem magyarországi születési hely esetén a születési országot is add meg!"
               required
               noOnlyLowerCase
               noOnlyUpperCase
        />
      </Grid>
      <Grid item xs={12} sm={registration ? 6 : 12} md={registration ? 4 : 12}>
        <DateField record={record}
                   field={_updateFields.dateOfBirth}
                   pickerClassName="w-full"
                   label="Születési idő"
                   required
                   openToYearOnUntouched
                   disableFuture
        />
      </Grid>
      <Grid item xs={12} sm={registration ? 6 : 12} md={registration ? 4 : 12}>
        <Input record={record}
               field={_updateFields.mothersName}
               maxLength={70}
               label="Anyja neve (leánykori)"
               fakeContentRegex={/^[^\s\d]+[ ]+[^\s\d]+/i}
               required
               noOnlyLowerCase
               noOnlyUpperCase
        />
      </Grid>
      {!special && !issue && !registration && (
        <Grid item xs={12}>
          <TextField
            disabled
            label="Osztály, osztályfőnök"
            defaultValue={`${safeGroup}, ${record[_updateFields.formTeacher]}`}
            className="mb-32 w-full"
            variant={variant}
          />
        </Grid>
      )}
      {!issue && !registration && (
        <Grid item xs={12}>
          <Membership record={record}
                      special={special}
                      field={_updateFields.mem}
                      onChangeCb={onChangeCb}
                      label="Érettségi"
                      specialLabel="Tagság jogcíme"
                      tooltip="Tagság jogcímének módosítása"
                      helperText={!special && (
                        <span>Csak rendes tagság esetén állítható &ndash; tanári és tiszteletbeli tagság esetén nem.</span>
                      )}
          />
        </Grid>
      )}
      {!special && !issue && !registration && mem === dbEnums.membership.GRADUATED && (
        <Grid item xs={12}>
          <Input record={record}
                 field={_updateFields.gradName}
                 maxLength={70}
                 label="Érettségi bizonyítványban szereplő név"
                 fakeContentRegex={/^[^\s\d]+[ ]+[^\s\d]+/i}
                 required
                 noOnlyLowerCase
                 noOnlyUpperCase
          />
        </Grid>
      )}
      {!issue && !registration && (
        <Grid item xs={12}>
          <TextField
            disabled
            label="Regisztráció időpontja"
            defaultValue={moment(regDate).format("L")}
            className="mb-32 w-full"
            variant={variant}
          />
        </Grid>
      )}
      {!issue && !registration && (
        <Grid item xs={12}>
          <Input record={record}
                 field={_updateFields.note}
                 label="Tagsággal kapcsolatos megjegyzés"
                 helperText={`Legfeljebb ${maxLength} karakterben. Ez a megjegyzés kizárólag az adminisztrátorok számára látható.`}
                 className="mb-8"
                 maxLength={maxLength}
                 rows={rowsCount}
                 rowsMax={rowsMaxCount}
                 multiline
          />
        </Grid>
      )}
    </Grid>
  );
}

export default React.memo(Org);
