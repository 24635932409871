import React from "react";
import clsx from "clsx";
import Link from "next/link"
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import amber from "@material-ui/core/colors/amber";
import shortcutItems from "config/shortcuts";
import { FuseAnimateGroup } from "components/fuse";


const useStyles = makeStyles({
  root: {
    "&.horizontal": {},
    "&.vertical": {
      flexDirection: "column"
    }
  },
  item: {
    textDecoration: "none!important",
    color: "inherit"
  },
  addIcon: {
    color: amber[600]
  },
});


const FuseShortcuts = props => {
  const classes = useStyles(props);
  return (
    <div
      className={clsx(classes.root, props.variant, "flex flex-1", props.variant === "vertical" && "flex-grow-0 flex-shrink", props.className)}>
      <FuseAnimateGroup
        enter={{
          animation: "transition.expandIn"
        }}
        className={clsx("flex flex-1", props.variant === "vertical" && "flex-col")}
      >
        {shortcutItems.map(item => item && (
            <div key={item.id} className={classes.item}>
              <Link href={item.href} as={item.as || item.href}>
                <a rel="noopener noreferrer" role="button">
                  <Tooltip title={item.title} placement={props.variant === "horizontal" ? "bottom" : "left"}>
                    <IconButton className="w-40 h-40 p-0">
                      {item.icon ?
                        (
                          <Icon>{item.icon}</Icon>
                        ) :
                        (
                          <span className="text-20 font-bold uppercase">{item.title[0]}</span>
                        )
                      }
                    </IconButton>
                  </Tooltip>
                </a>
              </Link>
            </div>
          )
        )}
      </FuseAnimateGroup>
    </div>
  );
};

FuseShortcuts.propTypes = {};
FuseShortcuts.defaultProps = {
  variant: "horizontal"
};

export default React.memo(FuseShortcuts);
