import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import validator from "validator";
import { TextField, InputAdornment, Grid, Icon, Tooltip } from "@material-ui/core";
import { Controller, useField } from "components/form";
import { requirementMessages } from "../config";
import { getDefaultValueProp } from "../helpers";


export const validateEmailFormat = value => Boolean(validator.isEmail(value)) || requirementMessages.email.invalidFormat;


const EmailAddress = ({
                        record, field, requirements, required, defaultValue, getEndAdornment, errorEndAdornment,
                        onChangeCb, loading, disableError, className, spacing, alignItems, textFieldClassName,
                        variant = "outlined", label = "Email cím", helperText, maxLength, children, ...restProps
                      }) => {


  const onChange = useCallback(([{ target: { value } = {} }]) => {
    if (typeof onChangeCb === "function") onChangeCb(clsx(value));

    return clsx(value);
  }, [onChangeCb]);


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    required,
    validate: {
      validateEmailFormat,
      ...(requirements && requirements.validate),
    },
  }), [required, requirements]);


  const { name, error, errorType, helperTextOrError, rules } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const endAdornment = useMemo(() => (typeof getEndAdornment === "function" && getEndAdornment(errorType, helperTextOrError))
    || (Boolean(errorEndAdornment && error) && (
      <InputAdornment position="end">
        <Tooltip title={helperTextOrError}>
          <Icon className="text-red">error_outline</Icon>
        </Tooltip>
      </InputAdornment>
    )), [error, errorType, errorEndAdornment, helperTextOrError, getEndAdornment]);


  return (
    <Grid container spacing={spacing || 1} alignItems={alignItems || "flex-start"} className={className}>
      <Grid item xs={12}>
        <Controller
          {...restProps}
          {...getDefaultValueProp(defaultValue)}
          name={name}
          onChange={onChange}
          as={<TextField/>}
          rules={rules}
          label={label}
          inputProps={{
            maxLength: maxLength || 100,
            "aria-label": label,
          }}
          classes={{
            root: clsx("w-full", !clsx(textFieldClassName).includes("mb-") && "mb-32", textFieldClassName),
          }}
          variant={variant}
          helperText={!loading && helperTextOrError}
          required={Boolean(required)}
          error={!disableError && error}
          InputProps={{
            endAdornment,
          }}
        />
      </Grid>
      {children}
    </Grid>
  );
};

export default React.memo(EmailAddress);
