import { resolvers as alumnus } from "../alumnus";
import { resolvers as bannedIps } from "../bannedIps";
import { resolvers as card } from "../card";
import { resolvers as contacts } from "../contacts";
import { resolvers as group } from "../group";
import { resolvers as octopus } from "../octopus";
import { resolvers as regCode } from "../regCode";
import { resolvers as revisionRequest } from "../revisionRequest";
import { resolvers as search } from "../search";
import { resolvers as specialAccount } from "../specialAccount";


const resolvers = {
  ...alumnus,
  ...bannedIps,
  ...card,
  ...contacts,
  ...group,
  ...octopus,
  ...regCode,
  ...revisionRequest,
  ...search,
  ...specialAccount,
  Query: {
    ...alumnus.Query,
    ...bannedIps.Query,
    ...card.Query,
    ...contacts.Query,
    ...group.Query,
    ...octopus.Query,
    ...regCode.Query,
    ...revisionRequest.Query,
    ...search.Query,
    ...specialAccount.Query,
  },
  Mutation: {
    ...alumnus.Mutation,
    ...bannedIps.Mutation,
    ...card.Mutation,
    ...contacts.Mutation,
    ...group.Mutation,
    ...octopus.Mutation,
    ...regCode.Mutation,
    ...revisionRequest.Mutation,
    ...search.Mutation,
    ...specialAccount.Mutation,
  },
};

export default resolvers;
