import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { useOpenDialog } from "components/listPages";
import EditSpecialAccount from "./EditSpecialAccount";


const EditButton = specialAccount => {

  const edit = useOpenDialog({
    title: "Speciális felhasználói fiók szerkesztése",
    ContentComponent: (
      <EditSpecialAccount {...specialAccount}/>
    ),
  });

  return (
    <Tooltip title="Speciális felhasználói fiók szerkesztése">
      <IconButton size="small"
                  onClick={edit}
      >
        <Icon>edit</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(EditButton);
