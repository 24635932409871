import urlJoin from "url-join";
import clsx from "clsx";
import { noSSL } from "config/routing";


const httpOnly = true;


const secure = !noSSL;

const sameSite = !noSSL;


export const authAPIMethods = {
  anonymous: "anonymous",
  login: "login",
  backend: "backend",
  init: "init",
  issue: "issue",
  logout: "logout",
  media: "media",
  whoAmI: "whoAmI",
};


export const authAPIServices = {
  approveTerms: "approveTerms",
  changePsw: "changePsw",
  deleteSessions: "deleteSessions",
  encryptPsw: "encryptPsw",
  hydrateSessions: "hydrateSessions",
  revision: "revision",
  setPsw: "setPsw",
};


export const bodyKeys = {
  allowedRoles: "__allowedRoles",
  headers: "headers",
  remoteAddresses: "__remoteAddresses",
};


export const cookies = {
  CDS: {
    name: "CTZVe3b4E7",
    path: "/api",
    httpOnly,
    sameSite,
    secure,
  },
  mediaToken: {
    name: "Mz2J662mYw",
    path: "/api/media",
    httpOnly,
    sameSite,
    secure,
  },
  sessionId: {
    name: "S5re4IeHmH",
    path: "/api",
    httpOnly,
    sameSite,
    secure,
  },
};


export const getAuthServiceUrl = service => urlJoin(process.env.AUTH_BACKEND_URL, clsx(service));
