import React, { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { AutocompleteFreeSolo } from "components/form";


const defaultMinLength = 3;


const getHelperTip = minLength => (
  <span><i>Tipp:</i> amennyiben legalább {minLength || 0} karakter hosszú szöveget adsz meg, kiválaszthatóvál válnak a mások által
    leggyakrabban megadott (a karaktersorozattal egyezést mutató) elemek</span>
);


const JobAutocomplete = ({ query, minLength = defaultMinLength, helperText, helperTextTip = getHelperTip(minLength), ...props }) => {

  const { data: { options } = {} } = useQuery(query);

  const safeHelperText = useMemo(() => (
    <span>
      {helperText}
      {Boolean(helperText && helperTextTip) && (
        <span>&nbsp;</span>
      )}
      {helperTextTip}
    </span>
  ), [helperText, helperTextTip]);

  return (
    <AutocompleteFreeSolo
      {...props}
      options={options || []}
      fakeContentRegex={/[a-z]+/i}
      helperText={safeHelperText}
      minLength={minLength}
    />
  );
};

export default React.memo(JobAutocomplete);
