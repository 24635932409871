import { useCallback, useContext, useMemo, useState, useRef } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { isFnOrPromise, safeFnOrPromise } from "common/helpers";
import { UiMessages } from "config/messages";
import { updateSessionStore, validateMe } from "client/lib/session";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { fetchAlumnus } from "client/store/alumnus/resolvers/getters";
import { useSubmit } from "components/form";
import { issueStepConfigs } from "./config";
import useStatements from "./useStatements";
import useBar from "./useBar";


const useIssues = () => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { issues, personal: { alumnusId } } = useContext(SessionContext);


  const submitCb = useRef(null);


  const getSteps = useCallback(() => issues.reduce((steps, issue) => [
    ...steps,
    ...issueStepConfigs[issue]
  ], []), [issues]);


  const [steps] = useState(() => getSteps());

  const [activeStep, setActiveStep] = useState(0);

  const [statements, setStatements] = useState({});


  const { submitLoading, stopSubmitLoading, ...submit } = useSubmit(true);


  const client = useApolloClient();


  const registerSubmitCb = useCallback(cb => isFnOrPromise(cb) && (submitCb.current = cb), []);


  const unregisterSubmitCb = useCallback(() => submitCb.current = null, []);


  const onNextCb = useCallback((...args) => safeFnOrPromise(submitCb.current)(...args), []);


  const onEndCb = useCallback(async () => {
    if (!await validateMe()) {
      await updateSessionStore(true);
      await fetchAlumnus(alumnusId, client, true);
      showMessage(UiMessages.issues.thankYou);
    }
  }, [alumnusId, client, showMessage]);


  const step = useMemo(() => steps[activeStep], [activeStep, steps]);


  const stepId = useMemo(() => step && step.id, [step]);


  const { stepStatements, doStatement, revokeStatement, toggleStatement } = useStatements({
    stepId,
    statements,
    setStatements,
  });


  const bar = useBar({
    steps,
    stepStatements,
    activeStep,
    setActiveStep,
    submitLoading,
    stopSubmitLoading,
    unregisterSubmitCb,
    onNextCb,
    onEndCb,
  });


  return {
    steps,
    step,
    activeStep,
    submitLoading,
    ...submit,
    registerSubmitCb,
    bar,
    stepStatements,
    doStatement,
    revokeStatement,
    toggleStatement,
  };

};

export default useIssues;
