import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Fab, Grid, Tooltip } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { Alert } from "components/common";
import { getPanelKey, getPrefixedId, isNew } from "../helpers";
import { FormContext } from "./Form";
import usePanels from "./usePanels";
import Panel from "./Panel";


const Panels = ({
                  primaryId, isFull, addRecord, panelKey, gridProps, onErrorEntityStr, newRecordTypename, issue,
                  registration, Header, HeaderAlert, className, ...panelProps
                }) => {


  const { deletedPrefixedIds, deleteRecord, newRecords, newRecordEntityStr, records } = useContext(FormContext);


  const { onAddClick, disableAdd, activePanelKey, dirtyPanelKeys, ...panelControl } = usePanels({
    addRecord,
    onErrorEntityStr,
    newRecordTypename,
    panelKey,
  });


  const getPanel = useCallback(record => {
    const { __typename, id } = record || {};
    if (!__typename || !id) return null;

    const prefixedId = getPrefixedId(id);
    if (deletedPrefixedIds && deletedPrefixedIds.includes(prefixedId)) return null;

    const isPrimary = issue || id === primaryId;
    const _isNew = isNew(id);
    const key = getPanelKey(__typename, prefixedId);
    const expanded = key === activePanelKey || __typename === activePanelKey;
    const dirty = Boolean(registration && dirtyPanelKeys.includes(key));

    return (
      <Panel key={key}
             expanded={expanded}
             __typename={__typename}
             record={record}
             recordId={id}
             prefixedId={prefixedId}
             isPrimary={isPrimary}
             isNew={_isNew}
             deleteRecord={deleteRecord}
             issue={issue}
             registration={registration}
             dirty={dirty}
             {...panelControl}
             {...panelProps}/>
    );
  }, [activePanelKey, deleteRecord, deletedPrefixedIds, dirtyPanelKeys, issue, panelControl, panelProps, primaryId, registration]);


  return (
    <>
      {Boolean(Header || HeaderAlert) && (
        <div className="mb-32">
          {Header || (
            <Alert>{HeaderAlert}</Alert>
          )}
        </div>
      )}
      <Grid container spacing={4} justify="space-between" className={className}>
        <Grid item xs={12} sm={10} md={11}
              className={clsx((!gridProps || !gridProps.className) && "max-w-xl")} {...gridProps}>
          {Boolean(records) && records.map(getPanel)}
          {Boolean(newRecords) && newRecords.map(getPanel)}
        </Grid>
        {!disableAdd && (
          <Grid item xs={12} sm={2} md={1} className="md:mr-24 lg:mr-12 xl:mr-0">
            <Tooltip title={clsx("Új", newRecordEntityStr || "bejegyzés")}>
              <div>
                <Fab color="primary" aria-label="add" disabled={isFull} onClick={onAddClick}>
                  <AddIcon/>
                </Fab>
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default React.memo(Panels);
