import texts from "config/texts";
import { withCapital } from "common/helpers";
import { default as Account } from "./Account";
import { default as Address } from "./Address";
import { default as Avatar } from "./Avatar";
import { default as Cooperation } from "./Cooperation";
import { default as Email } from "./Email";
import { default as Hobby } from "./Hobby";
import { default as Job } from "./Job";
import { default as News } from "./News";
import { default as Org } from "./Org";
import { default as Card } from "./Card";
import { default as Phone } from "./Phone";
import { default as Social } from "./Social";
import { default as Study } from "./Study";


export const profileHelps = {
  account: {
    title: "Alumnus fiók adminisztrációja",
    content: Account,
  },
  avatar: {
    title: "Profilkép",
    content: Avatar,
  },
  address: {
    title: "Postai címek",
    content: Address,
  },
  cooperation: {
    title: "Együttműködési lehetőségek",
    content: Cooperation,
  },
  email: {
    title: "Email címek",
    content: Email,
  },
  hobby: {
    title: "Érdeklődési kör, hobbik",
    content: Hobby,
  },
  job: {
    title: "Munkahelyek, projektek",
    content: Job,
  },
  news: {
    title: "Feliratkozások",
    content: News,
  },
  org: {
    title: "Hivatalos adatok adminisztrációja",
    content: Org,
  },
  card: {
    title: `${withCapital(texts.cardsName)} adminisztrációja`,
    content: Card,
  },
  phone: {
    title: "Telefonszámok",
    content: Phone,
  },
  social: {
    title: "Webes elérhetőségek",
    content: Social,
  },
  study: {
    title: "Tanulmányok",
    content: Study,
  },
};
