import urljoin from "url-join";
import clsx from "clsx";
import buildUrl from "build-url";
import serializeJson from "serialize-javascript";
import { getSecurityHeaders } from "client/lib/session";
import { errorLogLib } from "client/lib/errorLog";
import { errors } from "common/errors";
import { getValidatedData } from "./data";
import { backendApiBasePath } from "./config";


const validateService = service => {
  if (!service) {
    errorLogLib("backendApi", "validateService", "missing service");
    return errors.client.backendApi.MISSING_SERVICE;
  }

  if (!service.path || !service.settings) {
    errorLogLib("backendApi", "validateService", { service }, "invalid backend service");
    return errors.client.backendApi.INVALID_SERVICE;
  }
};


const getUrl = (service, path, queryParams) => buildUrl(urljoin(backendApiBasePath, service.path, clsx(path)), {
  queryParams: { ...queryParams },
});


const extendHeaders = ({ settings: { headers = {} } = {} }) => ({
  ...getSecurityHeaders(),
  ...headers,
});


const getBody = (body, { contentJson }) => contentJson
  ? serializeJson(body || {})
  : body;


const getSettings = (service, body) => ({
  ...service.settings,
  headers: extendHeaders(service),
  body: getBody(body, service),
});


const fetchService = async ({ acceptJson, acceptBlob }, url, settings) => {
  try {
    const response = await fetch(url, settings);

    const rawData = acceptJson
      ? await response.json()
      : acceptBlob
        ? await response.blob()
        : await response.text();
    return { rawData, status: response.status };

  } catch (e) {
    errorLogLib("backendApi", "fetchService", e);
    return errors.client.backendApi.NETWORK_ERROR;
  }
};


export const backendFetch = async (service, body, path, queryParams) => {
  const invalidService = validateService(service);
  if (invalidService) return {
    ...invalidService,
    data: {},
  };

  const url = getUrl(service, path, queryParams);
  const settings = getSettings(service, body);

  const { error: networkError, rawData, status } = await fetchService(service, url, settings);

  if (networkError) return { error: networkError, data: {} };

  return await getValidatedData(service, rawData, status);
};
