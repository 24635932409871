import React from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { DOWNLOAD_CSV, DOWNLOAD_XLSX } from "client/store/contacts";


const ExportButtons = () => {

  const [downloadCSV] = useMutation(DOWNLOAD_CSV);

  const [downloadXLSX] = useMutation(DOWNLOAD_XLSX);

  return (
    <>
      <Grid item>
        <div className="min-w-32">
          <Tooltip title="Letöltés Excel táblázatként (.xslx)">
            <IconButton size="small"
                        onClick={downloadXLSX}
            >
              <FontAwesomeIcon className="text-24" icon={faFileExcel} color="rgba(0, 0, 0, 0.4)"/>
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
      <Grid item>
        <div className="min-w-32">
          <Tooltip title="Letöltés szövegfájlként (.csv)">
            <IconButton size="small"
                        onClick={downloadCSV}
            >
              <FontAwesomeIcon className="text-24" icon={faFileCsv} color="rgba(0, 0, 0, 0.4)"/>
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </>
  );
};

export default React.memo(ExportButtons);
