import { useCallback } from "react";


const useValues = ({ getValues: _getValues, getDefaultValues }) => {

  const getValues = useCallback(() => _getValues({ nest: true }), [_getValues]);


  const parseValues = useCallback((values, __typename, prefixedId) => values && values[__typename] && values[__typename][prefixedId], []);


  const getItemValues = useCallback((__typename, prefixedId) => {
    const formValues = getValues();
    const defaultValues = getDefaultValues();

    return parseValues(formValues, __typename, prefixedId)
      || parseValues(defaultValues, __typename, prefixedId)
      || {};
  }, [getDefaultValues, getValues, parseValues]);


  const getValue = useCallback((__typename, prefixedId, field) => {
    const formValues = getItemValues(__typename, prefixedId);
    return formValues[field];
  }, [getItemValues]);


  return {
    getItemValues,
    getValue,
    getValues,
  };

};

export default useValues;
