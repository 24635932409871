import React from "react";
import Layout from "components/layout";
import { useLogComponentError } from "components/error";
import { useSubmit } from "components/form";
import EditPage from "./edit/EditPage";
import ProfilePage from "./profile/ProfilePage";
import useAlumnus from "./useAlumnus";
import useAlumnusRoute from "./useAlumnusRoute";


export const AlumnusContext = React.createContext(null);


const Alumnus = () => {

  const { submitLoading, untouched, disableSubmit, enableSubmit, getLastSubmit, startSubmit } = useSubmit(true);


  const { alumnusId, myProfile, editMode, forbidden, ...alumnusRoute } = useAlumnusRoute({
    untouched,
  });


  const { loading, error, notFound, ...restAlumnus } = useAlumnus({
    alumnusId,
    editMode,
    getLastSubmit,
    submitLoading,
    forbidden,
  });


  const contextValue = {
    alumnusId,
    myProfile,
    submitLoading,
    untouched,
    disableSubmit,
    enableSubmit,
    startSubmit,
    editMode,
    ...restAlumnus,
    ...alumnusRoute,
  };


  useLogComponentError("Alumnus", error);


  return (
    <AlumnusContext.Provider value={contextValue}>
      <Layout loading={loading} error={error} notFound={notFound} forbidden={forbidden}>
        {editMode
          ? (
            <EditPage/>
          ) : (
            <ProfilePage/>
          )}
      </Layout>
    </AlumnusContext.Provider>
  );
};

export default React.memo(Alumnus);
