import { errors, isSameAppError } from "common/errors";
import { tokens, setSession, setTokens } from "./session";


const updateAuthData = async authData => {
  const tokenData = Object.fromEntries(Object.entries(authData).filter(([key]) => Object.keys(tokens).includes(key)));
  setTokens(tokenData);

  const sessionData = Object.fromEntries(Object.entries(authData).filter(([key]) => !Object.keys(tokens).includes(key)));
  await setSession(sessionData);
};


export const updateInitialized = async value => await setSession({ initialized: Boolean(value) });


export const updateIdentified = async value => await setSession({ identified: Boolean(value) });


export const setLoaded = async success => await setSession({
  loaded: true,
  failed: !success,
});


export const setLoading = async () => await setSession({
  loaded: false,
  failed: false,
});


export const setFetched = async () => await setSession({
  fetching: false,
});


export const setFetching = async () => await setSession({
  fetching: true,
});


const updateBanData = async ({ ignoreBan }, error) => {
  if (ignoreBan) return null;

  const banned = error.banned || isSameAppError({ error }, errors.server.general.BANNED);
  const bannedIp = (banned && error.ip) || null;
  const banExpiry = (banned && error.banExpiry) || null;

  await setSession({
    banned,
    bannedIp,
    banExpiry,
    permanentBan: banned && !banExpiry,
  });
};


export const applyAuthDataToSession = async (service, authData, error) => {
  if (authData) await updateAuthData(authData);
  if (service && error) await updateBanData(service, error);
};
