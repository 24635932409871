import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { DateField, Input, Membership } from "components/form";
import Code from "./Code";


export const __typename = "reg_code";


export const _updateFields = {
  code: "code",
  name: "name",
  mem: "mem",
  group: "group",
  formTeacher: "formTeacher",
  expiry: "expiry",
  note: "note",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.code, _updateFields.name],
};


const _fieldAliases = {
  [_updateFields.mem]: "membership",
  [_updateFields.group]: "grp",
  [_updateFields.formTeacher]: "form_teacher",
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


const RegCode = ({ isNew, ...record }) => {

  const [mem, setMem] = useState(record[_updateFields.mem]);

  const onChangeCb = useCallback(mem => setMem(mem), []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Input record={record}
               field={_updateFields.name}
               disabled={Boolean(record.used)}
               label="Akinek a kódot kiállítod"
               className="mb-16"
               required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Code {...record}/>
      </Grid>
      <Grid item xs={12}>
        <Membership record={record}
                    field={_updateFields.mem}
                    disabled={!isNew}
                    onChangeCb={onChangeCb}
                    label="Tagság jogcíme"
                    regCode
        />
      </Grid>
      {mem === dbEnums.membership.NONGRADUATED && (
        <>
          <Grid item xs={12} sm={4}>
            <Input record={record}
                   field={_updateFields.group}
                   disabled={Boolean(record.used)}
                   fakeContentRegex={/^(19|20)[\d]{2}[ABÁ]?$/i}
                   label="Osztály"
                   className="mb-16"
                   maxLength={5}
                   required
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Input record={record}
                   field={_updateFields.formTeacher}
                   disabled={Boolean(record.used)}
                   label="Osztályfőnök(ök)"
                   className="mb-16"
                   helperText="Több osztályfőnök esetén vesszővel és szóközzel elválasztva"
                   maxLength={200}
                   required
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={4}>
        <DateField record={record}
                   field={_updateFields.expiry}
                   disabled={Boolean(record.used)}
                   pickerClassName="w-full"
                   label="Felhasználási határidő"
                   className="mb-8"
                   showTodayButton
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Input record={record}
               field={_updateFields.note}
               className="mb-8"
               label="Megjegyzés"
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(RegCode);
