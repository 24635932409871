import { useCallback, useContext, useMemo } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { emitUiStateEvent, UiStateContext, uiStateEvents } from "client/lib/providers/UiStateProvider";


const useOpenDialog = ({ title, ContentComponent, enabledOKButton }) => {

  const { dialog: { openDialog } } = useContext(UiStateContext);


  const theme = useTheme();

  const screenXsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const screenMdDown = useMediaQuery(theme.breakpoints.down("md"));


  const onOK = useCallback(() => {
    emitUiStateEvent(uiStateEvents.form.submitStarted);

    // to abort dialog closing
    return { stayOpened: true };
  }, []);


  const dialogOptions = useMemo(() => ({
    title,
    ContentComponent,
    OKButton: "Mentés",
    colorOK: "secondary",
    cancelButton: "Mégsem",
    maxWidth: "md",
    fullScreen: screenXsDown,
    fullWidth: screenMdDown,
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    disabledOKButton: !enabledOKButton,
    onOK,
  }), [title, ContentComponent, screenXsDown, screenMdDown, enabledOKButton, onOK]);


  return useCallback(() => openDialog(dialogOptions), [dialogOptions, openDialog]);

};

export default useOpenDialog;
