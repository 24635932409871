import { GET_ALUMNUS_GROUP } from "client/store/alumnus";
import { errorLogStore } from "client/lib/errorLog";


export const fetchGroupMembers = async ({ group }, client) => {
  if (!group) return null;
  try {
    const { data: { members = [] } = {} } = await client.query({
      query: GET_ALUMNUS_GROUP,
      variables: {
        groups: [group],
      },
    });
    return members;

  } catch (e) {
    errorLogStore("group", "fetchGroupMembers", e);
  }
};
