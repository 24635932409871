import React from "react";
import clsx from "clsx";
import { Icon, Typography } from "@material-ui/core";
import FlagIcon from "./FlagIcon";


const CityAndCountry = ({ alumnus: { residency: { country, cityAndCountry } } = {}, innerClassName }) => Boolean(cityAndCountry) && (
  <div className="flex items-start mt-8">
    <Icon className="text-18 mr-8" color="action">home</Icon>
    <div className={clsx("flex flex-row flex-wrap", innerClassName)}>
      <Typography className="mb-4">
        <span className={clsx(country && "mr-6")}>{cityAndCountry}</span>
        {country && (
          <FlagIcon code={country} disableHun/>
        )}
      </Typography>
    </div>
  </div>
);

export default React.memo(CityAndCountry);
