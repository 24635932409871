import React, { useCallback, useContext } from "react";
import { Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UiMessages } from "config/messages";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import EditButton from "./EditButton";
import DelButton from "./DelButton";


const RowControl = regCode => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const onCopy = useCallback(() => showMessage(UiMessages.meta.copiedToClipboard("A regisztrációs kód")), [showMessage]);

  return (
    <Grid container spacing={1} alignItems="center" className="sm:min-w-128">
      <Grid item>
        <EditButton {...regCode}/>
      </Grid>
      <Grid item>
        <DelButton {...regCode}/>
      </Grid>
      <Grid item>
        <CopyToClipboard text={regCode.code}
                         onCopy={onCopy}
        >
          <Tooltip title="Kód másolása a vágólapra">
            <IconButton size="small">
              <Icon>file_copy</Icon>
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      </Grid>
    </Grid>
  );
};

export default React.memo(RowControl);
