import { getValidatedSettings } from "./settings";
import getMissingSubcriptions from "./getMissingSubcriptions";
import getTasks from "./getTasks";
import analyzeLists from "./analyzeLists";
import getCapacity from "./getCapacity";
import extendTasks from "./extendTasks";
import { GET_NEWSLETTER_SUBSCRIPTIONS } from "../queries";
import { onStart, onEnd, subStates } from "./octopusState";
import { errorLogStore } from "client/lib/errorLog";
import { decoratedError, errors } from "common/errors";


const getSubscriptions = async (client) => {
  const { error, data } = await client.query({
    query: GET_NEWSLETTER_SUBSCRIPTIONS,
    fetchPolicy: "network-only",
  });

  return error
    ? decoratedError(errors.client.octopus.APOLLO_ERROR, { message: error })
    : data;
};


const getMembersObject = ({ members = [] } = {}) => (members && members.reduce((acc, member) => {
  const { nls: { e: email } = {} } = member || {};
  return email
    ? {
      ...acc,
      [email]: member,
    }
    : acc
}, {})) || {};


const getSubscribedMembers = ({ members = [] } = {}) => (members && members.filter(({ nls: { s } = {} } = {}) => s)) || [];


const getResults = async (client, settings) => {
  try {

    const subscriptions = await getSubscriptions(client);
    if (subscriptions.error) return subscriptions;

    const membersObject = getMembersObject(subscriptions);
    const subscribedMembers = getSubscribedMembers(subscriptions);
    const subscribedMembersCount = subscribedMembers.length || 0;

    const listInfo = await analyzeLists(settings, membersObject);
    if (listInfo.error) return listInfo;

    const missingSubcriptions = getMissingSubcriptions(client, subscriptions, listInfo);
    if (missingSubcriptions.error) return missingSubcriptions;

    return {
      ...listInfo,
      ...missingSubcriptions,
      subscribedMembersCount,
    };

  } catch (e) {
    const message = errorLogStore("octopus", "getResults", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};


const getWrapperStats = wrapper => Object.entries(wrapper || {}).reduce((stats, [key, value]) => {
  const count = (value && value.length) || 0;
  return count
    ? { ...stats, [key]: count }
    : stats;
}, {});


const getStats = results => Object.fromEntries(Object.entries(results || {}).map(([key, value]) => [key, getWrapperStats(value)]));


const getCapacityAndTasks = async (results, settings) => {
  try {

    const tasks = getTasks(results);
    if (tasks.error) return tasks;

    const capacity = await getCapacity(settings, tasks);
    if (capacity.error) return capacity;

    const extendedTasks = await extendTasks(tasks, capacity, settings);
    if (extendedTasks.error) return extendedTasks;

    return {
      ...capacity,
      ...extendedTasks,
    };

  } catch (e) {
    const message = errorLogStore("octopus", "getCapacityAndTasks", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};


const getStatusUpdates = async (client) => {
  try {

    const settings = await getValidatedSettings(client);
    if (settings.error) return settings;

    const { subscribedMembersCount, ...results } = await getResults(client, settings);
    if (results.error) return results;

    const capacityAndTasks = await getCapacityAndTasks(results, settings);
    if (capacityAndTasks.error) return capacityAndTasks;

    const stats = getStats(results);

    return {
      ...capacityAndTasks,
      stats,
      subscribedMembersCount,
      error: null,
    };

  } catch (e) {
    const message = errorLogStore("octopus", "getStatusUpdates", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};


const octopusRefreshStatus = async (_root, _args, { client }) => {
  onStart(client, subStates.status);
  const updates = await getStatusUpdates(client);
  onEnd(client, subStates.status, updates);
  return null;
};

export default octopusRefreshStatus;
