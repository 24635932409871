import gql from "graphql-tag";
import { getUpdateHelper } from "client/store/helpers";
import { fragmentSetting } from "./queries";


export const UPSERT_SETTING = gql`
  mutation UpsertSetting($id: String!, $value: jsonb!) {
    results: insert_setting(objects: {id: $id, value: $value}, on_conflict: {constraint: setting__pkey, update_columns: [value]}) {
      affected_rows
      returning {
        ...setting
      }
    }
  }

  ${fragmentSetting}
`;


export const updateUpsertSetting = getUpdateHelper(fragmentSetting, "setting", ({ id, value }) => ({ id, value }));
