import clsx from "clsx";
import cookie from "cookie";
import moment from "moment";
import { cookies } from "./config";


export const getCookie = ({ name, ...options }, value) => name && value && cookie.serialize(name, value, options);


const getMediaTokenCookie = mediaToken => {
  if (mediaToken) return getCookie(cookies.mediaToken, clsx(mediaToken));

  // if mediaToken is emtpy the client-side cookie should be deleted (by setting an expiration date in the past)
  const mediaTokenCookieWExpiry = {
    ...cookies.mediaToken,
    expires: moment().subtract(1, "hours").toDate(),
  };

  return getCookie(mediaTokenCookieWExpiry, "");
};


export const getAuthCookies = ({ session }) => {
  const { sessionId, mediaToken } = session || {};
  if (!sessionId) return null;

  const sessionIdCookie = sessionId && getCookie(cookies.sessionId, sessionId);
  const mediaTokenCookie = getMediaTokenCookie(mediaToken);

  return [sessionIdCookie, mediaTokenCookie];
};
