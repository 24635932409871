import clsx from "clsx";
import { getService } from "./helpers";


export const registration = {
  healthcheck: getService("registration/healthcheck", { ignoreBan: true }),
  getTerms: getService("registration/getTerms", { ignoreBan: true }),
  checkGraduate: getService("registration/checkGraduate"),
  checkRegCode: getService("registration/checkRegCode"),
  getCountries: getService("registration/getCountries"),
  email: {
    isUnique: getService("registration/email/isUnique"),
    isVerified: getService("registration/email/isVerified"),
    sendCode: getService("registration/email/sendCode"),
    verifyCode: getService("registration/email/verifyCode"),
  },
  phone: {
    isUnique: getService("registration/phone/isUnique"),
    isVerified: getService("registration/phone/isVerified"),
    sendCode: getService("registration/phone/sendCode"),
    verifyCode: getService("registration/phone/verifyCode"),
  },
  registration: getService("registration/registration"),
  adminRegistration: getService("registration/adminRegistration"),
};


const registrationApiRegex = /^registration\/(.*)$/;


export const isRegistrationService = service => Boolean(registrationApiRegex.test(clsx(service.path)));
