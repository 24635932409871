import React, { useEffect, useState } from "react";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import { Collapse, Icon, IconButton, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FuseNavVerticalGroup from "./FuseNavVerticalGroup";
import FuseNavVerticalItem from "./FuseNavVerticalItem";
import FuseNavBadge from "./FuseNavBadge";


const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    "&.open": {
      backgroundColor: "rgba(0,0,0,.08)"
    }
  },
  item: {
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingRight: 12,
    color: theme.palette.text.primary,
    "&.active > .list-item-text > span": {
      fontWeight: 600
    },
  }
}));

const needsToBeOpened = (location, item) => {
  return location && isUrlInChildren(item, location.pathname)
};

const isUrlInChildren = (parent, url) => {
  return parent.children && parent.children.some(child => child.url === url || url.includes(child.url) || (child.children && isUrlInChildren(child, url)));
};

const FuseNavVerticalCollapse = props => {

  const { item, nestedLevel } = props;

  const [open, setOpen] = useState(() => needsToBeOpened(props.location, props.item));

  const classes = useStyles(props);


  let paddingValue = 40 + (nestedLevel * 16);

  const listItemPadding = nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-24";

  useEffect(() => {
    if (needsToBeOpened(props.location, props.item)) {
      setOpen(true);
    }
  }, [props.location, props.item]);

  const handleClick = () => {
    setOpen(!open);
  };

  return !item.disallowed && (
    <ul className={clsx(classes.root, open && "open")}>

      <ListItem
        button
        className={clsx(classes.item, listItemPadding, "list-item")}
        onClick={handleClick}
      >
        {item.icon && (
          <Icon color="action" className="text-16 flex-shrink-0 mr-16">{item.icon}</Icon>
        )}
        <ListItemText className="list-item-text" primary={item.title} classes={{ primary: "text-14" }}/>
        {Boolean(item.badge) && (
          <FuseNavBadge className="mr-4" badge={item.badge}/>
        )}
        <IconButton disableRipple className="w-16 h-16 p-0">
          <Icon className="text-16 arrow-icon" color="inherit">
            {open ? "expand_less" : "expand_more"}
          </Icon>
        </IconButton>
      </ListItem>

      {item.children && (
        <Collapse in={open} className="collapse-children">
          {
            item.children.map(item => (

              <React.Fragment key={item.id}>

                {item.type === "group" && (
                  <FuseNavVerticalGroup item={item} nestedLevel={nestedLevel + 1}/>
                )}

                {item.type === "collapse" && (
                  <NavVerticalCollapse item={item} nestedLevel={nestedLevel + 1}/>
                )}

                {(!item.type || item.type === "item") && (
                  <FuseNavVerticalItem item={item} nestedLevel={nestedLevel + 1}/>
                )}

              </React.Fragment>
            ))
          }
        </Collapse>
      )}
    </ul>
  );
};

FuseNavVerticalCollapse.propTypes = {
  item: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.string,
      children: PropTypes.array,
    })
};
FuseNavVerticalCollapse.defaultProps = {};

const NavVerticalCollapse = React.memo(FuseNavVerticalCollapse);

export default NavVerticalCollapse;
