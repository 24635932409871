import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { FormControl, Input, Grid, Typography, IconButton, Icon, Tooltip } from "@material-ui/core";
import { GET_YEARS_MIN_MAX } from "client/store/alumnus";
import { useQuery } from "@apollo/react-hooks";
import { emitUiStateEvent, uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { LogComponentError } from "components/error";
import { normalizeYear } from "./helpers";


const YearFilters = ({ value, onChange, disabled, from }) => {

  const refYear = useRef();

  const [year, setYear] = useState(value);


  const { error, loading, data: { yearsMinMax: { years: { min: { year: minYear } = {}, max: { year: maxYear } = {} } = {} } = {} } = {} } = useQuery(GET_YEARS_MIN_MAX);


  const label = useMemo(() => from ? "Ettől:" : "Eddig:", [from]);


  const placeholder = useMemo(() => `Pl. ${from ? minYear : maxYear}`, [from, maxYear, minYear]);


  const onEnterKey = useCallback(event => event.keyCode === 13 && emitUiStateEvent(uiStateEvents.search.updateStarted), []);


  const handleChange = useCallback(event => {
    const year = normalizeYear(event.target.value, minYear, maxYear);
    onChange(year);
    setYear(year);
  }, [maxYear, minYear, onChange]);


  const onUpdateFinished = useCallback(() => {
    if (value !== year) setYear(value);
  }, [year, value]);


  const onClear = useCallback(() => {
    onChange("");
    setYear("");
  }, [onChange]);


  useEffect(() => {
    const target = refYear && refYear.current;
    if (target && !loading) {
      target.addEventListener("keydown", onEnterKey, false);
      return () => {
        target.removeEventListener("keydown", onEnterKey, false);
      };
    }
  }, [loading, onEnterKey]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.search.updateFinished, onUpdateFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.search.updateFinished, onUpdateFinished);
    }
  }, [onUpdateFinished]);


  if (error) return LogComponentError("YearsFilters", error);


  return !loading && (
    <FormControl>
      <Grid container spacing={1} alignItems="center" wrap="nowrap">
        <Grid item>
          <Typography className="text-13 sm:text-14"
                      color={disabled ? "textSecondary" : "textPrimary"}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item>
          <Input
            placeholder={placeholder}
            value={clsx(year)}
            onChange={handleChange}
            disabled={disabled}
            inputRef={refYear}
            inputProps={{
              "aria-label": label,
              min: minYear,
              max: maxYear,
              className: clsx("text-13 sm:text-14", year && "text-white", year ? "w-40" : "w-64"),
            }}
            type="tel"
            endAdornment={Boolean(year) && (
              <Tooltip title="Szűrő törlése">
                <IconButton onClick={onClear}
                            size="small"
                >
                  <Icon className="text-white text-16">clear</Icon>
                </IconButton>
              </Tooltip>
            )
            }
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default React.memo(YearFilters);
