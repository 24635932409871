import { getService } from "./helpers";


export const account = {
  approveTerms: getService("account/approveTerms"),
  changePassword: getService("account/changePassword"),
  changePasswordIssue: getService("account/changePasswordIssue"),
  changeStatus: getService("account/changeStatus"),
  checkAdmin: getService("account/checkAdmin"),
  checkUniqueUsername: getService("account/checkUniqueUsername"),
  createSpecialAccount: getService("account/createSpecialAccount"),
  deleteAccountBan: getService("account/deleteAccountBan"),
  deleteIpBans: getService("account/deleteIpBans"),
  deleteSpecialAccount: getService("account/deleteSpecialAccount"),
  hydrateSessions: getService("account/hydrateSessions"),
};
