import React, { useContext } from "react";
import { Typography, Link } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const Alert = ({ severity = "info", noHelpSuffix, className, children }) => {

  const { help: { openHelp } } = useContext(UiStateContext);

  return (
    <div className={className}>
      <MuiAlert severity={severity}>
        <Typography variant="caption">
          {children}
        </Typography>
        {!noHelpSuffix && (
          <Typography variant="caption">
            &nbsp;További információk a&nbsp;
            <Link role="button" className="text-blue cursor-pointer" onClick={openHelp}>
              <Typography variant="caption">súgóban</Typography>
            </Link>.
          </Typography>
        )}
      </MuiAlert>
    </div>
  );
};

export default React.memo(Alert);
