import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { Icon, InputLabel, MenuItem, Select, FormControl, Input } from "@material-ui/core";
import { defaultEmptySelectFilterLabel } from "../config";


const defaultInitValue = "";


const SelectFilter = ({
                        filterId, filterValue, setFilter, items, label, className, selectClassName, inputClassName,
                        noFilterLabel = defaultEmptySelectFilterLabel
                      }) => {


  const onChange = useCallback(event => {
    const value = event.target.value || defaultInitValue;
    setFilter(filterId, value);
  }, [filterId, setFilter]);


  const htmlId = useMemo(() => `selectFilter-${filterId}`, [filterId]);


  return items && items.length > 1 && (
    <FormControl className={className}>
      <InputLabel htmlFor={htmlId}>{label}</InputLabel>
      <Select
        onChange={onChange}
        value={filterValue || defaultInitValue}
        input={<Input label={label}/>}
        inputProps={{
          id: htmlId,
          className: inputClassName,
        }}
        className={clsx(selectClassName)}
      >
        <MenuItem key="_all" value="" className="text-14 py-12"><i>{noFilterLabel}</i></MenuItem>
        {items.map(({ key, value, iconColor, icon }, index) => (
          <MenuItem key={key || index} value={key} className="py-12">
            <div className="flex flex-row items-center">
              {Boolean(icon) && (
                <Icon className="text-16 mr-12" color={iconColor || "action"}>{icon}</Icon>
              )}
              <span className="text-14">{value}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(SelectFilter);
