import React, { useCallback, useContext, useState } from "react";
import clsx from "clsx";
import { Grid, Typography, Tooltip, Icon } from "@material-ui/core";
import texts from "config/texts";
import { accent } from "common/helpers";
import { MiniChip } from "components/common";
import { ListContext } from "components/listPages";
import RowControl from "./RowControl";
import Details from "./Details";


const Row = ({ id, fullName, group, formTeacher, email, phone, address, nws, nwsEmail, magazine, status, note, ...restProps }) => {

  const { allDetails } = useContext(ListContext)

  const [details, setDetails] = useState(false);

  const toggleDetails = useCallback(() => setDetails(details => !details), []);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <RowControl id={id}
                    details={details}
                    toggleDetails={toggleDetails}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Tooltip title={nws ? clsx(accent(nwsEmail, true), nwsEmail, "email címre kéri") : "Nem kér"}>
              <div>
                <MiniChip label="Hírlevél"
                          colorClassName={nws ? "bg-green" : "bg-red"}
                />
              </div>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={magazine ? "Kér" : "Nem kér"}>
              <div>
                <MiniChip label={texts.magazineName}
                          colorClassName={magazine ? "bg-green" : "bg-red"}
                />
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography className="sm:min-w-256">
          <span>{fullName}</span>
          &nbsp;
          {formTeacher
            ? (
              <Tooltip title={`Osztályfőnök: ${formTeacher}`}>
                <span className="items-center">
                  <span>({group}</span>
                  <Icon className="text-13 text-blue pl-1">info</Icon>
                  <span>)</span>
                </span>
              </Tooltip>
            )
            : (
              <span>({group})</span>
            )}
        </Typography>
      </Grid>
      <Grid item>
        <div className="sm:min-w-184">
          <Typography variant="caption">{email}</Typography>
        </div>
      </Grid>
      <Grid item>
        <div className="sm:min-w-96">
          <Typography variant="caption">{phone}</Typography>
        </div>
      </Grid>
      <Grid item>
        <div className="sm:min-w-256">
          <Typography variant="caption">{address}</Typography>
        </div>
      </Grid>
      {Boolean(details || allDetails) && (
        <>
          <Details {...restProps}
                   id={id}
                   status={status}
          />
          {Boolean(nwsEmail) && (
            <Grid item>
              <Typography variant="caption">Hírlevél email: {nwsEmail}</Typography>
            </Grid>
          )}
          {Boolean(note) && (
            <Grid item xs={12}>
              <Typography variant="caption">{note}</Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default React.memo(Row);
