import clsx from "clsx";
import memoizee from "memoizee";
import validator from "validator";
import { modes, basePath, defaultPage, defaultTab } from "config/profile";


export const normalizePage = memoizee(page => (page && validator.isNumeric(clsx(page)) && parseInt(page)) || defaultPage);


export const normalizeTab = memoizee(tab => (tab && validator.isNumeric(clsx(tab)) && parseInt(tab)) || defaultTab);


const getSafeEditMode = memoizee((editMode, page) => editMode
  ? modes.edit
  : page.toString() !== defaultPage.toString()
    ? modes.profile
    : ""
);


const getSafePage = memoizee((page, tab) => tab
  ? page.toString()
  : clsx(page)
);


const getSafeTab = memoizee((tab, panelKey) => panelKey
  ? tab.toString()
  : clsx(tab)
);


export const serializePath = memoizee((aId, editMode, page, tab, panelKey) => {
  const safeEditMode = getSafeEditMode(editMode, page);
  const safePage = safeEditMode && getSafePage(page, tab);
  const safeTab = safePage && getSafeTab(tab, panelKey);
  const safePanelKey = safeTab && clsx(panelKey);

  return [basePath, aId, safeEditMode, safePage, safeTab, safePanelKey].filter(v => v).join("/").trim();
});
