import { useMemo } from "react";
import useFilterByRoles from "./useFilterByRoles";


const useForbidden = (allowedRoles, disallowedSpecial) => {

  const config = useMemo(() => ({
    allowedRoles: allowedRoles || [],
    disallowedSpecial,
  }), [allowedRoles, disallowedSpecial]);


  const { disallowed } = useFilterByRoles({
    config,
  });


  return useMemo(() => Boolean(disallowed), [disallowed]);

};

export default useForbidden;
