import { getService } from "./helpers";


export const octopus = {
  createContact: getService("octopus/createContact"),
  createList: getService("octopus/createList"),
  deleteContact: getService("octopus/deleteContact"),
  getContacts: getService("octopus/getContacts"),
  getList: getService("octopus/getList"),
  getLists: getService("octopus/getLists"),
  updateContact: getService("octopus/updateContact"),
};
