import React, { useCallback, useContext } from "react";
import { Grid } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { withAccent } from "common/helpers";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { useConfirm } from "components/common";
import { Input, ChipSelect, useTags, PrivacyPolicy, FormContext, PanelContext, DeletePanel } from "components/form";
import { AlumnusContext } from "components/alumnus";
import { setDefaultValueProp } from "../helpers";
import PrimaryEmailAddress from "./PrimaryEmailAddress";
import SecondaryEmailAddress from "./SecondaryEmailAddress";


export const tagEntity = dbEnums.tagEntity.EMAIL;


export const __typename = "email";


const _updateFields = {
  privacy: "privacy",
  email: "email",
  note: "note",
  tags: "tags",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.privacy, _updateFields.email],
};


export const fieldAliases = {
  [__typename]: {
    [_updateFields.tags]: "tags",
  },
};


export const newRecordValues = {
  [_updateFields.email]: "",
  [_updateFields.note]: null,
  [_updateFields.tags]: [],
};


const noteHelperText = (
  <span>Pl. <span className="text-blue">ritkán olvasom</span></span>
);


const tagsHelperText = (
  <span>Pl. <span className="text-blue">munkahelyi</span>. A lenyíló listában a leggyakrabban megadott
                          kulcsszavak közül is választhatsz.</span>
);


const getConfirmOptions = email => ({
  title: "Törlés megerősítése",
  content: `Biztosan törlöd ${withAccent(email)} email címet?`,
  OKButton: "Igen, törlöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
});


const Email = () => {

  const { account: { id: myAccountId }, personal: { privacy } } = useContext(SessionContext);

  // AlumnusContext doesn't exist on EditContact issue step
  const { myProfile, alumnus: { account } = {} } = useContext(AlumnusContext) || {};

  const { getValue } = useContext(FormContext);

  const { record, prefixedId, isPrimary, isNew, deleteRecord, issue } = useContext(PanelContext);


  const { manager } = account || {};

  const { id: accountIdAsManager } = manager || {};

  const accountId = ((myProfile || issue) && myAccountId) || accountIdAsManager;


  const getEmail = useCallback(() => getValue(__typename, prefixedId, _updateFields.email)
    , [getValue, prefixedId]);


  const confirmOptions = useCallback(() => getConfirmOptions(getEmail()), [getEmail]);


  const confirm = useConfirm({
    confirmOptions,
    disabled: isNew,
  });


  const { loading, tags } = useTags(tagEntity);


  const onDelete = useCallback(() => confirm(deleteRecord, prefixedId)
    , [confirm, prefixedId, deleteRecord]);


  if (loading || !accountId) return null;

  return (
    <Grid container spacing={2} item>
      <Grid container spacing={0} justify="space-between" item xs={12} wrap="wrap-reverse">
        <Grid item xs={12} md={8}>
          <PrivacyPolicy record={record}
                         field={_updateFields.privacy}
                         defaultValue={setDefaultValueProp(isNew, privacy)}
                         tooltip={isPrimary ? "A kapcsolattartási email címre" : "Az alábbi email címre"}/>
        </Grid>
        <DeletePanel disabled={isPrimary}
                     onDelete={onDelete}
                     label="Email cím törlése"
        />
      </Grid>
      <Grid item xs={12}>
        {isPrimary
          ? (
            <PrimaryEmailAddress record={record}
                                 field={_updateFields.email}
                                 accountId={accountId}
                                 required
            />
          )
          : (
            <SecondaryEmailAddress record={record}
                                   field={_updateFields.email}
                                   defaultValue={setDefaultValueProp(isNew, newRecordValues[_updateFields.email])}
                                   required
            />
          )}
      </Grid>
      <Grid item xs={12}>
        <ChipSelect record={record}
                    field={_updateFields.tags}
                    label="Kulcsszavak, hashtag-ek"
                    options={tags}
                    defaultValue={setDefaultValueProp(isNew, newRecordValues[_updateFields.tags])}
                    helperText={tagsHelperText}
        />
      </Grid>
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.note}
               className="mb-8"
               label="Megjegyzés, feltételek"
               helperText={noteHelperText}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Email);
