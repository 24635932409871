import gql from "graphql-tag";


export const GET_YEARS_MIN_MAX = gql`
  query GetYearsMinMax {
    yearsMinMax: alumnus_aggregate(where: {year: {_gt: 0}}) {
      years: aggregate {
        min {
          year
        }
        max {
          year
        }
      }
    }
  }
`;
