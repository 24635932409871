import { normalizeStr } from "common/helpers";


export const fullName = ({ pre, last, first, suf, nick }) => {
  const nickname = nick ? `(${nick})` : null;
  const full = [pre, last, first, suf, nickname].join(" ");
  return normalizeStr(full) || null;
};


const alumnus_name = {
  fullName,
};

export default alumnus_name;
