import Bowser from "bowser";
import { allowedPlatforms } from "./platforms";


export const checkBrowser = () => {
  try {
    const browser = window && Bowser.getParser(window.navigator.userAgent);
    return browser && browser.satisfies(allowedPlatforms);

  } catch (e) {
    return false;
  }
};
