import React, { useMemo } from "react";
import moment from "moment";
import clsx from "clsx";
import { Chip, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dbEnums, labels } from "common/enums";
import { MiniChip } from "components/common";
import RowControl from "./RowControl";


const useStyles = makeStyles(() => ({
  revoked: {
    border: "1px solid #E3342F",
  },
  duplicated: {
    border: "1px solid #F6993F;",
  },
}));


const Row = card => {

  const { nr, name, group, status, dup, issueDate, exp } = card;


  const revoked = useMemo(() => status === dbEnums.cardStatus.REVOKED, [status]);


  const statusColorClassName = useMemo(() => status === dbEnums.cardStatus.REVOKED
    ? "bg-red"
    : status === dbEnums.cardStatus.ACTIVE
      ? "bg-green"
      : "bg-orange", [status]);


  const classes = useStyles();


  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <RowControl {...card}/>
      </Grid>
      {Boolean(nr) && (
        <Grid item>
          <div className="min-w-56">
            <Chip label={nr}
                  color="secondary"
                  className={clsx(dup && classes.duplicated, dup && "text-orange", revoked && classes.revoked, revoked && "text-red")}
                  size="small"
                  variant="outlined"
            />
          </div>
        </Grid>
      )}
      <Grid item>
        <Typography className="sm:min-w-256">{name} ({group})</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1} className="sm:min-w-96">
          {Boolean(dup) && (
            <Grid item>
              <MiniChip label="Duplikált"
                        colorClassName="bg-orange"
              />
            </Grid>
          )}
          <Grid item>
            <MiniChip label={labels.cardStatus[status]}
                      colorClassName={statusColorClassName}
            />
          </Grid>
        </Grid>
      </Grid>
      {Boolean(issueDate) && (
        <Grid item>
          <div className="sm:min-w-128">
            <Typography variant="caption">
              Kiállítva: {moment(issueDate).format("L")}
            </Typography>
          </div>
        </Grid>
      )}
      {Boolean(exp) && (
        <Grid item>
          <Typography variant="caption">
            Lejárt/visszavonva: {moment(exp).format("L")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Row);
