import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { accent } from "common/helpers";
import { helps } from "components/help";
import { modes } from "./Verification";
import VerificationHelpEmailLink from "./VerificationHelpEmailLink";


const VerificationHelp = ({ mode, className }) => {

  const { help: { openHelp } } = useContext(UiStateContext);

  const { envVars: { supportEmail } } = useContext(SessionContext);


  const onHelpClick = useCallback(() => openHelp(helps.verify[mode]), [openHelp, mode]);


  return (
    <div className={clsx("mt-8", className)}>
      <Typography className="mt-16" variant="caption">
        <b>Nem kaptad meg a kódot?</b>
      </Typography>
      <ol className="text-12">
        {mode !== modes.admin && (
          <li>Kérjük, először ellenőrizd, hogy
            megfelelő {mode === modes.email ? "email címet" : "telefonszámot"} adtál-e meg!
          </li>
        )}
        {mode === modes.email && (
          <li>Ellenőrizd, hogy a levél nem került-e az email fiókod spam/levélszemét vagy promóciók mappájába!</li>
        )}
        <li>Kérjük, várj türelemmel! Ha néhány percen belül nem érkezik meg az {mode === modes.email ? "email" : "SMS"},
          kérj új kódot!
        </li>
        <li>Ha a 2. próbálkozás után sem kaptad meg az {mode === modes.email ? "emailt" : "SMS-t"},
          próbálkozz újra néhány órával később! Kérjük, ne kezdeményezz sorozatos kiküldést!
          Az {mode === modes.email ? "email" : "SMS"} számos szerveren keresztül érkezik
          a {mode === modes.email ? "postfiókodba" : "telefonodra"}; ezek bármelyikében előfordulhat átmeneti
          késleltetés. Ilyenkor az újbóli kiküldés nem célravezető.
        </li>
        <li className="mb-8">
          <span>Ha a kézbesítés mindezek után sem történik meg, írj egy üzenetet {accent(supportEmail)}&nbsp;</span>
          <VerificationHelpEmailLink/>
          <span>&nbsp;címre!</span>
        </li>
      </ol>
      {mode !== modes.admin && (
        <Typography className="mt-8" variant="caption">
          <span>További információk&nbsp;</span>
          <a rel="noopener noreferrer" className="my-1 cursor-pointer" onClick={onHelpClick}>a súgóban</a>
          <span>.</span>
        </Typography>
      )}
    </div>
  );
};

export default React.memo(VerificationHelp);
