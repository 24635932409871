import { authEnums } from "common/enums";
import { ContactStep } from "./contact";
import { NewsStep } from "./news";
import { OrgStep } from "./org";
import { PswcStep } from "./pswc";
import { TermsStep } from "./terms";


export const stepIds = {
  terms: "terms",
  pswc: "pswc",
  org: "org",
  contact: "contact",
  news: "news",
};


export const stepConfig = {
  [stepIds.terms]: {
    id: stepIds.terms,
    label: "Adatkezelés",
    irreversible: true,
    requiredStatements: 3,
    ContentComponent: TermsStep,
  },
  [stepIds.pswc]: {
    id: stepIds.pswc,
    label: "Jelszóváltoztatás",
    irreversible: true,
    requiredStatements: 1,
    ContentComponent: PswcStep,
  },
  [stepIds.org]: {
    id: stepIds.org,
    label: "Személyes adatok",
    requiredStatements: 1,
    ContentComponent: OrgStep,
  },
  [stepIds.contact]: {
    id: stepIds.contact,
    label: "Elérhetőségek",
    requiredStatements: 1,
    ContentComponent: ContactStep,
  },
  [stepIds.news]: {
    id: stepIds.news,
    label: "Beállítások",
    requiredStatements: 1,
    ContentComponent: NewsStep,
  },
};


export const issueStepConfigs = {
  [authEnums.issueType.requiredTerms]: [stepConfig[stepIds.terms]],
  [authEnums.issueType.weakPassword]: [stepConfig[stepIds.pswc]],
  [authEnums.issueType.missingOrg]: [stepConfig[stepIds.org]],
  [authEnums.issueType.requiredRevision]: [stepConfig[stepIds.contact], stepConfig[stepIds.news]],
};


export const confirmOptions = {
  title: "Biztosan kijelentkezel?",
  content: "A következő bejelentkezéskor ismét megkérünk majd a szükséges nyilatkozatok megtételére. " +
    "A felületre akkor léphetsz tovább, ha valamennyi szükséges nyilatkozatot megtetted.",
  OKButton: "Kijelentkezem",
  classNameOK: "text-red",
  cancelButton: "Folytatom a nyilatkozattételt",
  colorCancel: "secondary",
};
