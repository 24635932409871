import memoizee from "memoizee";
import { getSearchFilters } from "client/lib/providers/uiState";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { getSearchHash } from "./searchHash";
import fetchIds from "./fetchIds";
import fetchResults from "./fetchResults";


const _calcNumPages = memoizee((pageSize, numRows) => {
  if (!pageSize || pageSize < 0) return numRows || 1;

  const quotient = Math.floor(numRows / pageSize);
  const extra = (numRows % pageSize) ? 1 : 0;
  return quotient + extra;
});


export const calcNumPages = (pageSize, rows) => {
  const numRows = (Array.isArray(rows) && rows.length) || 0;
  return _calcNumPages(pageSize, numRows);
};


const getNumPages = memoizee((_searchHash, rawIds) => calcNumPages(getSearchFilters().pageSize, rawIds)
  , { length: 1 });


const searchResults = async (_root, _args, { client }) => {
  const searchHash = getSearchHash();

  const rawIds = await fetchIds(searchHash, client);

  const numPages = getNumPages(searchHash, rawIds);

  emitUiStateEvent(uiStateEvents.search.numPagesChanged, numPages);

  if (numPages > 0) return await fetchResults(searchHash, rawIds, client);
};

export default searchResults;
