import gql from "graphql-tag";


export const UPDATE_ALUMNUS_ADDRESS = gql`
  mutation UpdateAlumnusAddress($id: Int!, $changes: alumnus_address_set_input) {
    results: update_alumnus_address(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;


export const UPDATE_ALUMNUS_SECONDARY_ADDRESS = gql`
  mutation UpdateAlumnusSecondaryAddress($id: Int!, $changes: alumnus_secondary_address_set_input) {
    results: update_alumnus_secondary_address(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;
