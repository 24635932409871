import gql from "graphql-tag";


export const SAVE_AVATAR = gql`
  mutation SaveAvatar($id: Int!, $item: jsonb!, $file: jsonb!, $crop: jsonb!, $deleted: Bool!) {
    saveAvatar(id: $id, item: $item, file: $file, crop: $crop, deleted: $deleted) @client
  }
`;


export const UPDATE_ALUMNUS_PICTURE = gql`
  mutation UpdateAlumnusPicture($id: Int!, $changes: alumnus_picture_set_input) {
    results: update_alumnus_picture(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;
