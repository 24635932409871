import gql from "graphql-tag";


export const SAVE_EMAILS = gql`
  mutation SaveEmails($emails: jsonb!, $primaryEmailId: Int!, $deletedIds: [Int!], $alumnusId: Int!, $issue: Boolean) {
    saveEmails(emails: $emails, primaryEmailId: $primaryEmailId, deletedIds: $deletedIds, alumnusId: $alumnusId, issue: $issue) @client
  }
`;


export const UPSERT_SECONDARY_EMAILS = gql`
  mutation UpsertSecondaryEmails($records: [email_insert_input!]!) {
    results: insert_email(objects: $records, on_conflict: {constraint: email__pkey, update_columns: [privacy, email, note, tags]}) {
      affected_rows
    }
  }
`;


export const DELETE_SECONDARY_EMAILS = gql`
  mutation DeleteSecondaryEmails($ids: [Int!]!) {
    results: delete_email(where: {id: {_in: $ids}, is_primary: {_eq: false}}) {
      affected_rows
    }
  }
`;
