import React from "react";
import clsx from "clsx";
import useForm from "./useForm";
import useAddRecords from "./useAddRecords";
import useDeleteRecords from "./useDeleteRecords";
import useSave from "./useSave";
import useHandleSubmit from "./useHandleSubmit";
import Panels from "./Panels";


export const FormContext = React.createContext({});


const Form = ({
                records, updateFields, requiredFields, fieldAliases, disabled, issue, registration, saveCb,
                savePostProcess, disableSubmit, enableSubmit, newRecordTypename, newRecordValues, maxRecordsCount,
                newRecordEntityStr, onErrorEntityStr, primaryId, mode, PanelContents, PanelContentsProps, panelKey,
                captionCb, titleCb, getTitleComponentCb, captionWidth, gridProps, Header, HeaderAlert, className, children
              }) => {


  const { clearError, dirty, isSubmitted, formErrors, handleSubmit, getControl, getItemValues, getValue, getValues, register, unregister, setValue, triggerValidation, validateUniqueness } = useForm({
    records,
    mode,
  });


  const { deletedStoredIds, deletedPrefixedIds, deleteRecord } = useDeleteRecords({
    primaryId,
    enableSubmit,
  });


  const { newRecords, isFull, addRecord } = useAddRecords({
    records,
    newRecordTypename,
    newRecordValues,
    primaryId,
    deletedPrefixedIds,
    maxRecordsCount,
    enableSubmit,
  });


  const save = useSave({
    records,
    deletedIds: deletedStoredIds,
    getValues,
    updateFields,
    requiredFields,
    fieldAliases,
    saveCb,
  });


  const safeHandleSubmit = useHandleSubmit({
    triggerValidation,
    handleSubmit,
    dirty,
    save,
    savePostProcess,
    disableSubmit,
    enableSubmit,
  });


  const contextValue = {
    clearError,
    dirty,
    disabled,
    deletedPrefixedIds,
    deleteRecord,
    fieldAliases,
    formErrors,
    getControl,
    getItemValues,
    getValue,
    getValues,
    isSubmitted,
    newRecords,
    newRecordEntityStr,
    records,
    register,
    unregister,
    requiredFields,
    setValue,
    triggerValidation,
    updateFields,
    validateUniqueness,
  };


  const PanelsProps = {
    addRecord,
    captionCb,
    captionWidth,
    getTitleComponentCb,
    isFull,
    newRecordTypename,
    onErrorEntityStr,
    PanelContents,
    PanelContentsProps,
    panelKey,
    primaryId,
    titleCb,
    gridProps,
    issue,
    registration,
    Header,
    HeaderAlert,
  };


  return (
    <div className={clsx("flex flex-col my-8", !PanelContents && className)}>
      <form onSubmit={safeHandleSubmit}>
        <FormContext.Provider value={contextValue}>
          {PanelContents
            ? (
              <Panels className={className} {...PanelsProps}/>
            )
            : children
          }
        </FormContext.Provider>
      </form>
    </div>
  );
};

export default React.memo(Form);
