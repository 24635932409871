import { errorLogStore } from "client/lib/errorLog";
import { fragmentAlumnus, GET_ALUMNUS_GROUP } from "client/store/alumnus/queries";


export const fetchAlumnus = async (id, client, forcedRefetch) => {
  if (!id || !client) return null;

  try {
    const { data } = await client.query({
      query: GET_ALUMNUS_GROUP,
      fetchPolicy: forcedRefetch ? "network-only" : "cache-first",
      variables: {
        ids: [id],
      },
    });
    const { members = [] } = data || {};
    return members[0];

  } catch (e) {
    errorLogStore("alumnus", "fetchAlumnus", e);
  }
};


export const getCachedAlumnus = (id, cache, getCacheKey) => {
  if (id && cache && getCacheKey) {
    const cacheId = getCacheKey({ __typename: "alumnus", id });

    try {
      return cache.readFragment({ fragmentName: "alumnus", fragment: fragmentAlumnus, id: cacheId });

    } catch (e) {
      // error will occured when other alumnus fragments with reduced field set are loaded in the cache (e.g. alumnus_nls)
      return null;
    }
  }
};
