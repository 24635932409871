import React, { useMemo } from "react";
import clsx from "clsx";
import { Avatar, Chip as MuiChip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
  chip: {
    "& svg": {
      fill: "#fff",
    }
  },
  chipdanger: {
    borderColor: "rgba(227, 52, 47, 0.5)",
  },
  chipwarning: {
    borderColor: "rgba(246, 153, 63, 0.5)",
  },
  chipsuccess: {
    borderColor: "rgba(85, 195, 158, 0.5)",
  },
  avatar: {
    width: "30px !important",
    height: "30px !important",
    marginLeft: "0 !important",
  },
  bigAvatar: {
    width: "38px !important",
    height: "38px !important",
    marginLeft: "0 !important",
  },
  avatardanger: {
    backgroundColor: "#e3342f",
  },
  avatarwarning: {
    backgroundColor: "#f6993f",
  },
  avatarsuccess: {
    backgroundColor: "#55c39e",
  },
}));


const Chip = ({ label, color, AvatarComponent, bigAvatar, avatarClassName, className, size = "small", variant = "outlined" }) => {

  const classes = useStyles();

  const normalizedColor = useMemo(() => clsx(color).toLowerCase().trim(), [color]);

  return (
    <MuiChip
      avatar={
        <Avatar
          className={clsx(
            classes.avatar,
            bigAvatar && classes.bigAvatar,
            normalizedColor && classes[`avatar${normalizedColor}`],
            avatarClassName
          )}>
          {Boolean(AvatarComponent) && (
            <AvatarComponent/>
          )}
        </Avatar>
      }
      label={label}
      className={clsx(
        classes.chip,
        normalizedColor && classes[`chip${normalizedColor}`],
        !clsx(className).includes("my") && "my-2",
        className,
      )}
      variant={variant}
      size={size}
    />
  );
};

export default React.memo(Chip);
