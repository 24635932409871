import React from "react";
import clsx from "clsx";
import { Grid, List, ListItem, Typography } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";
import { Pager } from "components/listPages";
import { noResultsLabel } from "../config";


const Rows = ({
                RowComponent, rows, idField = "id", isChecked, toggleRow, enableRipple, listClassName, listItemClassName,
                noResultsClassName, ...restContext
              }) =>
  rows && rows.length
    ? (
      <List className={clsx("p-0", listClassName)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {rows.map(row => {

              const id = row[idField];
              const _isChecked = () => typeof isChecked === "function" && isChecked(id);
              const onCheck = () => toggleRow && toggleRow(id);

              return (
                <ListItem key={id}
                          onClick={onCheck}
                          disableRipple={!enableRipple}
                          disableTouchRipple={!enableRipple}
                          button
                          dense
                          className={clsx("border-solid select-text cursor-auto border-b-1 px-0 sm:px-8",
                            !clsx(listItemClassName).includes("py-") && "py-16", listItemClassName)}
                >
                  <RowComponent isChecked={_isChecked} onCheck={onCheck} {...row}/>
                </ListItem>
              )
            })}
          </Grid>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Pager {...restContext}/>
          </Grid>
        </Grid>
      </List>
    )
    : (
      <FuseAnimate delay={100}>
        <Grid container alignItems="center" justify="center" className="h-full min-h-128 px-16 sm:px-20">
          <Grid item>
            <Typography color="textSecondary"
                        variant="h5"
                        className={clsx("text-center sm:text-20", !clsx(noResultsClassName).includes("text-") && "text-16 md:text-24", noResultsClassName)}
            >
              {noResultsLabel}
            </Typography>
          </Grid>
        </Grid>
      </FuseAnimate>
    );

export default React.memo(Rows);
