import { applyAuthDataToSession, handleUnauthorized, validateMe } from "client/lib/session";
import { errors } from "common/errors";
import { errorHandler } from "./errorHandler";
import { isAuthService, isRegistrationService } from "./services";


const isObject = (service, rawData) => service.acceptJson && rawData && typeof rawData === "object";


const getErrorByStatus = status => {
  if (status === 200) return {};
  if (status === 400) return errors.server.general.BAD_REQUEST;
  if (status === 401) return errors.server.general.UNAUTHORIZED;
  if (status === 403) return errors.server.general.FORBIDDEN;
  if (status === 404) return errors.server.general.NOT_FOUND;
  return errors.server.general.SERVER_ERROR;
};


const parseError = ({ error }, status) => !error
  ? getErrorByStatus(status)
  : typeof error === "object"
    ? { error }
    : errors.server.general.SERVER_ERROR;


const parseRawData = async (service, rawData, status) => {
  if (!isObject(service, rawData)) return { data: rawData };

  const { __auth, ...data } = rawData;

  const { error } = parseError(rawData, status);

  await applyAuthDataToSession(service, __auth, { ...error });

  if (error) errorHandler(service, status, error);

  return { error, data };
};


const validateUnauthorized = async (service, status) => {
  // no invalidate session on auth api requests to avoid infinite loop
  if (isAuthService(service)) return null;

  if (status === 401) {

    if (isRegistrationService(service)) {
      await validateMe();
    } else {
      await handleUnauthorized();
    }

    return errors.server.general.UNAUTHORIZED;
  }
};


export const getValidatedData = async (service, rawData, status) => {
  const unauthorized = await validateUnauthorized(service, status);
  if (unauthorized) return { ...unauthorized, data: {} };

  return await parseRawData(service, rawData, status);
};
