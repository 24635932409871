import { dbEnums } from "common/enums";
import { UPSERT_REG_CODES } from "./mutations";
import { GET_REG_CODES } from "./queries";


const parseVariables = ({ ...record }) => {
  if (record.membership === dbEnums.membership.TEACHER || record.membership === dbEnums.membership.HONORARY) {
    record.form_teacher = null;
    record.grp = record.membership;
  }

  return { records: [record] };
};


const saveRegCode = async (_root, { record }, { client }) => {

  const variables = parseVariables(record);

  const { error } = await client.mutate({
    mutation: UPSERT_REG_CODES,
    variables,
  });
  if (error) throw new Error(error);

  await client.query({
    query: GET_REG_CODES,
    fetchPolicy: "network-only",
  });
};

export default saveRegCode;
