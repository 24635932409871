import nextTick from "next-tick";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { backendServices } from "client/lib/backendApi";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { useField, validatePhoneFormat as _validatePhoneFormat, FormContext } from "components/form";
import { setCodeRequirements } from "components/form/email";
import { requirementMessages } from "../config";
import { __typename, updateFields } from "./Phone";


const verificationFieldName = "phoneVerificationCode";


const usePrimaryPhoneNumber = ({ record, field, accountId, requirements, fetch, registration }) => {

  const verifiedPhones = useRef([]);

  const isUnique = useRef(true);


  const { authenticated } = useContext(SessionContext);

  const { triggerValidation, validateUniqueness: _validateUniqueness } = useContext(FormContext);


  const [codeReqs, setCodeReqs] = useState({});

  const [codeSent, setCodeSent] = useState(false);

  const [verifiedToolTip, setVerifiedToolTip] = useState(!registration);


  const verificationService = useMemo(() => !registration
    ? backendServices.alumnus.isPhoneVerified
    : authenticated
      ? backendServices.account.checkAdmin
      : backendServices.registration.phone.isVerified,
    [authenticated, registration]);


  const uniqueService = useMemo(() => registration ? backendServices.registration.phone.isUnique : backendServices.alumnus.isPrimaryPhoneUnique
    , [registration]);


  const isVerified = useCallback(phone => phone === record[field] || (verifiedPhones.current || []).includes(phone)
    , [field, record]);


  const setVerified = useCallback(phone => {
    if (!isVerified(phone)) verifiedPhones.current = [...verifiedPhones.current, phone];
    setVerifiedToolTip(true);
  }, [isVerified]);


  const validatePhoneFormat = useCallback(value => _validatePhoneFormat(value, true), []);


  const validateUniquePerAlumnus = useCallback(value => validatePhoneFormat(value) !== true
    || Boolean(isVerified(value))
    || !_validateUniqueness(__typename, updateFields[__typename].phone, value, record.id)
    || requirementMessages.phone.notUniquePerAlumnus
    , [validatePhoneFormat, isVerified, _validateUniqueness, record.id]);


  const validateUniqueness = useCallback(value => Boolean(isVerified(value))
    || Boolean(isUnique.current)
    || requirementMessages.verification.notUniquePrimaryPhone
    , [isVerified]);


  const validateVerification = useCallback(phone => Boolean(isVerified(phone))
    || requirementMessages.verification.unverifiedPhone
    , [isVerified]);


  const checkValid = useCallback(value =>
    validatePhoneFormat(value) === true && !_validateUniqueness(__typename, updateFields[__typename].phone, value, record.id)
    , [_validateUniqueness, record.id, validatePhoneFormat]);


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    validate: {
      validatePhoneFormat,
      validateUniquePerAlumnus,
      validateUniqueness,
      validateVerification,
      ...(requirements && requirements.validate),
    },
  }), [requirements, validatePhoneFormat, validateUniquePerAlumnus, validateUniqueness, validateVerification]);


  const { clearError, errorType } = useField({
    record,
    field,
  });


  const onVerified = useCallback(phone => {
    setVerified(phone);
    clearError();
    nextTick(triggerValidation);
  }, [clearError, setVerified, triggerValidation]);


  const _setCodeRequirements = useCallback((alphabet, length) => setCodeRequirements(setCodeReqs)(alphabet, length), []);


  const handleVerifiedResponse = useCallback((phone, error, { admin, verified = admin, alphabet, length }) => {
    if (!error && verified) setVerified(phone);

    _setCodeRequirements(alphabet, length);

    clearError();
    triggerValidation();
  }, [clearError, _setCodeRequirements, setVerified, triggerValidation]);


  const fetchVerified = useCallback(async phone => {
    const { banned, error, data } = await fetch(verificationService, { phone });

    if (!banned) handleVerifiedResponse(phone, error, data);
  }, [fetch, handleVerifiedResponse, verificationService]);


  const handleUniqueResponse = useCallback((error, { unique }) => {
    isUnique.current = Boolean(!error && unique);
    clearError();
    triggerValidation();
  }, [clearError, triggerValidation]);


  const fetchUnique = useCallback(async phone => {
    const { banned, error, data } = await fetch(uniqueService, {
      phone,
      ...(!registration ? { accountId } : {}),
    });

    if (!banned) handleUniqueResponse(error, data);
  }, [fetch, uniqueService, registration, accountId, handleUniqueResponse]);


  const onChangeCb = useCallback(async value => {
    if (isVerified(value)) {
      clearError();
      setVerifiedToolTip(true);

    } else if (value && checkValid(value)) {
      await fetchUnique(value);
      if (isUnique.current) await fetchVerified(value);

    } else {
      clearError();
      setVerifiedToolTip(false);
    }

    setCodeSent(false);
  }, [checkValid, clearError, fetchUnique, fetchVerified, isVerified]);


  const verificationArgs = useMemo(() => ({
    field: verificationFieldName,
    codeReqs,
    onVerified,
    codeSent,
    setCodeSent,
  }), [codeReqs, codeSent, onVerified]);


  return {
    onChangeCb,
    errorType,
    fieldRequirements,
    verifiedToolTip,
    verificationArgs,
  };
};

export default usePrimaryPhoneNumber;
