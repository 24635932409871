import React, { useCallback, useContext, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { GET_COUNTRIES } from "client/store/alumnus";
import { residency_typename } from "client/store/alumnus/resolvers/getters/alumnus";
import { useConfirm } from "components/common";
import { AlumnusContext } from "components/alumnus";
import { CheckBox, Input, PanelContext, PrivacyPolicy, CountrySelect } from "components/form";
import { jump } from "components/form/newsletter";
import Zip from "./Zip";


export const typenames = {
  address: "alumnus_address",
  sAddress: "alumnus_secondary_address",
  residency: residency_typename,
};


const _updateFields = {
  privacy: "privacy",
  country: "country",
  zip: "zip",
  city: "city",
  address: "address",
  addressName: "name",
};


export const updateFields = {
  [typenames.address]: _updateFields,
  [typenames.sAddress]: {
    ..._updateFields,
    isResidence: "isResidence",
  },
  [typenames.residency]: {
    country: "country",
    cityAndCountry: "cityAndCountry",
    fromSecondary: "fromSecondary",
  }
};


export const requiredFields = {
  [typenames.address]: [_updateFields.privacy, _updateFields.country, _updateFields.zip, _updateFields.city, _updateFields.address],
  [typenames.sAddress]: [_updateFields.privacy, updateFields[typenames.sAddress].isResidence],
};


const _fieldAliases = {
  [_updateFields.country]: "country_code",
  [_updateFields.addressName]: "address_name",
};


export const fieldAliases = {
  [typenames.address]: _fieldAliases,
  [typenames.sAddress]: {
    ..._fieldAliases,
    [updateFields[typenames.sAddress].isResidence]: "is_residence",
  },
};


const Address = () => {

  const { record, prefixedId, issue } = useContext(PanelContext);

  // AlumnusContext doesn't exist on EditContact issue step
  const { setRoute, untouched } = useContext(AlumnusContext) || {};


  const { loading, error, data: { countries } = {} } = useQuery(GET_COUNTRIES);


  const secondary = useMemo(() => record.__typename === typenames.sAddress, [record.__typename]);


  const confirm = useConfirm({
    confirmOptions: jump.confirmOptions,
    disabled: untouched,
  });


  const navigateToNewsletter = useCallback(() => setRoute && setRoute({
    disableConfirm: true,
    editMode: true,
    page: jump.page,
    tab: jump.tab,
  }), [setRoute]);


  const onNewsletter = useCallback(() => confirm(navigateToNewsletter), [confirm, navigateToNewsletter]);


  return Boolean(!loading && !error && countries) && (
    <Grid item container spacing={2}>
      {!issue && (
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item xs="auto">
            <Typography color="textSecondary" className="mb-12 text-13">
              {secondary
                ? "Itt egy másik címedet vagy a – levelezési címtől eltérő – tartózkodási helyedet adhatod meg."
                : "Az Alumni erre a címen fogja számodra kiküldeni leveleit, kiadványait."}
            </Typography>
          </Grid>
          {!secondary && (
            <Grid item xs="auto" className="mb-12">
              <Tooltip title="Ugrás a kiadvány feliratkozásokhoz">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={onNewsletter}
                >
                  Ugrás a kiadványokhoz
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}

      <Grid item xs={12}>
        <PrivacyPolicy record={record}
                       field={_updateFields.privacy}
                       tooltip={`A ${secondary ? "másodlagos" : "levelezési"} címre`}
        />
      </Grid>
      <Grid item xs={12}>
        <CountrySelect record={record}
                       field={_updateFields.country}
                       countries={countries}
                       label="Ország"
                       required={!secondary}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} sm={4}>
            <Zip record={record}
                 field={_updateFields.zip}
                 countryField={_updateFields.country}
                 prefixedId={prefixedId}
                 required={!secondary}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Input record={record}
                   field={_updateFields.city}
                   label="Település"
                   required={!secondary}
                   maxLength={50}
                   noOnlyUpperCase
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.address}
               label="Cím"
               required={!secondary}
               noOnlyUpperCase
               helperText="Utca, házszám, lépcsőház, emelet, ajtó, postafiók stb."
               maxLength={50}
        />
      </Grid>
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.addressName}
               maxLength={50}
               label="Címzett neve"
               className={secondary ? "mb-20" : "mb-8"}
               helperText="Csak akkor töltsd ki, ha más névre kéred címezni a leveleket!"/>
      </Grid>
      {Boolean(secondary) && (
        <Grid item xs={12}>
          <CheckBox record={record}
                    field={updateFields[typenames.sAddress].isResidence}
                    className="mb-8"
                    title="Itt élek"
                    helperText={(
                      <span>
                        Az adatlapodon az itt szereplő város és ország jelenik majd meg a&nbsp;
                        <span className="text-blue">&quot;Város, ország, ahol él&quot;</span> blokkban (a levelezési
                        cím városa és országa helyett).
                      </span>
                    )}/>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Address);
