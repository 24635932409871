import React from "react";


export const page = 3;

export const tab = 0;


export const confirmOptions = {
  title: "Ugrás a hírlevél beállításaihoz, mentés nélkül",
  content: (
    <span>A hírlevélhez való ugrással <b>az itt végzett módosítások elvesznek</b>.
      Előbb célszerű elmenteni a módosításokat, majd ezután navigálni a hírlevélhez. Mégis folytatod?
    </span>
  ),
  OKButton: "Igen, ugrás a hírlevélhez",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
};
