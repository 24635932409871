import React from "react";
import clsx from "clsx";
import Linkify from "react-linkify";
import { AppBar, Card, CardContent, Chip, Grid, Icon, Toolbar, Typography } from "@material-ui/core";
import texts from "config/texts";
import { withCapital } from "common/helpers";
import { ExternalLink } from "components/common";


const linkDecorator = (href, text, key) => (
  <span key={key}>
    <ExternalLink href={href}>{text}</ExternalLink>
  </span>
);


const LeasureCard = ({ alumnus, hobby: isHobby }) => {

  const entity = isHobby ? alumnus.hobby : alumnus.cooperation;

  const { discount, hobby, cooperation, tagsW } = entity || {};
  const { tags } = tagsW || {};

  const description = isHobby ? hobby : cooperation;

  const title = isHobby ? "Érdeklődési kör, hobbik" : "Együttműködési lehetőségek";
  const icon = isHobby ? "wb_sunny" : "chat";


  return Boolean(description || discount || tags) && (
    <Card className="w-full mb-16">
      <AppBar position="static" elevation={0}>
        <Toolbar className="pl-16 pr-8">
          <Typography variant="subtitle1" color="inherit" className="flex-1">{title}</Typography>
        </Toolbar>
      </AppBar>

      <CardContent>

        {Boolean(description) && (
          <div className={clsx("flex items-start mt-8 mb-4", tags && "mb-16")}>
            <Icon className="text-16 mr-8 mt-1" color="action">{icon}</Icon>
            <Typography className="text-15 whitespace-pre-line">
              <Linkify componentDecorator={linkDecorator}>{description}</Linkify>
            </Typography>
          </div>
        )}

        {Boolean(tags && tags.length) && (
          <Grid container spacing={1} className="mt-4">
            {Boolean(discount) && (
              <Grid item xs="auto">
                <Chip label={withCapital(texts.discountProviderName)} color="secondary" className="bg-red-light"/>
              </Grid>
            )}
            {tags.map(({ value, label }) => (
              <Grid key={value} item xs="auto">
                <Chip label={label} color="secondary"/>
              </Grid>
            ))}
          </Grid>
        )}

      </CardContent>
    </Card>
  );
};

export default React.memo(LeasureCard);
