import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { Suspense } from "components/common";
import { Form } from "components/form/formControl";
import { fieldAliases, Newsletter, requiredFields, updateFields } from "components/form/newsletter";
import Statement from "../Statement";
import useNewsStep from "./useNewsStep";


const NewsStep = ({ submitLoading, disableSubmit, enableSubmit, paper, statementClassName, statementColor, ...props }) => {

  const { queryLoading, error, address, news, saveCb, statement, toggleApprove } = useNewsStep(props);


  const content = (
    <Form className="max-w-md md:max-w-lg lg:max-w-2xl"
          records={[news]}
          updateFields={updateFields}
          requiredFields={requiredFields}
          fieldAliases={fieldAliases}
          disabled={submitLoading}
          disableSubmit={disableSubmit}
          enableSubmit={enableSubmit}
          saveCb={saveCb}
    >
      <Newsletter record={news}
                  address={address}
                  issue
      />
    </Form>
  );


  return (
    <Suspense loading={submitLoading || queryLoading}
              className="py-32 md:py-48 lg:py-64"
    >
      {!error && (
        <div className="w-full">

          <Typography className="my-16 mb-24">
            Az Alumni kiadványaival, hírlevelével kapcsolatban legutóbb az alábbi nyilatkozatokat tetted. <i>Kérjük,
            ellenőrizd ezek aktualitását! Ha szükséges, módosítsd őket!</i>
          </Typography>

          {paper
            ? (
              <Paper className="max-w-xl mb-8">
                <div className="px-24 py-8">
                  {content}
                </div>
              </Paper>
            )
            : content
          }

          <Statement checked={statement}
                     onCheckCb={toggleApprove}
                     className={statementClassName}
                     color={statementColor}
                     title="Nyilatkozat a hírlevél beállításokról"
                     label="Megerősítem, hogy a fent megadott hírlevél beállítások valósak és aktuálisak."
          />

        </div>
      )}
    </Suspense>
  );
};

export default React.memo(NewsStep);
