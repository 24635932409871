import React from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { Suspense } from "components/common";
import Statement from "../Statement";
import useOrgStep from "./useOrgStep";
import EditOrg from "./EditOrg";


const OrgStep = ({ submitLoading, disableSubmit, enableSubmit, statementClassName, statementColor, ...props }) => {

  const { loading, error, org, saveCb, statement, toggleApprove } = useOrgStep(props);

  return (
    <Suspense loading={submitLoading || loading}
              className="py-32 md:py-48 lg:py-64"
    >
      {!error && (
        <div className="w-full">

          <Typography className="my-16">
            Nyilvántartásunk szerint a személyes adataid közül egy vagy több adat hiányzik. A felületre való továbblépés
            feltétele, hogy ezeket az adatokat az Alumni számára &ndash; a valóságnak megfelelően &ndash; megadd. <i>Az
            űrlap alatti jelölőnégyzet bekapcsolásával erről tehetsz nyilatkozatot.</i>
          </Typography>

          <Typography className="mb-32">
            Az itt megadott személyes adatok kizárólag számodra, valamint az Alumni adminisztrátorai számára lesznek
            láthatók a felületen.
          </Typography>

          <EditOrg org={org}
                   saveCb={saveCb}
                   loading={submitLoading}
                   disableSubmit={disableSubmit}
                   enableSubmit={enableSubmit}
          />

          <Statement checked={statement}
                     onCheckCb={toggleApprove}
                     className={clsx(statementClassName, "my-16 mb-32")}
                     color={statementColor}
                     title="Nyilatkozat a személyes adatokról"
                     label="Megerősítem, hogy a fent megadott személyes adatok a valóságnak megfelelnek."
          />

        </div>
      )}
    </Suspense>
  );
};

export default React.memo(OrgStep);
