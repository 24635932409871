import { useCallback, useState } from "react";


const useCheck = ({ _getFilteredIds, rows }) => {

  const [checkedIds, setCheckedIds] = useState([]);

  const [checked, setChecked] = useState(0);

  const [checkedFiltered, setCheckedFiltered] = useState(0);

  const [all, setAll] = useState(false);

  const [indeterminate, setIndeterminate] = useState(false);

  const [uncheckedFilter, setUncheckedFilter] = useState(false);

  const [checkedFilter, setCheckedFilter] = useState(false);


  const _onFilterChecked = useCallback(() => {
    setAll(true);
    setIndeterminate(false);
    setCheckedFilter(true);
    setUncheckedFilter(false);
  }, []);


  const _onFilterUnchecked = useCallback(() => {
    setAll(false);
    setIndeterminate(false);
    setCheckedFilter(false);
    setUncheckedFilter(true);
  }, []);


  const _onFiltersChanged = useCallback(() => {
    setCheckedFilter(false);
    setUncheckedFilter(false);
  }, []);


  const _updateChecked = useCallback((_checkedIds = checkedIds) => {
    const filteredIds = _getFilteredIds();

    setChecked(_checkedIds.length);

    const checkedFiltered = _checkedIds.filter(id => filteredIds.includes(id)).length;
    setCheckedFiltered(checkedFiltered);

    setAll(Boolean(checkedFiltered && checkedFiltered === filteredIds.length));

    setIndeterminate(Boolean(checkedFiltered && checkedFiltered !== filteredIds.length));

    _onFiltersChanged();
  }, [_getFilteredIds, _onFiltersChanged, checkedIds]);


  const toggleAll = useCallback(() => {
    const filteredIds = _getFilteredIds();

    const checkedFiltered = checkedIds.filter(id => filteredIds.includes(id)).length;

    const all = Boolean(checkedFiltered && checkedFiltered === filteredIds.length);

    const ids = all
      ? [...checkedIds.filter(id => !filteredIds.includes(id))]
      : [...checkedIds, ...filteredIds.filter(id => !checkedIds.includes(id))];

    setCheckedIds(ids);

    _updateChecked(ids);
  }, [_getFilteredIds, checkedIds, _updateChecked]);


  const toggleRow = useCallback(id => {
    const ids = checkedIds.includes(id)
      ? checkedIds.filter(_id => _id !== id)
      : [...checkedIds, id];

    setCheckedIds(ids);

    _updateChecked(ids);
  }, [checkedIds, _updateChecked]);


  const resetChecked = useCallback(() => {
    setCheckedIds([]);
    _updateChecked([]);
  }, [_updateChecked]);


  const isChecked = useCallback(id => checkedIds.includes(id), [checkedIds]);


  return {
    _onFilterChecked,
    _onFilterUnchecked,
    _onFiltersChanged,
    _updateChecked,
    rows,
    checked,
    checkedFiltered,
    all,
    indeterminate,
    toggleAll,
    toggleRow,
    resetChecked,
    isChecked,
    checkedIds,
    checkedFilter,
    uncheckedFilter,
  };
};

export default useCheck;
