import React, { useCallback, useContext, useMemo } from "react";
import momentTz from "moment-timezone";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { Autorenew } from "@material-ui/icons";
import { UiMessages } from "config/messages";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { useConfirm } from "components/common";
import { Label } from "components/form";
import { tooltipDisabled } from "./Status";


const confirmOptions = {
  title: "Jelszóváltoztatási link kiküldése",
  content: "Biztosan kiküldöd a jelszóváltoztatási linket tartalmazó emailt?",
  OKButton: "Igen, kiküldöm",
  colorOK: "secondary",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
};


const PswrLink = ({ account: { id }, verified, checkVerified }) => {

  const { message: { showMessage } } = useContext(UiStateContext);


  const confirm = useConfirm({
    confirmOptions,
  });


  const tooltip = useMemo(() => verified ? "Jelszóváltoztatási link kiküldése" : tooltipDisabled, [verified]);


  const sendPswrLink = useCallback(async () => {
    if (!await checkVerified()) return;

    const { error } = await backendFetch(backendServices.pswrLink.sendAdminLink, {
      id,
      tz: momentTz.tz.guess(true),
    });

    showMessage(error ? UiMessages.form.submit.saveError : UiMessages.form.account.pswrLinkSent);
  }, [checkVerified, id, showMessage]);


  const onClick = useCallback(() => confirm(sendPswrLink)
    , [sendPswrLink, confirm]);


  return (
    <Label label="Jelszóváltoztatási link kiküldése"
           helperText="Az alumnus a kapcsolattartási email címére kiküldött linkkel a jelenlegi jelszó ismerete nélkül változtathatja meg jelszavát"
    >
      <Tooltip title={tooltip}>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Autorenew color={verified ? "secondary" : "disabled"}/>}
            disabled={!verified}
            onClick={onClick}
          >
            Link kiküldése
          </Button>
        </Grid>
      </Tooltip>
    </Label>
  );
};

export default React.memo(PswrLink);
