import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { requirementMessages } from "../config";
import Input from "./Input";


const ConfirmInput = ({
                        record, field, requirements, required, confirmValue, confirmField = `${field}_confirm`,
                        InputComponent = Input, hiddenUntouched, entity, noPaste, ...restProps
                      }) => {


  const msg = useMemo(() => requirementMessages.confirmValue(entity), [entity]);


  const isConfirmValue = useCallback(value => clsx(value).trim() === clsx(confirmValue).trim() || msg
    , [msg, confirmValue]);


  const onPaste = useCallback(e => {
    if (noPaste) return Boolean(e.preventDefault()) || false;
  }, [noPaste]);


  const hasChanged = useMemo(() => record[field] !== confirmValue, [confirmValue, field, record]);


  const _requirements = useMemo(() => ({
    ...requirements,
    validate: {
      ...(requirements && requirements.validate),
      isConfirmValue,
    },
  }), [isConfirmValue, requirements]);


  return Boolean(!hiddenUntouched || hasChanged) && (
    <InputComponent {...restProps}
                    record={record}
                    field={confirmField}
                    required={required || true}
                    requirements={_requirements}
                    onPaste={onPaste}
    />
  );
};

export default React.memo(ConfirmInput);
