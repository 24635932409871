import { Observable } from "apollo-link";
import { onError } from "apollo-link-error";
import { getSecurityHeaders, validateMe } from "client/lib/session";
import { isUnauthorizedError } from "./unauthorized";


//TODO: temporary workaround until a new Apollo release supporting async onError links
const asyncOnError = (asyncCb, operation, forward) => new Observable(observer => {
  if (typeof asyncCb === "function" && operation && forward) asyncCb()
    .then(() => {
      const subscriber = {
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer)
      };

      forward(operation).subscribe(subscriber)
    })

    .catch(e => {
      observer.error(e);
    });
});


export const reauthenticateLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (isUnauthorizedError(graphQLErrors, networkError)) {

    operation.setContext({
      headers: getSecurityHeaders(true),
    });

    return asyncOnError(validateMe, operation, forward);
  }
});
