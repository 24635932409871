import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import texts from "config/texts";
import { useOpenDialog } from "components/listPages";
import { __typename } from "components/form/card";
import EditCard from "./EditCard";


const EditButton = card => {

  const record = {
    ...card,
    __typename,
  };


  const edit = useOpenDialog({
    title: `${texts.cardName} szerkesztése`,
    ContentComponent: (
      <EditCard {...record}/>
    ),
  });


  return (
    <Tooltip title={`${texts.cardName} szerkesztése`}>
      <IconButton size="small"
                  onClick={edit}
      >
        <Icon>edit</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(EditButton);
