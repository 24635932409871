import React, { useCallback, useContext } from "react";
import { customAlphabet } from "nanoid";
import { nolookalikes } from "nanoid-dictionary";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { UiMessages } from "config/messages";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { Input } from "components/form";
import { _updateFields } from "./SpecialAccount";


const randomPswLength = 12;


export const getRandomCode = () => customAlphabet(nolookalikes, randomPswLength)();


const RandomPsw = record => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const onCopy = useCallback(() => showMessage(UiMessages.meta.copiedToClipboard("A speciális fiók ideiglenes jelszava")), [showMessage]);

  return (
    <div className="flex flex-row items-center">
      <Input record={record}
             disabled
             field={_updateFields.password}
             label="Ideiglenes bejelentkezési jelszó"
             className="mb-16 mr-8"
             required
      />
      <CopyToClipboard text={record[_updateFields.password]}
                       onCopy={onCopy}
      >
        <Tooltip title="Ideiglenes jelszó másolása a vágólapra"
                 classes={{
                   popper: "z-9999",
                 }}
        >
          <span>
            <IconButton className="mb-16">
              <Icon>file_copy</Icon>
            </IconButton>
          </span>
        </Tooltip>
      </CopyToClipboard>
    </div>
  );
};

export default React.memo(RandomPsw);
