import FileSaver from "file-saver";
import { errors } from "common/errors";
import { errorLogLib } from "client/lib/errorLog";
import { backendFetch } from "./backendFetch";


const { saveAs } = FileSaver;


const saveBuffer = ({ fileExtension, fileType, acceptBlob }, data, fileName) => {
  try {
    const fileNameWExtension = `${fileName}.${fileExtension}`;
    const normalizedData = acceptBlob
      ? data
      : (data && data.data)
        ? data.data
        : data;

    const blob = new Blob([normalizedData], { type: fileType });

    saveAs(blob, fileNameWExtension);
    return {};

  } catch (e) {
    errorLogLib("backendApi", "saveBuffer", e);
    return errors.client.backendApi.ON_SAVE_BUFFER;
  }
};


const validateServiceAndFileName = (service, fileName) => {
  if (!fileName) return errors.client.backendApi.MISSING_FILENAME;

  const { fileExtension, fileType } = service;
  if (!fileExtension || !fileType) {
    errorLogLib("backendApi", "validateServiceAndFileName", { service }, "invalid backend service: missing fileExtension and/or fileType");
    return errors.client.backendApi.INVALID_SERVICE;
  }
};


export const downloadFile = async (service, body, fileName) => {
  const invalid = validateServiceAndFileName(service, fileName);
  if (invalid) return invalid;

  const { error, data } = await backendFetch(service, body);
  if (error) return { error };

  return saveBuffer(service, data, fileName);
};
