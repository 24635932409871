import gql from "graphql-tag";


const fragmentTag = gql`
  fragment tag on view_tag {
    value: id
    entity
    label: tag
    cnt: occurrences
  }
`;


export const GET_ENTITY_TAGS = gql`
  query GetEntityTags($entities: [tag__entity!]) {
    tags: view_tag(where: {entity: {_in: $entities}}, order_by: {occurrences: desc_nulls_last, tag: asc_nulls_last}) {
      ...tag
    }
  }

  ${fragmentTag}
`;
