import gql from "graphql-tag";


export const GET_CONTACTS = gql`
  query GetContacts {
    contacts: alumnus(order_by: {view_alumnus_contacts_student: {last_name: asc_nulls_last, first_name: asc_nulls_last, grp_text: asc_nulls_last}}) {
      id
      student: view_alumnus_contacts_student {
        fullName: full_name
        group: grp_text
        formTeacher: form_teacher
        email
        phone
        address: full_address
        lastRevision: last_revision
        status
        statusLabel: status_text
        exp: status_expiry
        nwsEmail: newsletter_email
        nws: subscription_newsletter
        magazine: subscription_magazine
      }
      manager: view_alumnus_contacts_manager {
        note: admin_note
        regDate: reg_date
        lastTerms: last_terms
        lastUpdate: last_update
        lastLogin: last_login
      }
    }
  }
`;


export const GET_CONTACTS_TO_EXPORT = gql`
  query GetContactsToExport {
    contacts: alumnus(order_by: {view_alumnus_contacts_student: {last_name: asc_nulls_last, first_name: asc_nulls_last, grp_text: asc_nulls_last}}) {
      id
      student: view_alumnus_contacts_student {
        pre: name_prefix
        last: last_name
        first: first_name
        suf: name_suffix
        nick: nickname
        fullName: full_name
        group: grp_text
        formTeacher: form_teacher
        email
        phone
        address: full_address
        lastRevision: last_revision
        status: status_text
        exp: status_expiry
        nwsEmail: newsletter_email
        nws: subscription_newsletter
        magazine: subscription_magazine
      }
      manager: view_alumnus_contacts_manager {
        note: admin_note
        regDate: reg_date
        lastTerms: last_terms
        lastUpdate: last_update
        lastLogin: last_login
      }
    }
  }
`;
