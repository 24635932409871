import React from "react";
import clsx from "clsx";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { Paper, Chip, Typography, TextField, MenuItem } from "@material-ui/core";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  root: {
    "& .fuse-chip-select__input": {
      color: theme.palette.text.primary
    },
    "&.standard": {
      "& $placeholder": {},
      "& $valueContainer": {
        paddingTop: 4
      }
    },
    "&.filled": {
      "& $placeholder": {
        left: 12
      },
      "& $valueContainer": {
        paddingTop: 24,
        paddingLeft: 12
      },
      "& $chip": {
        border: "1px solid rgba(0, 0, 0, 0.12)"
      }
    },
    "&.outlined": {
      "& $placeholder": {
        left: 12
      },
      "& $valueContainer": {
        paddingLeft: 8,
        paddingTop: 8,
        paddingBottom: 6,
      }
    }
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    paddingBottom: 4,
    paddingTop: 12,
    minHeight: 40
  },
  chip: {
    margin: "4px 4px 4px 0"
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 14
  },
  placeholder: {
    position: "absolute",
    left: 0,
    fontSize: 14,
    margin: 0
  },
  paper: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing(),
    left: 0,
    heigth: 64,
    right: 0,
  },
  divider: {
    height: theme.spacing(2)
  }
}));


export const newTagObjKey = "__isNew__"; // by react-select (via FuseChipSelect)


const NoOptionsMessage = props => {
  const classes = useStyles();

  return (
    <Typography
      color="textSecondary"
      className={classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.selectProps.noOptionsContent || props.children}
    </Typography>
  );
};


const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};


const Control = props => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      className={clsx(classes.root, props.selectProps.textFieldProps.variant)}
      required={props.selectProps.required}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};


const Option = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.isSelected ? "font-600" : "font-400"}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};


const Placeholder = props => {
  const classes = useStyles();

  return (
    <Typography
      color="textSecondary"
      className={classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};


const SingleValue = props => {
  const classes = useStyles();

  return (
    <Typography className={classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};


const ValueContainer = props => {
  const classes = useStyles();

  return <div className={classes.valueContainer}>{props.children}</div>;
};


const MultiValue = props => {
  const classes = useStyles();

  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(classes.chip, {
        [classes.chipFocused]: props.isFocused
      }, props.data.class)}
      onDelete={event => {
        props.removeProps.onClick();
        props.removeProps.onMouseDown(event);
      }}
    />
  );
};


const Menu = props => {
  const classes = useStyles();

  return (
    <Paper square className={classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};


const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};


const FuseChipSelect = props => {

  const handleOnChange = value => props.onChange && props.onChange(value || []);

  return (
    props.variant === "fixed" ? (
      <Select
        classNamePrefix="fuse-chip-select"
        {...props}
        components={components}
        onChange={handleOnChange}
      />
    ) : (
      <CreatableSelect
        classNamePrefix="fuse-chip-select"
        {...props}
        components={components}
        onChange={handleOnChange}
        onInputChange={inputValue => inputValue.length <= props.maxLength
          ? inputValue
          : inputValue.substr(0, props.maxLength)
        }
      />
    )
  );
};

export default React.memo(FuseChipSelect);
