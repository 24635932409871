import Router from "next/router";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { Scrollbars } from "./common";
import { CenterCardWrapper, RightCardWrapper } from "./wrappers";


const useLayout = ({ help, wrapper }) => {

  const { help: { setHelp } = {} } = useContext(UiStateContext);


  const [routerLoading, setRouterLoading] = useState(false);


  const WrapperComponent = useMemo(() => {
    if (wrapper === "center") return CenterCardWrapper;
    if (wrapper === "right") return RightCardWrapper;

    return Scrollbars;
  }, [wrapper]);


  const handleRouteStartChange = useCallback(() => setRouterLoading(true), []);

  const handleRouteChanged = useCallback(() => setRouterLoading(false), []);


  useEffect(() => {
    setHelp(help)
  }, [help, setHelp]);


  useEffect(() => {
    Router.events.on("routeChangeStart", handleRouteStartChange);
    Router.events.on("routeChangeComplete", handleRouteChanged);
    Router.events.on("routeChangeError", handleRouteChanged);

    return () => {
      Router.events.off("routeChangeStart", handleRouteStartChange);
      Router.events.off("routeChangeComplete", handleRouteChanged);
      Router.events.off("routeChangeError", handleRouteChanged);
    }
  }, [handleRouteChanged, handleRouteStartChange]);


  return {
    routerLoading,
    WrapperComponent,
  };

};

export default useLayout;
