import clsx from "clsx";
import nextTick from "next-tick";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getGlobal, setGlobal, useGlobal } from "reactn";
import { useMutation } from "@apollo/react-hooks";
import { dbEnums } from "common/enums";
import { SEARCH } from "client/store/search";
import { emitUiStateEventNextTick, uiStateEventEmitter, uiStateEvents } from "./events";


export const sortTypes = {
  byRelevance: {
    id: "byRelevance",
    label: "Relevancia szerint",
  },
  byFullName: {
    id: "byFullName",
    label: "Név szerint",
  },
};


const pageSizes = [12, 24, 48];


const defaultValues = {
  isLoading: false,
  fetchStarted: false,
  outdatedPager: false,
  members: undefined,
  previousFilters: undefined,

  searchText: "",
  category: dbEnums.searchCategories.ALL,
  yearFrom: 0,
  yearTo: 0,
  sortType: sortTypes.byRelevance.id,
  sortDesc: false,

  pageSize: 12,

  prefetchedPages: [],
  page: 1,
  numPages: 0,
};


export const getSearchFilters = () => (getGlobal() || {})["searchFilters"] || {};

export const getSearchPages = () => (getGlobal() || {})["searchPages"] || {};

export const setCount = async rawIds => await setGlobal(global => ({
  ...global,
  count: (rawIds || []).length,
}));


const useUiSearch = () => {

  const [{ count = 0 }, setGlobal] = useGlobal();

  const previousFilters = useRef(defaultValues.previousFilters);

  const [isLoading, setIsLoading] = useState(defaultValues.isLoading);
  const [fetchStarted, setFetchStarted] = useState(defaultValues.fetchStarted);
  const [outdatedPager, setOutdatedPager] = useState(defaultValues.outdatedPager);
  const [members, setMembers] = useState(defaultValues.members);

  const [searchText, _setSearchText] = useState(defaultValues.searchText);
  const [category, _setCategory] = useState(defaultValues.category);
  const [yearFrom, _setYearFrom] = useState(defaultValues.yearFrom);
  const [yearTo, _setYearTo] = useState(defaultValues.yearTo);
  const [sortType, _setSortType] = useState(defaultValues.sortType);
  const [sortDesc, setSortDesc] = useState(defaultValues.sortDesc);
  const [pageSize, _setPageSize] = useState(defaultValues.pageSize);

  const [prefetchedPages, setPrefetchedPages] = useState(defaultValues.prefetchedPages);
  const [page, _setPage] = useState(defaultValues.page);
  const [numPages, setNumPages] = useState(defaultValues.numPages);


  const emptyFilters = useMemo(() => searchText === defaultValues.searchText
    && category === dbEnums.searchCategories.ALL
    && yearFrom === defaultValues.yearFrom
    && yearTo === defaultValues.yearTo
    , [category, searchText, yearFrom, yearTo]);


  const clearPages = useCallback(() => {
    setPrefetchedPages(defaultValues.prefetchedPages);
    _setPage(defaultValues.page);
  }, []);


  const clearFilters = useCallback(() => {
    _setSearchText(defaultValues.searchText);
    _setCategory(defaultValues.category);
    _setYearFrom(defaultValues.yearFrom);
    _setYearTo(defaultValues.yearTo);

    clearPages();
  }, [clearPages]);


  const reset = useCallback(() => {
    setIsLoading(defaultValues.isLoading);
    setFetchStarted(defaultValues.fetchStarted);
    setOutdatedPager(defaultValues.outdatedPager);
    setMembers(defaultValues.members);

    previousFilters.current = defaultValues.previousFilters;

    _setSortType(defaultValues.sortType);
    setSortDesc(defaultValues.sortDesc);
    _setPageSize(defaultValues.pageSize);

    clearFilters();

    setNumPages(defaultValues.numPages);
  }, [clearFilters]);


  const sanitizeSearchText = useCallback(searchText => clsx(searchText).replace(/'/g, "\""), []);

  const setSearchText = useCallback(searchText => _setSearchText(sanitizeSearchText(searchText)), [sanitizeSearchText]);

  const setCategory = useCallback(category => _setCategory(Object.values(dbEnums.searchCategories).includes(category) ? category : dbEnums.searchCategories.ALL), []);

  const setYearFrom = useCallback(yearFrom => _setYearFrom(yearFrom || defaultValues.yearFrom), []);

  const setYearTo = useCallback(yearTo => _setYearTo(yearTo || defaultValues.yearTo), []);

  const setPageSize = useCallback(pageSize => _setPageSize(pageSize || defaultValues.pageSize), []);

  const setPage = useCallback(page => page && _setPage(parseInt(page)), []);

  const setSearchNumPages = useCallback(numPages => setNumPages(parseInt(numPages || 0)), []);

  const addPrefetchedSearchPage = useCallback(page => setPrefetchedPages(pages => pages.filter(f => f !== page).concat([page])), []);

  const startLoading = useCallback(() => setIsLoading(true), []);

  const onCompleted = useCallback(({ members } = {}) => {
    setMembers(members || defaultValues.members);
    setIsLoading(false);
    setFetchStarted(false);
    setOutdatedPager(false);
    emitUiStateEventNextTick(uiStateEvents.search.updateFinished);
  }, []);


  const [doSearch] = useMutation(SEARCH, { onCompleted });


  const handleOutdated = useCallback(toUpdate => {
    const _filters = {
      searchText,
      category,
      yearFrom,
      yearTo,
      sortType,
      sortDesc,
      pageSize,
      ...toUpdate,
    };

    if (!previousFilters.current || Object.entries(previousFilters.current).some(([key, value]) => value !== _filters[key])) {
      setOutdatedPager(true);
      clearPages();
      previousFilters.current = _filters;
    }
  }, [category, clearPages, pageSize, searchText, sortDesc, sortType, yearFrom, yearTo]);


  const updateResults = useCallback(toUpdate => {
    if (fetchStarted) return;

    setIsLoading(true);
    setFetchStarted(true);

    handleOutdated(toUpdate);

    nextTick(() => doSearch && doSearch());
  }, [doSearch, fetchStarted, handleOutdated]);


  const update = useCallback(() => updateResults(), [updateResults]);


  const setSortType = useCallback(sortType => {
    if (sortType) {
      _setSortType(sortType);
      setSortDesc(false);

      updateResults({
        sortType,
      });
    }
  }, [updateResults]);


  const toggleSortDesc = useCallback(() => {
    setSortDesc(sortDesc => !sortDesc);
    updateResults({
      sortDesc: !sortDesc,
    });
  }, [sortDesc, updateResults]);


  useEffect(() => {
    setGlobal(global => ({
      ...global,
      searchFilters: {
        searchText,
        category,
        yearFrom,
        yearTo,
        sortType,
        sortDesc,
        pageSize,
      },
      searchPages: {
        prefetchedPages,
        page,
        numPages,
      },
    })).then();
  }, [category, numPages, page, pageSize, prefetchedPages, searchText, setGlobal, sortDesc, sortType, yearFrom, yearTo]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.search.loadingStarted, startLoading);
    uiStateEventEmitter.on(uiStateEvents.search.numPagesChanged, setSearchNumPages);
    uiStateEventEmitter.on(uiStateEvents.search.pagePrefetched, addPrefetchedSearchPage);
    uiStateEventEmitter.on(uiStateEvents.search.reseted, reset);
    uiStateEventEmitter.on(uiStateEvents.search.updateStarted, update);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.search.loadingStarted, startLoading);
      uiStateEventEmitter.off(uiStateEvents.search.numPagesChanged, setSearchNumPages);
      uiStateEventEmitter.off(uiStateEvents.search.pagePrefetched, addPrefetchedSearchPage);
      uiStateEventEmitter.off(uiStateEvents.search.reseted, reset);
      uiStateEventEmitter.off(uiStateEvents.search.updateStarted, update);
    }
  }, [addPrefetchedSearchPage, reset, setSearchNumPages, startLoading, update]);


  return {
    isLoading,
    startLoading,
    outdatedPager,
    members,
    clearFilters,

    emptyFilters,
    searchText,
    category,
    yearFrom,
    yearTo,
    sortType,
    sortDesc,

    pageSizes,
    pageSize,
    setPageSize,

    setSortType,
    toggleSortDesc,
    setSearchText,
    setCategory,
    setYearFrom,
    setYearTo,

    prefetchedPages,
    page,
    setPage,
    numPages,
    count,
  };
};

export default useUiSearch;
