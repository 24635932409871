import React, { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { GET_SPECIAL_ACCOUNTS } from "client/store/specialAccount";
import { useList, ListPage, ListContext } from "components/listPages";
import { getFilterTestFnObj } from "components/listPages/helpers";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Row from "./Row";


const fields = {
  toFilter: "toFilter",
};


const filters = {
  toFilter: getFilterTestFnObj(fields.toFilter),
};


const SpecialAccountsList = () => {

  const forbidden = useForbidden([dbEnums.accountRoles.MANAGER]);


  const { reload, ...list } = useList({ filters });


  const { refetch: refetchAccounts, error, loading } = useQuery(GET_SPECIAL_ACCOUNTS, {
    onCompleted: ({ accounts } = {}) => accounts && reload(accounts),
  });


  const refetch = useCallback(async () => {
    const { data: { accounts } = {} } = await refetchAccounts();
    if (accounts) reload(accounts);
  }, [refetchAccounts, reload]);


  const contextValue = {
    ...list,
    refetch,
  };


  return (
    <ListContext.Provider value={contextValue}>
      <ListPage forbidden={forbidden}
                loading={loading}
                error={error}
                title="Speciális felhasználók"
                icon="face"
                HeaderComponent={Header}
                ToolbarComponent={Toolbar}
                RowComponent={Row}
      />
    </ListContext.Provider>
  );
};

export default React.memo(SpecialAccountsList);
