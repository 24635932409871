import { default as BannedIps } from "./BannedIps";
import Dashboard from "./Dashboard";
import { default as MyAccount } from "./MyAccount";
import { default as Octopus } from "./Octopus";
import { default as Search } from "./Search";
import { default as Verification } from "./Verification";


export const helps = {
  dashboard: {
    title: "Kezdőlap",
    content: Dashboard,
  },
  myAccount: {
    title: "Jelszóváltoztatás",
    content: MyAccount,
  },
  bannedIps: {
    title: "Kitiltott IP címek",
    content: BannedIps,
  },
  octopus: {
    title: "Az EmailOctopus használata",
    content: Octopus,
  },
  search: {
    title: "A kereső működéséről",
    content: Search,
  },
  verify: {
    email: {
      title: "Kapcsolattartási email cím megerősítése",
      content: Verification,
      props: {
        phone: false,
      },
    },
    phone: {
      title: "Kapcsolattartási telefonszám megerősítése",
      content: Verification,
      props: {
        phone: true,
      },
    },
  },
};
