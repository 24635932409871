import React from "react";
import { EmailAddress } from "components/form";
import useSecondaryEmailAddress from "./useSecondaryEmailAddress";


const SecondaryEmailAddress = ({ record, requirements, required, label = "További email cím", ...restProps }) => {

  const { fieldRequirements } = useSecondaryEmailAddress({
    record,
    requirements,
  });

  return (
    <EmailAddress {...restProps}
                  record={record}
                  requirements={fieldRequirements}
                  required={required || true}
                  label={label}
    />
  );
};

export default React.memo(SecondaryEmailAddress);
