import React from "react";
import { Typography } from "@material-ui/core";
import texts from "config/texts";
import { accent } from "common/helpers";


const News = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon az <b>Alumni küldeményeinek</b> részedre való megküldéséhez adhatod hozzájárulásodat, vagy
      visszavonhatod korábbi hozzájárulásodat.
    </Typography>
    <Typography className="my-20">
      {accent(texts.magazineName, true)} <span className="text-blue">{texts.magazineName} folyóiratot</span> postai
      úton küldjük ki számodra, az általad megadott <b>levelezési címre</b>. Ezt a címet nem ezen a lapon, hanem a <i>Postai
      címek</i> lapján tudod megváltoztatni, amelyet az <span className="text-blue">Ugrás a címekhez</span> gombra
      kattintva is megnyithatsz.
    </Typography>
    <Typography className="my-20">
      Az <span className="text-blue">Alumni hírlevelét</span> emailben küldjük ki számodra, az általad megadott <b>kézbesítési
      email címre</b>. Ezt az email címet ezen a lapon módosíthatod. Módosításkor &ndash; az elírások elkerülése
      végett &ndash; ismételten meg kell adnod a kézbesítési email címet.
    </Typography>
    <Typography className="mt-20">
      Fontos, hogy <b>amikor</b> &ndash; az <i>Email címek</i> lapon &ndash; <b>megváltoztatod a kapcsolattartási email
      címedet, ellenőrizd, hogy nem szeretnéd-e egyúttal a hírlevél kézbesítési email címedet is megváltoztatni</b>!
    </Typography>
  </>
);

export default React.memo(News);
