import React, { useContext } from "react";
import clsx from "clsx";
import { Grid, Icon, Typography } from "@material-ui/core";
import { FormContext, Label } from "components/form";
import { strLower, strNumeric, strSpecial, strUpper, minLength, allowedHelper } from "./config";


const criteria = [
  {
    label: "Tartalmazzon legalább 2 kisbetűt",
    regex: new RegExp(`^(?=.*[${strLower}].*[${strLower}]).+$`),
  },
  {
    label: "Tartalmazzon legalább 2 nagybetűt",
    regex: new RegExp(`^(?=.*[${strUpper}].*[${strUpper}]).+$`),
  },
  {
    label: "Tartalmazzon legalább 1 számjegyet",
    regex: new RegExp(`^(?=.*[${strNumeric}]).+$`),
  },
  {
    label: "Tartalmazzon legalább 1 szóközt vagy írásjelet:",
    helper: allowedHelper,
    regex: new RegExp(`^(?=.*[${strSpecial}]).+$`),
  },
  {
    label: "Legyen legalább 8 karakter hosszú",
    regex: new RegExp(`^.{${minLength},}$`),
  },
];


const PswRequirements = ({ value }) => {

  const { dirty } = useContext(FormContext);

  return (
    <Label label="A jelszóra vonatkozó formai követelmények:" labelGridClassName="mb-4">
      <Grid item container spacing={1} className="ml-12">
        {criteria.map(({ label, helper, regex }, index) => {

          const valid = regex.test(clsx(value));

          const className = clsx(value && !valid && dirty && "text-red");

          return (
            <Grid key={index} item xs={12} container spacing={1} alignItems="flex-start" wrap="nowrap">
              <Grid item>
                <Icon color={value && dirty ? "secondary" : "action"} className={clsx("text-16", className)}>
                  {valid ? "check" : "priority_high"}
                </Icon>
              </Grid>
              <Grid item>

                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="caption" color={value && dirty ? "secondary" : "textSecondary"}
                                className={clsx(className)}>
                      {label}
                    </Typography>
                  </Grid>
                  {Boolean(helper) && (
                    <Grid item>
                      <Typography variant="caption" className="text-blue">{helper}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Label>
  );
};

export default React.memo(PswRequirements);
