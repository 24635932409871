import React, { useState } from "react";
import { modes, Verification } from "components/form/verification";


const verificationFieldName = "adminVerificationCode";


const AdminVerification = ({ account, loading, ...restProps }) => {

  const [codeSent, setCodeSent] = useState(false);

  return (
    <Verification record={account}
                  field={verificationFieldName}
                  codeSent={codeSent}
                  setCodeSent={setCodeSent}
                  mode={modes.admin}
                  parentLoading={loading}
                  className="mb-0"
                  noHelp
                  {...restProps}
    />
  );
};

export default React.memo(AdminVerification);
