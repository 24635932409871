import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { GET_BANNED_IPS, GET_BANNED_IPS_W_COUNTRIES } from "client/store/bannedIps";
import { helps } from "components/help";
import { useAdminVerification } from "components/form";
import { useList, ListPage, ListContext } from "components/listPages";
import { getFilterTestFnObj } from "components/listPages/helpers";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Sidebar from "./Sidebar";
import Row from "./Row";


export const fields = {
  ip: "ip",
};

const idField = fields.ip;


const __typename = "__sessionAccount";


const filters = {
  [fields.ip]: getFilterTestFnObj(fields.ip),
};


const BannedIpsList = () => {

  const { account: { id } } = useContext(SessionContext);


  const loaded = useRef(false);


  const [loading, setLoading] = useState(true);

  const [verified, setVerified] = useState(true);


  const forbidden = useForbidden([dbEnums.accountRoles.MANAGER]);


  const adminVerification = useAdminVerification({
    forbidden,
    setVerified,
    verified,
  });


  const { reload, ...list } = useList({
    idField,
    filters,
  });


  const { refetch: refetchIps, error } = useQuery(GET_BANNED_IPS, {
    skip: forbidden,
  });

  const { refetch: refetchCountries, data: { bannedIpsWCountries } = {} } = useQuery(GET_BANNED_IPS_W_COUNTRIES, {
    skip: forbidden,
  });


  const refetch = useCallback(async () => {
    setLoading(true);

    const { data: { ips: data } = {} } = await refetchIps();
    await refetchCountries();
    reload(data);

    setLoading(false);
  }, [refetchCountries, refetchIps, reload]);


  const account = useMemo(() => ({
    id,
    __typename,
  }), [id]);


  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      refetch();
    }
  }, [refetch]);


  const contextValue = {
    ...list,
    ...adminVerification,
    idField,
    account,
    bannedIpsWCountries,
    verified,
    refetch,
  };


  return (
    <ListContext.Provider value={contextValue}>
      <ListPage forbidden={forbidden}
                loading={loading}
                error={error}
                notFound={!id}
                title="Kitiltott IP címek"
                icon="network_locked"
                help={helps.bannedIps}
                HeaderComponent={Header}
                RowComponent={Row}
                ToolbarComponent={Toolbar}
                SidebarComponent={Sidebar}
                adminVerification
                multiSelect
      />
    </ListContext.Provider>
  );
};

export default React.memo(BannedIpsList);
