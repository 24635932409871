import React, { useMemo } from "react";
import moment from "moment";
import clsx from "clsx";
import Router from "next/router";
import { Typography, Button } from "@material-ui/core";
import PleaseRetryBanned from "./BannedInfo";
import ErrorBase from "./ErrorBase";


const Banned = ({ bannedIp, banExpiry, banExpiryFormatted }) => {

  const permanent = useMemo(() => !banExpiry && !banExpiryFormatted, [banExpiry, banExpiryFormatted]);


  const formattedExpiry = useMemo(() => {
    if (banExpiry) {
      const safeExpiry = moment(banExpiry).startOf("m").add(1, "m");
      const format = safeExpiry.dayOfYear() === moment().dayOfYear() ? "HH:mm[ (UTC]Z[)]" : "L HH:mm[ (UTC]Z[)]";
      return safeExpiry.format(format);
    }
  }, [banExpiry]);


  const description = useMemo(() => permanent
    ? `Határozatlan idejű ${clsx(bannedIp && "IP")} kitiltás`
    : `Átmeneti ${clsx(bannedIp && "IP")} kitiltás eddig:`
    , [bannedIp, permanent]);


  return (
    <ErrorBase title={401} noStartPage>

      <Typography variant="h5" color="textSecondary" className={bannedIp ? "mb-12" : "mb-24"}>
        {description}
        {Boolean(banExpiryFormatted || formattedExpiry) && (
          <span>&nbsp;<span className="text-blue">{banExpiryFormatted || formattedExpiry}</span></span>
        )}
      </Typography>

      {Boolean(bannedIp) && (
        <Typography variant="h6" color="textSecondary" className="mb-24">
          Kitiltott IP cím:&nbsp;
          <span className="text-blue">{bannedIp}</span>
        </Typography>
      )}

      <PleaseRetryBanned bannedIp={bannedIp}
                         permanent={permanent}
      />

      {Boolean(!permanent) && (
        <div className="mt-48">
          <Button onClick={Router.reload}>Újraellenőrzés</Button>
        </div>
      )}

    </ErrorBase>
  );
};

export default React.memo(Banned);
