import React, { useCallback, useContext, useState } from "react";
import { useRouter } from "next/router"
import { Grid, Typography } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";
import { BackButton } from "components/common";
import { AlumnusAvatar, AlumnusPictureModal } from "components/alumnus/common";
import { AlumnusContext } from "components/alumnus";
import ProfileMenu from "./ProfileMenu";
import HeaderName from "../HeaderName";


const ProfileHeader = () => {

  const { alumnus, editable } = useContext(AlumnusContext);


  const [dialogOpen, setDialogOpen] = useState(false);


  const { query: { from, fromAs, fromName } } = useRouter();


  const toggleDialog = useCallback(() => setDialogOpen(open => !open), []);


  return (
    <Grid container spacing={2} alignItems="center" justify="space-between" className="my-8 sm:mb-12 mx-10 sm:ml-16">

      {Boolean(from) && (
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={12}>
            <BackButton from={from} fromAs={fromAs} fromName={fromName}/>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} container spacing={3} alignItems="flex-end">

        <Grid item xs={12} sm={8} container spacing={1} alignItems="center" wrap="nowrap">
          {alumnus.img && alumnus.img.src && (
            <Grid item>
              <AlumnusAvatar alumnus={alumnus} rounded={4} avatarClassName="mr-16" size={72} onClick={toggleDialog}/>
            </Grid>
          )}
          <HeaderName alumnus={alumnus}>
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="text-13">{alumnus.safeGroup}</Typography>
            </FuseAnimate>
          </HeaderName>
        </Grid>

        {Boolean(editable) && (
          <Grid item xs={12} sm={4} container className="sm:justify-end">
            <Grid item>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <ProfileMenu className="justify-end"/>
              </FuseAnimate>
            </Grid>
          </Grid>
        )}

      </Grid>

      <AlumnusPictureModal alumnus={alumnus} open={dialogOpen} onClose={toggleDialog} className=""/>

    </Grid>
  );
};

export default React.memo(ProfileHeader);
