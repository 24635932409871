import React, { useMemo } from "react";
import clsx from "clsx";
import { AppBar, Chip, Card, CardContent, Toolbar, Typography, List, Grid, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/react-hooks";
import { GET_ALUMNUS_GROUP } from "client/store/alumnus";
import { LogComponentError } from "components/error";
import { AlumnusAvatar } from "components/alumnus/common";


const useStyles = makeStyles(() => ({
  CardContent: {
    "&:last-child": {
      paddingBottom: 4,
    },
  },
}));


const GroupmatesCard = ({ alumnus: { group, special, safeGroup, id, org: { formTeacher } = {} } }) => {

  const { error, data: { members } = {} } = useQuery(GET_ALUMNUS_GROUP, {
    skip: special || !group,
    variables: {
      groups: [group],
    },
  });


  const appBarTitle = useMemo(() => special ? "Csoport" : "Osztály", [special]);


  const groupTitle = useMemo(() => special ? "Csoport" : "Osztály, osztályfőnök", [special]);


  const groupBadge = useMemo(() => special ? safeGroup : `${group}, ${formTeacher}`, [formTeacher, group, safeGroup, special]);


  const classes = useStyles();


  if (error) return LogComponentError("GroupmatesCard", error);


  if (!id || !group) return null;

  return (
    <Card className="w-full mb-16">
      <AppBar position="static" elevation={0}>
        <Toolbar className="pl-16 pr-8">
          <Typography variant="subtitle1" color="inherit" className="flex-1">
            {appBarTitle}
          </Typography>
          {!special && (
            <Chip
              icon={
                <FontAwesomeIcon className="text-18 p-1" icon={faUsers} color="rgba(0, 0, 0, 0.4)"/>
              }
              label={safeGroup}
              className="mr-5"
              color="default"
            />
          )}
        </Toolbar>
      </AppBar>

      <CardContent className={clsx("py-0 px-4", classes.CardContent)}>
        <div className="p-16 pb-8">
          {!special && (
            <Typography className="font-bold mt-8 mb-6 text-15">{groupTitle}</Typography>
          )}
          <div className="flex items-center">
            <FontAwesomeIcon className="text-16 mr-8" icon={faUsers} color="rgba(0, 0, 0, 0.4)"/>
            <Typography className="mt-8 mb-4">{groupBadge}</Typography>
          </div>
        </div>

        {Boolean(!special && members && members.length) && (
          <div className="mt-12 mb-8">
            <Typography className="pl-16 font-bold text-15">Regisztrált osztálytársak</Typography>
            <List className="ml-1 p-8">
              <Grid container spacing={1} className="p-4">
                {members.map(alumnus => alumnus.id !== id && !alumnus.hiddenAccount && (
                  <Grid item xs="auto" key={alumnus.id}>
                    <AlumnusAvatar size={64}
                                   alumnus={alumnus}
                                   link
                                   hidden={(alumnus.id === id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </List>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default React.memo(GroupmatesCard);
