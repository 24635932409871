import analyzeContact from "./analyzeContact";
import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { errors, decoratedError } from "common/errors";


const groupByStatuses = analyzedContacts => analyzedContacts && analyzedContacts.reduce((acc, { status, ...rest }) => {
  if (!acc[status]) acc[status] = [];
  return {
    ...acc,
    [status]: [
      ...acc[status],
      {
        ...rest,
      },
    ]
  };
}, {});


const analyzeList = async (listId, membersObject) => {
  const { error, data: { data: contacts = {} } } = await backendFetch(backendServices.octopus.getContacts, { listId });
  if (error) return { error };

  const analyzedContacts = (contacts && contacts.map(contact => {
    const item = analyzeContact(contact, membersObject);
    return {
      ...item,
      listId,
    }
  })) || [];

  return groupByStatuses(analyzedContacts) || {};
};


const analyzeLists = async (settings, membersObject) => {
  try {
    const { primaryList: { id: pId }, secondaryList: { id: sId } } = settings;

    const primaryList = await analyzeList(pId, membersObject);
    if (primaryList.error) return primaryList;

    const secondaryList = await analyzeList(sId, membersObject);
    if (secondaryList.error) return secondaryList;

    return {
      primaryList,
      secondaryList,
    };

  } catch (e) {
    const message = errorLogStore("octopus", "analyzeLists", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};

export default analyzeLists;
