import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { DeletePanel, PanelContext } from "components/form";
import { setDefaultValueProp } from "../helpers";
import useCard, { _updateFields, newRecordValues } from "./useCard";
import CardStatus from "./CardStatus";
import CardEntry from "./CardEntry";


const Card = () => {

  const { record, isNew } = useContext(PanelContext);

  const { status, onDelete, onStatusChange } = useCard();

  return (
    <Grid container spacing={2} item>
      <Grid container spacing={0} justify="space-between" item xs={12} wrap="wrap-reverse">
        <Grid item xs={12} md={8}>
          <CardStatus record={record}
                      field={_updateFields.status}
                      defaultValue={setDefaultValueProp(isNew, newRecordValues[_updateFields.status])}
                      onChangeCb={onStatusChange}
                      tooltip="Kártya státuszának állítása"
          />
        </Grid>
        <DeletePanel onDelete={onDelete}
                     label="Kártya törlése"
        />
      </Grid>
      <CardEntry status={status}
                 record={record}
                 _updateFields={_updateFields}
      />
    </Grid>
  );
};

export default React.memo(Card);
