import React, { useCallback, useMemo } from "react";
import validator from "validator";
import { requirementMessages } from "../config";
import Input from "./Input";


const URL = ({ requirements, maxLength, ...restProps }) => {

  const validateFormat = useCallback(url => {
    if (!url) return true;

    if (/[\s]+/.test(url)) return requirementMessages.url.whitespace;

    return validator.isURL(url, {
      protocols: ["http", "https"],
      require_tld: true,
      require_protocol: true,
      disallow_auth: true,
    }) || requirementMessages.url.invalid;
  }, []);


  const _requirements = useMemo(() => ({
    ...requirements,
    validate: {
      ...(requirements && requirements.validate),
      validateFormat,
    },
  }), [requirements, validateFormat]);


  return (
    <Input
      {...restProps}
      requirements={_requirements}
      maxLength={maxLength || 150}
    />
  );
};

export default React.memo(URL);
