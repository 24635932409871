import React, { useContext, useRef } from "react";
import { Controller as RHFController } from "react-hook-form";
import { FormContext } from "components/form";


const Controller = ({ name, ...props }) => {

  const { getControl } = useContext(FormContext);

  const control = useRef(getControl());

  return Boolean(control.current) && (
    <RHFController {...props}
                   control={control.current}
                   name={name}
    />
  );
};

export default React.memo(Controller);
