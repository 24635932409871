export const statementsConfig = [
  {
    id: "approveTerms",
    label: "Tudomásul veszem a hatályos Adatkezelési Tájékoztatóban írtakat",
    required: true,
  },
  {
    id: "agreeRegistration",
    label: "Hozzájárulok adataim kezeléséhez az Alumni tagságom nyilvántartásához, valamint rendszerüzeneteinek kiküldéséhez a kapcsolattartási email címemre és telefonszámomra (I.)",
    required: true,
  },
  {
    id: "agreeEvents",
    label: "Hozzájárulok adataim kezeléséhez a rendezvények szervezéséhez, valamint ahhoz, hogy az Alumni ezzel összefüggésben leveleket és általános kiadványokat küldjön a levelezési címemre (VII.)",
    required: true,
  },
];
