import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { GET_BANNED_IPS } from "./queries";


const deleteIpBans = async (_root, { ips }, { client }) => {
  const { error } = await backendFetch(backendServices.account.deleteIpBans, { ips });
  if (error) throw new Error();

  try {
    const { ips: cachedIps } = client.readQuery({ query: GET_BANNED_IPS });
    client.writeQuery({
      query: GET_BANNED_IPS,
      data: {
        ips: (cachedIps || []).filter(({ ip }) => !ips.includes(ip))
      },
    });

  } catch (e) {
    errorLogStore("bannedIps", "deleteIpBans", e);
    throw e;
  }
};

export default deleteIpBans;
