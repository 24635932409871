import clsx from "clsx";
import nextTick from "next-tick";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { backendServices } from "client/lib/backendApi";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { useField, validateEmailFormat, FormContext } from "components/form";
import { requirementMessages } from "../config";
import { __typename, updateFields } from "./Email";


const verificationFieldName = "emailVerificationCode";


export const setCodeRequirements = setCodeReqs => (alphabet, length) => {
  const safeAlphabet = clsx(alphabet);
  const safeLength = parseInt(length);

  if (safeAlphabet || safeLength) {
    setCodeReqs(current => {
      const updated = { ...current };
      if (safeAlphabet) updated.codeAlphabet = safeAlphabet;
      if (safeLength) updated.codeLength = safeLength;
      return updated;
    });
  }
};


const usePrimaryEmailAddress = ({ record, field, accountId, requirements, fetch, registration }) => {

  const verifiedEmails = useRef([]);

  const isUnique = useRef(true);


  const { authenticated } = useContext(SessionContext);

  const { triggerValidation, validateUniqueness: _validateUniqueness } = useContext(FormContext);


  const [codeReqs, setCodeReqs] = useState({});

  const [codeSent, setCodeSent] = useState(false);

  const [verifiedToolTip, setVerifiedToolTip] = useState(!registration);


  const verificationService = useMemo(() => !registration
    ? backendServices.alumnus.isEmailVerified
    : authenticated
      ? backendServices.account.checkAdmin
      : backendServices.registration.email.isVerified,
    [authenticated, registration]);


  const uniqueService = useMemo(() => registration ? backendServices.registration.email.isUnique : backendServices.alumnus.isPrimaryEmailUnique
    , [registration]);


  const isVerified = useCallback(email => email === record[field] || (verifiedEmails.current || []).includes(email)
    , [field, record]);


  const setVerified = useCallback(email => {
    if (!isVerified(email)) verifiedEmails.current = [...verifiedEmails.current, email];
    setVerifiedToolTip(true);
  }, [isVerified]);


  const validateUniquePerAlumnus = useCallback(value => validateEmailFormat(value) !== true
    || Boolean(isVerified(value))
    || !_validateUniqueness(__typename, updateFields[__typename].email, value, record.id)
    || requirementMessages.email.notUniquePerAlumnus
    , [isVerified, _validateUniqueness, record]);


  const validateUniqueness = useCallback(value => Boolean(isVerified(value))
    || Boolean(isUnique.current)
    || requirementMessages.verification.notUniquePrimaryEmail
    , [isVerified]);


  const validateVerification = useCallback(email => Boolean(isVerified(email))
    || requirementMessages.verification.unverifiedEmail
    , [isVerified]);


  const checkValid = useCallback(value =>
    validateEmailFormat(value) === true && !_validateUniqueness(__typename, updateFields[__typename].email, value, record.id)
    , [_validateUniqueness, record]);


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    validate: {
      validateUniquePerAlumnus,
      validateUniqueness,
      validateVerification,
      ...(requirements && requirements.validate),
    },
  }), [requirements, validateUniquePerAlumnus, validateUniqueness, validateVerification]);


  const { clearError, errorType } = useField({
    record,
    field,
  });


  const onVerified = useCallback(email => {
    setVerified(email);
    clearError();
    nextTick(triggerValidation);
  }, [clearError, setVerified, triggerValidation]);


  const _setCodeRequirements = useCallback((alphabet, length) => setCodeRequirements(setCodeReqs)(alphabet, length), []);


  const handleVerifiedResponse = useCallback((email, error, { admin, verified = admin, alphabet, length }) => {
    if (!error && verified) setVerified(email);

    _setCodeRequirements(alphabet, length);

    clearError();
    triggerValidation();
  }, [clearError, _setCodeRequirements, setVerified, triggerValidation]);


  const fetchVerified = useCallback(async email => {
    const { banned, error, data } = await fetch(verificationService, { email });

    if (!banned) handleVerifiedResponse(email, error, data);
  }, [fetch, handleVerifiedResponse, verificationService]);


  const handleUniqueResponse = useCallback((error, { unique }) => {
    isUnique.current = Boolean(!error && unique);
    clearError();
    triggerValidation();
  }, [clearError, triggerValidation]);


  const fetchUnique = useCallback(async email => {
    const { banned, error, data } = await fetch(uniqueService, {
      email,
      ...(!registration ? { accountId } : {}),
    });

    if (!banned) handleUniqueResponse(error, data);
  }, [fetch, uniqueService, registration, accountId, handleUniqueResponse]);


  const onChangeCb = useCallback(async value => {
    if (isVerified(value)) {
      clearError();
      setVerifiedToolTip(true);

    } else if (value && checkValid(value)) {
      await fetchUnique(value);
      if (isUnique.current) await fetchVerified(value);

    } else {
      clearError();
      setVerifiedToolTip(false);
    }

    setCodeSent(false);
  }, [checkValid, clearError, fetchUnique, fetchVerified, isVerified]);


  const verificationArgs = useMemo(() => ({
    field: verificationFieldName,
    codeReqs,
    onVerified,
    codeSent,
    setCodeSent,
  }), [codeReqs, codeSent, onVerified]);


  return {
    onChangeCb,
    errorType,
    fieldRequirements,
    verifiedToolTip,
    verificationArgs,
  };
};

export default usePrimaryEmailAddress;
