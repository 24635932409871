import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import validator from "validator";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import { TextField, InputAdornment, Grid, Icon, Tooltip } from "@material-ui/core";
import FlagIcon from "components/alumnus/FlagIcon";
import { Controller, useField } from "components/form";
import { requirementMessages } from "../config";
import { getDefaultValueProp } from "../helpers";


const domesticFormatRegex = /^[\s]*06.*$/;


export const parsePhoneCountry = phone => {
  const parsed = parsePhoneNumberFromString(clsx(phone));
  return (parsed && parsed.isValid())
    ? parsed.country
    : undefined;
};


export const validatePhoneFormat = (value, onlyMobilePhone) => {
  if (domesticFormatRegex.test(value)) return requirementMessages.phone.domesticFormat;

  const parsed = parsePhoneNumberFromString(value);

  const valid = parsed && parsed.isValid();
  if (!valid) return requirementMessages.phone.invalidFormat;

  if (onlyMobilePhone && !validator.isMobilePhone(value)) return requirementMessages.phone.onlyMobilePhone;

  return true;
};


const defaultHelperText = (
  <span>Nemzetközi formátumban (pl. <span className="text-blue">+36201234567</span>)</span>
);


const PhoneNumber = ({
                       record, field, requirements, required, defaultValue, getEndAdornment, errorEndAdornment,
                       onChangeCb, loading, disableError, className, spacing, alignItems, textFieldClassName,
                       variant = "outlined", label = "Telefonszám", helperText = defaultHelperText, children, ...restProps
                     }) => {


  const [country, _setCountry] = useState(() => parsePhoneCountry(record && record[field]));


  const setCountry = useCallback(phone => _setCountry(parsePhoneCountry(phone)), []);


  const onChange = useCallback(([{ target: { value } = {} }]) => {
    const phone = validator.whitelist(clsx(value), "+0-9");

    setCountry(phone);

    if (typeof onChangeCb === "function") onChangeCb(phone);

    return phone;
  }, [onChangeCb, setCountry]);


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    required,
    validate: {
      validatePhoneFormat,
      ...(requirements && requirements.validate),
    },
  }), [required, requirements]);


  const { name, error, errorType, helperTextOrError, rules } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const endAdornment = useMemo(() => (typeof getEndAdornment === "function" && getEndAdornment(errorType, helperTextOrError))
    || (Boolean(errorEndAdornment && error) && (
      <InputAdornment position="end">
        <Tooltip title={helperTextOrError}>
          <Icon className="text-red">error_outline</Icon>
        </Tooltip>
      </InputAdornment>
    )), [getEndAdornment, errorType, helperTextOrError, errorEndAdornment, error]);


  return (
    <Grid container spacing={spacing || 1} alignItems={alignItems || "flex-start"} className={className}>
      <Grid item xs={12}>
        <Controller
          {...restProps}
          {...getDefaultValueProp(defaultValue)}
          name={name}
          onChange={onChange}
          as={<TextField/>}
          rules={rules}
          label={label}
          inputProps={{
            maxLength: 20,
            "aria-label": label,
          }}
          classes={{
            root: clsx("w-full", !clsx(textFieldClassName).includes("mb-") && "mb-32", textFieldClassName),
          }}
          variant={variant}
          helperText={!loading && helperTextOrError}
          required={Boolean(required)}
          error={!disableError && error}
          InputProps={{
            startAdornment: Boolean(country) && (
              <InputAdornment position="start">
                <FlagIcon className="mr-4" code={country}/>
              </InputAdornment>
            ),
            endAdornment,
          }}
        />
      </Grid>
      {children}
    </Grid>
  );
};

export default React.memo(PhoneNumber);
