import React, { useCallback, useContext } from "react";
import { Grid } from "@material-ui/core";
import texts from "config/texts";
import { dbEnums } from "common/enums";
import { accent, withAccent, withCapital } from "common/helpers";
import { CheckBox, ChipSelect, FormContext, Input, PrivacyPolicy, useTags } from "components/form";


export const tagEntity = dbEnums.tagEntity.COOPERATION;

export const __typename = "alumnus_cooperation";


const maxLength = 1000;

const rowsCount = 5;

const rowsMaxCount = 10;


const _updateFields = {
  privacy: "privacy",
  discount: "discount",
  cooperation: "cooperation",
  tags: "tags",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.privacy, _updateFields.discount],
};


const _fieldAliases = {
  [_updateFields.tags]: "tags",
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


const getDiscountHelperText = discount => (
  <span>
    Ha bekapcsolod, az adatlapod az <i>Alumnus keresőben</i>&nbsp;{accent(texts.discountProvidersName)}&nbsp;
    <i>{texts.discountProvidersName}</i> szűrővel kilistázott találatok között is megjelenik.

    {Boolean(discount) && (
      <span>
        <br/><br/><b>Vegyél fel egy vagy több kulcsszót, amelyek {withAccent(texts.discountName)} tárgyát jelölik! Egyúttal a leírás
        mezőben add meg a szükséges információkat a kedvezménynyújtás feltételeivel, részleteivel
        kapcsolatban!</b> Az <i>Alumnus keresőben</i> mind a kulcsszavak, mind pedig leírás alapján megtalálható leszel.

        <br/><br/><b>Az adatvédelmi beállítás erre is vonatkozik!</b> Pl. osztálytárs láthatóság beállítása esetén csak
        az osztálytársak fogják látni, hogy {texts.discountProviderName} vagy.
      </span>
    )}
  </span>
);


const getTagsHelperText = discount => (
  <span>
    Pl. <span className="text-blue">tréning</span> vagy <span className="text-blue">jogi tanácsadás</span>. A lenyíló
    listában a leggyakrabban megadott kulcsszavak közül is választhatsz. Ezek alapján is megtalálható leszel
    az <i>Alumnus keresőben</i>.
    {Boolean(discount) && (
      <span>
        <b>&nbsp;{accent(texts.discountProvidersName, true)} <i>{texts.discountProvidersName}</i> szűrővel
          kilistázott találati kártyádon az itt megadott kulcsszavak lesznek láthatók.</b><br/><br/>
      </span>
    )}
  </span>
);


const getCooperationHelperText = discount => (
  <span>
    {Boolean(discount) && (
      <span>
        <b>Add meg, hogy mire, milyen feltételekkel vonatkozik {withAccent(texts.discountName)}!</b> Az itt
        megadott leírás az adatlapodon lesz olvasható.<br/><br/>
      </span>
    )}

    Milyen <i>együttműködési lehetőségekre</i> lennél nyitott? Miben tudnál <i>segítséget nyújtani</i>? Üzleti vagy
    bármilyen más területen. Legfeljebb {maxLength} karakterben. Az itt leírtak alapján is megtalálható leszel
    az <i>Alumnus keresőben</i>.

    <br/><br/>Ahhoz, hogy
    az adatlapon a <i>webes hivatkozások</i> kattinthatók legyenek, mindig teljes URL-t adj meg! Pl. a <span
    className="text-blue">https://example.com</span> megfelelő, a <span className="text-red">example.com</span> viszont
    nem.
  </span>
);


const Cooperation = ({ record, prefixedId, discountField }) => {

  const { getValue } = useContext(FormContext);


  const { loading, tags } = useTags(tagEntity);


  const getDiscount = useCallback(() => getValue(record && record.__typename, prefixedId, discountField)
    , [discountField, getValue, prefixedId, record]);


  if (loading) return null;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <PrivacyPolicy record={record}
                       field={_updateFields.privacy}
                       tooltip="Az együttműködési lehetőségekre"
                       className="mb-24"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckBox record={record}
                  field={_updateFields.discount}
                  className="mb-32"
                  title={`${withCapital(texts.discountNameAccusative)} nyújtok a diáktársaknak`}
                  helperText={getDiscountHelperText(getDiscount())}
        />
      </Grid>
      <Grid item xs={12}>
        <ChipSelect record={record}
                    field={_updateFields.tags}
                    label="Kulcsszavakban"
                    options={tags}
                    helperText={getTagsHelperText(getDiscount())}
                    className="mb-16"
        />
      </Grid>
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.cooperation}
               label="Ajánlatok, webes hivatkozásokkal"
               helperText={getCooperationHelperText(getDiscount())}
               className="mb-8"
               maxLength={maxLength}
               rows={rowsCount}
               rowsMax={rowsMaxCount}
               multiline
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Cooperation);
