import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { IconButton, FormControl, Select, Input, MenuItem, Grid, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDown, faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { sortTypes } from "client/lib/providers/uiState";
import { emitUiStateEvent, uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { SearchContext } from "client/lib/providers/SearchProvider";


const SortFilter = () => {

  const { setSortType, toggleSortDesc, sortType, sortDesc } = useContext(SearchContext);


  const [localSortType, setLocalSortType] = useState(sortType);

  const [localSortDesc, setLocalSortDesc] = useState(sortDesc);


  const toggleIcon = useMemo(() => localSortDesc ? faSortAmountDown : faSortAmountDownAlt, [localSortDesc]);


  const handleSortTypeChange = useCallback(event => {
    const sortType = event.target.value;
    emitUiStateEvent(uiStateEvents.search.loadingStarted);
    setLocalSortType(sortType);
    setSortType(sortType);
  }, [setSortType]);


  const handleToggleSortDesc = useCallback(() => {
    emitUiStateEvent(uiStateEvents.search.loadingStarted);
    setLocalSortDesc(sortDesc => !sortDesc);
    toggleSortDesc();
  }, [toggleSortDesc]);


  const onUpdateFinished = useCallback(() => {
    if (sortType !== localSortType) setLocalSortType(sortType);
    if (sortDesc !== localSortDesc) setLocalSortDesc(sortDesc);
  }, [localSortDesc, localSortType, sortDesc, sortType]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.search.updateFinished, onUpdateFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.search.updateFinished, onUpdateFinished);
    }
  }, [onUpdateFinished]);


  return (
    <Grid container spacing={1} justify="flex-end" alignItems="center" wrap="nowrap">
      <Grid item>
        <Tooltip title="Rendezés">
          <FormControl>
            <Select
              value={localSortType || sortTypes.byRelevance.id}
              onChange={handleSortTypeChange}
              input={
                <Input
                  id="sorttype-label-placeholder"
                  className="text-13 sm:text-14"
                />
              }
            >
              <MenuItem value={sortTypes.byRelevance.id}
                        key={sortTypes.byRelevance.id}>{sortTypes.byRelevance.label}</MenuItem>
              <MenuItem value={sortTypes.byFullName.id}
                        key={sortTypes.byFullName.id}>{sortTypes.byFullName.label}</MenuItem>
            </Select>
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item>
        <IconButton onClick={handleToggleSortDesc}>
          <FontAwesomeIcon className={clsx("text-18", sortDesc && "text-blue")}
                           icon={toggleIcon}
                           color="rgba(0,0,0,0.3)"/>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(SortFilter);
