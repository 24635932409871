import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { GET_SPECIAL_ACCOUNTS } from "./queries";


const delSpecialAccount = async (_root, { id }, { client }) => {
  const { error } = await backendFetch(backendServices.account.deleteSpecialAccount, {
    id,
  });
  if (error) throw new Error();

  try {
    const { accounts: cachedAccounts } = client.readQuery({ query: GET_SPECIAL_ACCOUNTS });
    client.writeQuery({
      query: GET_SPECIAL_ACCOUNTS,
      data: {
        accounts: (cachedAccounts || []).filter(({ id: cachedId }) => cachedId !== id)
      },
    });

  } catch (e) {
    errorLogStore("specialAccounts", "delSpecialAccount", e);
    throw e;
  }
};

export default delSpecialAccount;
