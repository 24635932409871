import gql from "graphql-tag";


export const DOWNLOAD_CSV = gql`
  mutation DownloadContactsCSV {
    returning: downloadContactsCSV @client {
      error
    }
  }
`;


export const DOWNLOAD_XLSX = gql`
  mutation DownloadContactsXLSX {
    returning: downloadContactsXLSX @client {
      error
    }
  }
`;
