import account from "./account";
import alumnus from "./alumnus";
import alumnus_address from "./alumnus_address";
import alumnus_name from "./alumnus_name";
import alumnus_picture from "./alumnus_picture";
import alumnus_status from "./alumnus_status";
import country from "./country";
import email from "./email";
import job from "./job";
import phone from "./phone";
import social from "./social";
import study from "./study";
import tag from "./tag";
import view_account_resctricted_manager from "./view_account_resctricted_manager";
import view_action_log from "./view_action_log";
import view_alumnus_org_restricted_me from "./view_alumnus_org_restricted_me";
import alumnusWGroupmates from "./alumnusWGroupmates";
import ipsWCountries from "./ipsWCountries";


export { safeGroup } from "./alumnus";
export { fullName } from "./alumnus_name";
export { revisionStatuses } from "./alumnus_status";

export { fetchAlumnus } from "./getAlumnus";


export const getters = {
  account,
  alumnus,
  alumnus_address,
  alumnus_cooperation: tag,
  alumnus_hobby: tag,
  alumnus_name,
  alumnus_picture,
  alumnus_secondary_address: alumnus_address,
  alumnus_status,
  country,
  email: {
    ...email,
    ...tag,
  },
  job,
  phone: {
    ...phone,
    ...tag,
  },
  social,
  study,
  view_account_resctricted_manager,
  view_action_log,
  view_alumnus_org_restricted_me,
  Query: {
    alumnusWGroupmates,
    ipsWCountries,
  },
};
