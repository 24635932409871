import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { newRecordIdSuffix } from "components/form/config";
import { __typename, _updateFields, getRandomCode } from "components/form/specialAccount";


import { useOpenDialog } from "components/listPages";
import EditSpecialAccount from "./EditSpecialAccount"


const getEmptyRecord = () => ({
  id: `1${newRecordIdSuffix}`,
  [_updateFields.password]: getRandomCode(),
  [_updateFields.username]: "",
  [_updateFields.role]: dbEnums.accountRoles.STUDENT,
  [_updateFields.note]: "",
  __typename,
});


const getContentComponent = () => (
  <EditSpecialAccount isNew {...getEmptyRecord()}/>
);


const AddButton = () => {

  const add = useOpenDialog({
    title: "Új speciális felhasználói fiók létrehozása",
    ContentComponent: getContentComponent(),
  });

  return (
    <Tooltip title="Új speciális felhasználó hozzáadása">
      <IconButton size="small"
                  onClick={add}
      >
        <Icon>add</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(AddButton);
