import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Grid, Input, FormControl, IconButton, Icon, Tooltip, Hidden } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SearchContext } from "client/lib/providers/SearchProvider";
import {
  emitUiStateEvent,
  emitUiStateEventNextTick,
  uiStateEventEmitter,
  uiStateEvents
} from "client/lib/providers/UiStateProvider";


const SearchTextFilter = () => {

  const { isLoading, setSearchText, searchText } = useContext(SearchContext);

  const [localSearchText, setLocalSearchText] = useState(searchText);


  const ref = useRef();


  const theme = useTheme();

  const screenSmUp = useMediaQuery(theme.breakpoints.up("sm"));


  const onChange = useCallback(event => {
    const searchText = clsx(event.target.value);
    setLocalSearchText(searchText);
  }, []);


  const onBlur = useCallback(() => setSearchText(localSearchText), [localSearchText, setSearchText]);


  const onClear = useCallback(() => {
    setLocalSearchText("");
    setSearchText("");
  }, [setSearchText]);


  const onEnterKey = useCallback(event => {
    if (event.keyCode === 13) {
      onBlur();
      emitUiStateEventNextTick(uiStateEvents.search.updateStarted);
    }
  }, [onBlur]);


  const onUpdateStarted = useCallback(() => searchText !== localSearchText && setLocalSearchText(searchText)
    , [localSearchText, searchText]);


  const onRefresh = useCallback(() => emitUiStateEvent(uiStateEvents.search.updateStarted), []);


  const endAdornment = useMemo(() => (
    <Grid container spacing={1} alignItems="center" justify="flex-end"
          className={localSearchText ? "w-148 md:w-136" : "w-48 md:w-64"}>
      {Boolean(localSearchText) && (
        <Hidden xsDown>
          <Grid item>
            <Tooltip title="Szűrő törlése" placement="bottom">
              <div>
                <IconButton onClick={onClear} disabled={isLoading}>
                  <Icon>clear</Icon>
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        </Hidden>
      )}
      <Grid item>
        <Tooltip title="Keresés indítása" placement="bottom">
          <div>
            <IconButton onClick={onRefresh} disabled={isLoading}>
              <Icon>search</Icon>
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  ), [isLoading, localSearchText, onClear, onRefresh]);


  useEffect(() => {
    const target = ref && ref.current;
    if (target) {
      target.addEventListener("keydown", onEnterKey, false);
      return () => {
        target.removeEventListener("keydown", onEnterKey, false);
      }
    }
  }, [onEnterKey]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.search.updateStarted, onUpdateStarted);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.search.updateStarted, onUpdateStarted);
    }
  }, [onUpdateStarted]);


  return (
    <FormControl className="w-full">
      <Input
        endAdornment={endAdornment}
        placeholder={screenSmUp ? "Keresés az alumnusok adataiban" : "Keresés"}
        className="w-full mx-4"
        value={localSearchText}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isLoading}
        inputRef={ref}
        inputProps={{
          "aria-label": "keresés",
          className: "text-13 sm:text-14",
        }}
        disableUnderline
      />
    </FormControl>
  );
};

export default React.memo(SearchTextFilter);
