import memoizee from "memoizee";
import clsx from "clsx";
import { emitUiStateEvent, emitUiStateEventNextTick, uiStateEvents } from "client/lib/providers/UiStateProvider";


export const normalizeYear = memoizee((year, minYear, maxYear) => {
  const yearStr = clsx(year).trim();
  if (!yearStr) return 0;

  const yearNumber = Math.floor(parseInt(yearStr));
  const yearPositiveNumber = Math.max(yearNumber, 0);
  if (clsx(yearPositiveNumber).length < 4) return yearPositiveNumber;

  const yearMaxLimited = Math.min(yearPositiveNumber, maxYear);
  return Math.max(yearMaxLimited, minYear);
});


export const handlerWrapper = cb => {
  emitUiStateEvent(uiStateEvents.search.loadingStarted);
  if (typeof cb === "function") cb();
  emitUiStateEventNextTick(uiStateEvents.search.updateStarted);
};
