import { Add, Build, Check, Delete, HelpOutline } from "@material-ui/icons";
import { dbEnums, labels } from "common/enums";
import { dateStr } from "common/helpers";


const cardDetails = {
  [dbEnums.cardStatus.REQUESTED]: {
    statusStr: labels.cardStatus[dbEnums.cardStatus.REQUESTED],
    icon: "priority_high",
    color: "warning",
    avatar: Add,
  },
  [dbEnums.cardStatus.PREPARATION]: {
    statusStr: labels.cardStatus[dbEnums.cardStatus.PREPARATION],
    icon: "build",
    color: "warning",
    avatar: Build,
  },
  [dbEnums.cardStatus.ACTIVE]: {
    statusStr: labels.cardStatus[dbEnums.cardStatus.ACTIVE],
    icon: "check",
    color: "success",
    avatar: Check,
  },
  [dbEnums.cardStatus.REVOKED]: {
    statusStr: labels.cardStatus[dbEnums.cardStatus.REVOKED],
    icon: "pan_tool",
    color: "danger",
    avatar: Delete,
  },
  _default: {
    statusStr: "Ismeretlen státusz",
    icon: "help_outline",
    avatar: HelpOutline,
  },
};


export const extendedCards = ({ cards }) => (cards && cards.map(card => {
  const details = cardDetails[card.status] || cardDetails._default;
  return {
    id: card.id,
    status: {
      ...details,
      status: card.status
    },
    nr: card.nr,
    issueDate: dateStr(card.issueDate),
    exp: dateStr(card.exp),
  };
}) || null);
