import React, { useCallback, useContext, useEffect, useRef } from "react";
import { legacyHideSplashId } from "pages/_document";
import { Splash } from "components/common";
import { ModuleLoaderContext } from "./ModuleLoader";


const SplashScreen = () => {

  const { splashScreen } = useContext(ModuleLoaderContext);
  const splashWrapperRef = useRef(null);

  const hideNode = useCallback(ref => {
    const element = ref && ref.current;
    if (element) {
      element.style.visibility = "hidden";
      element.style.display = "none";
    }
  }, []);

  // hide splash screen and legacy script tag if useEffect is working (in modern browsers)
  useEffect(() => {
    if (!splashScreen) {
      const legacyHideSplash = window.document.getElementById(legacyHideSplashId);
      if (legacyHideSplash) legacyHideSplash.remove();

      hideNode(splashWrapperRef);
    }
  }, [hideNode, splashScreen]);

  return (
    <div ref={splashWrapperRef}>
      <Splash/>
    </div>
  );
};

export default React.memo(SplashScreen);
