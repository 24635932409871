import { useCallback, useContext, useEffect, useMemo } from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { fetchAlumnus } from "client/store/alumnus/resolvers/getters";
import { GET_ALUMNUS_GROUP } from "client/store/alumnus/queries";
import { UPDATE_ALUMNUS_ORG } from "client/store/alumnus/mutations";
import { __typename } from "components/form/org";


const statementId = "approvedOrg";


const useOrgStep = ({ stepStatements, toggleStatement, registerSubmitCb }) => {

  const { personal: { alumnusId } } = useContext(SessionContext);


  const { loading, error, data: { members } = {} } = useQuery(GET_ALUMNUS_GROUP, {
    skip: !alumnusId,
    variables: {
      ids: [alumnusId]
    },
  });


  const client = useApolloClient();


  const { org: { id, me: { dateOfBirth, placeOfBirth, mothersName } = {} } = {} } = useMemo(() => (!loading && !error && members && members[0]) || {}, [loading, error, members]);


  const org = useMemo(() => ({
    id,
    dateOfBirth,
    placeOfBirth,
    mothersName,
    __typename,
  }), [dateOfBirth, id, mothersName, placeOfBirth]);


  const statement = useMemo(() => Boolean(stepStatements && stepStatements.includes(statementId))
    , [stepStatements]);


  const toggleApprove = useCallback(() => toggleStatement(statementId), [toggleStatement]);


  const validateChanges = useCallback(changes => Object.values(changes || {}).find(change => !change), []);


  const saveOrg = useCallback(async jobs => {
    const { id, ...changes } = (jobs[__typename] || [])[0] || {};
    if (!id || validateChanges(changes)) return true;

    const { error } = await client.mutate({
      mutation: UPDATE_ALUMNUS_ORG,
      variables: {
        id,
        changes,
      },
    });

    if (!error) {
      await backendFetch(backendServices.account.hydrateSessions);
      await fetchAlumnus(alumnusId, client, true);
    }

    return error;
  }, [alumnusId, client, validateChanges]);


  const saveCb = useCallback(async ({ jobs, jobsCount }) => {
    if (jobsCount) {
      const error = await saveOrg(jobs);
      if (error) return { error };
    }

    return {
      noMessage: true,
      noStopLoading: true,
      ignore: true,
    };
  }, [saveOrg]);


  const onSubmit = useCallback(() => emitUiStateEvent(uiStateEvents.form.submitStarted), []);


  useEffect(() => {
    if (typeof registerSubmitCb === "function") registerSubmitCb(onSubmit);
  }, [registerSubmitCb, onSubmit]);


  return {
    loading,
    error,
    org,
    saveCb,
    statement,
    toggleApprove,
  };
};

export default useOrgStep;
