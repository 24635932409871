import React from "react";
import { Typography } from "@material-ui/core";
import { codeTypeConfig, codeTypes } from "server/lib/verify/code/config";


const BannedIps = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon a rendszer által eszközölt <b>IP cím kitiltásokat</b> adminisztrálhatod.
    </Typography>
    <Typography variant="subtitle1" className="mt-24">Jogosultság megerősítése</Typography>
    <Typography className="my-20">
      Mivel az IP cím kitiltás feloldása kritikus fontosságú adminisztrátori művelet, ezért a <b>feloldásokhoz előbb
      meg kell adnod a kapcsolattartási telefonszámodra érkező SMS megerősítő kódot</b>. A megerősítő kód megadását
      követően <b>{codeTypeConfig[codeTypes.ADMIN_ACTION].verificationExpirationInMinutes} percig leszel
      jogosult</b> kitiltások feloldására &ndash; vagy más lapokon egyéb kritikus adminisztrátori műveletek
      végrehajtására. Ennek lejártával új megerősítő kód szükséges.
    </Typography>
    <Typography variant="subtitle1" className="mt-24">Automatikus kitiltások</Typography>
    <Typography className="mt-20">
      A rendszer automatikusan <span
      className="text-blue">kitiltja a látogató/bejelentkezett felhasználó IP címét</span>, amennyiben a bejelentkezés
      vagy bármely egyéb művelet során <b>sorozatosan megsérti a biztonsági protokollt</b> (pl. <i>a többször
      hibásan megadott jelszó</i>). Amennyiben a látogató/bejelentkezett felhasználó IP címe kitiltásra kerül:
    </Typography>
    <ul>
      <li className="mb-12"><b>Meg kell várnia a kitiltás lejáratát</b>, amely <i>első alkalommal 2 perc</i>. Ezt
        követően <b>újrapróbálkozhat</b> a művelettel.
      </li>
      <li className="mb-12">Ha a következő alkalommal már <b>megfelelően végzi el a műveletet</b> (pl. <i>megfelelő
        jelszót ad meg</i>), akkor <b>a kitiltás automatikusan törlésre kerül</b>.
      </li>
      <li className="mb-12">Ha a <b>következő alkalommal is megsérti a biztonsági protokollt</b> (pl. <i>ismét rossz
        jelszót ad meg</i>), akkor <b>a kitiltás ideje progresszív jelleggel nő</b>: újabb <i>5</i>, <i>10</i>, <i>30
        perc</i>, ezt követően <i>1</i>, <i>2</i>, <i>12</i>, <i>24</i>, végül pedig <i>48 óra</i>. A következő kitiltás
        már <i>határozatlan időre</i> szól.
      </li>
    </ul>
    <Typography className="mt-20">
      A hibásan megadott bejelentkezési adatokon kívül a biztonsági protokoll egyéb műveletekkel is megsérthető (pl. a
      biztonsági rendszer megkerülésére, manipulálására tett kísérletkor). Egyes esetekben a rendszer már az első
      alkalommal határozatlan idejű kitiltást eszközöl.
    </Typography>
    <Typography className="mt-20">
      A kitiltásról a rendszer <b>minden esetben tájékoztatja a látogatót/bejelentkezett felhasználót, beleértve magát a
      kitiltott IP címet és a kitiltás határidejét is</b>. Számára két lehetőség adódik: vagy megvárja a kitiltás
      lejártát, vagy felveszi a kapcsolatot az Alumnival. Utóbbi esetben fontos, hogy <b>tudassa a kitiltott IP
      címet</b> az adminisztrátorral, aki <b>ezen a lapon </b>&ndash; a megfelelő listaelemek kijelölésével
      (jelölőnégyzettel vagy a sorra kattintással), majd a <span
      className="text-blue">&quot;Kijelölt IP kitiltások törlése&quot;</span> gombra kattintva &ndash; <b>tudja
      feloldani a kitiltás(oka)t</b>.
    </Typography>
  </>
);

export default React.memo(BannedIps);
