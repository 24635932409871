import React, { useContext, useMemo } from "react";
import dynamic from "next/dynamic";
import { dbEnums } from "common/enums";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { AlumnusContext } from "components/alumnus";

const EditAccount = dynamic(() => import("./adminTab/EditAccount"));
const EditOrg = dynamic(() => import("./adminTab/EditOrg"));
const EditCards = dynamic(() => import("./adminTab/EditCards"));

const EditAddress = dynamic(() => import("./contactTab/EditAddress"));
const EditEmail = dynamic(() => import("./contactTab/EditEmail"));
const EditPhone = dynamic(() => import("./contactTab/EditPhone"));
const EditSocial = dynamic(() => import("./contactTab/EditSocial"));

const EditAvatar = dynamic(() => import("./nameTab/EditAvatar"));
const EditName = dynamic(() => import("./nameTab/EditName"));

const EditCooperation = dynamic(() => import("./networkingTab/EditCooperation"));
const EditHobby = dynamic(() => import("./networkingTab/EditHobby"));
const EditJob = dynamic(() => import("./networkingTab/EditJob"));
const EditStudy = dynamic(() => import("./networkingTab/EditStudy"));

const EditNewsletter = dynamic(() => import("./subscriptionsTab/EditNewsletter"));


const EditContent = () => {

  const { account: { roles } } = useContext(SessionContext);

  const { page, tab } = useContext(AlumnusContext);


  const adminMode = useMemo(() => roles.includes(dbEnums.accountRoles.MANAGER), [roles]);


  return (
    <div className="p-16 sm:p-24">
      {page === 0 && tab === 0 &&
      (
        <EditEmail/>
      )}
      {page === 0 && tab === 1 &&
      (
        <EditPhone/>
      )}
      {page === 0 && tab === 2 &&
      (
        <EditAddress/>
      )}
      {page === 0 && tab === 3 &&
      (
        <EditSocial/>
      )}
      {page === 1 && tab === 0 &&
      (
        <EditName/>
      )}
      {page === 1 && tab === 1 &&
      (
        <EditAvatar/>
      )}
      {page === 2 && tab === 0 &&
      (
        <EditJob/>
      )}
      {page === 2 && tab === 1 &&
      (
        <EditStudy/>
      )}
      {page === 2 && tab === 2 &&
      (
        <EditHobby/>
      )}
      {page === 2 && tab === 3 &&
      (
        <EditCooperation/>
      )}
      {page === 3 && tab === 0 &&
      (
        <EditNewsletter/>
      )}
      {page === 4 && tab === 0 && adminMode &&
      (
        <EditOrg/>
      )}
      {page === 4 && tab === 1 && adminMode &&
      (
        <EditCards/>
      )}
      {page === 4 && tab === 2 && adminMode &&
      (
        <EditAccount/>
      )}
    </div>
  );
};

export default React.memo(EditContent);
