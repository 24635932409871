import React, { useCallback, useContext } from "react";
import nextTick from "next-tick";
import momentTz from "moment-timezone";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { UiMessages } from "config/messages";
import { accent } from "common/helpers";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { useConfirm } from "components/common";


const getConfirmOptions = ({ username }) => ({
  title: "Jelszóváltoztatási link kiküldése",
  content: (
    <span>Biztosan kiküldesz egy jelszóváltoztatási linket {accent(username)} <b>{username}</b> felhasználónévhez tartozó fiókhoz?</span>
  ),
  OKButton: "Igen, kiküldöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
});


const PswrLinkButton = specialAccount => {

  const { message: { showMessage } } = useContext(UiStateContext);


  const confirm = useConfirm({
    confirmOptions: getConfirmOptions(specialAccount),
  });


  const sendLink = useCallback(async () => {
    const { error } = await backendFetch(backendServices.pswrLink.sendAdminLink, {
      id: specialAccount.id,
      tz: momentTz.tz.guess(true),
    });

    nextTick(() => showMessage(error ? UiMessages.form.submit.saveError : UiMessages.specialAccounts.pswrLinkSent));
  }, [specialAccount.id, showMessage]);


  const onClick = useCallback(() => confirm(sendLink), [confirm, sendLink]);


  return (
    <Tooltip title="Jelszóváltoztatási link kiküldése">
      <span>
        <IconButton size="small"
                    onClick={onClick}
        >
          <Icon>autorenew</Icon>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default React.memo(PswrLinkButton);
