import React, { useContext, useMemo, useState } from "react";
import clsx from "clsx";
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, Icon } from "@material-ui/core";
import { alumnusRouteConfig, modes } from "config/profile";
import { useFilterByRoles } from "client/lib/session";
import { AlumnusContext } from "components/alumnus";


const ProfileMenu = ({ className }) => {

  const { myProfile, setRoute } = useContext(AlumnusContext);


  const [anchorEl, setAnchorEl] = useState(null);


  const buttonTitle = useMemo(() => myProfile ? "Adataim módosítása" : "Adatok módosítása", [myProfile]);


  const editPages = useFilterByRoles({
    config: alumnusRouteConfig[modes.edit],
    childrenKey: "tabs",
  });


  const options = useMemo(() => editPages.filter(({ disallowed }) => !disallowed), [editPages]);


  const items = useMemo(() => options.map(({ title, icon }, index) => {
    const onClick = () => setRoute({
      editMode: true,
      page: index,
    });

    return (
      <MenuItem key={index} onClick={onClick}>
        <ListItemIcon className="min-w-40">
          <Icon>{icon || "chevron_right"}</Icon>
        </ListItemIcon>
        <ListItemText primary={title}/>
      </MenuItem>
    )
  }), [options, setRoute]);


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={clsx(className)}>

      <Button className="normal-case" variant="contained" color="secondary"
              aria-label={buttonTitle} onClick={handleClick}>{buttonTitle}</Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items}
      </Menu>
    </div>
  );
};

export default React.memo(ProfileMenu);
