import clsx from "clsx";
import serializeJson from "serialize-javascript";
import { onError } from "apollo-link-error";
import { consoleError } from "client/lib/errorLog";


const logGraphQLError = ({ message, locations, path }) =>
  consoleError(`[GraphQL error] Message: ${message}${clsx(locations && `, Location: ${locations}`)}${clsx(locations && `, Path: ${path}`)}`);


const logNetworkError = networkError => consoleError(`[GraphQL network error]: ${serializeJson(networkError)}`);


export const logErrorLink = onError(async ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.map(logGraphQLError);
  if (networkError) logNetworkError(networkError);
});
