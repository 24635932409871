import { errorLogLib } from "client/lib/errorLog";
import { createStore, destroyStore } from "client/store";
import { getSession, setSession } from "./session";


export const updateSessionStore = async recreate => {
  try {
    const { storeClient: previousClient } = getSession();
    if (previousClient && !recreate) return true;

    const storeClient = createStore();
    if (!storeClient) return false;

    await setSession({ storeClient });
    return true;

  } catch (e) {
    errorLogLib("session", "updateSessionStore", e);
    return false;
  }
};


export const destroySessionStore = async () => {
  await setSession({ storeClient: null });

  destroyStore();
};
