import memoizee from "memoizee";
import clsx from "clsx";
import { normalizeStr } from "common/helpers";


export const parseRows = data => (data || [])
  .map(row => row && typeof row === "object" ? row : undefined)
  .filter(row => row !== undefined);


export const getIds = (rawRows, idField) => idField ? (rawRows || []).map(row => row[idField]) : [];


export const filterRows = (rawRows, idField, page, pageSize, filteredIds) => {
  const start = Math.max(0, page - 1) * pageSize;
  const end = page * pageSize;

  const ids = filteredIds.slice(start, end);

  return (rawRows || []).filter(row => ids.includes(row[idField]));
};


export const onCheckboxClick = ev => ev.stopPropagation();


export const normalizePage = memoizee((page, numPages) => {
  const pageStr = clsx(page).trim();
  if (!pageStr) return 1;

  const pageNumber = Math.floor(parseInt(pageStr));
  const pageMaxLimited = Math.min(pageNumber, numPages);
  return Math.max(pageMaxLimited, 1);
});


const normalizeValue = memoizee((value, caseSensitive) => normalizeStr(value, !caseSensitive));


const filterValue = memoizee((normalizedSearchStr, fieldValue, strict, caseSensitive) => {
  const normalizedFieldValue = normalizeValue(fieldValue, caseSensitive);

  return strict
    ? normalizedFieldValue === normalizedSearchStr
    : normalizedFieldValue.includes(normalizedSearchStr);
});


const getFilterTestFn = (fieldNames, { strict, caseSensitive } = {}) => (searchStr, record) => {
  const normalizedSearchStr = normalizeValue(searchStr, caseSensitive);
  if (!normalizedSearchStr || !record) return true;

  return fieldNames.some(fieldName => filterValue(normalizedSearchStr, record[fieldName], strict, caseSensitive));
};


export const getFilterTestFnObj = (fieldNames, config) => ({
  testFn: getFilterTestFn(Array.isArray(fieldNames) ? fieldNames : [fieldNames], config),
});
