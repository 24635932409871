import validator from "validator";
import clsx from "clsx";


const href = ({ email }) => {
  const normalizedEmail = clsx(email).trim();
  if (!validator.isEmail(normalizedEmail)) return null;
  return encodeURI(`mailto:${normalizedEmail}`) || null;
};


const email = {
  href,
};

export default email;
