// all values must be uppercased

export const accountRoles = {
  ALUMNUS: "ALUMNUS",
  RETAILER: "RETAILER",
  STUDENT: "STUDENT",
  MANAGER: "MANAGER",
};


export const accountStatus = {
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
  DELETED: "DELETED",
};


export const action = {
  LOGIN: "LOGIN",
  UPDATE: "UPDATE",
  PSW_CHANGE: "PSW_CHANGE",
  PSW_CHANGE_W_TOKEN: "PSW_CHANGE_W_TOKEN",
  PSW_RESET: "PSW_RESET",
  REGISTRATION: "REGISTRATION",
  REVISION: "REVISION",
  ACCOUNT_ST_CHANGE: "ACCOUNT_ST_CHANGE",
  BANS_DELETED: "BANS_DELETED",
};


export const membership = {
  GRADUATED: "GRADUATED",
  NONGRADUATED: "NONGRADUATED",
  TEACHER: "TEACHER",
  HONORARY: "HONORARY",
};


export const tagEntity = {
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  COOPERATION: "COOPERATION",
  HOBBY: "HOBBY",
};


export const cardStatus = {
  REQUESTED: "REQUESTED",
  PREPARATION: "PREPARATION",
  ACTIVE: "ACTIVE",
  REVOKED: "REVOKED",
};


export const socialSite = {
  UNKNOWN: "UNKNOWN",
  FACEBOOK: "FACEBOOK",
  INSTAGRAM: "INSTAGRAM",
  LINKEDIN: "LINKEDIN",
  PINTEREST: "PINTEREST",
  SKYPE: "SKYPE",
  YOUTUBE: "YOUTUBE",
  TWITTER: "TWITTER",
};


export const searchCategories = {
  ALL: "ALL",
  NAME_GROUP: "NAME_GROUP",
  FORM_TEACHER: "FORM_TEACHER",
  CONTACT: "CONTACT",
  HOBBY: "HOBBY",
  DISCOUNT: "DISCOUNT",
  PROFESSIONAL: "PROFESSIONAL",
};
