import moment from "moment";
import { labels } from "common/enums";


const membershipStr = ({ mem }) => labels.membership[mem] || "Érvénytelen";


const fullBirth = ({ dateOfBirth, placeOfBirth }) => {
  const fDateOfBirth = dateOfBirth
    ? moment(dateOfBirth).format("L")
    : "[Hiányzó adat: születés ideje]";

  const fPlaceOfBirth = placeOfBirth
    ? `${placeOfBirth.trim()},`
    : "[Hiányzó adat: születés helye],";

  return [fPlaceOfBirth, fDateOfBirth].join(" ").trim();
};


const view_alumnus_org_restricted_me = {
  membershipStr,
  fullBirth,
};

export default view_alumnus_org_restricted_me;
