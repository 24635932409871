import clsx from "clsx";
import moment from "moment";
import { normalizeStr } from "common/helpers";
import { getSession } from "client/lib/session";
import { fetchGroupMembers } from "./fetch";


const headers = {
  name: "Név",
  aFullAddress: "Levelezési cím (teljes)",
  aCountry: "Ország",
  aZip: "Irányítószám",
  aCity: "Település",
  aAddress: "Cím",
  aAddressName: "Címzett neve (ha eltér a névtől)",
  sFullAddress: "Másodlagos cím (teljes)",
  sCountry: "Ország 2",
  sZip: "Irányítószám 2",
  sCity: "Település 2",
  sAddress: "Cím 2",
  sAddressName: "Címzett neve (ha eltér a névtől) 2",
  _email: "Email cím",
  _phone: "Telefon",
  _notes: "(megjegyzés)",
};


const mapRawMembers = members => {
  let maxEmails = 1;
  let maxPhones = 1;
  const rawMembers = (members && members.map(member => {
    const {
      name: { fullName: name },
      address,
      sAddress,
      emails,
      phones,
    } = member;

    const { countryName: aCountry, zip: aZip, city: aCity, address: aAddress, name: aAddressName, full: aFullAddress } = address || {};
    const { countryName: sCountry, zip: sZip, city: sCity, address: _sAddress, name: sAddressName, full: sFullAddress } = sAddress || {};

    if (emails && emails.length > maxEmails) maxEmails = emails.length;
    if (phones && phones.length > maxPhones) maxPhones = phones.length;

    return {
      [headers.name]: name,
      [headers.aFullAddress]: clsx(aFullAddress),
      [headers.aCountry]: clsx(aCountry),
      [headers.aZip]: clsx(aZip),
      [headers.aCity]: clsx(aCity),
      [headers.aAddress]: clsx(aAddress),
      [headers.aAddressName]: clsx(aAddressName),
      [headers.sFullAddress]: clsx(sFullAddress),
      [headers.sCountry]: clsx(sCountry),
      [headers.sZip]: clsx(sZip),
      [headers.sCity]: clsx(sCity),
      [headers.sAddress]: clsx(_sAddress),
      [headers.sAddressName]: clsx(sAddressName),
      emails,
      phones,
    }
  })) || [];

  return {
    rawMembers,
    maxEmails,
    maxPhones,
  }
};


const getNotes = (tagsW, note) => {
  const { str } = tagsW || {};
  const notes = (str && note)
    ? [str, note].join("; ")
    : str || note;
  return normalizeStr(notes);
};


const parseEmails = emails => (emails && emails.map(
  ({ email, tagsW, note }) => {
    const notes = getNotes(tagsW, note);
    return {
      item: email,
      notes,
    };
  }
)) || [];


const parsePhones = phones => (phones && phones.map(
  ({ phone, parsed: { normalized } = {}, tagsW, note }) => {
    const notes = getNotes(tagsW, note);
    return {
      item: normalized || phone,
      notes,
    };
  }
)) || [];


const reduceItems = (items, baseKey) => (items && items.reduce((acc, item) => {
  const nextNum = acc.nextNum + 1 || 1;
  const numStr = nextNum.toString();
  const keyItem = [baseKey, numStr].join(" ").trim();
  const keyNotes = [keyItem, headers._notes].join(" ").trim();

  const itemsAcc = {
    ...acc.items,
    [keyItem]: item.item,
  };
  if (item.notes) itemsAcc[keyNotes] = item.notes;

  return {
    items: itemsAcc,
    nextNum,
  }
}, {}));


const mapEmails = ({ emails }) => {
  const parsedEmails = parseEmails(emails);
  const reduced = reduceItems(parsedEmails, headers._email);
  return reduced && reduced.items;
};


const mapPhones = ({ phones }) => {
  const parsedPhones = parsePhones(phones);
  const reduced = reduceItems(parsedPhones, headers._phone);
  return reduced && reduced.items;
};


const normalizeMembers = rawMembers => rawMembers.map((member => {
  const emails = mapEmails(member);
  const phones = mapPhones(member);
  const normalized = {
    ...member,
    ...emails,
    ...phones,
  };
  delete normalized.emails;
  delete normalized.phones;
  return normalized;
}));


const getDynamicHeaders = (baseHeader, maxNum) => [...Array(maxNum || 0).keys()].reduce((dynamicHeaders, i) => {
  const title = [baseHeader, i].join(" ").trim();
  const titleNotes = [title, headers._notes].join(" ").trim();
  return [...dynamicHeaders, title, titleNotes];
}, []);


const getHeaders = (maxEmails, maxPhones) => {
  const emailsHeaders = getDynamicHeaders(headers._email, maxEmails);
  const phonesHeaders = getDynamicHeaders(headers._phone, maxPhones);
  return [
    headers.name,
    ...emailsHeaders,
    ...phonesHeaders,
    headers.aFullAddress,
    headers.aCountry,
    headers.aZip,
    headers.aCity,
    headers.aAddress,
    headers.aAddressName,
    headers.sFullAddress,
    headers.sCountry,
    headers.sZip,
    headers.sCity,
    headers.sAddress,
    headers.sAddressName,
  ]
};


const getTitle = (group, extended) => {
  const date = moment().format("YYYY-MM-DD");
  const title = extended ? "Alumni Online export" : "Alumni export";
  return [group, title, date].join(" ");
};


const getUserGroup = () => {
  const { personal } = getSession();
  const { grp } = personal || {};
  return grp || null;
};


const exportData = async client => {
  const group = getUserGroup();
  if (!group) return null;

  const fetchedMembers = await fetchGroupMembers({ group }, client);

  const { rawMembers, maxEmails, maxPhones } = mapRawMembers(fetchedMembers);
  const rows = normalizeMembers(rawMembers);
  const fields = getHeaders(maxEmails, maxPhones);
  const title = getTitle(group, false);
  const fileName = getTitle(group, true);

  return {
    rows,
    fields,
    title,
    fileName,
  };
};

export default exportData;
