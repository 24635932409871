import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Grid, Button, Tooltip, Typography } from "@material-ui/core";
import { AlumnusContext } from "components/alumnus";
import FlagIcon from "components/alumnus/FlagIcon";
import { PanelContext } from "components/form";
import { getPrefixedId } from "../helpers";
import { typenames } from "./Address";


const Residency = () => {

  const { alumnus: { sAddress } } = useContext(AlumnusContext);

  const { selectPanel, record: { country, cityAndCountry, fromSecondary, secondaryFilled } } = useContext(PanelContext);


  const jumpToSecondary = useCallback(() => sAddress && selectPanel(typenames.sAddress, getPrefixedId(sAddress.id)), [sAddress, selectPanel]);


  return (
    <Grid container spacing={2} item>
      <Grid item xs={12} className="mb-8">
        <Typography color="textSecondary" className="mb-8 text-13">
          Az adatlapod <span className="text-blue">&quot;Város, ország, ahol él&quot;</span> blokkjában a&nbsp;
          <span className="text-blue">{fromSecondary ? "másodlagos" : "levelezési"} címben</span> szereplő város és
          ország jelenik meg:
        </Typography>
        <Typography className="mb-24">
          {Boolean(country) && (
            <span className="min-w-20">
              <FlagIcon code={country}/>
            </span>
          )}
          <span className={clsx(country && "ml-8")}>{cityAndCountry}</span>
        </Typography>
        {secondaryFilled
          ? (
            <Typography color="textSecondary" className="text-13 mb-24">
              Amennyiben a <span className="text-blue">{fromSecondary ? "levelezési" : "másodlagos"} cím</span> városát
              és országát tüntetnél fel adatlapod <span
              className="text-blue">&quot;Város, ország, ahol él&quot;</span> blokkjában, {fromSecondary ? "kapcsold ki" : "jelöld be"} az&nbsp;
              <span className="text-blue">&quot;Itt élek&quot;</span>&nbsp;jelölőnégyzetet a <span
              className="text-blue">másodlagos cím</span> szerkesztésénél
              {fromSecondary ? "! Amennyiben már nem aktuális, töröld a másodlagos cím városát és országát! Végül" : ", majd"}
              &nbsp;kattints a mentés gombra!
            </Typography>
          )
          : (
            <Typography color="textSecondary" className="text-13 mb-24">
              Amennyiben más várost és országot tüntetnél fel adatlapod <span className="text-blue">&quot;Város, ország, ahol él&quot;</span> blokkjában,
              add meg a várost és országot a <span className="text-blue">másodlagos cím</span> szerkesztésénél, egyúttal
              jelöld be az <span className="text-blue">&quot;Itt élek&quot;</span>&nbsp;jelölőnégyzetet, majd kattints a
              mentés gombra!
            </Typography>
          )
        }
        <Tooltip title="A másodlagos cím űrlapjának kinyitása">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={jumpToSecondary}
          >
            {secondaryFilled ? "Ugrás a másodlagos címhez" : "A másodlagos cím kinyitása"}
          </Button>
        </Tooltip>
        <Typography color="textSecondary" className="text-13 mt-32">
          Az adatlapod <span className="text-blue">&quot;Város, ország, ahol él&quot;</span> blokkjában megjelenő adatok
          láthatóságáról a megfelelő cím (levelezési vagy másodlagos cím) adatvédelmi beállításával rendelkezhetsz.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(Residency);
