import { errors } from "common/errors";
import { dbEnums } from "common/enums";
import { authAPIMethods } from "../config";
import { getAuthCookies } from "../helpers";
import { query } from "../query";


// only on server side
const { actionLog, alumnusStatus } = typeof window === "undefined" ? require("server/lib/db") : {};
const { insertActionLog } = actionLog || {};
const { updateLastLogin } = alumnusStatus || {};


const service = authAPIMethods.login;


const postLogin = async ({ id: accountId }, ip) => {
  // log login action
  if (insertActionLog) await insertActionLog(dbEnums.action.LOGIN, accountId, ip);

  // update lastLogin field in alumnusStatus table
  if (updateLastLogin) await updateLastLogin(accountId);
};


export const login = async req => {
  const { error, data } = await query({ service, req });
  if (error) return { error };

  const { CSRFToken, issues, account, personal, ip } = data;
  const __cookies = getAuthCookies(data);
  if (!CSRFToken || !__cookies || !account) return errors.server.general.SERVER_ERROR;

  await postLogin(account, ip)

  return {
    __cookies,
    __auth: {
      CSRFToken,
      account: account || {},
      issues: issues || {},
      personal: personal || {},
    },
  };
};
