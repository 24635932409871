import gql from "graphql-tag";


export const GET_REVISION_INVOLVED = gql`
  query GetRevisionInvolved($lastRevision: timestamptz!) {
    involved: alumni_revision_involved(args: {last_revision: $lastRevision}) {
      cnt
    }
  }
`;


export const GET_REVISION_REQUESTS = gql`
  query GetRevisionRequests {
    requests: view_revision_request(order_by: {created: desc}) {
      id
      created
      updated
      required
      accountId: by_account__id
      alumnusId: by_alumnus__id
      fullName: by_name
      lastRevision: last_revision
      note
      involved
      completed
      revised
      notRevised: not_revised
    }
  }
`;


export const GET_REVISION_REQUEST_CONTACTS = gql`
  query GetRevisionRequestContacts($id: Int!) {
    requests: view_revision_request(where: {id: {_eq: $id}}) {
      contacts: not_revised_contacts
    }
  }
`;
