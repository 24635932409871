import React from "react";
import { Form } from "components/form";
import { Org, __typename, updateFields, fieldAliases } from "components/form/org";


const requiredFields = {
  [__typename]: [updateFields[__typename].placeOfBirth, updateFields[__typename].dateOfBirth, updateFields[__typename].mothersName],
};


const EditOrg = ({ org, saveCb, loading, disableSubmit, enableSubmit }) => (
  <Form className="max-w-md md:max-w-lg lg:max-w-xl"
        records={[org]}
        updateFields={updateFields}
        requiredFields={requiredFields}
        fieldAliases={fieldAliases}
        disabled={loading}
        disableSubmit={disableSubmit}
        enableSubmit={enableSubmit}
        saveCb={saveCb}
  >
    <Org record={org}
         issue
    />
  </Form>
);

export default React.memo(EditOrg);
