import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { FormContext, Input } from "components/form";
import { requirementMessages } from "../config";


const domesticCountry = "HU";


const Zip = ({ record, countryField, prefixedId, required, label = "Irányítószám", maxLength, ...restProps }) => {

  const { getValue } = useContext(FormContext);


  const getCountry = useCallback(() => getValue(record && record.__typename, prefixedId, countryField)
    , [countryField, getValue, prefixedId, record]);


  const domesticZip = useCallback(zip => {
    const country = getCountry();
    const domestic = clsx(country).toUpperCase() === domesticCountry.toUpperCase();
    if (!domestic) return true;

    const domesticRegex = /^[1-9]\d{3}$/;
    const normalized = clsx(zip).trim();

    return !normalized || domesticRegex.test(normalized) || requirementMessages.address.domesticZip;
  }, [getCountry]);


  const requirements = useMemo(() => ({
    validate: {
      domesticZip,
    },
  }), [domesticZip]);


  return (
    <Input
      {...restProps}
      record={record}
      label={label}
      required={required}
      requirements={requirements}
      maxLength={maxLength || 16}
    />
  );
};

export default React.memo(Zip);
