import React, { useCallback } from "react";
import clsx from "clsx";
import { FormControl, FormHelperText } from "@material-ui/core";
import { FuseChipSelect, newTagObjKey } from "components/fuse";
import { getDefaultValueProp } from "../helpers";
import Controller from "./Controller"
import useField from "./useField"


const ChipSelect = ({
                      record, field, label, options, defaultValue, helperText, className, textFieldProps, maxLength, maxItems,
                      variant = "outlined", placeholder = "Kulcsszó létrehozásához kezdj el írni", ...restProps
                    }) => {


  const { name } = useField({
    field,
    record,
    helperText,
  });


  const onChange = useCallback(([items]) => {

    // react-select workaround: recently added element's label sometimes is a node instead of a string
    const safeItems = (items || []).map(({ [newTagObjKey]: isNew, value, label, ...item }) => ({
      ...item,
      [newTagObjKey]: Boolean(isNew),
      label: isNew ? value : label,
      value,
    }));

    // limit num of items
    return safeItems.slice(0, maxItems || 30);

  }, [maxItems]);


  const formatCreateLabel = useCallback(value => (<span>Új létrehozása: <i>{value}</i></span>), []);


  const handleIsValidNewOption = useCallback((inputValue, selectValue, selectOptions) => {
    if (!inputValue.trim().length) return false;
    return !selectOptions.find(el => el.label === inputValue);
  }, []);


  return (
    <FormControl variant={variant}
                 className={clsx("w-full z-99", !clsx(className).includes("mb-") && "mb-32", className)}>
      <Controller
        {...restProps}
        {...getDefaultValueProp(defaultValue)}
        name={name}
        as={<FuseChipSelect/>}
        onChange={onChange}
        options={options || []}
        textFieldProps={{
          InputLabelProps: {
            shrink: true
          },
          ...textFieldProps,
          variant,
          label,
        }}
        placeholder={clsx(placeholder)}
        noOptionsContent={(
          <span>Hozz létre új kulcsszót!</span>
        )}
        isValidNewOption={handleIsValidNewOption}
        formatCreateLabel={formatCreateLabel}
        maxLength={maxLength || 50}
        isMulti
      />
      {Boolean(helperText) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(ChipSelect);
