import React from "react";
import { Typography } from "@material-ui/core";


const Org = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon az alumnus <b>hivatalos tagsági adatait</b> adminisztrálhatod.
    </Typography>
    <Typography className="my-20">
      Ezen adatok egy része <b>csak olvasható</b>. Ugyanakkor a többi adat <b>módosítása is csak akkor indokolt, ha az
      alumnus a regisztrációnál rosszul adta meg őket</b>.
    </Typography>
    <Typography className="mt-20">
      E tekintetben kivételt jelenthet a <span className="text-blue">tagsággal kapcsolatos megjegyzés</span>, melynek
      kitöltése, módosítása az alumnus általi adatbeviteltől függetlenül történhet. Az itt felvitt információ <b>csak az
      adminisztrátorok számára lesz látható</b>, így az alumnusok &ndash; így az érintett alumnus &ndash; számára
      semmilyen formában nem lesz látható.
    </Typography>
  </>
);

export default React.memo(Org);
