import clsx from "clsx";
import { INSERT_TAGS } from "client/store/alumnus";
import { newTagObjKey } from "components/fuse";


const insertTags = async (client, records) => {
  if (!records || !records.length) return [];

  const { error, data: { results: { returning } = {} } = {} } = await client.mutate({
    mutation: INSERT_TAGS,
    variables: {
      records,
    },
  });

  if (error) throw new Error(error);

  return (returning || []).map(({ id } = {}) => id);
};


const getExistingTagIds = rawTags => (rawTags || []).filter(({ [newTagObjKey]: newTag }) => !newTag).map(({ value }) => parseInt(value));


const getNewTags = (rawTags, entity) => (rawTags || []).filter(({ [newTagObjKey]: newTag }) => newTag).map(({ label }) => ({
  tag: label,
  entity,
}));


const getInsertedTagIds = async (_root, { rawTags, entity }, { client }) => {
  const existingTagIds = getExistingTagIds(rawTags);

  const newTags = getNewTags(rawTags, clsx(entity));

  const newTagsIds = await insertTags(client, newTags);

  return existingTagIds.concat(newTagsIds);
};

export default getInsertedTagIds;
