import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Checkbox, FormControl, FormLabel, FormControlLabel, FormHelperText } from "@material-ui/core";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";


const MultiStatement = ({
                          title, statementsConfig, stepStatements, toggleStatement, className, color = "primary",
                          labelKey = "label", errorHelperText = "A csillaggal jelzett nyilatkozatokat kötelező megtenni"
                        }) => {


  const [touched, setTouched] = useState(false);


  const statementError = useMemo(() => Boolean(touched && statementsConfig.find(({ id, required }) => required && !stepStatements.includes(id)))
    , [statementsConfig, stepStatements, touched]);


  const someRequired = useMemo(() => statementsConfig.some(({ required }) => required), [statementsConfig]);


  const getStatement = useCallback(id => stepStatements.includes(id), [stepStatements]);


  const toggleApprove = useCallback(id => toggleStatement(id), [toggleStatement]);


  const onSubmitStarted = useCallback(() => setTouched(true), []);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitStarted, onSubmitStarted);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitStarted, onSubmitStarted);
    }
  }, [onSubmitStarted]);


  return (
    <div className={clsx(!clsx(className).includes("my-") && "my-32", className)}>
      <FormControl required={someRequired} error={statementError}>
        <FormLabel component="legend" className="mb-12">{title}</FormLabel>

        {Boolean(statementsConfig) && statementsConfig.map(({ id, [labelKey || "label"]: label, required }) => (
          <FormControlLabel
            key={id}
            label={(
              <i className={clsx(statementError && required && "text-red")}>
                {clsx(label, required && "*")}
              </i>
            )}
            className="my-4"
            control={
              <Checkbox
                checked={getStatement(id)}
                onChange={() => toggleApprove(id)}
                color={color}
              />
            }
          />
        ))}

        {Boolean(statementError) && (
          <FormHelperText>{errorHelperText}</FormHelperText>
        )}

      </FormControl>
    </div>
  );
};

export default React.memo(MultiStatement);
