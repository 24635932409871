import clsx from "clsx";


const countryName = ({ cName }) => (cName && cName.n) || null;


const buildDomestic = (name, zip, city, address) => {
  const cityWComma = city && `${city}${clsx(address && ",")}`;
  return clsx(name, zip, cityWComma, address).trim();
};


const buildForeign = (name, zip, city, address, country) => {
  const addressWComma = address && `${address},`;
  const cityWComma = city && `${city}${clsx(!zip && country && ",")}`;
  const zipWComma = zip && `${zip}${clsx(country && ",")}`;
  return clsx(name, addressWComma, cityWComma, zipWComma, country).trim();
};


const full = ({ country, zip, city, address, name, cName, countryName: _countryName }) => {
  const { n } = cName || {};
  const countryName = n || _countryName || "";

  const normalizedCountryCode = clsx(country).trim().toUpperCase();

  const domestic = (normalizedCountryCode === "HU");
  const extendedName = name && `${name} névre,`;

  if (domestic) return buildDomestic(extendedName, zip, city, address) || null;

  const normalizedCountry = clsx(countryName).trim() || normalizedCountryCode;
  return buildForeign(extendedName, zip, city, address, normalizedCountry) || null;
};


const alumnus_address = {
  countryName,
  full,
};

export default alumnus_address;
