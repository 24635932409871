import { useCallback, useContext } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { SAVE_EMAILS, SAVE_PHONES, UPDATE_ALUMNUS_ADDRESS } from "client/store/alumnus/mutations";
import { fetchAlumnus } from "client/store/alumnus/resolvers/getters";
import { getSaveCb } from "components/form";
import * as addressForm from "components/form/address";
import * as emailForm from "components/form/email";
import * as phoneForm from "components/form/phone";


const useSaveContact = ({ alumnusId, primaryEmailId, primaryPhoneId, previousEmail, ignoredSaveCbReturn }) => {

  const { message: { showMessage } } = useContext(UiStateContext);


  const client = useApolloClient();


  const saveEmail = useCallback(async ({ jobs }) => await client.mutate({
    mutation: SAVE_EMAILS,
    variables: {
      emails: jobs[emailForm.__typename],
      primaryEmailId,
      alumnusId,
      issue: true,
    },
  }), [alumnusId, client, primaryEmailId]);


  const savePhone = useCallback(async ({ jobs }) => await client.mutate({
    mutation: SAVE_PHONES,
    variables: {
      phones: jobs[phoneForm.__typename],
      primaryPhoneId,
      alumnusId,
      issue: true,
    },
  }), [alumnusId, client, primaryPhoneId]);


  const saveAddress = useCallback(async ({ jobs }) => await getSaveCb(client, {
    [addressForm.typenames.address]: UPDATE_ALUMNUS_ADDRESS,
  })(jobs), [client]);


  const doSave = useCallback(async jobs => {
    const { error: emailError } = await saveEmail({ jobs });
    if (emailError) return;

    const { error: phoneError } = await savePhone({ jobs });
    if (phoneError) return;

    const { error: addressError } = await saveAddress({ jobs });
    if (addressError) return;

    return true;
  }, [saveAddress, saveEmail, savePhone]);


  const isUpdatedEmailAddress = useCallback(jobs => Boolean((jobs[emailForm.__typename] || [])
    .find(({ id, email }) => id === primaryEmailId && email !== previousEmail)
  ), [previousEmail, primaryEmailId]);


  return useCallback(async ({ jobs, jobsCount }) => {
    const saved = await doSave(jobs);
    if (!saved) return { error: true };

    if (isUpdatedEmailAddress(jobs)) showMessage(UiMessages.issues.updatedUsername);

    if (jobsCount && !ignoredSaveCbReturn) await fetchAlumnus(alumnusId, client, true);

    return {
      noMessage: true,
      noStopLoading: true,
      ignore: Boolean(ignoredSaveCbReturn),
    };
  }, [alumnusId, client, doSave, ignoredSaveCbReturn, isUpdatedEmailAddress, showMessage]);

};

export default useSaveContact;
