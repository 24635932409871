import { withAccent } from "common/helpers";


export const UiMessageVariants = {
  default: "default",
  error: "error",
  info: "info",
  success: "success",
  warning: "warning",
};


export const UiMessages = {
  alumnus: {
    reloaded: ["Az adatlapot más is szerkesztette, ezért újratöltöttük."],
  },
  auth: {
    expiredSession: ["Lejárt munkamenet. Kérjük, jelentkezz be újra!", UiMessageVariants.warning, {
      autoHideDuration: 0,
    }],
    failedLogout: ["Kijelentkezés során hiba lépett fel.", UiMessageVariants.error],
    failedLogin: ["Sikertelen bejelentkezés. Ellenőrizd a bejelentkezési adatokat, majd próbálkozz újra! Igény esetén kérj új jelszót!", UiMessageVariants.error, {
      autoHideDuration: 10 * 1000,
    }],
    invalidAccountStatus: supportEmail => [`A felhasználói fiókod jelenleg nem elérhető. Kérjük, vedd fel velünk a kapcsolatot ${withAccent(supportEmail)} email címen!`, UiMessageVariants.error, {
      autoHideDuration: 0,
    }],
    notSupportedUserAgent: ["Elavult böngésző. Kérjük frissítsd a böngészőt, vagy próbálkozz másik böngészőből vagy eszközről.", UiMessageVariants.error, {
      autoHideDuration: 0,
    }],
    unauthorized: ["Érvénytelen munkamenet. Kérjük, jelentkezz be újra!", UiMessageVariants.error, {
      autoHideDuration: 0,
    }],
  },
  form: {
    account: {
      activated: ["A fiók letiltását sikeresen feloldottuk. Az alumnus számára újra engedélyezett a bejelentkezés.", UiMessageVariants.success],
      bansDeleted: ["Valamennyi kitiltást és szolgáltatás-korlátozást sikeresen feloldottunk.", UiMessageVariants.success],
      ipBanDeleted: selectedCount => [`${withAccent(selectedCount, true)} IP címre vonatkozó kitiltást sikeresen feloldottuk.`, UiMessageVariants.success],
      inactivated: ["A fiókot sikeresen letiltottuk. Az alumnus a letiltás feloldása esetén tud majd újra bejelentkezni.", UiMessageVariants.success],
      pswrLinkSent: ["A jelszóváltoztatási linket tartalmazó emailt sikeresen kiküldtük.", UiMessageVariants.success],
    },
    panel: {
      onError: entity => [`Javítsd a hibásan kitöltött ${entity || "tételt"}!`, UiMessageVariants.warning],
      onMissingRequired: entity => [`Töltsd ki az imént megkezdett ${entity || "tételt"}.`, UiMessageVariants.warning],
    },
    submit: {
      invalidForm: ["Hiányosan vagy hibásan kitöltött űrlap.", UiMessageVariants.warning],
      saved: ["A változtatásokat sikeresen elmentettük.", UiMessageVariants.success],
      saveError: ["Szerver hiba lépett fel. A mentés sikertelen. Próbáld újra!", UiMessageVariants.error, {
        autoHideDuration: 10 * 1000,
      }],
    },
    verify: {
      success: ["Sikeres megerősítés.", UiMessageVariants.success, {
        autoHideDuration: 10 * 1000,
      }],
    },
  },
  issues: {
    updatedUsername: ["A következő bejelentkezéskor már az imént megadott email címet add meg!", UiMessageVariants.info],
    thankYou: ["Sikeres nyilatkozattétel. Köszönjük együttműködésedet és türelmedet!", UiMessageVariants.success, {
      autoHideDuration: 3 * 1000,
    }],
  },
  meta: {
    copiedToClipboard: message => [`${message} a vágólapra másolva.`, UiMessageVariants.success],
  },
  password: {
    copiedToClipboard: ["Bejelentkezési felhasználóneved a vágólapra másolva.", UiMessageVariants.success],
    failedPasswordChange: ["Sikertelen jelszóváltoztatás. Kérjük, próbáld újra!", UiMessageVariants.error],
    passwordChange: ["A tényleges jelszóváltoztatást ebben a verzióban még nem hajtjuk végre.", UiMessageVariants.info],
    sameAsCurrent: ["Az új jelszó nem lehet azonos a jelenlegivel. Kérjük, adj meg másik jelszót!", UiMessageVariants.error],
    successPasswordChange: ["A jelszót sikeresen megváltoztattad. A bejelentkezéshez már az új jelszót használhatod.", UiMessageVariants.success],
    wrongPassword: ["Jelszóváltoztatás sikertelen. Nem jól adtad meg a jelenlegi jelszót.", UiMessageVariants.error],
  },
  pswrLink: {
    banned: banExpiryFormatted => [banExpiryFormatted
      ? `Biztonsági okokból ${banExpiryFormatted}-ig nem kérhetsz új jelszót.`
      : "Biztonsági okokból határozatlan ideig nem kérhetsz új jelszót. Kérjük vedd fel a kapcsolatot az Alumnival."
      , UiMessageVariants.error, {
        autoHideDuration: 10 * 1000,
      }],
    failedVerification: ["Hibásan megadott adatok.", UiMessageVariants.error],
  },
  regCodes: {
    deleted: ["A regisztrációs kódot sikeresen töröltük.", UiMessageVariants.success],
  },
  registration: {
    cancel: ["A regisztrációs folyamatot megszakítottad. Később bármikor újrakezdheted.", UiMessageVariants.warning, {
      autoHideDuration: 5 * 1000,
    }],
  },
  revision: {
    thankYou: ["Sikeres adatellenőrzés. Köszönjük együttműködésedet!", UiMessageVariants.success],
    missingStatements: ["Kérjük, tedd meg a szükséges nyilatkozatokat!", UiMessageVariants.warning],
  },
  specialAccounts: {
    deleted: ["A speciális felhasználói fiókot kódot sikeresen töröltük.", UiMessageVariants.success],
    disabled: ["A speciális felhasználói fiókot kódot letiltottuk.", UiMessageVariants.success],
    enabled: ["A speciális felhasználói fiókra vonatkozó letiltást feloldottuk.", UiMessageVariants.success],
    pswrLinkSent: ["Sikeresen kiküldtük a jelszóváltoztatási linket a speciális felhasználó email címére.", UiMessageVariants.success],
  },
};
