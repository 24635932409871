import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Checkbox, FormControl, FormLabel, FormControlLabel, FormHelperText } from "@material-ui/core";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";


const Statement = ({ checked, onCheckCb, title, label, className, color = "primary", errorHelperText = "Kötelező nyilatkozni" }) => {

  const [touched, setTouched] = useState(false);


  const statementError = useMemo(() => touched && !checked, [checked, touched]);


  const onCheck = useCallback(() => {
    setTouched(true);
    onCheckCb();
  }, [onCheckCb]);


  const onSubmitStarted = useCallback(() => setTouched(true), []);


  const onModeSelect = useCallback(() => setTouched(false), []);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitStarted, onSubmitStarted);
    uiStateEventEmitter.on(uiStateEvents.revision.onDirty, onSubmitStarted);
    uiStateEventEmitter.on(uiStateEvents.registration.onModeSelect, onModeSelect);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitStarted, onSubmitStarted);
      uiStateEventEmitter.off(uiStateEvents.revision.onDirty, onSubmitStarted);
      uiStateEventEmitter.off(uiStateEvents.registration.onModeSelect, onModeSelect);
    }
  }, [onModeSelect, onSubmitStarted]);


  return (
    <div className={clsx(!clsx(className).includes("my-") && "my-32", className)}>
      <FormControl required error={statementError}>
        <FormLabel component="legend">{title}</FormLabel>
        <FormControlLabel
          className="my-4"
          label={<i>{label}</i>}
          control={
            <Checkbox
              checked={checked}
              onChange={onCheck}
              color={color}
            />
          }
        />
        {Boolean(statementError) && (
          <FormHelperText>{errorHelperText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default React.memo(Statement);
