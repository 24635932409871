import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Typography, LinearProgress } from "@material-ui/core";


const Suspense = ({ children, className, delay: specifiedDelay, loading: isLoading = true }) => {
  const [delayFinished, setDelayFinished] = useState(false);
  const [loadingStarted, setLoadingStarted] = useState(true);

  const resetDelay = () => setDelayFinished(false);

  useEffect(() => {
    const loadCompleted = loadingStarted && !isLoading;
    setLoadingStarted(isLoading);
    if (loadCompleted) resetDelay();
  }, [loadingStarted, isLoading]);

  useEffect(() => {
    const timer = isLoading && specifiedDelay && setTimeout(() => {
      setDelayFinished(true);
    }, specifiedDelay || 0);
    return () => specifiedDelay && clearTimeout(timer);
  }, [specifiedDelay, isLoading]);


  // loading page shouldn't appear if content has been loaded
  if (!loadingStarted) return children;

  // if a delay has been speficied but it's in progress yet
  if (specifiedDelay && !delayFinished) return children;

  return (
    <div className={clsx("flex flex-1 flex-col items-center justify-center", className)}>
      <Typography className="text-20 mb-16" color="textSecondary">Adatok betöltése...</Typography>
      <LinearProgress className="w-xs" color="secondary" variant="query"/>
    </div>
  );
};

export default React.memo(Suspense);
