import React, { useContext } from "react";
import clsx from "clsx";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";


const Dialog = () => {

  const {
    dialog: {
      open,
      disabled,
      title,
      content,
      ContentComponent,
      cancelButton,
      OKButton,
      classNameOK,
      classNameCancel,
      colorOK,
      colorCancel,
      onCancel,
      onOK,
      maxWidth,
      fullScreen,
      fullWidth,
      disableBackdropClick,
      disableEscapeKeyDown,
    }
  } = useContext(UiStateContext);


  return (
    <MuiDialog
      open={open}
      onClose={onCancel}
      maxWidth={maxWidth || "sm"}
      fullScreen={Boolean(fullScreen)}
      fullWidth={Boolean(fullWidth)}
      disableBackdropClick={Boolean(disableBackdropClick)}
      disableEscapeKeyDown={Boolean(disableEscapeKeyDown)}
    >
      {Boolean(title) && (
        <DialogTitle>{title}</DialogTitle>
      )}
      {Boolean(ContentComponent || content) && (
        <DialogContent>
          {ContentComponent || (
            <DialogContentText>
              {content}
            </DialogContentText>
          )}
        </DialogContent>
      )}
      <DialogActions className="px-24 py-12">
        {Boolean(cancelButton) && (
          <Button onClick={onCancel}
                  color={colorCancel || "primary"}
                  className={clsx(classNameCancel)}
          >
            {cancelButton}
          </Button>
        )}
        {Boolean(OKButton) && (
          <Button onClick={onOK}
                  color={colorOK || "primary"}
                  className={clsx(classNameOK)}
                  disabled={Boolean(disabled)}
                  autoFocus
          >
            {OKButton}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

export default React.memo(Dialog);
