import { getService, contentTypes } from "./helpers";
import { account } from "./account";
import { alumnus } from "./alumnus";
import { auth } from "./auth";
import { blob } from "./blob";
import { octopus } from "./octopus";
import { pswrLink } from "./pswrLink";
import { registration } from "./registration";
import { smsapicom } from "./smsapicom";
import { verify } from "./verify";


export const services = {
  account,
  alumnus,
  auth,
  blob,
  fileUpload: getService("media/upload", {
    contentType: contentTypes.octet,
  }),
  geoip: getService("geoip"),
  octopus,
  pswrLink,
  registration,
  smsapicom,
  verify,
};
