import React, { useContext, useMemo } from "react"
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { SessionContext } from "client/lib/providers/SessionProvider";
import parseHtml from "./parseHtml";


export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTypography-h1": {
      fontSize: "2.6rem",
      lineHeight: "1.3",
      fontWeight: 400,
      margin: "3rem 0",
      textAlign: "center",
    },
    "& .MuiTypography-h2": {
      fontSize: "2.2rem",
      lineHeight: "1.4",
      fontWeight: 400,
      margin: "2.4rem 0 2rem 0",
    },
    "& .MuiTypography-h3": {
      fontSize: "1.8rem",
      lineHeight: "1.5",
      fontWeight: 400,
      margin: "2rem 0 1.2rem 0",
    },
    "& .MuiTypography-h4": {
      fontSize: "1.6rem",
      lineHeight: "1.6",
      fontWeight: 400,
      margin: "1.6rem 0 1rem 0",
    },
    "& .MuiTypography-h5": {
      fontSize: "1.4rem",
      lineHeight: "1.7",
      fontWeight: 400,
      margin: "1.2rem 0 0.6rem 0",
    },
    "& p": {
      margin: "1.4rem 0",
      fontSize: "1.2rem",
    },
    "& p.data-pre-list": {
      marginBottom: "0.4rem",
    },
    "& p.end": {
      margin: "3rem 0 0",
      textAlign: "center",
    },
    "& ul": {
      "list-style-type": "square",
    },
    "& ol, & ul": {
      margin: "0.8rem 0 1.2rem 0",
      fontSize: "1.2rem",
    },
    "& li": {
      padding: "0.3rem 0",
    },
    "& li:first-child": {
      paddingTop: 0,
    },
    "& li:last-child": {
      paddingBottom: 0,
    },
  },
}));


const Terms = ({ className }) => {

  const { terms } = useContext(SessionContext);

  const { html } = terms || {};

  const CurrentTerms = useMemo(() => parseHtml(html), [html]);

  const classes = useStyles();

  return Boolean(CurrentTerms) && (
    <div className={clsx(classes.root, className)}>
      {CurrentTerms}
    </div>
  );
};

export default React.memo(Terms);
