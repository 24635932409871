import gql from "graphql-tag";


export const UPSERT_REVISION_REQUESTS = gql`
  mutation UpsertRevisionRequests($records: [revision_request_insert_input!]!) {
    results: insert_revision_request(objects: $records, on_conflict: {constraint: revision_request__pkey, update_columns: [required, note]}) {
      affected_rows
    }
  }
`;


export const DELETE_REVISION_REQUEST = gql`
  mutation DeleteRevisionRequest($id: Int!) {
    results: delete_revision_request(where: { _and: {id: {_eq: $id}}}) {
      affected_rows
    }
  }
`;


export const DOWNLOAD_CSV = gql`
  mutation DownloadRevisionRequestsCSV($id: Int!) {
    returning: downloadRevisionRequestsCSV(id: $id) @client {
      error
    }
  }
`;


export const DOWNLOAD_XLSX = gql`
  mutation DownloadRevisionRequestsXLSX($id: Int!) {
    returning: downloadRevisionRequestsXLSX(id: $id) @client {
      error
    }
  }
`;
