import React from "react";
import { Typography } from "@material-ui/core";
import { maxRecordsCounts } from "config/form";


const Job = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon a <b>jelenlegi és múltbeli munkahelyeidet, projektjeidet</b> oszthatod meg. Egy-egy
      munkahelyet/projektet egy-egy bejegyzés létrehozásával adhatsz meg (legfeljebb {maxRecordsCounts.job} bejegyzés
      hozható létre).
    </Typography>
    <Typography className="my-20">
      <b>A <span className="text-blue">munkahely/projekt</span>, illetve a <span className="text-blue">beosztás, munkakör</span> adatokat
        kötelező megadni.</b> Előbbivel a <i>&quot;hol/kinek?&quot;</i>, utóbbival a <i>&quot;mit?&quot;</i> kérdésre
      válaszolhatsz.
    </Typography>
    <Typography className="my-20">
      Megadhatod a munkahely/projekt relevanciájának időtartományát is. Ennek kitöltése opcionális, viszont az
      időtartomány nem lehet alulról nyitott. Ha tehát megadod az időtartomány tetejét, vagyis a <span
      className="text-blue">befejezés évét</span>, akkor a <span className="text-blue">kezdés évét</span> is meg kell
      adnod (a <span className="text-blue">befejezés éve</span> bármikor üresen hagyható).
    </Typography>
    <Typography className="my-20">
      <b>A munkahely/projekt aktualitását az időtartománytól függetlenül jelölheted meg</b>, a <span
      className="text-blue">jelenleg is ezen a munkahelyen/projekten dolgozom</span> jelölőnégyzet bekapcsolásával.
      Ilyenkor az adatlapon a munkahely/projekt mellett megjelenik az <i>Aktuális</i> felirat, függetlenül attól, hogy a
      relevancia időtartományát megadtad-e vagy milyen értékekkel adtad meg.
    </Typography>
    <Typography className="my-20">
      Amikor a <span className="text-blue">befejezés évét</span> megadod/módosítod, akkor
      a <span className="text-blue">jelenleg is ezen a munkahelyen/projekten dolgozom</span> jelölőnégyzet&nbsp;
      <b>automatikusan kikapcsolt állapotba kerül</b> (hiszen ilyenkor múltbeliséget feltételezünk). Természetesen ezt
      a jelölőnégyzet újbóli bejelölésével felülbírálhatod.
    </Typography>
    <Typography className="my-20">
      A munkahelyhez/projekthez kapcsolódóan egyéb információkat is megoszthatsz, így a munkavégzéshez kapcsolódó <span
      className="text-blue">országot</span>, <span className="text-blue">települést</span> vagy <span
      className="text-blue">weboldalt</span>, a munkahely/projekt <span
      className="text-blue">iparági besorolását</span>, illetve rövid <span className="text-blue">megjegyzést</span> is
      fűzhetsz a bejegyzéshez.
    </Typography>
    <Typography className="my-20">
      Az egyes munkahely-/projektbejegyzések mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Az adott adatvédelmi beállítás értelemszerűen csak a megfelelő bejegyzésre
      vonatkozik. Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
    <Typography className="mt-20">
      Egy-egy munkahelybejegyzést a <span className="text-blue">Törlés</span> gombra kattintva <b>törölhetsz</b>.
    </Typography>
  </>
);

export default React.memo(Job);
