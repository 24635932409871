import clsx from "clsx";
import { Observable } from "apollo-link";
import { onError } from "apollo-link-error";
import { handleUnauthorized } from "client/lib/session";


const accessDeniedCode = "access-denied";

const failedStartCode = "start-failed";

const messageSearchString = "unauthorized";


const isUnauthorizedGraphQLError = graphQLErrors =>
  Boolean(graphQLErrors && graphQLErrors.some(({ extensions = {} }) => clsx(extensions.code).toLowerCase() === accessDeniedCode.toLowerCase()));


const isUnauthorizedNetworkError = networkError => {
  const { statusCode, message, extensions } = networkError || {};
  const { code } = extensions || {};

  const failedStart = code === failedStartCode && clsx(message).toLowerCase().includes(messageSearchString.toLowerCase());

  return Boolean(statusCode === 401 || failedStart);
};


export const isUnauthorizedError = (graphQLErrors, networkError) => isUnauthorizedGraphQLError(graphQLErrors) || isUnauthorizedNetworkError(networkError);


export const unauthorizedLink = onError(({ graphQLErrors, networkError }) => {
  if (isUnauthorizedError(graphQLErrors, networkError)) {

    //TODO: temporary workaround until a new Apollo release supporting async onError links
    return new Observable(observer => {
      handleUnauthorized()
        .then(() => observer.complete())
        .catch(() => observer.complete());
    });
  }
});
