import React, { useCallback, useContext } from "react";
import nextTick from "next-tick";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { UiMessages } from "config/messages";
import { accent, safeFnOrPromise } from "common/helpers";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { ListContext } from "components/listPages";
import { useConfirm } from "components/common";


const getConfirmOptions = ({ username }) => ({
  title: "Speciális felhasználói fiók törlése",
  content: (<span>Biztosan törlöd {accent(username)} <b>{username}</b> felhasználónévhez tartozó fiókot?</span>),
  OKButton: "Igen, törlöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
});


const DelButton = specialAccount => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { refetch, resetFilters } = useContext(ListContext);


  const confirm = useConfirm({
    confirmOptions: getConfirmOptions(specialAccount),
  });


  const deleteSpecialAccount = useCallback(async () => {
    const { error } = await backendFetch(backendServices.account.deleteSpecialAccount, { id: specialAccount.id });

    if (!error) {
      await safeFnOrPromise(refetch)();
      resetFilters();
    }

    nextTick(() => showMessage(error ? UiMessages.form.submit.saveError : UiMessages.specialAccounts.deleted));
  }, [specialAccount.id, showMessage, refetch, resetFilters]);


  const onDelete = useCallback(() => confirm(deleteSpecialAccount), [confirm, deleteSpecialAccount]);


  return (
    <Tooltip title="Speciális felhasználói fiók törlése">
      <span>
        <IconButton size="small"
                    onClick={onDelete}
                    className="text-red"
        >
          <Icon>delete</Icon>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default React.memo(DelButton);
