import React, { useContext } from "react";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { ExternalLink } from "components/common";


const emailSubject = "Alumni Online! megerősítő kód probléma";


const VerificationHelpEmailLink = () => {

  const { envVars: { supportEmail, supportEmailUri } } = useContext(SessionContext);

  const uri = `${supportEmailUri}?subject=${emailSubject}`;

  const verificationHelpHrefString = encodeURI(uri);

  return (
    <ExternalLink href={verificationHelpHrefString} noIcon>{supportEmail}</ExternalLink>
  );
}

export default React.memo(VerificationHelpEmailLink);
