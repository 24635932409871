import React, { useMemo } from "react";
import Link from "next/link"
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { pathNames } from "config/routing";


const AlumnusLink = ({ hidden, alumnus, meLink, linkParams, className, color = "primary" }) => {

  const { fullName } = alumnus.name || {};


  const hrefParams = useMemo(() => clsx(linkParams && Object.entries(linkParams)
      .reduce((hrefParams, [key, value]) => hrefParams + (hrefParams ? "&" : "?") + `${key}=${value}`, ""))
    , [linkParams]);


  const href = useMemo(() => `${pathNames.alumnus.href}${hrefParams}`, [hrefParams]);


  const as = useMemo(() => pathNames.alumnus.as(meLink ? "me" : alumnus.id), [alumnus, meLink]);


  return Boolean(!hidden && fullName) && (
    <Link href={href} as={as || href}>
      <a rel="noopener noreferrer" role="button">
        <Typography className={clsx("inline font-medium", className)} color={color} paragraph={false}>
          {fullName}
        </Typography>
      </a>
    </Link>
  );
};

export default React.memo(AlumnusLink);
