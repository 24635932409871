import React, { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import texts from "config/texts";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { GET_CARDS } from "client/store/card";
import { useList, ListPage, ListContext } from "components/listPages";
import { getFilterTestFnObj } from "components/listPages/helpers";
import Header from "./Header";
import Toolbar from "./Toolbar";
import Row from "./Row";


const fields = {
  nr: "nr",
  dup: "dup",
  statusLabel: "statusLabel",
  group: "group",
  name: "name",
};


const filters = {
  toFilter: getFilterTestFnObj([fields.nr, fields.dup, fields.statusLabel, fields.group, fields.name]),
};


const CardsList = () => {

  const forbidden = useForbidden([dbEnums.accountRoles.MANAGER, dbEnums.accountRoles.RETAILER, dbEnums.accountRoles.STUDENT]);

  const nonAdmin = useForbidden([dbEnums.accountRoles.MANAGER]);


  const { reload, ...list } = useList({ filters });


  const filterCards = useCallback(cards => (cards || []).filter(({ status }) =>
    !nonAdmin || status === dbEnums.cardStatus.ACTIVE || status === dbEnums.cardStatus.REVOKED
  ), [nonAdmin]);


  const { refetch: refetchCards, error, loading } = useQuery(GET_CARDS, {
    onCompleted: ({ cards } = {}) => cards && reload(filterCards(cards)),
  });


  const refetch = useCallback(async () => {
    const { data: { cards } = {} } = await refetchCards();
    if (cards) reload(filterCards(cards));
  }, [filterCards, refetchCards, reload]);


  const contextValue = {
    ...list,
    refetch,
  };


  return (
    <ListContext.Provider value={contextValue}>
      <ListPage forbidden={forbidden}
                loading={loading}
                error={error}
                title={texts.cardsName}
                icon="credit_card"
                HeaderComponent={Header}
                ToolbarComponent={Toolbar}
                RowComponent={Row}
      />
    </ListContext.Provider>
  );
};

export default React.memo(CardsList);
