import nextTick from "next-tick";
import moment from "moment";
import { useCallback, useContext, useMemo, useState } from "react";
import { dbEnums } from "common/enums";
import { withAccent } from "common/helpers";
import { useConfirm } from "components/common";
import { FormContext, PanelContext } from "components/form";
import { isNew, serializeFieldName } from "../helpers";


export const __typename = "card";


export const _updateFields = {
  status: "status",
  nr: "nr",
  issueDate: "issueDate",
  exp: "exp",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.status],
};


export const fieldAliases = {
  [__typename]: {
    [_updateFields.status]: "status",
    [_updateFields.nr]: "card_number",
    [_updateFields.issueDate]: "date_of_issue",
    [_updateFields.exp]: "expiry",
  },
};


export const newRecordValues = {
  [_updateFields.status]: dbEnums.cardStatus.REQUESTED,
  [_updateFields.nr]: null,
  [_updateFields.issueDate]: null,
  [_updateFields.exp]: null,
};


const getDeleteConfirmOptions = nr => ({
  title: "Törlés megerősítése",
  content: `Biztosan törlöd ${withAccent(nr)}. sorszámú kártyabejegyzést?`,
  OKButton: "Igen, törlöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
});


const onActiveConfirmOptions = {
  title: "Kibocsátás dátumának beállítása",
  content: "A kártya státuszát az imént aktívra állítottad. Szeretnéd a kibocsátás dátumát a mai nappal megadni?",
  OKButton: "Igen",
  colorOK: "secondary",
  cancelButton: "Nem, más dátumot adok meg",
  colorCancel: "inherit",
};


const onRevokedConfirmOptions = {
  ...onActiveConfirmOptions,
  title: "Lejárat dátumának beállítása",
  content: "A kártya státuszát az imént visszavontra állítottad. Szeretnéd a lejárat dátumát a mai nappal megadni?",
};


const useCard = ({ record: pRecord, recordId: pRecordId, prefixedId: pPrefixedId, dialog } = {}) => {

  const { getValue, setValue, triggerValidation } = useContext(FormContext);

  // On cards page there is no PanelContext
  const { record: cRecord, recordId: cRecordId, prefixedId: cPrefixedId, deleteRecord } = useContext(PanelContext) || {};


  const record = pRecord || cRecord;

  const recordId = pRecordId || cRecordId;

  const prefixedId = pPrefixedId || cPrefixedId;


  const [status, setStatus] = useState(record[_updateFields.status]);


  const disabled = useMemo(() => isNew(recordId), [recordId]);


  const getNr = useCallback(() => getValue(__typename, prefixedId, _updateFields.nr)
    , [getValue, prefixedId]);


  const getIssueDate = useCallback(() => getValue(__typename, prefixedId, _updateFields.issueDate)
    , [getValue, prefixedId]);


  const getExp = useCallback(() => getValue(__typename, prefixedId, _updateFields.exp)
    , [getValue, prefixedId]);


  const deleteConfirmOptions = useCallback(() => getDeleteConfirmOptions(getNr()), [getNr]);


  const deleteConfirm = useConfirm({
    confirmOptions: deleteConfirmOptions,
    disabled: disabled,
  });


  const onDelete = useCallback(() => deleteConfirm(deleteRecord, prefixedId)
    , [deleteConfirm, prefixedId, deleteRecord]);


  const onActiveConfirm = useConfirm({
    confirmOptions: onActiveConfirmOptions,
    disabled: dialog,
  });


  const fillDate = useCallback(field => {
    const fieldName = serializeFieldName(__typename, prefixedId, field);
    setValue(fieldName, moment().format("YYYY-MM-DD"));
    nextTick(triggerValidation);
  }, [prefixedId, setValue, triggerValidation]);


  const clearExp = useCallback(() => {
    const fieldName = serializeFieldName(__typename, prefixedId, _updateFields.exp);
    setValue(fieldName, null);
    nextTick(triggerValidation);
  }, [prefixedId, setValue, triggerValidation]);


  const onRevokedConfirm = useConfirm({
    confirmOptions: onRevokedConfirmOptions,
    disabled: dialog,
  });


  const onStatusChange = useCallback(value => {
    if (value === status) return;

    setStatus(value);

    if (value !== dbEnums.cardStatus.REVOKED) clearExp();

    if (value === dbEnums.cardStatus.ACTIVE && !getIssueDate()) return onActiveConfirm(fillDate, _updateFields.issueDate).then();
    if (value === dbEnums.cardStatus.REVOKED && !getExp()) return onRevokedConfirm(fillDate, _updateFields.exp).then();

    nextTick(triggerValidation);
  }, [status, clearExp, getIssueDate, onActiveConfirm, fillDate, getExp, onRevokedConfirm, triggerValidation]);


  return {
    status,
    onStatusChange,
    onDelete,
  };

};

export default useCard;
