import { errorLogStore } from "client/lib/errorLog";
import { extendIps } from "client/store/alumnus/resolvers/getters/ipsWCountries";
import { GET_BANNED_IPS } from "./queries";


export const bannedIpsWCountries = async (_root, _args, { client }) => {
  try {
    const { data: { ips } = {} } = await client.query({ query: GET_BANNED_IPS });
    if (!(ips && ips.length > 0)) return [];

    return await extendIps(ips);

  } catch (e) {
    errorLogStore("bannedIps", "bannedIpsWCountries", e, "on fetch geoip");
    return [];
  }
};

export default bannedIpsWCountries;
