import React, { useContext } from "react";
import moment from "moment";
import clsx from "clsx";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import envVars from "config/envVars";
import texts from "config/texts";
import { SessionContext } from "client/lib/providers/SessionProvider";


const useStyles = makeStyles(() => ({
  root: {
    color: "rgba(0, 0, 0, 0.8)",
  },
}));


const startYear = 2014;

export const productName = "Alumni Online!";

const author = "Szulyovszky Dávid";

const authorWGroup = `${author} 2006A`;

const version = envVars.appVersion && `v${envVars.appVersion}`;


const Copyright = ({ noVersion, inner }) => {

  const { authenticated } = useContext(SessionContext);

  const classes = useStyles();

  return (
    <Typography variant="caption"
                color={inner ? "textSecondary" : "textPrimary"}
                className={clsx("text-11", !inner && classes.root)}
    >
      <Grid container spacing={1} className={clsx(!authenticated && "md:pt-1")}>
        <Grid item>
          {clsx(productName, !noVersion && version)}
        </Grid>
        <Grid item>
          &copy;&nbsp;{authenticated ? authorWGroup : author}
        </Grid>
        <Grid item>
          &copy;&nbsp;{startYear}{clsx(moment().year() > startYear && `-${moment().year()}`)}&nbsp;{texts.organization}
        </Grid>
      </Grid>
    </Typography>
  );
};

export default React.memo(Copyright);
