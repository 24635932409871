import React from "react";
import { PhoneNumber } from "components/form";
import useSecondaryPhoneNumber from "./useSecondaryPhoneNumber";


const SecondaryPhoneNumber = ({ record, requirements, required, label = "További telefonszám", ...restProps }) => {

  const { fieldRequirements } = useSecondaryPhoneNumber({
    record,
    requirements,
  });

  return (
    <PhoneNumber {...restProps}
                 record={record}
                 requirements={fieldRequirements}
                 required={required || true}
                 label={label}
    />
  );
};

export default React.memo(SecondaryPhoneNumber);
