import React, { useCallback, useContext, useMemo } from "react";
import { FormContext } from "../formControl";
import { requirementMessages } from "../config";
import Input from "./Input";


const Year = ({ record, prefixedId, min, max, minYearField, maxYearField, requirements, ...restProps }) => {

  const { getValue } = useContext(FormContext);


  const safeMin = useMemo(() => min || 1900, [min]);

  const safeMax = useMemo(() => max || new Date().getFullYear(), [max]);


  const getMinYear = useCallback(() => minYearField && getValue(record && record.__typename, prefixedId, minYearField)
    , [minYearField, getValue, prefixedId, record]);


  const getMaxYear = useCallback(() => maxYearField && getValue(record && record.__typename, prefixedId, maxYearField)
    , [maxYearField, getValue, prefixedId, record]);


  const validateOtherYear = useCallback(year => {
    const minYear = getMinYear();
    const maxYear = getMaxYear();

    if (!year && maxYear) return requirementMessages.yearInterval.missingFromYear;

    if (year && minYear && parseInt(minYear) > year) return requirementMessages.yearInterval.fromYear;
    if (year && maxYear && parseInt(maxYear) < year) return requirementMessages.yearInterval.toYear;

    return true;
  }, [getMaxYear, getMinYear]);


  const _requirements = useMemo(() => ({
    ...requirements,
    validate: {
      ...(requirements && requirements.validate),
      validateOtherYear,
    },
    min: {
      value: safeMin,
      message: `Az év nem lehet kevesebb, mint ${safeMin}`,
    },
    max: {
      value: safeMax,
      message: `Az év nem lehet több, mint ${safeMax}`,
    },
  }), [requirements, safeMax, safeMin, validateOtherYear]);


  return (
    <Input
      {...restProps}
      record={record}
      requirements={_requirements}
      onlyNumbers
    />
  );
};

export default React.memo(Year);
