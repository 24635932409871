import React, { useCallback, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { DateField, Input } from "components/form";
import { getStatus } from "./CardStatus";


const minNr = 0;

const maxNr = 1e16;


const CardEntry = ({ status, record, _updateFields }) => {

  const statusRef = useRef(status);


  const getRequiredErrorStr = useCallback(status => `${getStatus(status).label} kártyánál kötelező megadni`, []);


  const requiredOnActive = useMemo(() => status === dbEnums.cardStatus.ACTIVE && getRequiredErrorStr(status), [getRequiredErrorStr, status]);


  const requiredOnRevoked = useMemo(() => status === dbEnums.cardStatus.REVOKED && getRequiredErrorStr(status), [getRequiredErrorStr, status]);


  const conditionalRequiredNrAndIssueDate = useCallback(value => (statusRef.current !== dbEnums.cardStatus.ACTIVE && statusRef.current !== dbEnums.cardStatus.REVOKED)
    || Boolean(value && clsx(value).trim())
    || getRequiredErrorStr(statusRef.current)
    , [getRequiredErrorStr]);


  const nrRequirements = useMemo(() => ({
    min: {
      value: minNr,
      message: `A kártyaszám nem lehet kisebb, mint ${minNr}`,
    },
    max: {
      value: maxNr,
      message: `A kártyaszám nem lehet nagyobb, mint ${maxNr}`,
    },
    validate: {
      conditionalRequiredNrAndIssueDate,
    }
  }), [conditionalRequiredNrAndIssueDate]);


  const issueDateRequirements = useMemo(() => ({
    validate: {
      conditionalRequiredNrAndIssueDate,
    }
  }), [conditionalRequiredNrAndIssueDate]);


  const conditionalRequiredExp = useCallback(value => statusRef.current !== dbEnums.cardStatus.REVOKED
    || Boolean(value && clsx(value).trim())
    || getRequiredErrorStr(statusRef.current)
    , [getRequiredErrorStr]);


  const expRequirements = useMemo(() => ({
    validate: {
      conditionalRequiredExp,
    }
  }), [conditionalRequiredExp]);


  useEffect(() => {
    statusRef.current = status;
  }, [status]);


  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12} md={4}>
        <Input record={record}
               field={_updateFields.nr}
               requirements={nrRequirements}
               componentRequired={requiredOnActive || requiredOnRevoked}
               onlyNumbers
               className="mb-32 md:mb-8"
               label="Kártyaszám"
               helperText="Csak számjegyeket tartalmazhat"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateField record={record}
                   field={_updateFields.issueDate}
                   requirements={issueDateRequirements}
                   componentRequired={requiredOnActive || requiredOnRevoked}
                   className="mb-32 md:mb-8"
                   label="Kibocsátás dátuma"
                   pickerClassName="w-full"
                   showTodayButton
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DateField record={record}
                   field={_updateFields.exp}
                   requirements={expRequirements}
                   componentRequired={requiredOnRevoked}
                   disabled={status !== dbEnums.cardStatus.REVOKED}
                   className="mb-8"
                   label="Lejárat/visszavonás dátuma"
                   pickerClassName="w-full"
                   showTodayButton
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(CardEntry);
