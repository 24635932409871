import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { maxBodyBufferSizeInBytes } from "server/lib/api/config";


export const accept = "image/jpeg, image/png, image/apng, image/webp";

const allowedExtensionsText = ".jpg, .jpeg, .png, .apng, .webp";

export const minSize = 1024; // in bytes

export const maxSize = maxBodyBufferSizeInBytes; // in bytes

const minDimension = 150; // in pixels


const useFileUpload = setFile => {

  const [error, setError] = useState("");


  const validateFilesCount = useCallback((acceptedFiles, fileRejections) => {
    const accepted = (acceptedFiles || []).length || 0;
    const rejected = (fileRejections || []).length || 0;

    const count = accepted + rejected;
    if (count > 1) setError("Egyszerre csak egy képfájl tölthető be");

    return count !== 1;
  }, []);


  const loadImage = useCallback(file => new Promise(resolve => {
    try {
      const img = new Image();
      img.src = file && URL.createObjectURL(file);

      img.onerror = () => {
        setError("Hiba a kép beolvasásakor");
        resolve(null);
      };

      img.onload = () => {
        resolve(img);
      };

    } catch (e) {
      setError("Hiba a kép beolvasásakor");
      resolve(null);
    }
  }), []);


  const validateDimensions = useCallback(async img => {
    const invalidWidth = img.width < minDimension;
    const invalidHeight = img.height < minDimension;

    if (invalidWidth) setError(`A kép nem elég széles (minimum szélesség: ${minDimension} pixel)`);
    if (invalidHeight) setError(`A kép nem elég magas (minimum magasság: ${minDimension} pixel)`);

    return invalidWidth || invalidHeight;
  }, []);


  const handleAcceptedFiles = useCallback(async acceptedFiles => {
    const file = acceptedFiles && acceptedFiles[0];
    const img = file && await loadImage(file);
    if (!file || !img) return null;

    const invalidDimensions = await validateDimensions(img);
    if (invalidDimensions) return null;

    setFile(file);
    return true;
  }, [setFile, loadImage, validateDimensions]);


  const validateFileSize = useCallback(file => {
    if (file.size < minSize) setError(`Túl kicsi fájlméret (minimum ${Math.floor(minSize / 1024)} kB)`);
    if (file.size > maxSize) setError(`Túl nagy fájlméret (maximum ${Math.floor(maxSize / 1024 / 1024)} MB)`);

    return file.size < minSize || file.size > maxSize;
  }, []);


  const handleFileRejections = useCallback(fileRejections => {
    const file = fileRejections && fileRejections[0] && fileRejections[0].file;
    if (!file) return null;

    const invalidFileSize = validateFileSize(file);
    if (invalidFileSize) return null;

    setError(`Nem megfelelő formátum (engedélyezett fájltípusok: ${allowedExtensionsText})`);
  }, [validateFileSize]);


  const onDrop = useCallback(async (acceptedFiles, fileRejections) =>
    validateFilesCount(acceptedFiles, fileRejections) || await handleAcceptedFiles(acceptedFiles) || handleFileRejections(fileRejections)
    , [handleAcceptedFiles, handleFileRejections, validateFilesCount]);


  const { open, ...dropzone } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop,
  });


  const onOpen = useCallback(e => {
    e.preventDefault();
    open();
  }, [open]);


  return {
    error,
    onOpen,
    ...dropzone,
  };
};

export default useFileUpload;
