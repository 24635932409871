import React, { useCallback, useMemo, useRef } from "react";
import clsx from "clsx";
import Layout from "components/layout";
import { FusePageCarded } from "components/fuse";
import SidebarHeader from "./SidebarHeader";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Content from "./Content";


export const ListContext = React.createContext({});


const ListPage = ({
                    error, loading, notFound, forbidden, title, icon, help, adminVerification, multiSelect, noFilters,
                    HeaderComponent, ToolbarComponent, SidebarComponent, RowComponent, headerHeight
                  }) => {


  const pageLayout = useRef(null);


  const toggleSidebar = useCallback(() => pageLayout.current && pageLayout.current.toggleLeftSidebar(), []);


  const theme = useTheme();

  const screenMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const screenLgUp = useMediaQuery(theme.breakpoints.up("lg"));


  const safeHeaderHeight = useMemo(() => (!screenLgUp && SidebarComponent) || (!screenMdUp && !SidebarComponent)
    ? headerHeight || 240
    : undefined
    , [SidebarComponent, headerHeight, screenLgUp, screenMdUp]);


  return (
    <Layout error={error} notFound={!loading && notFound} forbidden={forbidden} loading={loading} help={help}>
      <FusePageCarded
        classes={{
          root: "w-full pb-20 sm:pb-36",
          header: "items-center w-full h-full"
        }}
        headerHeight={safeHeaderHeight}
        header={HeaderComponent && (
          <HeaderComponent disableSidebar={!SidebarComponent}
                           toggleSidebar={toggleSidebar}
                           title={title}
                           icon={icon}
                           noFilters={noFilters}
                           className={clsx(SidebarComponent ? "px-8" : "px-16", "lg:px-20")}
          />
        )}
        contentToolbar={ToolbarComponent && (
          <ToolbarComponent/>
        )}
        content={
          <Content RowComponent={RowComponent}
                   adminVerification={adminVerification}
                   multiSelect={multiSelect}
          />
        }
        leftSidebarHeader={SidebarComponent && (
          <SidebarHeader title={title}
                         icon={icon}
          />
        )}
        leftSidebarContent={SidebarComponent && (
          <SidebarComponent/>
        )}
        ref={pageLayout}
      />
    </Layout>
  );
};

export default React.memo(ListPage);
