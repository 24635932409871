import React, { useCallback, useContext, useMemo } from "react"
import clsx from "clsx";
import { Scrollbars as ReactCustomScrollbars } from "react-custom-scrollbars";
import { makeStyles } from "@material-ui/core/styles";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


export const useStyles = makeStyles(theme => ({
  scrollThumbCenter: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "rgba(0, 0, 0, 0.33)",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  scrollThumbRight: {
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "rgba(0, 0, 0, 0.33)",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "rgba(0, 0, 0, 0.33)",
    },
  },
  scrollThumbFull: {
    backgroundColor: "rgba(0, 0, 0, 0.33)",
  },
  scrollTrack: {
    minWidth: "0.9rem",
    zIndex: 2000,
  },
}));


const Scrollbars = ({ children, center, right, className, thumbClassName }) => {

  const { scroll: { refCb } } = useContext(UiStateContext);


  const classes = useStyles();


  const _thumbClassName = useMemo(() => center
    ? classes.scrollThumbCenter
    : right
      ? classes.scrollThumbRight
      : classes.scrollThumbFull, [center, classes.scrollThumbCenter, classes.scrollThumbFull, classes.scrollThumbRight, right]);


  const renderThumbVertical = useCallback(props => (
    <div {...props} className={clsx(_thumbClassName, "rounded-2", thumbClassName)}/>
  ), [_thumbClassName, thumbClassName]);


  const renderTrackVertical = useCallback(props => (
    <div {...props} className={clsx(classes.scrollTrack, "right-0 h-full")}/>
  ), [classes.scrollTrack])


  return (
    <ReactCustomScrollbars className={className}
                           autoHide
                           renderThumbVertical={renderThumbVertical}
                           renderTrackVertical={renderTrackVertical}
                           ref={refCb}
    >
      {children}
    </ReactCustomScrollbars>
  );
};

export default React.memo(Scrollbars);
