import { useCallback, useEffect } from "react";
import { safeFnOrPromise } from "common/helpers";
import { emitUiStateEvent, uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";


const useHandleSubmit = ({ triggerValidation, handleSubmit, dirty, save, savePostProcess, disableSubmit, enableSubmit }) => {

  const onSubmitInvalid = useCallback(() => emitUiStateEvent(uiStateEvents.form.submitFinished, {
    success: false,
    invalid: true,
  }), []);


  const onSubmitCompleted = useCallback(async config => {
    await safeFnOrPromise(savePostProcess)();

    if (typeof disableSubmit === "function") disableSubmit();

    emitUiStateEvent(uiStateEvents.form.submitFinished, {
      ...config,
      success: true,
    });
  }, [disableSubmit, savePostProcess]);


  const onSubmitError = useCallback((config = {}) => emitUiStateEvent(uiStateEvents.form.submitFinished, {
    ...config,
    success: false,
  }), []);


  const onSubmit = useCallback(async (...submitArgs) => {
    if (!await triggerValidation()) return onSubmitInvalid();

    try {
      const { error, ...config } = await safeFnOrPromise(save)(...submitArgs) || {};

      if (error) {
        onSubmitError(config);
      } else {
        onSubmitCompleted(config);
      }

    } catch (e) {
      onSubmitError();
    }
  }, [onSubmitCompleted, onSubmitError, onSubmitInvalid, save, triggerValidation]);


  const safeHandleSubmit = useCallback((event, ...args) => {
    event.preventDefault();
    return handleSubmit(event, ...args);
  }, [handleSubmit]);


  useEffect(() => {
    if (dirty && typeof enableSubmit === "function") enableSubmit();
  }, [dirty, enableSubmit]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitStarted, onSubmit);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitStarted, onSubmit);
    }
  }, [onSubmit]);


  return safeHandleSubmit;

};

export default useHandleSubmit;
