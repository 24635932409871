import React from "react";


const FusePageSimpleHeader = props => {
  return (
    <div className={props.classes.header}>
      {props.header}
    </div>
  );
};

export default React.memo(FusePageSimpleHeader);
