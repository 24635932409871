import nextTick from "next-tick";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm as useReactHookForm } from "react-hook-form";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { getPrefixedId } from "../helpers";
import useValues from "./useValues";


const useForm = ({ records, mode = "onBlur" }) => {

  const serializeRecords = useCallback(() => (records || []).reduce((serializedRecords, record) => ({
    ...serializedRecords,
    [record.__typename]: {
      ...serializedRecords[record.__typename],

      // workaround: need to prefix the id because react-hook-form cannot handle properly numerics in nested names of form elements
      // (ie: in case of phone's note field "phone.id_1001.note" should be used instead of "phone.1001.note")
      [getPrefixedId(record.id)]: record
    },
  }), {}), [records]);


  const [defaultValues, setDefaultValues] = useState(() => serializeRecords());


  const getDefaultValues = useCallback(forced => {
    if (!forced && defaultValues) return defaultValues;

    const serializedRecords = serializeRecords();
    setDefaultValues(serializedRecords);

    return serializedRecords;
  }, [defaultValues, serializeRecords]);


  const { clearError, control, errors: formErrors, formState: { dirty, isSubmitted }, getValues: _getValues, handleSubmit, register, unregister, reset, setValue, triggerValidation } = useReactHookForm({
    defaultValues: getDefaultValues(false),
    mode,
  });


  const { getItemValues, getValue, getValues } = useValues({
    getValues: _getValues,
    getDefaultValues,
  });


  const safeControl = useRef(control);


  const getControl = useCallback(() => safeControl.current, []);


  const validateUniqueness = useCallback((__typename, field, value, recordId) => {
    const typeValues = getValues()[__typename] || {};
    const prefixedId = getPrefixedId(recordId);

    const sameItems = Object.entries(typeValues).filter(([pId, { [field]: v }]) => pId !== prefixedId && v === value);

    return Boolean(sameItems && sameItems.length);
  }, [getValues]);


  const onDataRefetched = useCallback(() => nextTick(() => {
    const defaultValues = getDefaultValues(true);
    reset(defaultValues);
  }), [getDefaultValues, reset]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.dataRefetched, onDataRefetched);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.dataRefetched, onDataRefetched);
    }
  }, [onDataRefetched]);


  return {
    clearError,
    dirty,
    isSubmitted,
    formErrors,
    getControl,
    getItemValues,
    getValue,
    getValues,
    handleSubmit,
    register,
    unregister,
    setValue,
    triggerValidation,
    validateUniqueness,
  };

};

export default useForm;
