import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { revisionStatuses } from "client/store/alumnus/resolvers/getters";
import { AlumnusContext } from "components/alumnus";


const StatusAlert = ({ alumnus: { status, name, stats } }) => {

  const { publicProps, revisionDate, revisionStatus } = status || {};


  const { myProfile } = useContext(AlumnusContext);


  const firstName = useMemo(() => name && (name.nick || name.first), [name]);


  const description = useMemo(() => revisionStatus === revisionStatuses.upToDate
    ? clsx(firstName, "mintegy", revisionDate, "megerősítette adatait.")
    : clsx(firstName, "utoljára mintegy", revisionDate, "erősítette meg adatait.")
    , [revisionStatus, firstName, revisionDate]);


  return Boolean(!myProfile && publicProps && stats && stats.anyData) && (
    <Grid item xs={12} className="md:pr-32">
      <Alert severity={publicProps.severity}>
        <Typography variant="caption">{publicProps.status}&nbsp;{description}</Typography>
      </Alert>
    </Grid>
  );
};

export default React.memo(StatusAlert);
