import { dbEnums } from "common/enums";
import { trimStr } from "common/helpers";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { removeMetaKeys, doMutation, getTagIds } from "client/store/helpers";
import { UPSERT_SECONDARY_EMAILS, DELETE_SECONDARY_EMAILS } from "client/store/alumnus";


const tagEntity = dbEnums.tagEntity.EMAIL;


const normalizeRecord = async (client, { tags: rawTags, note: rawNote, id, ...data }, { primaryEmailId, alumnusId }, issue) => {
  const note = trimStr(rawNote, true);
  const tags = await getTagIds(tagEntity, rawTags, client);

  return {
    __isPrimary: Boolean(primaryEmailId && id === primaryEmailId),
    alumnus__id: alumnusId,
    ...data,
    ...(id ? { id } : {}),
    ...(issue ? { issue: true } : {}),
    note,
    tags,
  };
};


const normalizeRecords = async (client, { emails, ...args }, issue) => {
  const normalizedRecords = [];

  for (let email of (emails || [])) {
    const normalizedRecord = await normalizeRecord(client, email, args, issue);
    normalizedRecords.push(normalizedRecord);
  }

  return normalizedRecords;
};


const getPrimaryRecord = records => (records || [])
  .filter(({ __isPrimary }) => __isPrimary)
  .map(removeMetaKeys)[0];


const getSecondaryRecords = (records, deletedIds) => (records || [])
  .filter(({ id, __isPrimary }) => !__isPrimary && !(deletedIds || []).includes(id))
  .map(removeMetaKeys);


const savePrimary = async body => {
  const { error } = await backendFetch(backendServices.alumnus.updateEmail, body);
  if (error) throw new Error();
};


const saveEmails = async (_root, { deletedIds, issue, ...args }, { client }) => {
  const records = await normalizeRecords(client, args, issue);

  const primary = getPrimaryRecord(records);
  if (primary) await savePrimary(primary);

  if (!issue) {
    const secondaries = getSecondaryRecords(records, deletedIds);
    if (secondaries && secondaries.length) await doMutation(client, UPSERT_SECONDARY_EMAILS, {
      records: secondaries
    });

    if (deletedIds && deletedIds.length) await doMutation(client, DELETE_SECONDARY_EMAILS, {
      ids: deletedIds
    });
  }
};

export default saveEmails;
