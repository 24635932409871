import React from "react";
import { Typography } from "@material-ui/core";


const Dashboard = () => (
  <>
    <Typography className="mb-20">
      A <b>kezdőlapon</b> olyan információkra, teendőkre hívjuk fel a figyelmedet, amelyek segítségedre lehetnek
      abban, hogy alumnus adatlapod naprakész maradjon, ezáltal pedig minél hatékonyabban tudj részt venni az Alumni
      online kapcsolatépítő terében.
    </Typography>
    <Typography className="my-20">
      Elsőként arra kérdezünk rá, hogy aktuálisak-e az általunk nyilvántartott adatok a munkahelyedről, aktuálisan
      végzett tanulmányaidról, valamint a városról, országról, ahol élsz. Az itt megjelenő információk &ndash; többek
      között &ndash; az adatlapodon, az <span className="text-blue">Alumnus keresőben</span>, valamint az <span
      className="text-blue">Osztálytársak</span> oldalán is megjelennek.
    </Typography>
    <Typography className="my-20">
      <b>Kérjük, tartsd naprakészen alumnus adatlapodat! Amennyiben az itt megjelenő adatok közül bármelyik elavult
        (vagy hiányzó), kérjük, javítsd (vagy pótold)!</b> Ezt legkönnyebben a <span
      className="text-blue">módosítás</span> gombra kattintva teheted meg. Ezzel az alumnus adatlapod szerkesztő nézete
      nyílik meg, a módosítani kívánt adatnak megfelelő űrlappal.
    </Typography>
    <Typography className="my-20">
      Amennyiben az <span className="text-blue">alumnus adatlapodon</span> szereplő többi adatot szeretnéd megtekinteni,
      módosítani, kitölteni, akkor kattints a főmenü <span className="text-blue">Saját adatlapom</span> menüpontjára.
      Ekkor megnyílik az adatlapod, amelyet a fejlécben lévő <span
      className="text-blue">Adataim módosítása</span> gombra kattintva módosíthatsz.
    </Typography>
    <Typography className="my-20">
      A kezdőlapon a <span className="text-blue">Gyakran ismételt kérdések</span> közül néhány véletlenszerűen
      kiválasztott kérdés is megjelenik. A válaszokat a <span className="text-blue">Bővebben</span> gombra kattintva
      olvashatod. A főmenü <span className="text-blue">Segíthetünk?</span> menüpontja alól pedig magát a <span
      className="text-blue">Gyakran ismételt kérdések</span> oldalát nyithatod meg, ahol valamennyi kérdést és választ
      megtalálsz.
    </Typography>
    <Typography className="mt-20">
      A kezdőlap alján statisztikákat olvashatsz a regisztrált alumnusok illetve osztálytársak számáról, valamint a
      legutóbb regisztrált osztálytársaid listáját is megtekintheted. A regisztrált alumnusok számára (vagy a kártyán
      lévő gombra) kattintva az <span className="text-blue">Alumnus keresőre</span> navigálhatsz. Az osztálytársak
      számára (vagy a kártyán lévő gombra) kattintva az <span className="text-blue">Osztálytársak</span> oldalára
      navigálhatsz. (A legutóbb regisztrált osztálytársak fejlécében található <span
      className="text-blue">Mindenki</span> gomb is ez utóbbi oldalra navigál.)
    </Typography>
  </>
);

export default React.memo(Dashboard);
