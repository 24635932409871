import clsx from "clsx";
import { trimStr } from "common/helpers";
import { removeMetaKeys, doMutation } from "client/store/helpers";
import { UPSERT_JOBS, DELETE_JOBS } from "client/store/alumnus";


const normalizeRecord = async (client, { id, company: rawCompany, job: rawJob, note: rawNote, ...data }, { alumnusId }) => {
  const company = clsx(rawCompany).trim();
  const job = clsx(rawJob).trim();
  const note = trimStr(rawNote, true);

  return {
    alumnus__id: alumnusId,
    ...data,
    ...(id ? { id } : {}),
    company,
    job,
    note,
  };
};


const normalizeRecords = async (client, { jobs, ...args }) => {
  const normalizedRecords = [];

  for (let job of (jobs || [])) {
    const normalizedRecord = await normalizeRecord(client, job, args);
    normalizedRecords.push(normalizedRecord);
  }

  return normalizedRecords;
};


const getRecords = (records, deletedIds) => (records || [])
  .filter(({ id }) => !(deletedIds || []).includes(id))
  .map(removeMetaKeys);


const saveJobs = async (_root, { deletedIds, ...args }, { client }) => {
  const normalizedRecords = await normalizeRecords(client, args);

  const records = getRecords(normalizedRecords, deletedIds);
  if (records && records.length) await doMutation(client, UPSERT_JOBS, {
    records
  });

  if (deletedIds && deletedIds.length) await doMutation(client, DELETE_JOBS, {
    ids: deletedIds
  });
};

export default saveJobs;
