import { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dbEnums } from "common/enums";
import { GET_ENTITY_TAGS } from "client/store/alumnus";


const useTags = entity => {


  const skip = useMemo(() => !entity || !Object.values(dbEnums.tagEntity).includes(entity), [entity]);


  const { loading, error, data: { tags: rawTags } = {} } = useQuery(GET_ENTITY_TAGS, {
    fetchPolicy: "network-only",
    variables: {
      entities: [entity]
    },
    skip,
  });


  const tags = useMemo(() => (skip || error)
    ? []
    : rawTags || []
    , [error, rawTags, skip]);


  return {
    loading,
    tags,
  };

};

export default useTags;
