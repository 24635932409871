import { getService, contentTypes } from "./helpers";


export const blob = {
  csv: getService("blob/csv", {
    fileType: "text/csv;charset=UTF-8",
    fileExtension: "csv",
  }),
  xlsx: getService("blob/xlsx", {
    acceptContentType: contentTypes.octet,
    fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    fileExtension: "xlsx",
  }),
};
