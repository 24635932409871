import { dbEnums } from "common/enums";
import { pathNames } from "./routing";
import texts from "./texts";


const navigationConfig = [
  {
    id: "szolgaltatasok",
    title: "Alumnus szolgáltatások",
    allowedRoles: [dbEnums.accountRoles.ALUMNUS],
    type: "group",
    children: [
      {
        id: 1,
        title: "Kezdőlap",
        icon: "dashboard",
        href: pathNames.startPage.href(),
      },
      {
        id: 2,
        title: "Osztályom",
        disallowedSpecial: true,
        icon: "group",
        href: pathNames.group.href,
      },
      {
        id: 3,
        title: "Alumnus kereső",
        icon: "contacts",
        href: pathNames.search.href,
      },
      {
        id: 4,
        title: "Saját adatlapom",
        icon: "account_circle",
        href: pathNames.alumnus.href,
        as: pathNames.alumnus.as("me"),
        isActiveRegex: new RegExp(`^${pathNames.alumnus.as("me")}(?!/edit)`),
      },
      {
        id: 5,
        title: "Adataim módosítása",
        icon: "edit",
        href: pathNames.alumnus.href,
        as: `${pathNames.alumnus.as("me")}/edit`,
        isActiveRegex: new RegExp(`^${pathNames.alumnus.as("me")}/edit`),
      },
      {
        id: 6,
        title: "Adataim ellenőrzése",
        icon: "check_box",
        href: pathNames.revision.href,
      },
    ],
  },
  {
    id: "admin",
    title: "Adminisztráció",
    type: "group",
    allowedRoles: [dbEnums.accountRoles.MANAGER],
    children: [
      {
        id: 1,
        title: "Tagság",
        icon: "folder_shared",
        href: pathNames.contacts.href,
      },
      {
        id: 2,
        title: texts.cardsName,
        icon: "credit_card",
        href: pathNames.cards.href,
      },
      {
        id: 3,
        title: "EmailOctopus",
        icon: "email",
        href: pathNames.emailoctopus.href,
      },
      {
        id: 4,
        title: "Egyedi regisztrációs kódok",
        icon: "person_add",
        href: pathNames.regCodes.href,
      },
      {
        id: 5,
        title: "Speciális felhasználók",
        icon: "face",
        href: pathNames.specialAccounts.href,
      },
      {
        id: 6,
        title: "Papíralapú regisztráció",
        icon: "supervised_user_circle",
        href: pathNames.adminRegistration.href,
      },
      {
        id: 7,
        title: "Adategyeztetési körök",
        icon: "notifications",
        href: pathNames.revisionRequests.href,
      },
      {
        id: 8,
        title: "Kitiltott IP címek",
        icon: "network_locked",
        href: pathNames.bannedIps.href,
      },
    ],
  },
  {
    id: "specialadmin",
    title: "Adminisztráció",
    allowedRoles: [dbEnums.accountRoles.RETAILER, dbEnums.accountRoles.STUDENT],
    type: "group",
    children: [
      {
        id: 1,
        title: "Tagság",
        allowedRoles: [dbEnums.accountRoles.STUDENT],
        icon: "folder_shared",
        href: pathNames.contacts.href,
      },
      {
        id: 2,
        title: texts.cardsName,
        icon: "credit_card",
        href: pathNames.cards.href,
      },
    ],
  },
  {
    id: "informaciok",
    title: "Információk",
    type: "group",
    children: [
      {
        id: 1,
        title: "Segíthetünk?",
        icon: "dashboard",
        href: pathNames.faq.href,
        as: pathNames.faq.as(),
        isActiveRegex: /^\/faq\/[^/]+?/,
      },
      {
        id: 2,
        title: "Adatkezelési Tájékoztató",
        icon: "account_circle",
        href: pathNames.terms.href,
      },
    ],
  },
  {
    id: "app",
    title: "Szoftver",
    type: "group",
    allowedRoles: [dbEnums.accountRoles.MANAGER],
    children: [
      {
        id: 1,
        title: "Verziótörténet",
        icon: "check_box",
        href: pathNames.changelog.href,
      },
    ],
  },
];

export default navigationConfig;
