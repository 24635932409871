import gql from "graphql-tag";


export const GET_CARDS = gql`
  query GetCards {
    cards: view_card(order_by: {duplicated: desc_nulls_last, card_number: asc_nulls_first, created: desc_nulls_first, name: asc_nulls_first}) {
      id
      nr: card_number
      status
      statusLabel: status_text
      issueDate: date_of_issue
      exp: expiry
      alumnusId: alumnus__id
      group: grp_text
      name
      dup: duplicated_text
    }
  }
`;
