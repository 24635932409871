import { downloadCardsCSV, downloadCardsXLSX } from "./download";
import saveCard from "./saveCard";


export const resolvers = {
  Mutation: {
    downloadCardsCSV,
    downloadCardsXLSX,
    saveCard,
  },
};
