import { GET_SETTINGS, UPSERT_SETTING, updateUpsertSetting } from "client/store/setting";
import { octopusSettingKey } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { decoratedError, errors } from "common/errors";


const fetchSettings = (client, fetchPolicy = "cache-first") => client.query({
  query: GET_SETTINGS,
  variables: {
    ids: [octopusSettingKey]
  },
  fetchPolicy,
});


export const getSettings = async (client) => {
  const { error, data: { settings: [{ value = {} } = {}] = [] } = {} } = await fetchSettings(client, "network-only");

  return error
    ? decoratedError(errors.client.octopus.APOLLO_ERROR, { message: error })
    : value;
};


export const upsertSettings = (client, value) => client.mutate({
  mutation: UPSERT_SETTING,
  variables: {
    id: octopusSettingKey,
    value,
  },
  update: updateUpsertSetting,
});


const checkInitialized = ({ primaryList: { id: pId } = {}, secondaryList: { id: sId } = {} }) => pId && sId;


const validateSettings = (settings = {}) => !checkInitialized(settings)
  ? errors.client.octopus.UNINITIALIZED_SETTINGS
  : settings;


export const getValidatedSettings = async (client) => {
  const settings = await getSettings(client);
  if (settings.error) return settings;

  return validateSettings(settings);
};


export const updateSettings = async (client, updates) => {
  try {
    const settings = await getSettings(client);
    if (settings.error) return settings;

    const updatedSettings = {
      ...settings,
      ...updates,
      lastInit: Date.now(),
    };

    await upsertSettings(client, updatedSettings);
    return updatedSettings;

  } catch (e) {
    const message = errorLogStore("octopus", "updateSettings", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};
