import clsx from "clsx"
import { getFormattedCurrentDate } from "common/helpers";


const withTimestamp = message => `[${getFormattedCurrentDate()}] ${message}`;


export const consoleError = (message, error = "") => process.env.DEV_MODE && console.error(withTimestamp(message), error);


const log = (lib = "", module = "", fn = "", error = "", message = "") => {
  const errorMsg = `[${lib} error]${clsx(fn && ` in ${fn}`)}${clsx(module && ` @ ${module}`)}${clsx(message && ` ${message}`)}`;
  consoleError(errorMsg, error);
  return withTimestamp(errorMsg);
};


export const errorLogLib = (lib, fn, error, message) => log(lib, "", fn, error, message);


export const errorLogStore = (module, fn, error, message) => log("client/store", module, fn, error, message);
