export const decoratedError = (appError, errorPayload) => ({
  error: {
    ...(appError && appError.error),
    ...errorPayload,
  },
});


const decorateWrapperWServiceBan = (wrapper, expiry) => ({
  ...wrapper,
  serviceBan: {
    ...(wrapper && wrapper.serviceBan),
    banned: true,
    expiry,
  },
});


export const decorateWServiceBan = (response, expiry) => {
  const isError = response && response.error;

  const wrapper = isError ? response.error : response;
  const decorated = decorateWrapperWServiceBan(wrapper, expiry);

  return !isError
    ? decorated
    : {
      ...response,
      error: decorated,
    };
};


export const isSameAppError = (errA, errB) => {
  const _errA = (errA || {}).error || {};
  const _errB = (errB || {}).error || {};

  return _errA.service === _errB.service
    && _errA.code === _errB.code
    && _errA.clientSide === _errB.clientSide;
};
