import React from "react";
import clsx from "clsx"
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 21,
    borderRadius: 2,
    padding: "0 6px",
    fontSize: 11,
    backgroundColor: "rgba(0,0,0,.08);",
  },
  color: {
    width: 8,
    height: 8,
    marginRight: 4,
    borderRadius: "50%",
  },
}));


const MiniChip = ({ label, className, wrapperClassName, colorClassName, tooltip }) => {

  const classes = useStyles();

  const chip = (
    <div className={clsx("flex justify-start", wrapperClassName)}>
      <div className={clsx(classes.root, className)}>
        {Boolean(colorClassName) && (
          <div className={clsx(classes.color, colorClassName)}/>
        )}
        <div>{label}</div>
      </div>
    </div>
  )

  return !tooltip
    ? chip
    : (
      <Tooltip title={clsx(tooltip)}>
        {chip}
      </Tooltip>
    );
};

export default React.memo(MiniChip);
