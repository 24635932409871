import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { Icon } from "@material-ui/core";
import { useApolloClient } from "@apollo/react-hooks";
import { maxRecordsCounts } from "config/form";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { SAVE_EMAILS } from "client/store/alumnus";
import { Alert } from "components/common";
import { AlumnusContext } from "components/alumnus";
import { Form, Email } from "components/form";
import { __typename as nwsTypename, updateFields as nwsUpdateFields } from "components/form/newsletter";
import {
  requiredFields,
  updateFields,
  fieldAliases,
  newRecordValues,
  __typename,
  NwsEmail
} from "components/form/email";


const PanelContents = {
  [__typename]: Email,
  [nwsTypename]: NwsEmail,
};


// because of the extra panel for newsletter delivery address
const maxRecordsCount = maxRecordsCounts.email + 1;


export const captionCb = ({ __typename, expanded, isPrimary, prefixedId, getValue }) => {
  if (__typename === nwsTypename) return "Hírlevél kézbesítési címe";

  const email = getValue(__typename, prefixedId, updateFields[__typename].email);

  return expanded
    ? email ? clsx("Email cím szerkesztése") : "Add meg az új email címet!"
    : isPrimary ? "Kapcsolattartási email cím" : "További email cím"
};


export const titleCb = ({ __typename, prefixedId, getValue }) => {
  const fieldName = __typename === nwsTypename
    ? nwsUpdateFields[__typename].email
    : updateFields[__typename].email;

  return clsx(getValue(__typename, prefixedId, fieldName))
};


export const getTitleComponentCb = () => (
  <div className="min-w-20 mr-8 flex items-center">
    <Icon className="text-16" color="action">alternate_email</Icon>
  </div>
);


const EditEmail = () => {

  const { personal: { privacy } } = useContext(SessionContext);

  const { submitLoading, disableSubmit, enableSubmit, alumnus: { id: alumnusId, emails, primaryEmail: { id: primaryEmailId }, news } } = useContext(AlumnusContext);


  const client = useApolloClient();


  const newRecordValuesWPrivacy = useMemo(() => ({
    ...newRecordValues,
    [updateFields[__typename].privacy]: privacy || 0,
  }), [privacy]);


  const records = useMemo(() => news && news.nws ? [...emails, news] : emails, [emails, news]);


  const saveCb = useCallback(async ({ jobs, deletedIds }) => await client.mutate({
    mutation: SAVE_EMAILS,
    variables: {
      emails: jobs[__typename],
      deletedIds,
      primaryEmailId,
      alumnusId,
    },
  }), [alumnusId, client, primaryEmailId]);


  return (
    <Form className="max-w-full"
          records={records}
          updateFields={updateFields}
          requiredFields={requiredFields}
          fieldAliases={fieldAliases}
          disabled={submitLoading}
          disableSubmit={disableSubmit}
          enableSubmit={enableSubmit}
          saveCb={saveCb}
          newRecordTypename={__typename}
          newRecordValues={newRecordValuesWPrivacy}
          maxRecordsCount={maxRecordsCount}
          newRecordEntityStr="email cím"
          onErrorEntityStr="email címet"
          primaryId={primaryEmailId}
          PanelContents={PanelContents}
          captionWidth={5}
          captionCb={captionCb}
          titleCb={titleCb}
          getTitleComponentCb={getTitleComponentCb}
          Header={(
            <>
              <Alert className="mb-16">
                Az Alumnival való kapcsolattartás email címén felül további email címeket vehetsz fel a + gombbal.
                Mindegyik láthatóságát külön-külön állíthatod.
              </Alert>
              <Alert severity="warning" noHelpSuffix>
                A kapcsolattartási email cím megváltoztatásával egyúttal a bejelentkezési felhasználóneved is az új
                email címre módosul.
              </Alert>
            </>
          )}
    />
  );
};

export default React.memo(EditEmail);
