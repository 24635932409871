import gql from "graphql-tag";


export const UNSUBSCRIBE_ALUMNUS = gql`
  mutation UnsubscribeAlumnus($id: Int!) {
    results: update_alumnus_newsletter(where: {alumnus: {id: {_eq: $id}}}, _set: {subscription_newsletter: false}) {
      affected_rows
      returning {
        id
        nws: subscription_newsletter
      }
    }
  }
`;
