import React, { useState, useRef, useContext, useCallback, useEffect } from "react";
import clsx from "clsx";
import Router from "next/router";
import nextTick from "next-tick";
import { ClickAwayListener, Icon, IconButton, Paper, InputBase, Tooltip, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { pathNames } from "config/routing";
import { emitUiStateEventNextTick, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { SearchContext } from "client/lib/providers/SearchProvider";


const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    position: "relative"
  },
  input: {
    transition: theme.transitions.create(["background-color"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    }),
    "&:focus": {
      backgroundColor: theme.palette.background.paper
    },
  },
}));


const SearchBar = props => {

  const { isLoading, clearFilters, setSearchText } = useContext(SearchContext);

  const [opened, setOpened] = useState(false);

  const [localSearchText, setLocalSearchText] = useState("");


  const popperNode = useRef(null);
  const searchTextRef = useRef();


  const onChange = useCallback(event => setLocalSearchText(clsx(event.target.value)), []);


  const showSearch = useCallback(() => setOpened(true), []);


  const hideSearch = useCallback(() => {
    setOpened(false);
    setLocalSearchText("");
  }, []);


  const goToSearchPage = useCallback(() => {
    if (localSearchText && !isLoading) {
      hideSearch();
      clearFilters();
      nextTick(() => {
        setSearchText(localSearchText);
        emitUiStateEventNextTick(uiStateEvents.search.updateStarted);
        Router.push(pathNames.search.href, pathNames.search.href, { shallow: true });
      });
    }
  }, [clearFilters, hideSearch, isLoading, localSearchText, setSearchText]);


  const onEscKey = useCallback(event => event.keyCode === 27 && hideSearch(), [hideSearch]);

  const onEnterKey = useCallback(event => event.keyCode === 13 && goToSearchPage(), [goToSearchPage]);


  useEffect(() => {
    document.addEventListener("keydown", onEscKey, false);
    return () => {
      document.removeEventListener("keydown", onEscKey, false);
    }
  }, [onEscKey]);


  useEffect(() => {
    const target = searchTextRef && searchTextRef.current;
    if (target) {
      target.addEventListener("keydown", onEnterKey, false);
      return () => {
        target.removeEventListener("keydown", onEnterKey, false);
      }
    }
  }, [onEnterKey]);


  const classes = useStyles(props);


  return (
    <div className={clsx(classes.root, "flex", props.className)}>

      <Tooltip title="Kattints ide a kereséshez" placement="bottom">
        <div onClick={showSearch}>
          <IconButton className="w-64 h-64"><Icon>search</Icon></IconButton>
        </div>
      </Tooltip>

      {opened && (
        <ClickAwayListener onClickAway={hideSearch}>
          <Paper
            className="absolute left-0 right-0 h-full z-9999"
            square
          >
            <div className="flex items-center w-full" ref={popperNode}>
              <InputBase
                inputRef={searchTextRef}
                inputProps={{
                  className: "py-0 px-16 h-64",
                  "aria-label": "naked",
                }}
                fullWidth
                placeholder="Keress rá bármilyen alumnus adatra..."
                autoFocus
                onChange={onChange}
                value={localSearchText}
              />
              <Hidden xsDown>
                <IconButton onClick={hideSearch} className="mx-8">
                  <Icon>close</Icon>
                </IconButton>
              </Hidden>
              <IconButton onClick={goToSearchPage} className="mx-8" disabled={!localSearchText}>
                <Icon>search</Icon>
              </IconButton>
            </div>
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  )
};

export default React.memo(SearchBar);
