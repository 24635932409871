import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { safeFnOrPromise } from "common/helpers";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { SessionContext } from "client/lib/providers/SessionProvider";


const useBar = ({
                  steps, stepStatements, activeStep, setActiveStep, submitLoading, stopSubmitLoading, unregisterSubmitCb,
                  onNextCb, onBackCb = onNextCb, onEndCb,
                }) => {


  const { authenticated } = useContext(SessionContext);


  const increment = useRef(0);


  const step = useMemo(() => steps[activeStep], [activeStep, steps]);


  const requiredStatements = useMemo(() => {
    if (!step) return 0;

    if (authenticated && Object.keys(step).includes("authenticatedRequiredStatements")) return step.authenticatedRequiredStatements || 0;
    return step.requiredStatements || 0;
  }, [authenticated, step]);


  const last = useMemo(() => activeStep === steps.length - 1, [activeStep, steps.length]);


  const disabledBack = useMemo(() => {
    if (!activeStep || submitLoading) return true;

    const previous = activeStep - 1;
    const previousStep = steps[previous];

    return Boolean(!previousStep || previousStep.irreversible);
  }, [activeStep, steps, submitLoading]);


  const disabledNext = useMemo(() => submitLoading || stepStatements.length < requiredStatements
    , [submitLoading, stepStatements.length, requiredStatements]);


  const onBack = useCallback(() => {
    if (!disabledBack) {
      increment.current = -1;
      safeFnOrPromise(onBackCb)(true);
    }
  }, [disabledBack, onBackCb]);


  const onNext = useCallback(() => {
    if (!disabledNext) {
      increment.current = 1;
      safeFnOrPromise(onNextCb)(false);
    }
  }, [disabledNext, onNextCb]);


  const onSubmitFinished = useCallback(async ({ success }) => {
    if (!success) return;

    await safeFnOrPromise(unregisterSubmitCb)();

    if (last && increment.current > 0) {
      await safeFnOrPromise(onEndCb)();
    } else {
      await safeFnOrPromise(stopSubmitLoading)();
      setActiveStep(activeStep => activeStep + increment.current);
    }
  }, [last, onEndCb, setActiveStep, stopSubmitLoading, unregisterSubmitCb]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitFinished, onSubmitFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitFinished, onSubmitFinished);
    }
  }, [onSubmitFinished]);


  return {
    disabledNext,
    disabledBack,
    last,
    onBack,
    onNext,
  };

};

export default useBar;
