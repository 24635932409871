import gql from "graphql-tag";


export const GET_REG_CODES = gql`
  query GetRegCodes {
    regCodes: reg_code(order_by: {updated: desc_nulls_last}) {
      id
      created
      updated
      code
      name
      mem: membership
      expiry
      group: grp
      safeGroup @client
      formTeacher: form_teacher
      note
      regDate: reg_date
      alumnusId: alumnus__id
      alumnus {
        group: grp
        special @client
        safeGroup @client
        name: alumnus_name {
          first: first_name
          last: last_name
          pre: name_prefix
          suf: name_suffix
          nick: nickname
          fullName @client
        }
      }
      toFilter @client
      used @client
    }
  }
`;
