import clsx from "clsx";


const countryName = ({ cName }) => (cName && cName.n) || null;


const buildCompany = (company, city, country = "") => [company, city, country].join(" ").trim() || null;


const companyFull = ({ country, company, city, cName }) => {
  const { n: countryName = "" } = cName || {};
  const normalizedCountryCode = clsx(country).trim().toUpperCase();
  const normalizedCountry = clsx(countryName).trim() || normalizedCountryCode;
  const normalizedCompany = clsx(company).trim();
  const normalizedCity = clsx(city).trim();
  const domestic = (normalizedCountryCode === "HU");

  const companyWComma = (normalizedCity || (normalizedCountryCode && !domestic))
    ? `${normalizedCompany},`
    : normalizedCompany;
  if (domestic) return buildCompany(companyWComma, normalizedCity);

  const cityWComma = normalizedCountryCode
    ? `${normalizedCity},`
    : normalizedCity;
  return buildCompany(companyWComma, cityWComma, normalizedCountry);
};


const job = {
  countryName,
  companyFull,
};

export default job;
