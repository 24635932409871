import gql from "graphql-tag";


export const SAVE_STUDIES = gql`
  mutation SaveStudies($studies: jsonb!, $deletedIds: [Int!], $alumnusId: Int!) {
    saveStudies(studies: $studies, deletedIds: $deletedIds, alumnusId: $alumnusId) @client
  }
`;


export const UPSERT_STUDIES = gql`
  mutation UpsertStudies($records: [study_insert_input!]!) {
    results: insert_study(objects: $records, on_conflict: {constraint: study__pkey, update_columns: [privacy, institution, degree, current, year_from, year_to, country_code, city, web, note]}) {
      affected_rows
    }
  }
`;


export const DELETE_STUDIES = gql`
  mutation DeleteStudies($ids: [Int!]!) {
    results: delete_study(where: {id: {_in: $ids}}) {
      affected_rows
    }
  }
`;
