import clsx from "clsx";
import { createLogger, getDefaultFileOptions, getFormattedCurrentTS } from "../logger";
import { actions, logServices } from "../config";


// only on server side
const winston = typeof window === "undefined" ? require("winston") : {};
const { format } = winston || {};


const log = (logger, level, { message, action = actions.UNKNOWN, details, timestamp }) => logger && logger.log({
  timestamp: (timestamp || new Date()).toISOString(),
  level,
  message,
  action,
  details,
});


const consoleTemplateFn = ({ level, message, action, details: { phone, code } = {} }) =>
  `${getFormattedCurrentTS()} [${level}] ${message} Action: ${action}${clsx(phone && ` Phone: ${phone}`)}${clsx(code && ` Code: ${code}`)}`;


const loggerConfig = {
  consoleOptions: {
    level: "verbose",
    format: format && format.printf(consoleTemplateFn),
  },
  fileOptions: getDefaultFileOptions(logServices.SMS),
};


const logger = createLogger(logServices.SMS, loggerConfig, log);

export default logger;
