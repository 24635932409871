import gql from "graphql-tag";


export const SAVE_HOBBY = gql`
  mutation SaveHobby($record: jsonb!) {
    saveHobby(record: $record) @client
  }
`;


export const UPDATE_ALUMNUS_HOBBY = gql`
  mutation UpdateAlumnusHobby($id: Int!, $changes: alumnus_hobby_set_input) {
    results: update_alumnus_hobby(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;
