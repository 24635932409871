import gql from "graphql-tag";


export const DELELE_REG_CODE = gql`
  mutation DeleteRegCode($id: Int!) {
    results: delete_reg_code(where: { _and: {id: {_eq: $id}, alumnus__id: {_is_null: true}, reg_date: {_is_null: true}}}) {
      affected_rows
    }
  }
`;


export const DEL_REG_CODE = gql`
  mutation DelRegCode($id: Int!) {
    delRegCode(id: $id) @client
  }
`;


export const UPSERT_REG_CODES = gql`
  mutation UpsertRegCodes($records: [reg_code_insert_input!]!) {
    results: insert_reg_code(objects: $records, on_conflict: {constraint: reg_code__pkey, update_columns: [code, name, expiry, grp, form_teacher, note]}) {
      affected_rows
    }
  }
`;


export const SAVE_REG_CODE = gql`
  mutation SaveRegCode($record: jsonb!) {
    saveRegCode(record: $record) @client
  }
`;
