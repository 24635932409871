import React, { useMemo } from "react";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  FilledInput,
  OutlinedInput,
  InputLabel,
  Input,
  MenuItem,
  Select as MuiSelect
} from "@material-ui/core";
import { getDefaultValueProp } from "../helpers";
import Controller from "./Controller";
import useField from "./useField";


const Select = ({ record, field, required, defaultValue, options, helperText, label, className, variant = "outlined", ...restProps }) => {

  const fieldRequirements = useMemo(() => required && {
    required: required || true,
  }, [required]);


  const { name, rules, error, helperTextOrError } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const InputVariant = useMemo(() => {
    switch (variant) {
      case "outlined":
        return <OutlinedInput label={label}/>;
      case "filled":
        return <FilledInput/>;
      default:
        return <Input/>
    }
  }, [label, variant]);


  const children = useMemo(() => Array.isArray(options) && options.map(option => {
    const { value, label, disabled } = option || {};
    return (
      <MenuItem key={value} value={value} disabled={disabled}>{label}</MenuItem>
    );
  }), [options]);


  const inputProps = useMemo(() => ({
    id: name,
    required: Boolean(required),
  }), [name, required]);


  return (
    <FormControl className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}
                 error={error}
                 required={Boolean(required)}
                 variant={variant}>
      {Boolean(label) && (
        <InputLabel htmlFor={name}>{label}</InputLabel>
      )}
      <Controller
        {...restProps}
        {...getDefaultValueProp(defaultValue)}
        name={name}
        as={<MuiSelect/>}
        rules={rules}
        input={InputVariant}
        inputProps={inputProps}
      >
        {children}
      </Controller>
      {Boolean(helperTextOrError) && (
        <FormHelperText>{helperTextOrError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(Select);
