import React from "react";
import { Icon, IconButton, Grid } from "@material-ui/core";


const PageSteppers = ({ page, numPages, onFirstPage, onLastPage, onPageDown, onPageUp, disabled }) => (
  <>
    <Grid item>
      <IconButton onClick={onFirstPage} disabled={disabled || page === 1}>
        <Icon>first_page</Icon>
      </IconButton>
    </Grid>
    <Grid item>
      <IconButton onClick={onPageDown} disabled={disabled || page === 1}>
        <Icon>chevron_left</Icon>
      </IconButton>
    </Grid>
    <Grid item>
      <IconButton onClick={onPageUp} disabled={disabled || page === numPages}>
        <Icon>chevron_right</Icon>
      </IconButton>
    </Grid>
    <Grid item>
      <IconButton onClick={onLastPage} disabled={disabled || page === numPages}>
        <Icon>last_page</Icon>
      </IconButton>
    </Grid>
  </>
);

export default React.memo(PageSteppers);
