import React, { useEffect, useContext, useMemo, useState, useCallback } from "react";
import { useGlobal } from "reactn";
import { useRouter } from "next/router";
import { ApolloProvider } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { anonymousPages, onlyAnonymousPages, pathNames } from "config/routing";
import { authEnums } from "common/enums";
import { devConsoleDebug } from "common/helpers";
import { loadSession, globalKey, redirect } from "client/lib/session";
import { ModuleLoaderContext } from "client/lib/appLoading";
import { Banned, Error } from "components/error";
import { Splash } from "components/common";
import { IssuesLayout } from "components/issues";
import { uiStateEventEmitter, uiStateEvents } from "./uiState/events";
import { UiStateContext } from "./UiStateProvider";
import SearchProvider from "./SearchProvider";


export const SessionContext = React.createContext({});


// debug
const devDebugForceAllIssues = false;


const SessionProvider = ({ children }) => {

  const { moduleLoaded } = useContext(ModuleLoaderContext);

  const { message: { showMessage } } = useContext(UiStateContext);

  const { pathname } = useRouter();


  const [started, setStarted] = useState(false);

  const [localBan, setLocalBan] = useState(null);


  const [{ initialized, loaded, failed, fetching, banned, bannedIp, banExpiry, storeClient, account, issues, ...session } = {}] = useGlobal(globalKey);


  const issuesArray = useMemo(() =>
      authEnums.orderOfIssues.filter(issue => issues[issue] || (process.env.DEV_MODE && devDebugForceAllIssues))
    , [issues]);


  const anyIssues = useMemo(() => Boolean(issuesArray.length), [issuesArray.length]);


  const authenticated = useMemo(() => Boolean(loaded && account && account.id), [account, loaded]);


  const clientError = useMemo(() => !fetching && authenticated && !storeClient, [authenticated, fetching, storeClient]);


  const anonymousPage = useMemo(() => anonymousPages.includes(pathname), [pathname]);


  const onlyAnonymousPage = useMemo(() => onlyAnonymousPages.includes(pathname), [pathname]);


  const forbidden = useMemo(() => authenticated ? onlyAnonymousPage : !anonymousPage
    , [anonymousPage, authenticated, onlyAnonymousPage]);


  const loginPage = useMemo(() => pathname === pathNames.login.href, [pathname]);


  const showExpiredSessionMessage = useCallback(() => showMessage(UiMessages.auth.expiredSession)
    , [showMessage]);


  useEffect(() => {
    if (!started) {
      setStarted(true);
      loadSession().then(() => moduleLoaded("session"));
    }
  }, [moduleLoaded, started]);


  useEffect(() => {
    if (started && loaded && !fetching && forbidden && !banned) {
      if (authenticated) {
        devConsoleDebug("onAuthenticated by SessionProvider");
        redirect.onAuthenticated().then();
      } else if (!loginPage) {
        devConsoleDebug("onUnauthenticated by SessionProvider");
        redirect.onUnauthenticated().then();
      }
    }
  }, [authenticated, banned, fetching, forbidden, loaded, loginPage, started]);


  useEffect(() => {
    if (banned) {
      setLocalBan({ banned, bannedIp, banExpiry });
    }
  }, [banExpiry, banned, bannedIp]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.session.showExpiredSessionMessage, showExpiredSessionMessage);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.session.showExpiredSessionMessage, showExpiredSessionMessage);
    }
  }, [showExpiredSessionMessage]);


  const contextValue = {
    ...session,
    account,
    authenticated,
    issues: issuesArray,
    anyIssues,
  };


  return (
    <SessionContext.Provider value={contextValue}>
      {localBan
        ? (
          <Banned bannedIp={localBan.bannedIp} banExpiry={localBan.banExpiry}/>
        )
        : !started || !loaded || forbidden
          ? (
            <Splash/>
          )
          : failed || clientError
            ? (
              <Error serverError={!initialized}/>
            )
            : !authenticated
              ? children
              : (
                <ApolloProvider client={storeClient}>
                  {anyIssues
                    ? (
                      <IssuesLayout/>
                    ) : (
                      <SearchProvider>
                        {children}
                      </SearchProvider>
                    )}
                </ApolloProvider>
              )
      }
    </SessionContext.Provider>
  );
};

export default React.memo(SessionProvider);
