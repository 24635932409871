import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { errors } from "common/errors";


const upload = async (fileId, buffer, crop) => await backendFetch(backendServices.fileUpload, buffer, fileId, crop);


const fileToBuffer = async file => {
  try {
    return await file.arrayBuffer();

  } catch (e) {
    errorLogStore("alumnus", "fileToBuffer", e);
    return null;
  }
};


const normalizeDimension = value => parseFloat(value || 0).toFixed(4);


const normalizeCrop = crop => {
  const { x, y, width, height } = { ...crop };

  return {
    left: normalizeDimension(x),
    top: normalizeDimension(y),
    width: normalizeDimension(width),
    height: normalizeDimension(height),
  }
};


export const uploadPicture = async (fileId, file, crop) => {
  if (!fileId || !file) return errors.client.media.MISSING_FILEID_OR_FILE;

  const buffer = await fileToBuffer(file);
  if (!buffer) return errors.client.media.BUFFER_PARSE_ERROR;

  const normalizedCrop = normalizeCrop(crop);

  return await upload(fileId, buffer, normalizedCrop);
};
