import React from "react";
import { GET_STUDY_DEGREES, GET_STUDY_INSTITUTIONS } from "client/store/alumnus";
import { JobBase, _fieldAliases, _newRecordValues, _updateFields as __updateFields } from "components/form/job";


export const __typename = "study";


const _updateFields = {
  ...__updateFields,
  institution: "institution",
  degree: "degree",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.privacy, _updateFields.institution, _updateFields.degree, _updateFields.current],
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


export const newRecordValues = {
  ..._newRecordValues,
  [_updateFields.institution]: "",
  [_updateFields.degree]: "",
};


const Study = () => (
  <JobBase
    __typename={__typename}
    projectField={_updateFields.institution}
    projectQuery={GET_STUDY_INSTITUTIONS}
    roleField={_updateFields.degree}
    roleQuery={GET_STUDY_DEGREES}
    projectLabel="Intézmény, képzőhely"
    roleLabel="Végzettség, szak, képzés"
    currentTitle="Jelenleg is végzem ezt a képzést"
    confirmEntity="a képzést"
    privacyTooltip="Erre a képzésre vonatkozó"
  />
);

export default React.memo(Study);
