import React from "react";
import { FormLabel, FormHelperText, Grid } from "@material-ui/core";


const Label = ({ label, helperText, className, labelGridClassName, align = "left", children }) => (
  <Grid container spacing={1} className={className}>
    {Boolean(label) && (
      <Grid item xs={12} className={labelGridClassName}>
        <FormLabel className="text-12" align={align}>{label}</FormLabel>
      </Grid>
    )}
    <Grid item xs={12}>
      {children}
    </Grid>
    {Boolean(helperText) && (
      <Grid item xs={12}>
        <FormHelperText>{helperText}</FormHelperText>
      </Grid>
    )}
  </Grid>
);

export default React.memo(Label);
