import React, { useContext } from "react";
import moment from "moment";
import clsx from "clsx";
import Link from "next/link";
import { Grid, Typography, Icon } from "@material-ui/core";
import { pathNames } from "config/routing";
import { resolvers } from "client/store/alumnus";
import FlagIcon from "components/alumnus/FlagIcon";
import { ListContext } from "components/listPages";


const getByFullName = ({ byPre, byLast, byFirst, bySuf }) => resolvers.alumnus_name.fullName({
  pre: byPre,
  last: byLast,
  first: byFirst,
  suf: bySuf,
});


const Row = ({ ip, time, details: { icon, iconColor, actionStr }, byAdmin, byAlumnusId, ...byData }) => {

  const { ipsWCountries } = useContext(ListContext);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <Typography>{moment(time).format("L HH:mm")}</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Icon className="text-16" color={iconColor}>{icon}</Icon>
          </Grid>
          <Grid item>
            <Typography>{actionStr}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {Boolean(byAdmin) && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Icon className="text-16">verified_user</Icon>
            </Grid>
            <Grid item>
              <Typography>
                {!byAlumnusId
                  ? "ismeretlen admin"
                  : (
                    <Link href={pathNames.alumnus.href} as={pathNames.alumnus.as(byAlumnusId)}>
                      <a>{getByFullName(byData)}</a>
                    </Link>
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {Boolean(ip) && (
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <FlagIcon code={ipsWCountries && ipsWCountries[ip]}/>
            </Grid>
            <Grid item>
              <Typography>{clsx(ip)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Row);
