import clsx from "clsx";
import memoizee from "memoizee";
import { idPrefix, newRecordIdSuffix, requirementMessages } from "./config";


export const getSafeRequirements = requirements => Object.fromEntries(Object.entries(requirements || {}).map(([key, value]) => [
  key, !requirementMessages[key]
    ? value
    : value === true
      ? requirementMessages[key]
      : value
]));


export const getPrefixedId = memoizee(id => `${idPrefix}${id}`);


export const parsePrefixedId = memoizee(prefixedId => clsx(prefixedId).split(idPrefix)[1]);


export const getPanelKey = memoizee((__typename, prefixedId) => `${__typename}.${prefixedId}`);


export const parsePanelKey = memoizee(id => {
  const [__typename, prefixedId] = clsx(id).split(".");
  return {
    __typename,
    prefixedId,
  };
});


export const serializeFieldName = memoizee((__typename, prefixedId, field) => `${__typename}.${prefixedId}.${field}`);


export const isNew = memoizee(recordId => Boolean(recordId && clsx(recordId).includes(newRecordIdSuffix)));


// workaround for react-hook-form components: defaultValue prop appended to the component only if not being undefined
export const getDefaultValueProp = memoizee(defaultValue => defaultValue === undefined ? {} : { defaultValue });


// workaround for react-hook-form components: defaultValue prop set to undefined when it's belonging to a new record
export const setDefaultValueProp = memoizee((isNew, defaultValue) => isNew ? defaultValue : undefined);


const doMutation = async (client, mutation, id, changes) => {
  try {
    if (!client || !client.mutate || !mutation || !id) return {};

    return await client.mutate({
      mutation,
      variables: {
        id,
        changes,
      },
    });

  } catch (error) {
    return { error };
  }
};


export const getSaveCb = (client, mutations) => async jobs => {
  for (let [__typename, typeJobs] of Object.entries(jobs || {})) {
    for (let { id, ...changes } of typeJobs) {
      const mutation = mutations && mutations[__typename];

      const { error } = await doMutation(client, mutation, id, changes);
      if (error) return { error };
    }
  }

  return {};
};


export const getJobsCount = jobs => Object.values(jobs || {}).reduce((jobsCount, itemsWrapper) => jobsCount + (itemsWrapper || []).length, 0);
