import gql from "graphql-tag";


export const SAVE_CARD = gql`
  mutation SaveCard($card: jsonb!, $alumnusId: Int!) {
    saveCard(card: $card, alumnusId: $alumnusId) @client
  }
`;


export const DOWNLOAD_CSV = gql`
  mutation DownloadCardsCSV {
    returning: downloadCardsCSV @client {
      error
    }
  }
`;


export const DOWNLOAD_XLSX = gql`
  mutation DownloadCardsXLSX {
    returning: downloadCardsXLSX @client {
      error
    }
  }
`;
