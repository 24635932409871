import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UiMessageVariants } from "config/messages";
import { uiStateEventEmitter, uiStateEvents } from "./events";


const defaultValues = {
  message: null,
  options: {
    anchorOriginVertical: "top",
    anchorOriginHorizontal: "center",
    autoHideDuration: 5 * 1000,
    variant: UiMessageVariants.info,
  },
};


const useUiMessage = () => {

  const [message, setMessage] = useState(defaultValues.message);
  const [options, setOptions] = useState(defaultValues.options);

  const open = useMemo(() => Boolean(message), [message]);


  const reset = useCallback(() => {
    setMessage(defaultValues.message);
    setOptions(defaultValues.options);
  }, []);


  const hideMessage = useCallback(() => setMessage(defaultValues.message), []);


  const showMessage = useCallback(msg => {
    const [message, variant = defaultValues.options.variant, options = {}] = msg || [];
    if (!message) return;

    const autoHideDuration = options.autoHideDuration === 0
      ? null
      : options.autoHideDuration || defaultValues.options.autoHideDuration;

    setOptions({
      ...defaultValues.options,
      ...options,
      autoHideDuration,
      variant,
    });

    setMessage(clsx(message));
  }, []);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.message.showMessage, showMessage);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.message.showMessage, showMessage);
    }
  }, [showMessage]);


  return {
    message: {
      open,
      message,
      ...options,
      showMessage,
      hideMessage,
    },
    reset,
  };
};

export default useUiMessage;
