import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { GET_COUNTRIES } from "client/store/alumnus";
import { useConfirm } from "components/common";
import {
  Input,
  PanelContext,
  PrivacyPolicy,
  CountrySelect,
  Year,
  CheckBox,
  URL,
  FormContext,
  DeletePanel,
} from "components/form";
import { serializeFieldName, setDefaultValueProp } from "../helpers";
import JobAutocomplete from "./JobAutocomplete";


const maxLengthInline = 120;

const maxLengthNote = 500;

const rowsCount = 2;

const rowsMaxCount = 5;


export const _updateFields = {
  privacy: "privacy",
  current: "current",
  from: "from",
  to: "to",
  country: "country",
  city: "city",
  web: "web",
  note: "note",
};


export const _fieldAliases = {
  [_updateFields.from]: "year_from",
  [_updateFields.to]: "year_to",
  [_updateFields.country]: "country_code",
};


export const _newRecordValues = {
  [_updateFields.current]: true,
  [_updateFields.from]: null,
  [_updateFields.to]: null,
  [_updateFields.country]: null,
  [_updateFields.city]: null,
  [_updateFields.web]: null,
  [_updateFields.note]: null,
};


const currentHelperText = (
  <span>Valahányszor megadod/módosítod a <i>Befejezés évét</i>, a jelölőnégyzet automatikusan kikapcsolt állapotba kerül.
    Ezt a jelölőnégyzet újbóli bejelölésével bírálhatod felül.</span>
);


const webHelperText = (
  <span>Teljes webes hivatkozást (URL) adj meg (pl. <span className="text-blue">https://example.com</span>)</span>
);


const noteHelperText = (
  <span>Legfeljebb {maxLengthNote} karakterben.</span>
);


const getConfirmOptions = (confirmEntity, projectField, roleField, { [projectField]: company, [roleField]: job }) => ({
  title: "Törlés megerősítése",
  content: `Biztosan törlöd ezt ${confirmEntity}: ${company}, ${job}?`,
  OKButton: "Igen, törlöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
});


const JobBase = ({
                   __typename, projectField, projectQuery, roleField, roleQuery, sectorField, sectorQuery, projectLabel,
                   roleLabel, sectorLabel, sectorHelperText, sectorHelperTextAutocomplete, currentTitle, privacyTooltip,
                   confirmEntity
                 }) => {

  const { personal: { privacy } } = useContext(SessionContext);

  const { setValue, getItemValues } = useContext(FormContext);

  const { record, prefixedId, isNew, deleteRecord } = useContext(PanelContext);


  const { loading, error, data: { countries } = {} } = useQuery(GET_COUNTRIES);


  const confirmOptions = useCallback(() => getConfirmOptions(confirmEntity, projectField, roleField, getItemValues(__typename, prefixedId))
    , [__typename, confirmEntity, getItemValues, prefixedId, projectField, roleField]);


  const confirm = useConfirm({
    confirmOptions,
    disabled: isNew,
  });


  const onDelete = useCallback(() => confirm(deleteRecord, prefixedId)
    , [confirm, prefixedId, deleteRecord]);


  const onYearToChange = useCallback(event => {
    const yearTo = clsx(event.target.value).trim();
    if (yearTo) {
      const currentFieldName = serializeFieldName(__typename, prefixedId, _updateFields.current);
      setValue(currentFieldName, false);
    }
  }, [__typename, prefixedId, setValue]);


  return Boolean(!loading && !error && countries) && (
    <Grid item container spacing={2}>
      <Grid container spacing={0} justify="space-between" item xs={12} wrap="wrap-reverse">
        <Grid item xs={12} md={8}>
          <PrivacyPolicy record={record}
                         field={_updateFields.privacy}
                         defaultValue={setDefaultValueProp(isNew, privacy)}
                         tooltip={privacyTooltip}
          />
        </Grid>
        <DeletePanel onDelete={onDelete}
                     label="Bejegyzés törlése"
        />
      </Grid>
      <Grid item xs={12}>
        <JobAutocomplete record={record}
                         field={projectField}
                         label={projectLabel}
                         query={projectQuery}
                         maxLength={maxLengthInline}
                         required
        />
      </Grid>
      <Grid item xs={12}>
        {roleQuery
          ? (
            <JobAutocomplete record={record}
                             field={roleField}
                             label={roleLabel}
                             query={roleQuery}
                             maxLength={maxLengthInline}
                             required
            />
          )
          : (
            <Input record={record}
                   field={roleField}
                   fakeContentRegex={/[a-z]+/i}
                   label={roleLabel}
                   maxLength={maxLengthInline}
                   required
            />
          )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} sm={6}>
            <Year record={record}
                  field={_updateFields.from}
                  maxYearField={_updateFields.to}
                  prefixedId={prefixedId}
                  className="mb-20"
                  label="Kezdés éve"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Year record={record}
                  field={_updateFields.to}
                  minYearField={_updateFields.from}
                  prefixedId={prefixedId}
                  onChange={onYearToChange}
                  className="mb-20"
                  label="Befejezés éve"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CheckBox record={record}
                  field={_updateFields.current}
                  defaultValue={setDefaultValueProp(isNew, _newRecordValues[_updateFields.current])}
                  title={currentTitle}
                  helperText={currentHelperText}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} md={6}>
            <CountrySelect record={record}
                           field={_updateFields.country}
                           countries={countries}
                           label="Ország"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input record={record}
                   field={_updateFields.city}
                   label="Település"
                   maxLength={50}
                   noOnlyUpperCase
            />
          </Grid>
        </Grid>
      </Grid>
      {Boolean(sectorField) && (
        <Grid item xs={12}>
          {sectorQuery
            ? (
              <JobAutocomplete record={record}
                               field={sectorField}
                               label={sectorLabel}
                               query={sectorQuery}
                               minLength={2}
                               maxLength={maxLengthInline}
                               helperText={sectorHelperTextAutocomplete}
              />
            )
            : (
              <Input record={record}
                     field={sectorField}
                     label={sectorLabel}
                     helperText={sectorHelperText}
                     maxLength={maxLengthInline}
              />
            )}
        </Grid>
      )}
      <Grid item xs={12}>
        <URL record={record}
             field={_updateFields.web}
             label="Weboldal"
             helperText={webHelperText}
        />
      </Grid>
      <Grid item xs={12}>
        <Input record={record}
               field={_updateFields.note}
               label="Egyéb információk, tudnivalók"
               helperText={noteHelperText}
               maxLength={maxLengthNote}
               className="mb-8"
               rows={rowsCount}
               rowsMax={rowsMaxCount}
               multiline
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(JobBase);
