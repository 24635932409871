import React from "react";
import { Typography } from "@material-ui/core";
import { primaryListName, secondaryListName } from "server/lib/octopus/config";


const Octopus = () => (
  <>
    <Typography className="mb-20">
      Az EmailOctopus <b>2500 aktív feliratkozóig</b> biztosít lehetőséget az ingyenes használatra (ugyanaz az email cím
      két külön listában két külön feliratkozónak számít). Fontos ugyanakkor, hogy a listákat ne kelljen törölnünk az
      egyes hírlevélkiküldések között, ugyanis ilyenkor nem tudnánk biztosítani a hírlevelek alján található leiratkozás
      folyamatos lehetőségét a tagság számára. Emiatt <b>minden hírlevélkiküldés során</b> az alábbi műveletsor szerint
      célszerű eljárni:
    </Typography>
    <div>
      <ol>
        <li className="mb-12">Készítsük el a hírlevél piszkozatát az <span
          className="text-blue">EmailOctopus</span> felületén, de még ne küldjük ki!
        </li>
        <li className="mb-12">Szinkronizáljuk a listákat az <span className="text-blue">Alumni Online!</span> felületén
          (itt)! Ha elsőre nem minden feladat végrehajtása sikeres, ismételjük meg a szinkronizálást!
        </li>
        <li className="mb-12">Ha a szinkronizálást követően azt látjuk, hogy az <i>elsődleges lista</i> szabad
          kapacitása 0-nál nagyobb szám, akkor küldjük ki a hírlevelet az elsődleges lista (<b>{primaryListName}</b>)
          címzettjeinek az <span className="text-blue">EmailOctopus</span> felületén! Ez esetben további teendőnk nincs.
        </li>
        <li className="mb-12">Ha itt tartunk, akkor a szinkronizálást követően az elsődleges lista szabad kapacitása
          zérus, tehát a <i>másodlagos lista (<b>{secondaryListName}</b>)</i> is feltöltésre kerül. Az <span
            className="text-blue">EmailOctopus</span> felületén kattintsunk a <b>Lists</b> menüpontra, majd a listák
          felsorolásában kattintsunk az <b>{primaryListName}</b> listára! Ezután kattintsunk az <b>Exports</b> fülre,
          majd a betöltődő lapon az <b>Export subscribed contacts</b> gombra! Rövidesen megkezdődik az export fájl
          letöltése. A letöltést követően csomagoljuk ki az archívumot, és tartsuk számon a kicsomagolt .csv szövegfájl
          nevét és helyét. (Át is nevezhetjük.)
        </li>
        <li className="mb-12">Hajtsuk végre a <span
          className="text-blue">4. lépést</span> az <b>{secondaryListName}</b> listával is! Ezzel beszereztük
          az elsődleges és másodlagos lista aktív feliratkozóit tartalmazó két .csv szövegfájlt.
        </li>
        <li className="mb-12">Inaktiváljuk az <b>{primaryListName}</b> listában szereplő valamennyi email címet! Ehhez
          nyissuk meg az <b>{primaryListName}</b> listát, kattintsunk az <b>Imports</b> fülre, majd a megnyíló lapon
          kattintsunk az <b>Import contacts</b> gombra! A megnyíló lapon húzzuk a drag&drop mezőbe
          az <b>{primaryListName}</b> listához tartozó .csv szövegfájlt! A választható opcióknál válasszuk
          az <b>Unsubscribed</b> és az <b>Update the contact</b> opciókat! A <b>Next</b> gombra kattintva betöltődik a
          következő beállítási lépés oldala. Itt arra figyeljünk, hogy <i>csak az email cím</i> oszlopát jelöljük meg
          importálandó oszlopként. A többinél válasszuk a <b>Skip the column</b> opciót! Végül kattintsunk
          a <b>Next</b> gombra!
        </li>
        <li className="mb-12">Mivel ekkor az aktív feliratkozók száma 2500 alatt van, ezért most már kiküldhetjük
          az <span className="text-blue">1. lépésben</span> megszerkesztett hírlevél piszkozatot
          az <b>{secondaryListName}</b> lista címzettjeinek! Fontos ugyanakkor, hogy <i>ne</i> az azonnali, hanem az <b>időzített
            kiküldést</b> válasszuk! Legalább 10 perccel későbbi időpontot állítsunk be! Erre azért van szükség, hogy a
          kiküldés pillanatában minden címzett számára újra biztosított legyen a leiratkozás lehetősége (ld. további
          lépések).
        </li>
        <li className="mb-12">Inaktiváljuk a <b>{secondaryListName}</b> listában szereplő valamennyi email címet!
          (Ld. <span className="text-blue">6. lépés</span>, csak az <b>{secondaryListName}</b> listával.)
        </li>
        <li className="mb-12">Aktiváljuk újra az <b>{primaryListName}</b> listában szereplő valamennyi email címet!
          Ehhez a <span className="text-blue">6. lépést</span> kell újra végrehajtanunk, azzal a különbséggel, hogy az
          importálás opcióinál válasszuk a <b>Subscribed</b> és az <b>Update the contact</b> opciókat!
        </li>
        <li className="mb-12">Küldjük ki a hírlevelet az <b>{primaryListName}</b> lista címzettjeinek is! (Ld. <span
          className="text-blue">7. lépés.</span>) Itt is az időzített kiküldést válasszuk, az előzővel azonos
          időponttal!
        </li>
        <li className="mb-12">Végül aktiváljuk újra az <b>{secondaryListName}</b> listában szereplő valamennyi email
          címet! (Ld. <span className="text-blue">9. lépés</span>, csak az <b>{secondaryListName}</b> listával.)
        </li>
      </ol>
    </div>
    <Typography className="mt-24">
      Mivel az EmailOctopus <i>csak a kiküldés (vagy annak időzítése) pillanatában ellenőrzi</i> a 2500-as feliratkozói
      limitet, ezért a fenti műveletsorral egyik részkiküldés során sem ütközünk a 2500-as limitbe. Így annak ellenére
      az ingyenes csomagban maradhatunk, hogy valójában túllépjük a 2500-as limitet, miközben a tagság számára
      biztosított leiratkozási lehetőségről sem kell lemondanunk.
    </Typography>
  </>
);

export default React.memo(Octopus);
