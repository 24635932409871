import React from "react";
import { Typography } from "@material-ui/core";
import texts from "config/texts";
import { accent } from "common/helpers";


const Search = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon az <b>alumnus adatlapok tartalmában végezhetsz keresést</b>. Az alumnus adatbázisban tárolt <b>bármely
      szöveges adatra</b> rákereshetsz, amely <b>megtekintéséhez az
      illető</b> számodra &ndash; mint <i>osztálytársa</i>, <i>évfolyamtársa</i> vagy <i>diáktársa</i> számára &ndash;&nbsp;
      <b>hozzájárult</b>.
    </Typography>
    <Typography className="my-20">
      A kereső kifejezéseket a lap fejlécében található <b>szövegmezőben</b> adhatod meg. A beírást követően <b>nyomd
      meg</b> &ndash; a szövegmező jobb oldalán található &ndash; <b>keresés gombot</b> vagy <b>nyomj entert</b>!
    </Typography>
    <Typography className="my-20">
      A keresés <b>valamennyi kifejezésre megtörténik</b>. Pl. a <i className="text-blue">Kis János</i> kifejezés az
      összes <i><span className="text-blue">Kis</span>t</i> és <i><span
      className="text-blue">János</span>t</i> megkeresi. Az összetartozó kifejezéseket <b>idézőjelbe is tehetjük</b>: a
      kereső <b>ezeket együtt fogja tekinteni</b>. Pl. a <i className="text-blue">&quot;Kis
      János&quot;</i> kifejezésnél csak a <i><span className="text-blue">Kis János</span>ok</i> jelennek meg, a többi <i
      className="text-blue">Kis</i> és <i className="text-blue">János</i> viszont nem.
    </Typography>
    <Typography className="my-20">
      <b>A kis- és nagybetűk azonosnak számítanak</b>. <b>Az ékezetes betűk és ékezet nélküli változataik</b> szintén. A
      részleges egyezés is találatnak számít. Pl. a <i className="text-blue">kör</i> kifejezésre a <i><span
      className="text-blue">kor</span>eográfus</i>, a <i>Kodály <span className="text-blue">kör</span>önd</i> és
      a <i><span className="text-blue">Kőr</span>öshegy</i> is egyezésnek számít.
    </Typography>
    <Typography className="my-20">
      Egyszerre <b>több kifejezés is megadható</b>. A keresőmezőbe írt kulcsszavakat <b>szóközzel válaszd el&nbsp;</b>(a
      sorrend nem számít)! A kereső <b>ezekre külön-külön elvégzi a keresést</b>. Relevancia szerinti rendezéskor (ez az
      alapértelmezett), minél több kulcsszó található meg az alumnus adatlapján, annál előrébb kerül a találati
      listában. (Az azonos relevanciájú találatok ábécésorrendben jelennek meg.)
    </Typography>
    <Typography className="my-20">
      A kereső alapértelmezetten minden alumnus adatban keres. <b>A találatok</b> &ndash; a <span className="text-blue">Mit keressünk?</span> címke
      alatti, megfelelő kategóriaszűrő bekapcsolásával &ndash; <b>egy-egy adatcsoportra szűkíthetők</b>.
    </Typography>
    <Typography className="my-20">
      Az intézmények, érdeklődési körök stb. többféle névváltozatban szerepelhetnek. Ennek feltételezése
      esetén &ndash; a minél több releváns találat érdekében &ndash; <b> érdemes több potenciális alakváltozatot is
      megadni</b> (szóközzel elválasztva). Pl. <i className="text-blue">BME Műegyetem Műszaki Egyetem</i> vagy <i
      className="text-blue">foci futball labdarúgás</i>.
    </Typography>
    <Typography className="my-20">
      {accent(texts.discountNameAccusative, true)} <b>{texts.discountNameAccusative} felajánló
      diáktársakra</b> {accent(texts.discountProvidersName)} <span
      className="text-blue">{texts.discountProvidersName} szűrő</span> bekapcsolásával kereshetsz rá. <b>A találati
      lista a megfelelő keresőszavak beírásával szűrhető.</b> A találati kártyák csak az együttműködés kulcsszavait
      tartalmazzák. A találati kártyára kattintva nyithatod meg az alumnus adatlapját, amelyen &ndash; többek
      között &ndash; a kedvezménnyel kapcsolatos információkról tájékozódhatsz.
    </Typography>
    <Typography className="my-20">
      Ha a kapcsolattartási vagy másodlagos címben, egy munkahelyre vagy tanulmányra vonatkozó bejegyzésben
      szereplő <b>országra</b> szeretnénk szűrni, akkor vagy a magyar országnevet vagy a két betűjelű nemzetközi
      országkódot <i>(ISO 3166-1 alpha-2)</i> adjuk meg keresőszóként. Pl. <i className="text-blue">Svájc</i> vagy <i
      className="text-blue">ch</i>, <i className="text-blue">Észtország</i> vagy <i className="text-blue">EE</i>.
    </Typography>
    <Typography className="my-20">
      <b>Konkrét osztályra</b> így szűrhetünk: <i className="text-blue">2006A</i>, <b>évfolyamra</b> pedig így: <i
      className="text-blue">2006</i>. Ha kimondottan egy-egy osztály vagy évfolyam tagjait szeretnénk listázni,
      akkor az így megadott kifejezésen (pl. <i className="text-blue">2006A</i>) felül célszerű még a <span
      className="text-blue">Névben</span> kategóriaszűrőt is bekapcsolni.
    </Typography>
    <Typography className="my-20">
      A megadott kulcsszavaktól függetlenül, a találatokat <b>évfolyamtartomány szerint</b> is szűrhetjük. Ehhez
      állítsuk be a megfelelő évszűrőt, <b>a kezdő és/vagy a záró év megadásával</b>. (Egy-egy évszűrőt üresen
      hagyva az évtartomány az adott oldalon nyitva marad.)
    </Typography>
    <Typography className="mt-20">
      A találati kártyákon megjelenő címkék azokat az <b>adatcsoportokat</b> sorolják fel, amelyek a keresőmezőbe
      írt <b>kulcsszavakkal (részleges) egyezést</b> mutatnak.
    </Typography>
  </>
);

export default React.memo(Search);
