import clsx from "clsx";
import moment from "moment";
import { errorLogStore } from "client/lib/errorLog";
import { GET_REVISION_REQUEST_CONTACTS, GET_REVISION_REQUESTS } from "./queries";


const fetchContacts = async (client, id) => {
  try {
    const { data: { requests } = {} } = await client.query({
      query: GET_REVISION_REQUEST_CONTACTS,
      variables: {
        id,
      },
    });

    const [request] = requests || [];
    const { contacts } = request || {};

    return contacts || [];

  } catch (e) {
    errorLogStore("revisionRequest", "fetchContacts", e);
  }
};


const getRequest = (requests, id) => (requests || []).find(({ id: rId }) => rId === id);


const fetchRequest = async (client, id) => {
  try {
    const { data: { requests } = {} } = await client.query({ query: GET_REVISION_REQUESTS });

    return getRequest(requests, id);

  } catch (e) {
    errorLogStore("revisionRequest", "fetchRequest", e);
  }
};


const headers = {
  email: "Email cím",
  firstName: "Utónév",
};


const headersArray = [headers.email, headers.firstName];


const mapRawContacts = contacts => (contacts || []).map(({ email, first_name: firstName }) => ({
  [headers.email]: clsx(email),
  [headers.firstName]: clsx(firstName),
}));


const getTitle = ({ created }) => `${moment(created).format("YYYY-MM-DD")}-i AEK érintettjei`;


const getFileName = ({ created, required }) => {
  const date = moment().format("YYYY-MM-DD");
  const title = `${moment(created).format("YYYY-MM-DD")}-i ${clsx(required && "kötelező ")}AEK érintettjei`;
  return clsx(title, date);
};


const exportData = async (client, { id }) => {
  const request = await fetchRequest(client, id);
  if (!request) throw new Error();

  const fetchedContacts = await fetchContacts(client, id);

  const rows = mapRawContacts(fetchedContacts);
  const title = getTitle(request);
  const fileName = getFileName(request);

  return {
    rows,
    fields: headersArray,
    title,
    fileName,
  };
};

export default exportData;
