import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { InputAdornment, IconButton, InputLabel, FormControl, FormHelperText, OutlinedInput } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useField } from "components/form";
import { requirementMessages } from "../config";
import { regexInvalidChar, regexFormat, maxLength } from "./config";


const Password = ({
                    record, field, required, requirements, showPassword, setShowPassword, currentPassword, strong,
                    onChangeCb, label, helperText, className, textFieldClassName, variant = "outlined", ...restProps
                  }) => {


  const validateInvalidChar = useCallback(value => !strong || regexInvalidChar.test((clsx(value)))
    || requirementMessages.password.invalidChar, [strong]);


  const validateDbFormat = useCallback(value => !strong || regexFormat.test((clsx(value)))
    || requirementMessages.password.invalidFormat, [strong]);


  const differentFromCurrent = useCallback(value => !currentPassword || value !== currentPassword
    || requirementMessages.password.sameAsCurrent, [currentPassword]);


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    required: required || true,
    validate: {
      ...(requirements && requirements.validate),
      validateInvalidChar,
      validateDbFormat,
      differentFromCurrent,
    },
  }), [differentFromCurrent, required, requirements, validateDbFormat, validateInvalidChar]);


  const { error, name, helperTextOrError, register } = useField({
    field,
    record,
    helperText,
    fieldRequirements,
  });


  const onChange = useCallback(event => {
    const value = clsx(event.target.value);

    if (typeof onChangeCb === "function") onChangeCb(value);

    return value;
  }, [onChangeCb]);


  const handleClick = useCallback(() => typeof setShowPassword === "function" && setShowPassword(value => !value)
    , [setShowPassword]);


  const handleMouseDown = useCallback(event => event.preventDefault(), []);


  return (
    <FormControl className={clsx("w-full max-w-md", !clsx(className).includes("mb-") && "mb-32", className)}
                 required={Boolean(required)}
                 variant={variant}
    >
      <InputLabel htmlFor={field}>{label}</InputLabel>
      <OutlinedInput
        {...restProps}
        name={name}
        id={field}
        onChange={onChange}
        classes={{
          root: `w-full ${clsx(textFieldClassName)}`
        }}
        inputProps={{
          "aria-label": label,
          maxLength,
        }}
        inputRef={register()}
        error={error}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClick}
              onMouseDown={handleMouseDown}
              edge="end"
              tabIndex={-1}
            >
              {showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={clsx(label).length * 9}
      />
      <FormHelperText error={error}>{helperTextOrError}</FormHelperText>
    </FormControl>
  );
};

export default React.memo(Password);
