import React, { useContext } from "react";
import { Header as ListHeader, TextFilter, ListContext } from "components/listPages";
import { fields } from "./List";


const Header = props => {

  const { setFilter, filterValues } = useContext(ListContext);

  return (
    <ListHeader {...props}>
      <TextFilter filterId={fields.ip}
                  filterValue={filterValues[fields.ip]}
                  setFilter={setFilter}
                  disableUnderline
                  className="w-full px-8 sm:px-0"
                  fullWidth
      />
    </ListHeader>
  );
};

export default React.memo(Header);
