import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { FormContext } from "./Form";


export const PanelContext = React.createContext({});


const Panel = ({
                 __typename, record, recordId, prefixedId, isPrimary, isNew, issue, registration, dirty, expanded,
                 PanelContents, PanelContentsProps, disabled, onPanelClick, captionCb, titleCb, getTitleComponentCb,
                 captionWidth
               }) => {


  const { deleteRecord, formErrors, getItemValues, getValue } = useContext(FormContext);


  const error = Boolean((!registration || dirty) && formErrors && formErrors[__typename] && Object.keys(formErrors[__typename][prefixedId] || {}).length);


  const safeCaptionWidth = useMemo(() => Math.max(1, Math.min(12, captionWidth || 0)), [captionWidth]);


  const cbArgs = useMemo(() => ({
    __typename,
    prefixedId,
    expanded,
    isPrimary,
    getItemValues,
    getValue,
  }), [__typename, expanded, getItemValues, getValue, isPrimary, prefixedId]);


  const caption = useMemo(() => captionCb && captionCb(cbArgs), [captionCb, cbArgs]);


  const title = useMemo(() => titleCb && titleCb(cbArgs), [titleCb, cbArgs]);


  const TitleIconComponent = useMemo(() => Boolean(getTitleComponentCb) && getTitleComponentCb(cbArgs) || null, [cbArgs, getTitleComponentCb]);


  const PanelContent = useMemo(() => PanelContents && PanelContents[__typename], [PanelContents, __typename]);


  const PanelContentProps = useMemo(() => PanelContentsProps && PanelContentsProps[__typename], [PanelContentsProps, __typename]);


  const onChange = useCallback((_, isExpanded) => onPanelClick(__typename, isExpanded ? prefixedId : false), [onPanelClick, __typename, prefixedId]);


  const contextValue = {
    ...PanelContentProps,
    __typename,
    deleteRecord,
    disabled,
    error,
    isPrimary,
    isNew,
    prefixedId,
    record,
    recordId,
    issue,
    selectPanel: onPanelClick,
  };


  if (!PanelContent) return null;


  return (
    <ExpansionPanel expanded={expanded}
                    onChange={onChange}
                    disabled={disabled}
                    defaultExpanded={false}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        <Grid container spacing={1} alignItems="center">
          {Boolean(caption) && (
            <Grid item xs={12} md={safeCaptionWidth}>
              <Typography className={clsx("text-15 flex-shrink-0", error && "text-red")}>
                {caption}
              </Typography>
            </Grid>
          )}
          {Boolean((title || title === null) && !expanded) && (
            <Grid item xs={12} md={12 - safeCaptionWidth}>
              <div className="flex flex-row">
                {TitleIconComponent}
                <Typography className="text-15" color="textSecondary">{
                  title === null
                    ? !registration && (<i>Nincs</i>)
                    : title
                }</Typography>
              </div>
            </Grid>
          )}
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <PanelContext.Provider value={contextValue}>
          <PanelContent/>
        </PanelContext.Provider>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default React.memo(Panel);
