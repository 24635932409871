import { taskTypes } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { decoratedError, errors } from "common/errors";


const getCreateContactTasks = tasks => (tasks && tasks.filter(({ task }) => task === taskTypes.createContact)) || [];


const getOtherTasks = tasks => (tasks && tasks.filter(({ task }) => task !== taskTypes.createContact)) || [];


const extend = (tasks, listId) => tasks.map(task => ({
  ...task,
  listId,
}));


const getPrimaryTasks = (relevantTasks, capacity, { primaryList: { id: listId } }) => {
  const tasks = capacity
    ? relevantTasks.slice(0, capacity)
    : [];
  return extend(tasks, listId);
};


const getSecondaryTasks = (relevantTasks, capacity, { secondaryList: { id: listId } }) => {
  const tasks = relevantTasks.slice(capacity) || [];
  return extend(tasks, listId);
};


const extendTasks = ({ tasks }, { capacity = 0 }, settings) => {
  try {

    const relevantTasks = getCreateContactTasks(tasks);
    const otherTasks = getOtherTasks(tasks);

    const primaryTasks = getPrimaryTasks(relevantTasks, capacity, settings);
    const secondaryTasks = getSecondaryTasks(relevantTasks, capacity, settings);
    const extendedTasks = [].concat(otherTasks, primaryTasks, secondaryTasks);

    return {
      tasks: extendedTasks,
    };

  } catch (e) {
    const message = errorLogStore("octopus", "extendTasks", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};

export default extendTasks;
