import React, { useCallback, useContext } from "react";
import { Grid } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { withAccent } from "common/helpers";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { useConfirm } from "components/common";
import { DeletePanel, FormContext, PanelContext, PrivacyPolicy } from "components/form";
import { setDefaultValueProp } from "../helpers";
import SocialEntry from "./SocialEntry";


export const __typename = "social";


const _updateFields = {
  privacy: "privacy",
  site: "site",
  account: "account",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.privacy, _updateFields.site, _updateFields.account],
};


export const newRecordValues = {
  [_updateFields.site]: dbEnums.socialSite.UNKNOWN,
  [_updateFields.account]: "",
};


const helperText = (
  <span>Teljes webes hivatkozást (URL) adj meg (pl. <i
    className="text-blue">https://facebook.com/ezazenazonositom</i> vagy <i
    className="text-blue">https://www.sajatweboldalam.hu</i>)! Skype elérhetőség az alábbi séma szerint: <i
    className="text-blue">skype:gipszjakab</i>, ahol <i>gipszjakab</i> a Skype felhasználónév. További információk a súgóban.</span>
);


const getConfirmOptions = account => ({
  title: "Törlés megerősítése",
  content: `Biztosan törlöd ${withAccent(account)} bejegyzést?`,
  OKButton: "Igen, törlöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
});


const Social = () => {

  const { personal: { privacy } } = useContext(SessionContext);

  const { getValue } = useContext(FormContext);

  const { record, prefixedId, isNew, deleteRecord } = useContext(PanelContext);


  const getAccount = useCallback(() => getValue(__typename, prefixedId, _updateFields.account)
    , [getValue, prefixedId]);


  const confirmOptions = useCallback(() => getConfirmOptions(getAccount()), [getAccount]);


  const confirm = useConfirm({
    confirmOptions,
    disabled: isNew,
  });


  const onDelete = useCallback(() => confirm(deleteRecord, prefixedId)
    , [confirm, prefixedId, deleteRecord]);


  return (
    <Grid container spacing={2} item>
      <Grid container spacing={0} justify="space-between" item xs={12} wrap="wrap-reverse">
        <Grid item xs={12} md={8}>
          <PrivacyPolicy record={record}
                         field={_updateFields.privacy}
                         defaultValue={setDefaultValueProp(isNew, privacy)}
                         tooltip="Erre az elérhetőségre"/>
        </Grid>
        <DeletePanel onDelete={onDelete}
                     label="Bejegyzés törlése"
        />
      </Grid>
      <Grid item xs={12}>
        <SocialEntry record={record}
                     accountField={_updateFields.account}
                     siteField={_updateFields.site}
                     defaultValue=""
                     label="Közösségi média URL / weboldal URL"
                     helperText={helperText}
                     className="mb-4"
                     required
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Social);
