import { getClientError } from "./helpers";


export const clientErrors = {
  auth: {
    MISSING_CREDENTIALS: getClientError("login", "MISSING_CREDENTIALS"),
  },
  backendApi: {
    INVALID_SERVICE: getClientError("backendApi", "INVALID_SERVICE"),
    MISSING_FILENAME: getClientError("backendApi", "MISSING_FILENAME"),
    MISSING_SERVICE: getClientError("backendApi", "MISSING_SERVICE"),
    NETWORK_ERROR: getClientError("backendApi", "NETWORK_ERROR"),
    ON_SAVE_BUFFER: getClientError("backendApi", "ON_SAVE_BUFFER"),
  },
  media: {
    MISSING_FILEID_OR_FILE: getClientError("media", "MISSING_FILEID_OR_FILE"),
    BUFFER_PARSE_ERROR: getClientError("media", "BUFFER_PARSE_ERROR"),
  },
  octopus: {
    APOLLO_ERROR: getClientError("octopus", "APOLLO_ERROR"),
    BOTTLENECK_LIMITER: getClientError("octopus", "BOTTLENECK_LIMITER"),
    LIB_ERROR: getClientError("octopus", "LIB_ERROR"),
    ON_GET_TASKS: getClientError("octopus", "ON_GET_TASKS"),
    UNCOMPLETED_STATUS: getClientError("octopus", "UNCOMPLETED_STATUS"),
    UNINITIALIZED_SETTINGS: getClientError("octopus", "UNINITIALIZED_SETTINGS"),
  },
};
