import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0 7px",
    fontSize: 11,
    fontWeight: 600,
    height: 20,
    minWidth: 15,
    borderRadius: 20,
    display: "grid",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const FuseNavBadge = props => {
  const classes = useStyles(props);
  const { className, badge } = props;

  return (
    <div className={clsx(classes.root, className, "item-badge", badge.className)}>
      {badge.title}
    </div>
  )
};

FuseNavBadge.propTypes = {
  badge: PropTypes.shape(
    {
      title: PropTypes.node,
      bg: PropTypes.string,
      fg: PropTypes.string
    })
};
FuseNavBadge.defaultProps = {};

export default React.memo(FuseNavBadge);
