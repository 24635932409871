import React, { useMemo } from "react";
import clsx from "clsx";
import moment from "moment";
import { Chip, Grid, Typography } from "@material-ui/core";
import { dbEnums, labels } from "common/enums";
import { MiniChip } from "components/common";
import RowControl from "./RowControl";


const Row = specialAccount => {

  const { username, role, status, lastLogin, ip, note } = specialAccount;

  const statusColorClassName = useMemo(() => status === dbEnums.accountStatus.ACTIVE ? "bg-green" : "bg-red", [status]);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <RowControl {...specialAccount}/>
      </Grid>
      <Grid item>
        <div className="sm:min-w-256">
          <Chip label={username}
                color="primary"
                size="small"
                variant="outlined"
          />
        </div>
      </Grid>
      <Grid item>
        <Grid container spacing={1} className="sm:min-w-208">
          <Grid item>
            <MiniChip label={labels.accountRoles[role]}
                      colorClassName="bg-blue"
            />
          </Grid>
          <Grid item>
            <MiniChip label={labels.accountStatus[status]}
                      colorClassName={statusColorClassName}
            />
          </Grid>
        </Grid>
      </Grid>
      {Boolean(lastLogin) && (
        <Grid item>
          <div className="sm:min-w-352">
            <Typography variant="caption">
              {clsx("Utolsó bejelentkezés:", moment(lastLogin).format("L HH:mm"), ip && ` (IP: ${ip})`)}
            </Typography>
          </div>
        </Grid>
      )}
      {Boolean(note) && (
        <Grid item>
          <Typography variant="caption">{note}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Row);
