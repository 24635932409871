import React, { useContext, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { SessionContext } from "client/lib/providers/SessionProvider";
import AdminVerification from "./AdminVerification";
import useAdminVerification from "./useAdminVerification";
import Status from "./Status";
import Bans from "./Bans";
import PswrLink from "./PswrLink";


const Account = ({ account }) => {

  const { id, status } = account || {};


  const { account: { id: myAccountId } } = useContext(SessionContext);


  const [verified, setVerified] = useState(true);


  const me = useMemo(() => id === myAccountId, [id, myAccountId]);


  const deleted = useMemo(() => status === dbEnums.accountStatus.DELETED, [status]);


  const { checkVerified, ...verificationProps } = useAdminVerification({
    setVerified,
    verified,
  });


  return (
    <Grid item container spacing={5}>
      {!verified && !deleted && (
        <Grid item xs={12}>
          <AdminVerification account={account}
                             {...verificationProps}
          />
        </Grid>
      )}
      {!me && !deleted && (
        <Grid item xs={12}>
          <PswrLink account={account}
                    verified={verified}
                    checkVerified={checkVerified}
          />
        </Grid>
      )}
      {!me && (
        <Grid item xs={12}>
          <Status account={account}
                  deleted={deleted}
                  verified={verified}
                  checkVerified={checkVerified}
          />
        </Grid>
      )}
      {!deleted && (
        <Grid item xs={12}>
          <Bans account={account}
                verified={verified}
                checkVerified={checkVerified}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Account);
