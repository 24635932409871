import React, { useMemo } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { dateStr } from "common/helpers";
import { MiniChip } from "components/common";


const Details = ({ id, status, statusLabel, exp, lastRevision, regDate, lastUpdate, lastLogin, lastTerms }) => {

  const statusColorClassName = useMemo(() => status === dbEnums.accountStatus.DELETED
    ? "bg-red"
    : status === dbEnums.accountStatus.ACTIVE
      ? "bg-green"
      : "bg-orange", [status]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item>
          <MiniChip label={clsx("Azonosító:", id)}
                    colorClassName="bg-blue"
          />
        </Grid>
        <Grid item>
          <MiniChip label={statusLabel}
                    colorClassName={statusColorClassName}
          />
        </Grid>
        {Boolean(exp) && (
          <Grid item>
            <MiniChip label={clsx("Fióktörlés:", dateStr(exp))}
                      colorClassName="bg-red"
            />
          </Grid>
        )}
        {Boolean(lastRevision) && (
          <Grid item>
            <MiniChip label={clsx("Legutóbbi adatellenőrzés:", dateStr(lastRevision))}
                      colorClassName="bg-blue"
            />
          </Grid>
        )}
        {Boolean(lastUpdate) && (
          <Grid item>
            <MiniChip label={clsx("Legutóbbi adatmódosítás:", dateStr(lastUpdate))}
                      colorClassName="bg-blue"
            />
          </Grid>
        )}
        {Boolean(lastLogin) && (
          <Grid item>
            <MiniChip label={clsx("Legutóbbi bejelentkezés:", dateStr(lastLogin))}
                      colorClassName="bg-blue"
            />
          </Grid>
        )}
        {Boolean(regDate) && (
          <Grid item>
            <MiniChip label={clsx("Regisztrált:", dateStr(regDate))}
                      colorClassName="bg-purple"
            />
          </Grid>
        )}
        {Boolean(lastTerms) && (
          <Grid item>
            <MiniChip label={clsx("Feltételeket elfogadta:", dateStr(lastTerms))}
                      colorClassName="bg-purple"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(Details);
