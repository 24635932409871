import React from "react";
import clsx from "clsx";
import DOMPurify from "dompurify";
import { Parser as HtmlToReactParser, ProcessNodeDefinitions } from "html-to-react";
import { Typography } from "@material-ui/core";


const isValidNode = () => true;


const processNodeDefinitions = new ProcessNodeDefinitions(React);


const htmlToReactParser = new HtmlToReactParser();


const getTypographyVariant = tag => {
  const level = parseInt(clsx(tag).substr(1) || 9);
  return level > 5 ? "body2" : tag;
};


const processingInstructions = [
  {
    // Custom <p> processing
    shouldProcessNode: node => {
      return node.name === "p";
    },
    processNode: (node, ...restArgs) => {

      const attribArr = Object.keys(node.attribs || {});

      const cls = clsx(attribArr.includes("data-pre-list") && "data-pre-list", attribArr.includes("data-end") && "end");

      return processNodeDefinitions.processDefaultNode({
        ...node,
        attribs: {
          ...(cls ? { class: cls } : {}),
        },
      }, ...restArgs)
    },
  },
  {
    // Custom <h1>, <h2> etc. processing
    shouldProcessNode: node => {
      return node.name && /^p$|^h\d$/.test(node.name);
    },
    processNode: function processNode(node, children, index) {
      return React.createElement(Typography, { key: index, variant: getTypographyVariant(node.tagName) }, children);
    },
  },
  {
    // Custom <ol> processing
    shouldProcessNode: node => {
      return node.name === "ol" || node.name === "ul";
    },
    processNode: (node, ...restArgs) => {
      const attribs = {};

      if (node.attribs && node.attribs.start) attribs.start = node.attribs.start;
      if (node.attribs && node.attribs["data-list-style-type"]) attribs.style = `list-style-type: ${node.attribs["data-list-style-type"]}; ${attribs.style}`;

      return processNodeDefinitions.processDefaultNode({
        ...node,
        attribs,
      }, ...restArgs);
    },
  },
  {
    // remove all attributes for security reasons
    shouldProcessNode: () => true,
    processNode: (node, ...restArgs) => processNodeDefinitions.processDefaultNode({
      ...node,
      attribs: {},
    }, ...restArgs),
  },
];


const parseHtml = html => {
  const safeHtml = html && DOMPurify.sanitize(html);
  if (!safeHtml) return null;

  return htmlToReactParser.parseWithInstructions(safeHtml, isValidNode, processingInstructions);
};

export default parseHtml;
