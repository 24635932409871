import React, { useCallback, useContext, useMemo, useState } from "react";
import clsx from "clsx";
import Link from "next/link";
import { Button, Icon, ListItemIcon, ListItemText, Popover, MenuItem, Typography, Hidden } from "@material-ui/core";
import userMenuItems from "config/userMenu";
import { logout, useFilterByRoles } from "client/lib/session";
import { SessionContext } from "client/lib/providers/SessionProvider";
import alumnus_picture from "client/store/alumnus/resolvers/getters/alumnus_picture";
import { AlumnusAvatar } from "components/alumnus/common";


const UserMenu = () => {

  const { personal: { username, name, imgFileId } } = useContext(SessionContext);


  const [userMenu, setUserMenu] = useState(null);


  const firstName = useMemo(() => name && (name.nick || name.first), [name]);


  const src = useMemo(() => alumnus_picture.src({ fileId: imgFileId }), [imgFileId]);


  const alumnus = useMemo(() => ({
    name,
    img: {
      src,
    },
  }), [name, src]);


  const menuItems = useFilterByRoles({
    config: userMenuItems,
  });


  const userMenuClick = useCallback(event => {
    setUserMenu(event.currentTarget);
  }, []);


  const userMenuClose = useCallback(() => {
    setUserMenu(null);
  }, []);

  return (
    <>
      <Button className="h-64 mx-8" onClick={userMenuClick}>
        <Hidden smUp={!firstName}>
          <AlumnusAvatar alumnus={alumnus}
                         size={35}
                         avatarClassName="font-200"
                         defaultAvatar
                         noButton
                         rounded
          />
        </Hidden>

        <Hidden xsDown>
          <div className="flex flex-col ml-12 items-start">
            <Typography component="span" className="normal-case font-600 flex">
              {firstName || username}
            </Typography>
          </div>
        </Hidden>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          paper: "py-8"
        }}
      >
        <>
          {menuItems.map(({ id, href, as, icon, title, disabled, disallowed }) => !disallowed && (
            <Link key={id} href={clsx(href)} as={clsx(as || href)}>
              <MenuItem onClick={userMenuClose} disabled={disabled}>
                <ListItemIcon className="min-w-40">
                  <Icon>{icon}</Icon>
                </ListItemIcon>
                <ListItemText className="pl-0" primary={title}/>
              </MenuItem>
            </Link>
          ))}

          <MenuItem onClick={logout}>
            <ListItemIcon className="min-w-40">
              <Icon>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText className="pl-0" primary="Kijelentkezés"/>
          </MenuItem>

        </>
      </Popover>
    </>
  );
};

export default React.memo(UserMenu);
