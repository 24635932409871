import React, { useContext, useMemo } from "react"
import moment from "moment";
import { Typography, Grid } from "@material-ui/core";
import { SessionContext } from "client/lib/providers/SessionProvider";


const EffectiveDate = ({ className }) => {

  const { terms } = useContext(SessionContext);

  const { effective, note } = terms || {};

  const effectiveDate = useMemo(() => moment(effective).format("LL"), [effective]);

  return (
    <Grid container spacing={2} className={className}>
      <Grid item xs={12} className="flex flex-row">
        <Typography color="textSecondary" className="text-12">
          Hatályos:&nbsp;
        </Typography>
        <Typography className="text-12">{effectiveDate}</Typography>
      </Grid>
      {Boolean(note) && (
        <Grid item xs={12} className="flex flex-row">
          <Typography color="textSecondary" className="text-12">
            Közlemény:&nbsp;
          </Typography>
          <Typography className="text-12">{note}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(EffectiveDate);
