import clsx from "clsx";
import { labels } from "common/enums";
import { normalizeStr } from "common/helpers";


const toFilter = async ({ username, roles, note, banned, bannedServices }) => {
  const roleLabels = (roles || []).map(role => labels.accountRoles[role]).join(" ");

  const bannedLabel = clsx(banned && "kitiltott kitiltás");

  const bannedServiceLabel = clsx(bannedServices && "kitiltott kitiltás szolgáltatás korlátozás korlátozott");

  const raw = clsx(username, roleLabels, bannedLabel, bannedServiceLabel, note);
  return normalizeStr(raw, true);
};

export default toFilter;
