import { statusTasks } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { decoratedError, errors } from "common/errors";


const getTasksByType = (wrapper, statuses) => statuses.reduce((tasks, { code: status }) => (status && wrapper && wrapper[status])
  ? [
    ...tasks,
    ...wrapper[status],
  ]
  : tasks, []);


const getListsTasksByType = (lists, statuses) => statuses && lists && lists.reduce((tasks, list) => [
  ...tasks,
  ...getTasksByType(list, statuses)
], []);


const collectTasks = (lists, appSubscriptions) => statusTasks.reduce((collectedTasks, { task, statuses }) => {
  const listTasks = getListsTasksByType(lists, statuses) || [];
  const missingTasks = getTasksByType(appSubscriptions, statuses) || [];
  const tasks = [].concat(listTasks, missingTasks);

  const tasksWithTypes = tasks.map(rawTask => ({
    ...rawTask,
    task,
  }));

  return [
    ...collectedTasks,
    ...tasksWithTypes,
  ];
}, []);


const getTasks = ({ primaryList, secondaryList, appSubscriptions }) => {
  try {
    const tasks = collectTasks([primaryList, secondaryList], appSubscriptions);
    return { tasks };

  } catch (e) {
    const message = errorLogStore("octopus", "getTasks", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};

export default getTasks;
