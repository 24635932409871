import { getService } from "./helpers";


export const pswrLink = {
  checkLink: getService("pswrLink/checkLink"),
  pswReset: getService("pswrLink/pswReset"),
  sendAdminLink: getService("pswrLink/sendAdminLink"),
  sendLink: getService("pswrLink/sendLink"),
  verifyPhone: getService("pswrLink/verifyPhone"),
};
