import React, { useMemo } from "react";
import clsx from "clsx";
import { TextField, Grid, Button, Icon } from "@material-ui/core";
import { Controller } from "components/form";


const VerificationForm = ({
                            codeReqs, codeSent, parentLoading, banned, verificationLoading, error: _error, name,
                            onChange, sendCode, helperTextOrError: _helperTextOrError, placeholder, rules,
                            textFieldClassName, variant = "outlined"
                          }) => {


  const error = useMemo(() => _error && !parentLoading, [_error, parentLoading]);


  const disabled = useMemo(() => parentLoading || banned || verificationLoading, [banned, parentLoading, verificationLoading]);


  const helperTextOrError = useMemo(() => !parentLoading && _helperTextOrError, [_helperTextOrError, parentLoading]);


  const maxLength = useMemo(() => codeReqs && codeReqs.codeLength, [codeReqs]);


  return (
    <Grid container spacing={4} alignItems="flex-start" justify="space-between">
      {Boolean(codeSent) && (
        <Grid item xs={12} sm={7} className="mb-8">
          <Controller
            name={name}
            as={<TextField/>}
            rules={rules}
            defaultValue=""
            onChange={onChange}
            inputProps={{
              maxLength: maxLength || 255,
            }}
            classes={{
              root: `w-full ${clsx(textFieldClassName)}`
            }}
            variant={variant}
            helperText={helperTextOrError}
            placeholder={placeholder}
            required
            error={error}
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={codeSent ? 5 : 12} className={clsx(codeSent && "flex sm:justify-end")}>
        <Button variant="contained" className="sm:mt-8" onClick={sendCode} disabled={disabled}>
          {Boolean(codeSent) && (
            <Icon className="mr-8">refresh</Icon>
          )}
          {codeSent ? "Új kódot kérek" : "Kérem a kódot"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(VerificationForm);
