import React from "react";
import { Grid, Checkbox, Tooltip } from "@material-ui/core";


const Toolbar = ({ toggleAll, all, indeterminate, checked, children }) => (
  <Grid container justify="space-between" alignItems="center" className="w-full px-12">
    <Grid item container spacing={1} alignItems="center">
      <Grid item>
        <Tooltip title={all ? "Összes kijelölés törlése" : "Összes kijelölése"}>
          <Checkbox
            onChange={toggleAll}
            checked={all}
            indeterminate={indeterminate}
          />
        </Tooltip>
      </Grid>
      {checked > 0 && (
        <>
          <Grid item>
            <div className="border-solid border-0 border-r-1 h-48 w-1 mx-12 my-0"/>
          </Grid>
          {children}
        </>
      )}
    </Grid>
    <Grid item>
    </Grid>
  </Grid>
);

export default React.memo(Toolbar);
