import { useCallback, useContext, useMemo } from "react";
import { validateEmailFormat, FormContext } from "components/form";
import { requirementMessages } from "../config";
import { updateFields, __typename } from "./Email";


const useSecondaryEmailAddress = ({ record, requirements }) => {

  const { validateUniqueness } = useContext(FormContext);


  const validateUniquePerAlumnus = useCallback(value => validateEmailFormat(value) !== true
    || !validateUniqueness(__typename, updateFields[__typename].email, value, record.id)
    || requirementMessages.email.notUniquePerAlumnus
    , [record, validateUniqueness]);


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    validate: {
      validateUniquePerAlumnus,
      ...(requirements && requirements.validate),
    },
  }), [requirements, validateUniquePerAlumnus]);


  return {
    fieldRequirements,
  };

};

export default useSecondaryEmailAddress;
