import { getServerError } from "./helpers";


export const serverErrors = {
  authentication: {
    INVALID_ACCOUNT_STATUS: getServerError("general", "INVALID_ACCOUNT_STATUS", 403),
  },
  general: {
    BAD_REQUEST: getServerError("general", "BAD_REQUEST", 400),
    BANNED: getServerError("general", "BANNED", 403),
    FILE_SIZE_ERROR: getServerError("general", "FILE_SIZE_ERROR", 400),
    FORBIDDEN: getServerError("general", "FORBIDDEN", 403),
    INVALID: getServerError("general", "INVALID", 200),
    NETWORK_ERROR: getServerError("backendApi", "NETWORK_ERROR"),
    NOT_FOUND: getServerError("general", "NOT_FOUND", 404),
    NOT_SUPPORTED_USER_AGENT: getServerError("general", "NOT_SUPPORTED_USER_AGENT", 403),
    SERVER_ERROR: getServerError("general", "SERVER_ERROR"),
    UNAUTHORIZED: getServerError("general", "UNAUTHORIZED", 401),
  },
  healthcheck: {
    UNHEALTHY: getServerError("healthcheck", "UNHEALTHY"),
  },
  octopus: {
    API_ERROR: getServerError("octopus", "API_ERROR"),
    BOTTLENECK_LIMITER: getServerError("octopus", "BOTTLENECK_LIMITER"),
    ON_GET_OPTIONS: getServerError("octopus", "ON_GET_OPTIONS"),
    ON_PROCESS_TASK_FNS: getServerError("octopus", "ON_PROCESS_TASK_FNS"),
    MISSING_ARGS_ON_CHECKING_PARAMS: getServerError("octopus", "MISSING_ARGS_ON_CHECKING_PARAMS"),
  },
  sms: {
    GATEWAY_HANDLE_MESSAGE_PROMISE: getServerError("sms", "GATEWAY_HANDLE_MESSAGE_PROMISE"),
  },
};
