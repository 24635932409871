import { errors, isSameAppError } from "common/errors";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { validateMe } from "./load";
import { setFetched, setFetching } from "./update";
import { isAuthenticated } from "./getters";
import { updateSessionStore } from "./store";
import { getSession } from "./session";


const fetchLogin = async credentials => {
  await setFetching();

  const { error } = await backendFetch(backendServices.auth.login, credentials);
  const success = !error && await updateSessionStore(true);

  const { banned } = getSession();
  if (!success && !banned) await validateMe();

  const notSupportedUserAgent = isSameAppError({ error }, errors.server.general.NOT_SUPPORTED_USER_AGENT);
  const invalidAccountStatus = isSameAppError({ error }, errors.server.authentication.INVALID_ACCOUNT_STATUS);

  await setFetched();

  return {
    error: !success,
    notSupportedUserAgent,
    invalidAccountStatus,
  };
};


export const login = async credentials => {
  const invalidMe = await validateMe();
  if (invalidMe) return invalidMe;

  const { error, ...data } = (isAuthenticated() || await fetchLogin(credentials)) || {};
  if (error) return { error, ...data };

  return {};
};
