import React, { useMemo } from "react";
import clsx from "clsx";
import EnumField from "./EnumField";
import Label from "./Label";


export const options = [
  {
    value: 0,
    label: "Minden alumnus láthatja",
    icon: "group",
    color: "secondary",
    className: "",
  },
  {
    value: 1,
    label: "Csak az évfolyamtársaim láthatják",
    icon: "folder_open",
    color: "primary",
    className: "",
  },
  {
    value: 2,
    label: "Csak az osztálytársaim láthatják",
    icon: "folder_special",
    color: "primary",
    className: "",
  },
  {
    value: 3,
    label: "Csak az adminisztrátorok láthatják",
    icon: "lock",
    color: "primary",
    className: "",
  }
];


const PrivacyPolicy = ({
                         tooltip, tooltipSuffix = "vonatkozó adatvédelmi beállítás", className, helperText,
                         label = "Adatvédelmi beállítás", ...restProps
                       }) => {

  const tooltipWSuffix = useMemo(() => clsx(tooltip && clsx(tooltip, tooltipSuffix)).trim()
    , [tooltip, tooltipSuffix]);

  return (
    <Label label={label} helperText={helperText}>
      <EnumField
        {...restProps}
        tooltip={tooltipWSuffix}
        options={options}
        className={className}
      />
    </Label>
  );
};

export default React.memo(PrivacyPolicy);
