import gql from "graphql-tag";


export const INSERT_TAGS = gql`
  mutation InsertTags($records: [tag_insert_input!]!) {
    results: insert_tag(objects: $records, on_conflict: {constraint: tag__unique_entity_tag, update_columns: [tag]}) {
      affected_rows
      returning {
        id
      }
    }
  }
`;


export const GET_INSERTED_TAG_IDS = gql`
  mutation GetInsertedTagIds($rawTags: jsonb!, $entity: String!) {
    getInsertedTagIds(rawTags: $rawTags, entity: $entity) @client
  }
`;
