import gql from "graphql-tag";


export const SAVE_PHONES = gql`
  mutation SavePhones($phones: jsonb!, $primaryPhoneId: Int!, $deletedIds: [Int!], $alumnusId: Int!, $issue: Boolean) {
    savePhones(phones: $phones, primaryPhoneId: $primaryPhoneId, deletedIds: $deletedIds, alumnusId: $alumnusId, issue: $issue) @client
  }
`;


export const UPSERT_SECONDARY_PHONES = gql`
  mutation UpsertSecondaryPhones($records: [phone_insert_input!]!) {
    results: insert_phone(objects: $records, on_conflict: {constraint: phone__pkey, update_columns: [privacy, phone, note, tags]}) {
      affected_rows
    }
  }
`;


export const DELETE_SECONDARY_PHONES = gql`
  mutation DeleteSecondaryPhones($ids: [Int!]!) {
    results: delete_phone(where: {id: {_in: $ids}, is_primary: {_eq: false}}) {
      affected_rows
    }
  }
`;
