import { pathNames } from "./routing";


const shortcutItems = [
  {
    id: 1,
    href: pathNames.startPage.href(),
    title: "Kezdőlap",
    icon: "dashboard",
  },
  {
    id: 2,
    href: pathNames.alumnus.href,
    as: pathNames.alumnus.as("me"),
    title: "Saját adatlapom",
    icon: "account_circle",
  },
  {
    id: 3,
    href: pathNames.group.href,
    title: "Osztályom",
    icon: "group",
  },
  {
    id: 4,
    href: pathNames.search.href,
    title: "Alumnus kereső",
    icon: "contacts",
  },
];

export default shortcutItems;
