import React, { useContext } from "react"
import clsx from "clsx";
import Link from "next/link";
import { AppBar, Toolbar, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { pathNames } from "config/routing";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { ExternalLink } from "components/common";
import { Copyright } from "../common";


const useStyles = makeStyles(theme => ({
  footerLink: {
    color: theme.palette.text.primary,
  },
}));


const PublicMenu = ({ reduced, copyright }) => {

  const { envVars: { publicSiteUrl } } = useContext(SessionContext);

  const classes = useStyles();

  return (
    <div className="flex items-end">
      <AppBar className={clsx("relative z-10", reduced && "shadow-1")} color="default">
        <Toolbar className={clsx("flex items-center px-16", reduced ? "py-16 min-h-12" : "min-h-28 py-24 sm:py-16")}>
          <Grid container spacing={3} wrap="wrap-reverse">
            {Boolean(copyright) && (
              <Grid item xs={12} md="auto" className="md:mr-48">
                <Copyright noVersion/>
              </Grid>
            )}
            <Grid item xs={12} md="auto">
              <Grid container spacing={3}>
                <Grid item>
                  <Link href={pathNames.login.href}>
                    <a className={clsx(classes.footerLink, "hover:underline cursor-pointer text-13")} role="button">
                      Bejelentkezés
                    </a>
                  </Link>
                </Grid>
                {!reduced && (
                  <Grid item>
                    <Link href={pathNames.registration.href}>
                      <a className={clsx(classes.footerLink, "hover:underline cursor-pointer text-13")} role="button">
                        Regisztráció
                      </a>
                    </Link>
                  </Grid>
                )}
                {!reduced && (
                  <Grid item>
                    <Link href={pathNames.terms.href}>
                      <a className={clsx(classes.footerLink, "hover:underline cursor-pointer text-13")} role="button">
                        Adatkezelési Tájékoztató
                      </a>
                    </Link>
                  </Grid>
                )}
                <Grid item>
                  <ExternalLink href={publicSiteUrl} noIcon>
                    <span className={clsx(classes.footerLink, "hover:underline text-13")}>Az Alumni weboldala</span>
                  </ExternalLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default React.memo(PublicMenu);
