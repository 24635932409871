import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { helps } from "components/help";
import VerificationHelp from "./VerificationHelp";
import VerificationForm from "./VerificationForm";
import useVerification from "./useVerification";


export const modes = {
  admin: "admin",
  email: "email",
  phone: "phone",
};


const Verification = ({
                        mode, record, field, codeReqs, onVerified, codeSent, setCodeSent, parentLoading, noHelp, banned,
                        banExpiryFormatted, fetch, registration, helperText, className, textFieldClassName, variant = "outlined"
                      }) => {


  const { help: { openHelp } } = useContext(UiStateContext);


  const { verificationLoading, ...restVerification } = useVerification({
    mode,
    record,
    field,
    codeReqs,
    onVerified,
    setCodeSent,
    banned,
    fetch,
    registration,
    helperText,
  });


  const onHelpClick = useCallback(() => openHelp(helps.verify[mode]), [mode, openHelp]);


  return (
    <Grid container spacing={2}
          alignItems="center"
          className={clsx(!clsx(className).includes("mb") && "mb-32", className, parentLoading && !verificationLoading && "hidden")}>
      <Grid item xs={12} className="mt-4">
        <Typography className="flex flex-col" variant="caption">
          <span>
            {mode === modes.phone || mode === modes.email
              ? (
                <span>Ahhoz, hogy ellenőrizni tudjuk, hogy valóban jó {mode === modes.phone ? "számot" : "email címet"} adtál-e
                    meg,{clsx(mode === modes.phone && " SMS-ben")} egy megerősítő kódot küldünk erre
                    a {mode === modes.phone ? "telefonszámra" : "email címre"}.</span>
              )
              : (
                <span>Jogosultságod ellenőrzéséhez egy megerősítő kódot küldünk a kapcsolattartási telefonszámodra.</span>
              )
            }
            <span>&nbsp;Kiküldéshez kattints a gombra!</span>
            {mode !== modes.admin && (
              <span>&nbsp;További információk&nbsp;<a rel="noopener noreferrer" className="my-1 cursor-pointer"
                                                      onClick={onHelpClick}>a súgóban</a>.</span>
            )}
          </span>
        </Typography>
      </Grid>
      {Boolean(banned) && (
        <Grid item xs={12}>
          <Typography className="flex flex-col text-red" variant="caption">
            <span>
              A kódkiküldést és -kitöltést biztonsági okokból&nbsp;
              <b>{banExpiryFormatted ? `zárultuk, eddig: ${banExpiryFormatted}` : "határozatlan időre zárultuk"}</b>.&nbsp;A kód
              mező ez idő alatt nem tölthető ki, valamint új kód is csak ezután küldhető ki. A mező és a gomb a feloldást követően&nbsp;
              <b>automatikusan újra aktívvá válik</b>, így az oldalt <i>nem</i> szükséges újratölteni.
            </span>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <VerificationForm codeReqs={codeReqs}
                          codeSent={codeSent}
                          parentLoading={parentLoading}
                          banned={banned}
                          verificationLoading={verificationLoading}
                          textFieldClassName={textFieldClassName}
                          variant={variant}
                          {...restVerification}
        />
      </Grid>
      {Boolean(!noHelp && codeSent) && (
        <Grid item xs={12}>
          <VerificationHelp mode={mode}/>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Verification);
