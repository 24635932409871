import React, { useCallback, useContext } from "react";
import Router from "next/router";
import clsx from "clsx";
import { Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import { pathNames } from "config/routing";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { ListContext } from "components/listPages";


const RowControl = ({ id, details, toggleDetails }) => {

  const { allDetails } = useContext(ListContext)

  const readOnly = useForbidden([dbEnums.accountRoles.MANAGER]);

  const navigateToProfile = useCallback(() => Router.push(pathNames.alumnus.href, pathNames.alumnus.as(id)), [id]);

  const navigateToProfileEdit = useCallback(() => Router.push(pathNames.alumnus.href, `${pathNames.alumnus.as(id)}/edit`), [id]);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Tooltip title={clsx("További információk", details ? "elrejtése" : "megjelenítése")}>
          <IconButton size="small"
                      onClick={toggleDetails}
                      disabled={allDetails}
          >
            <Icon>{details || allDetails ? "more_horiz" : "more_vert"}</Icon>
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Ugrás az alumnus adatlapjára">
          <IconButton size="small"
                      onClick={navigateToProfile}
          >
            <Icon>account_circle</Icon>
          </IconButton>
        </Tooltip>
      </Grid>
      {!readOnly && (
        <Grid item>
          <Tooltip title="Alumnus adatlap szerkesztése">
            <IconButton size="small"
                        onClick={navigateToProfileEdit}
            >
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(RowControl);
