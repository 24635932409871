import clsx from "clsx";
import { createLogger, getDefaultFileOptions, getFormattedCurrentTS } from "../logger";
import { actions, logServices } from "../config";


// only on server side
const winston = typeof window === "undefined" ? require("winston") : {};
const { format } = winston || {};


const log = (logger, level, { message, action = actions.UNKNOWN, details }) => logger && logger.log({
  timestamp: new Date().toISOString(),
  level,
  message,
  action,
  details,
});


const consoleTemplateFn = ({ level, message, action, details: { to, delivered, admin } = {} }) =>
  `${getFormattedCurrentTS()} [${level}] ${message} Action: ${action}${clsx(admin && ` (admin)`)} To: ${to} Delivered: ${delivered}`;


const loggerConfig = {
  consoleOptions: {
    level: "verbose",
    format: format && format.printf(consoleTemplateFn),
  },
  fileOptions: getDefaultFileOptions(logServices.MAIL),
};


const logger = createLogger(logServices.MAIL, loggerConfig, log);

export default logger;
