import memoizee from "memoizee";
import { searchCategories } from "common/enums/db";
import { errorLogStore } from "client/lib/errorLog";
import { getSearchFilters, setCount, sortTypes } from "client/lib/providers/uiState";
import { GET_EMPTY_SEARCH, GET_SEARCH_IDS } from "../queries";


const getVariables = () => {
  const { sortType, sortDesc, searchText, category, yearFrom, yearTo } = getSearchFilters();

  const discount = category === searchCategories.DISCOUNT;

  const byRelevance = sortType === sortTypes.byRelevance.id;
  const relevance = sortDesc ? "asc_nulls_first" : "desc_nulls_last";
  const fullname = sortDesc ? "desc" : "asc";
  const orderBy = byRelevance
    ? [{ relevance }, { fullname }]
    : [{ fullname }];

  return {
    orderBy,
    ...(searchText ? { searchText } : {}),
    ...(searchText && category ? { category } : {}),
    ...(yearFrom ? { yearFrom } : {}),
    ...(yearTo ? { yearTo } : {}),
    ...(!searchText ? { discount } : {}),
  };
};


const doFetch = memoizee(async (_searchHash, client) => {
  const variables = getVariables(_searchHash);

  const { data: { ids: rawIds } } = await client.query({
    query: variables.searchText ? GET_SEARCH_IDS : GET_EMPTY_SEARCH,
    fetchPolicy: "network-only",
    variables,
  });

  return rawIds;
}, { length: 1 });


const fetchIds = async (_searchHash, client) => {
  try {
    const rawIds = await doFetch(_searchHash, client);
    await setCount(rawIds);
    return rawIds;

  } catch (e) {
    errorLogStore("search", "fetchIds", e);
    await setCount();
    throw e;
  }
};

export default fetchIds;
