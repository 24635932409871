import { UPSERT_CARDS } from "client/store/alumnus/mutations";
import { errorLogStore } from "client/lib/errorLog";
import { doMutation, removeMetaKeys } from "../helpers";
import { GET_CARDS } from "./queries";


const updateCache = (client, record) => {
  try {
    const { cards: cachedCards } = client.readQuery({ query: GET_CARDS });
    client.writeQuery({
      query: GET_CARDS,
      data: {
        cards: (cachedCards || []).map(cached => cached.id !== record.id ? cached : {
          ...cached,
          ...record,
        }),
      },
    });

  } catch (e) {
    errorLogStore("card", "updateCache", e);
    throw e;
  }
};


const saveCard = async (_root, { card, alumnusId }, { client }) => {
  const record = {
    ...removeMetaKeys(card),
    alumnus__id: alumnusId,
  };

  await doMutation(client, UPSERT_CARDS, {
    records: [record]
  });

  updateCache(client, record);
};

export default saveCard;
