import React, { useCallback, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar, IconButton, Icon, SnackbarContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark)
  },
}));


const autoHideDuration = 15 * 1000;


const ApiError = () => {

  const { apiError: { opened, message, hide } } = useContext(UiStateContext);

  const onClose = useCallback(() => hide(), [hide]);

  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      autoHideDuration={autoHideDuration}
      open={opened}
      onClose={onClose}
      ContentProps={{
        variant: "body2",
        headlineMapping: {
          body1: "div",
          body2: "div"
        }
      }}
    >
      <SnackbarContent
        className={classes.error}
        classes={{
          action: "pl-0"
        }}
        message={
          <div className="flex items-center">
            <Icon className="mr-8" color="inherit">error_outline</Icon>
            Hiba lépett fel. A részletek vágólapra másolásához kattints erre a gombra:
          </div>
        }
        action={[
          <CopyToClipboard key="copy" text={message}>
            <IconButton
              aria-label="Copy"
              color="inherit"
            >
              <Icon>file_copy</Icon>
            </IconButton>
          </CopyToClipboard>
          ,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default React.memo(ApiError);
