import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { newRecordIdSuffix } from "components/form/config";
import { __typename, _updateFields, getRandomCode } from "components/form/regCode";
import { useOpenDialog } from "components/listPages";
import EditRegCode from "./EditRegCode";


const getEmptyRecord = () => ({
  id: `1${newRecordIdSuffix}`,
  [_updateFields.mem]: dbEnums.membership.NONGRADUATED,
  [_updateFields.code]: getRandomCode(),
  [_updateFields.name]: "",
  [_updateFields.group]: "",
  [_updateFields.formTeacher]: "",
  [_updateFields.note]: "",
  [_updateFields.expiry]: null,
  __typename,
});


const getContentComponent = () => (
  <EditRegCode isNew {...getEmptyRecord()}/>
);


const AddButton = () => {

  const add = useOpenDialog({
    title: "Új regisztrációs kód hozzáadása",
    ContentComponent: getContentComponent(),
  });

  return (
    <Tooltip title="Új regisztrációs kód hozzáadása">
      <IconButton size="small"
                  onClick={add}
      >
        <Icon>add</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(AddButton);
