import { setGlobal, getGlobal } from "reactn";


export const globalKey = "session";


export const tokens = {
  cds: "",
  CSRFToken: "",
};


const emptySession = {
  // statuses
  initialized: false,
  identified: false,
  loaded: false,
  failed: false,
  fetching: false,

  // ban
  banExpiry: null,
  banned: false,
  permanentBan: false,
  bannedIp: null,

  // session data
  account: {},
  issues: {},
  personal: {},
  ip: "",
  userAgent: {},
  terms: {},
  envVars: {},
};


export const setTokens = data => Object.entries(data || {}).forEach(([key, value]) => tokens[key] = value);


export const clearSession = async () => await setGlobal({
  ...getGlobal(),
  [globalKey]: emptySession,
});


export const getSession = () => (getGlobal() || {})[globalKey] || {};


export const setSession = async data => await setGlobal({
  ...getGlobal(),
  [globalKey]: {
    ...getSession(),
    ...data,
  },
});


// initialize global state with emptySession
clearSession();
