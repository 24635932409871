import React, { useCallback, useContext, useMemo, useState } from "react";
import clsx from "clsx";
import { Button, Grid, Tooltip, Typography } from "@material-ui/core";
import texts from "config/texts";
import { accent, withAccent } from "common/helpers";
import { useConfirm } from "components/common";
import { AlumnusContext } from "components/alumnus";
import Address from "components/alumnus/profile/profileTab/Address";
import { CheckBox, ConfirmInput, EmailAddress } from "components/form";
import { typenames, jump } from "components/form/address";
import { getPanelKey, getPrefixedId } from "../helpers";


export const __typename = "alumnus_newsletter";


const _updateFields = {
  nws: "nws",
  magazine: "magazine",
  email: "email",
};


export const updateFields = {
  [__typename]: _updateFields,
};


export const requiredFields = {
  [__typename]: [_updateFields.nws, _updateFields.magazine],
};


const _fieldAliases = {
  [_updateFields.nws]: "subscription_newsletter",
  [_updateFields.magazine]: "subscription_magazine",
  [_updateFields.email]: "newsletter_email",
};


export const fieldAliases = {
  [__typename]: _fieldAliases,
};


const magazineHelperText = (
  <span>A jelölőnégyzet bejelölésével hozzájárulsz, hogy az Alumni {accent(texts.magazineName)}&nbsp;
    <i>{texts.magazineName} folyóiratot</i>&nbsp;<i>postai úton</i> megküldje számodra. Hozzájárulásodat a
    jelölőnégyzet kikapcsolásával vonhatod vissza.</span>
);


const nwsHelperText = (
  <span>A jelölőnégyzet bejelölésével hozzájárulsz, hogy az Alumni <i>email hírlevelét</i> megküldje számodra.
    Hozzájárulásodat a jelölőnégyzet kikapcsolásával vonhatod vissza.</span>
);


const Newsletter = ({ record, address, issue }) => {

  // AlumnusContext doesn't exist on EditContact issue step
  const { setRoute, untouched } = useContext(AlumnusContext) || {};


  const [magazine, setMagazine] = useState(record[_updateFields.magazine]);

  const [nws, setNws] = useState(record[_updateFields.nws]);

  const [email, setEmail] = useState(record[_updateFields.email]);


  const confirm = useConfirm({
    confirmOptions: jump.confirmOptions,
    disabled: untouched,
  });


  const addressInfo = useMemo(() => issue
    ? `${withAccent(texts.magazineName, true)} folyóirat kézbesítési címe az imént ellenőrzött levelezési cím:`
    : `${withAccent(texts.magazineName, true)} folyóirat kézbesítési címét a postai címek űrlapján adtad meg. A folyóirat kézbesítési címe:`
    , [issue]);


  const onMagazineChange = useCallback(value => setMagazine(value), []);


  const onNwsClick = useCallback(value => setNws(value), []);


  const onEmailChange = useCallback(value => setEmail(value), []);


  const navigateToAddress = useCallback(() => {
    const addressPrefixedId = getPrefixedId(address.id);
    const panelKey = getPanelKey(typenames.address, addressPrefixedId);

    setRoute && setRoute({
      disableConfirm: true,
      editMode: true,
      page: jump.page,
      tab: jump.tab,
      panelKey,
    });
  }, [address.id, setRoute]);


  const onAddress = useCallback(() => confirm(navigateToAddress), [confirm, navigateToAddress]);


  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <CheckBox record={record}
                  field={_updateFields.magazine}
                  onChangeCb={onMagazineChange}
                  title={clsx("Hozzájárulok", withAccent(texts.magazineName), "folyóirat részemre való, postai úton történő kiküldéséhez, egyúttal adataim kezeléséhez a folyóirat megküldéséhez (VI.)")}
                  helperText={magazineHelperText}
        />
        {Boolean(magazine) && (
          <div className="ml-32 mb-16">
            <Typography color="textSecondary" className="mb-8 text-13">
              {addressInfo}
            </Typography>
            <Address address={address} noIcon/>
            {!issue && (
              <Tooltip title="Ugrás a címek szerkesztéséhez">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="mt-16"
                  onClick={onAddress}
                >
                  Ugrás a címekhez
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <CheckBox record={record}
                  field={_updateFields.nws}
                  onChangeCb={onNwsClick}
                  title="Feliratkozom az Alumni email hírlevelére, egyúttal hozzájárulok adataim kezeléséhez a hírlevélküldéshez (IV.)"
                  helperText={nwsHelperText}
        />
        {Boolean(nws) && (
          <div className="ml-32 mt-4 mb-8">
            <EmailAddress
              record={record}
              field={_updateFields.email}
              required
              onChangeCb={onEmailChange}
              textFieldClassName="mb-0"
              label="Kézbesítési email cím"
              helperText="A hírlevél az itt megadott email címre kerül kiküldésre"
            />
            <ConfirmInput record={record}
                          field={_updateFields.email}
                          confirmValue={email}
                          className="mt-32 mb-0"
                          hiddenUntouched
                          noPaste
                          entity="email cím"
                          label="Email cím megerősítése"
                          helperText="Az imént megadott kézbesítési email cím ismételten"
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(Newsletter);
