import { useCallback, useMemo } from "react";


const useStatements = ({ stepId, statements, setStatements }) => {

  const stepStatements = useMemo(() => (statements && stepId && statements[stepId]) || [], [stepId, statements]);


  const doStatement = useCallback(statementId => setStatements(previousStatements => ({
    ...previousStatements,
    [stepId]: [
      ...(previousStatements[stepId] || []).filter(id => id !== statementId),
      statementId,
    ],
  })), [setStatements, stepId]);


  const revokeStatement = useCallback(statementId => setStatements(previousStatements => ({
    ...previousStatements,
    [stepId]: (previousStatements[stepId] || []).filter(id => id !== statementId),
  })), [setStatements, stepId]);


  const toggleStatement = useCallback(statementId => stepStatements.includes(statementId)
    ? revokeStatement(statementId)
    : doStatement(statementId)
    , [doStatement, revokeStatement, stepStatements]);


  return {
    stepStatements,
    doStatement,
    revokeStatement,
    toggleStatement,
  };

};

export default useStatements;
