import gql from "graphql-tag";


const fragmentAlumnusNLS = gql`
  fragment alumnus_nls on alumnus {
    id
    name: alumnus_name {
      id
      last: last_name
      first: first_name
    }
    nls: view_newsletter_subscription {
      id
      e: newsletter_email
      s: subscription_newsletter
    }
  }
`;


export const GET_NEWSLETTER_SUBSCRIPTIONS = gql`
  query GetNewsletterSubscriptions {
    members: alumnus {
      ...alumnus_nls
    }
  }

  ${fragmentAlumnusNLS}
`;


export const GET_OCTOPUS_STATE = gql`
  query GetOctopusState {
    octopusState @client {
      init {
        status
        startTime
        endTime
        error
        listInfo
        inProgress
      }
      status {
        status
        startTime
        endTime
        error
        stats
        tasks
        capacity
        primaryCount
        secondaryCount
        subscribedMembersCount
        inProgress
      }
      sync {
        status
        startTime
        endTime
        error
        numTasks
        completedTasks
        failedTasks
        percent
        stop
        inProgress
      }
    }
  }
`;
