import clsx from "clsx";
import { useCallback } from "react";
import { safeFnOrPromise } from "common/helpers";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { isNew, parsePrefixedId, getJobsCount } from "../helpers";


const notModified = "__notModified__";


const useSave = ({ records, deletedIds, getValues, updateFields, requiredFields, fieldAliases, saveCb }) => {

  const filterFieldEntry = useCallback((__typename, [field]) => Object.values(updateFields[__typename] || {}).includes(field)
    , [updateFields]);


  const getSafeField = useCallback((__typename, field) =>
      Object.keys((fieldAliases || {})[__typename] || {}).includes(field) ? fieldAliases[__typename][field] : field
    , [fieldAliases]);


  const getSafeValue = useCallback((__typename, field, value) => (requiredFields[__typename] || []).includes(field)
    ? value
    : value || null
    , [requiredFields]);


  const normalizeFieldEntry = useCallback((__typename, newRecord, [field, value]) => ([
    getSafeField(__typename, field),
    getSafeValue(__typename, field, value)
  ]), [getSafeValue, getSafeField]);


  const getJobFromItem = useCallback((__typename, isNew, item) => Object.fromEntries(Object.entries(item || {})
    .filter(fieldEntry => filterFieldEntry(__typename, fieldEntry))
    .map(fieldEntry => normalizeFieldEntry(__typename, isNew, fieldEntry))
  ), [filterFieldEntry, normalizeFieldEntry]);


  const getNormalizedRecord = useCallback((__typename, id) => {
    const strId = clsx(id);
    const record = (records || []).find(({ id: i, __typename: t }) => t === __typename && clsx(i) === strId);

    return record && Object.fromEntries(Object.entries(record).map(([field, value]) => [
      getSafeField(__typename, field),
      value,
    ]));
  }, [getSafeField, records]);


  const isRecordModified = useCallback((record, item) =>
      Object.entries(item).some(([field, value]) => Object.keys(record).includes(field) && value !== record[field])
    , []);


  const isItemModified = useCallback((__typename, id, item) => {
    const record = getNormalizedRecord(__typename, id);
    return record && isRecordModified(record, item);
  }, [getNormalizedRecord, isRecordModified]);


  const parseJob = useCallback((__typename, [prefixedId, item]) => {
    const id = parsePrefixedId(prefixedId);
    const _isNew = isNew(id);

    const job = getJobFromItem(__typename, _isNew, item);

    if (!_isNew) job.id = parseInt(id || 0);
    if (!_isNew && !isItemModified(__typename, id, job)) job[notModified] = true;

    return [prefixedId, job];
  }, [isItemModified, getJobFromItem]);


  const filterModifiedItemEntry = useCallback(itemEntry => {
    const fields = Object.keys(itemEntry[1] || {});
    return !fields.includes(notModified)
  }, []);


  const parseJobsOfTypename = useCallback((__typename, itemsWrapper) => Object.fromEntries(Object.entries(itemsWrapper || {})
    .map(itemEntry => parseJob(__typename, itemEntry))
    .filter(filterModifiedItemEntry)
  ), [filterModifiedItemEntry, parseJob]);


  const parseJobs = useCallback(formValues => Object.fromEntries(Object.entries(formValues || {})
    .map(([__typename, itemsWrapper]) => [__typename, parseJobsOfTypename(__typename, itemsWrapper)])
    .map(([__typename, itemsWrapper]) => [__typename, Object.values(itemsWrapper || {})])
  ), [parseJobsOfTypename]);


  return useCallback(async (...submitArgs) => {
    /* the shape of formValues:
        {
          __typename1: {
            prefixedId_typename1_item1: { ...typename1_item1_fields },
            prefixedId_typename1_item2: { ...typename1_item2_fields },
          },
          __typename2: {
            prefixedId_typename2_item1: { ...typename2_item1_fields },
          },
        }
    */
    const formValues = getValues();

    emitUiStateEvent(uiStateEvents.form.loadingStarted);

    const jobs = parseJobs(formValues);
    const jobsCount = getJobsCount(jobs);

    return await safeFnOrPromise(saveCb)({
      jobs,
      deletedIds,
      jobsCount,
      submitArgs,
    });
  }, [deletedIds, getValues, parseJobs, saveCb]);

};

export default useSave;
