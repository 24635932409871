import React from "react"
import clsx from "clsx";
import { Card, CardContent, Grid, makeStyles } from "@material-ui/core"
import { Scrollbars } from "../common";
import { useStyles } from "../styles";


const useWrapperStyles = makeStyles(theme => ({
  cardContent: {
    "&:last-child": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 24,
      },
      [theme.breakpoints.up("md")]: {
        paddingBottom: 32,
      },
    },
  },
}));


const CenterCardWrapper = ({ wrapperClassName, footer, header = footer, children }) => {

  const classes = useStyles();

  const wrapperClasses = useWrapperStyles();

  return (
    <div className="flex flex-col h-full">
      {Boolean(header) && (
        <div>
          {header}
        </div>
      )}

      <div className={clsx(classes.publicRoot, "h-full")}>
        <Scrollbars center>
          <Grid container
                alignItems="stretch"
                className="w-full overflow-hidden sm:p-32"
          >

            <Grid item xs={12} className="flex flex-col flex-auto flex-shrink-0 items-center justify-center">
              <Card
                className={clsx("w-full rounded-none sm:rounded-4", !clsx(wrapperClassName).includes("max-") && "max-w-384", wrapperClassName)}>
                <CardContent
                  className={clsx(wrapperClasses.cardContent, "flex flex-col items-center justify-center p-24 sm:p-28 md:p-32 lg:p-36 pb-32 lg:pb-36")}
                >
                  {children}
                </CardContent>
              </Card>
            </Grid>

            {Boolean(footer) && footer}

          </Grid>
        </Scrollbars>
      </div>
    </div>
  );
};

export default React.memo(CenterCardWrapper);
