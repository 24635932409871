import { useCallback, useEffect, useMemo, useState } from "react";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { newRecordIdSuffix } from "../config";
import { getPrefixedId } from "../helpers";


const useAddRecords = ({
                         records, newRecordTypename, newRecordValues, primaryId, deletedPrefixedIds, maxRecordsCount = 0,
                         enableSubmit
                       }) => {

  const [newRecords, setNewRecords] = useState([]);


  const recordsCount = useMemo(() => {
    const stored = (records || []).length || 0;
    const added = (newRecords || []).length || 0;
    const deleted = (deletedPrefixedIds || []).length || 0;
    return stored + added - deleted;
  }, [deletedPrefixedIds, newRecords, records]);


  const isFull = useMemo(() => {
    const capacity = primaryId
      ? parseInt(maxRecordsCount) + 1
      : parseInt(maxRecordsCount);

    return recordsCount >= capacity
  }, [maxRecordsCount, primaryId, recordsCount]);


  const addRecord = useCallback(() => {
    if (isFull) return;

    const idWSuffix = `${new Date().getTime()}${newRecordIdSuffix}`;

    setNewRecords(newRecords => [
      ...newRecords,
      {
        ...newRecordValues,
        __typename: newRecordTypename,
        id: idWSuffix,
      },
    ]);

    if (typeof enableSubmit === "function") enableSubmit();

    return getPrefixedId(idWSuffix);
  }, [isFull, enableSubmit, newRecordValues, newRecordTypename]);


  const reload = useCallback(() => {
    setNewRecords([]);
  }, []);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.dataRefetched, reload);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.dataRefetched, reload);
    }
  }, [reload]);


  return {
    newRecords,
    isFull,
    addRecord,
  };
};

export default useAddRecords;
