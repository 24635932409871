import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { useMutation } from "@apollo/react-hooks";
import { Grid, IconButton, Icon, Tooltip } from "@material-ui/core";
import { UiMessages } from "config/messages";
import { withAccent } from "common/helpers";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { DELETE_IP_BANS } from "client/store/bannedIps";
import { useConfirm } from "components/common";
import { Toolbar as ListToolbar, ListContext } from "components/listPages"


const getConfirmOptions = checkedCount => ({
  title: "IP kitiltás(ok) feloldása",
  content: `Biztosan feloldod ${withAccent(checkedCount)} kijelölt IP címre vonatkozó kitiltást?`,
  OKButton: "Igen, feloldom",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
});


const Toolbar = () => {

  const { checkedIds, resetChecked, resetFilters, checked, toggleAll, all, indeterminate, verified, checkVerified, refetch } = useContext(ListContext);


  const { message: { showMessage } } = useContext(UiStateContext);


  const [doDelete] = useMutation(DELETE_IP_BANS);


  const confirm = useConfirm({
    confirmOptions: getConfirmOptions(checked),
  });


  const deleteIpBans = useCallback(async () => {
    if (checkVerified && !await checkVerified()) return;

    const { error } = await doDelete({ variables: { ips: checkedIds } });

    if (!error) {
      await refetch();
      resetFilters();
      resetChecked();
    }

    showMessage(error ? UiMessages.form.submit.saveError : UiMessages.form.account.ipBanDeleted(checkedIds.length));
  }, [checkVerified, doDelete, checkedIds, showMessage, resetFilters, resetChecked, refetch]);


  const onDelete = useCallback(() => confirm(deleteIpBans), [confirm, deleteIpBans]);


  return (
    <ListToolbar toggleAll={toggleAll}
                 all={all}
                 indeterminate={indeterminate}
                 checked={checked}
    >
      <Grid item>
        <Tooltip title={clsx("A kijelölt", checked > 1 && checked, "IP kitiltás feloldása")}>
          <div>
            <IconButton
              onClick={onDelete}
              disabled={!verified}
              color="secondary"
            >
              <Icon>lock_open</Icon>
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </ListToolbar>
  );
};

export default React.memo(Toolbar);
