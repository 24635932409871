import React from "react";
import Head from "next/head";


const appTitle = "Alumni Online!";
const appDescription = appTitle;
const appKeywords = "Alumni,Online,Alumni Online,Alumni Online!";


const AppHeaders = () => (
  <Head>
    <title>{appTitle}</title>
    <meta name="description" content={appDescription}/>
    <meta name="keywords" content={appKeywords}/>
  </Head>);

export default React.memo(AppHeaders);
