import clsx from "clsx";
import moment from "moment";
import memoizee from "memoizee";


export const normalizeStr = memoizee((str, toLowerCase, removeAccents) => {
  if (!str) return "";

  const dblSpacesRegex = /\s{2,}/g;
  const accentsRegex = /[\u0300-\u036f]/g;

  const dblSpacesRemoved = clsx(str).replace(dblSpacesRegex, " ");
  const accentsRemoved = removeAccents
    ? dblSpacesRemoved.normalize("NFD").replace(accentsRegex, "")
    : dblSpacesRemoved;
  const lowerCased = toLowerCase
    ? accentsRemoved.toLowerCase()
    : accentsRemoved;
  return lowerCased.trim();
});


export const trimStr = memoizee((str, nullable) => clsx(str).replace(/^\s+|\s+$/g, "")
  || (nullable ? null : "")
);


export const firstLetter = memoizee(str => normalizeStr(str, false, true).substr(0, 1));


export const accent = memoizee((str, upperCasedFirstLetter) => {
  const first = firstLetter(str).toLowerCase();
  const consonantNumber = clsx(str) === "10" || clsx(str) === "100" || clsx(str) === "10000";
  const vowel = ["a", "e", "i", "o", "u", "1", "5"].includes(first) && !consonantNumber;

  const upperCased = vowel ? "Az" : "A";
  return upperCasedFirstLetter ? upperCased : upperCased.toLowerCase();
});


export const withAccent = memoizee((str, upperCasedFirstLetter) => `${accent(str, upperCasedFirstLetter)} ${str}`);


export const withCapital = memoizee(str => `${clsx(str).substr(0, 1).toUpperCase()}${clsx(str).substr(1)}`);


export const getFormattedCurrentDate = () => moment().format("MM.DD. HH:mm:ss");


export const emptyFn = () => undefined;


export const isFnOrPromise = fn => Boolean(typeof fn === "function" || fn instanceof Promise);


export const safeFnOrPromise = fn => isFnOrPromise(fn) ? fn : emptyFn;


export const devConsoleDebug = (...args) => {
  if (process.env.DEV_MODE) console.debug(...args);
};


export const isClientSide = () => typeof window !== "undefined";


export const dateStr = (date, format = "L") => clsx(date && moment(date).format(format));
