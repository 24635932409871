import React, { useContext, useEffect, useMemo } from "react";
import clsx from "clsx";
import { AppBar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { validateMe } from "client/lib/session";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import alumnus_name from "client/store/alumnus/resolvers/getters/alumnus_name";
import alumnus_picture from "client/store/alumnus/resolvers/getters/alumnus_picture";
import { AlumnusAvatar } from "components/alumnus/common";


const avatarSizeUnfolded = 72;

const avatarSizeFolded = 32;


const specialName = "Speciális hozzáférés";


const useStyles = makeStyles(theme => ({
  root: {
    "&.user": {
      "& .username, & .email": {
        transition: theme.transitions.create("opacity", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut
        })
      }
    }
  },
  avatar: {
    top: 82,
    padding: 8,
    background: theme.palette.background.default,
    borderRadius: "50%",
    "&.navbarFolded": {
      top: 34,
      background: "none",
      padding: 0,
    },
  }
}));


const UserNavbarHeader = ({ navbarFolded }) => {

  const { personal: { username, alumnusId, name, imgFileId } } = useContext(SessionContext);


  const fullName = useMemo(() => alumnus_name.fullName(name || {}), [name]);


  const src = useMemo(() => alumnus_picture.src({ fileId: imgFileId }), [imgFileId]);


  const alumnus = useMemo(() => ({
    name,
    img: {
      src,
    },
  }), [name, src]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.dataRefetched, validateMe);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.dataRefetched, validateMe);
    }
  }, []);


  const classes = useStyles();


  return (
    <AppBar
      position="static"
      color="primary"
      elevation={0}
      classes={{ root: classes.root }}
      className={clsx("user relative flex flex-col z-0 pt-12",
        fullName
          ? clsx("items-center justify-center", navbarFolded ? "pb-24" : "mb-48 pb-64")
          : clsx("pb-36", !navbarFolded && "pl-24 mb-12")
      )}
    >
      {Boolean(!navbarFolded) && (
        <Typography className="username text-16 whitespace-no-wrap"
                    color="inherit">{fullName || specialName}</Typography>
      )}
      {!navbarFolded && (
        <Typography className="email text-13 mt-8 opacity-50 whitespace-no-wrap" color="inherit">{username}</Typography>
      )}
      {Boolean(alumnusId && !navbarFolded) && (
        <div className={clsx("absolute", classes.avatar)}>
          <AlumnusAvatar alumnus={alumnus}
                         size={navbarFolded ? avatarSizeFolded : avatarSizeUnfolded}
                         defaultAvatar
                         meLink
                         rounded
          />
        </div>
      )}
    </AppBar>
  );
};

export default React.memo(UserNavbarHeader);
