export const logFolder = "assets/log";

export const rotatedFileTransportDefaults = {
  datePattern: "YYYY-MM-DD",
  zipped: true,
  maxSize: "10m",
  maxFiles: "30d",
  utcInFilename: false,
};

export const logServices = {
  SMS: "sms",
  MAIL: "mail",
  SERVER: "server",
};

export const actions = {
  [logServices.SMS]: {
    ACCEPTED: "ACCEPTED",
    DELIVERED: "DELIVERED",
    EXPIRED: "EXPIRED",
    FAILED: "FAILED",
    FORWARDED: "FORWARDED",
    NOT_FOUND: "NOT_FOUND",
    QUEUE: "QUEUE",
    REJECTED: "REJECTED",
    SENT: "SENT",
    STOP: "STOP",
    UNDELIVERED: "UNDELIVERED",
    UNKNOWN: "UNKNOWN",
  },
  [logServices.MAIL]: {
    DELIVERED: "DELIVERED",
    UNDELIVERED: "UNDELIVERED",
  },
  UNKNOWN: "UNKNOWN",
};

export const smsapicomDeliveryMessages = {
  [actions[logServices.SMS].ACCEPTED]: {
    message: "Message is delivered to the operator.",
  },
  [actions[logServices.SMS].DELIVERED]: {
    message: "Message is delivered to the recipient.",
  },
  [actions[logServices.SMS].EXPIRED]: {
    message: "Messages expired.",
    isError: true,
  },
  [actions[logServices.SMS].FAILED]: {
    message: "Sending message failed.",
    isError: true,
  },
  [actions[logServices.SMS].FORWARDED]: {
    message: "Message with SMS code is forwarded to smsapi.com gateway.",
  },
  [actions[logServices.SMS].NOT_FOUND]: {
    message: "Wrong ID or report has expired.",
    isError: true,
  },
  [actions[logServices.SMS].QUEUE]: {
    message: "Message is waiting to be sent.",
  },
  [actions[logServices.SMS].REJECTED]: {
    message: "Message is undelivered (invalid number, roaming error etc).",
    isError: true,
  },
  [actions[logServices.SMS].SENT]: {
    message: "Message is sent without a final delivery report.",
  },
  [actions[logServices.SMS].STOP]: {
    message: "Bulk has been stopped by the user.",
    isError: true,
  },
  [actions[logServices.SMS].UNDELIVERED]: {
    message: "Message is undelivered (invalid number, roaming error etc).",
    isError: true,
  },
  [actions[logServices.SMS].UNKNOWN]: {
    message: "No report (the message may be either delivered or not).",
  },
};
