import React, { useContext } from "react";
import Link from "next/link";
import { Grid, List, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { SearchContext } from "client/lib/providers/SearchProvider";
import { FuseAnimate } from "components/fuse";
import { Suspense } from "components/common";
import { noResultsLabel, Pager } from "components/listPages";
import AlumnusCard from "./AlumnusCard";
import usePager from "./usePager";


const linkParams = {
  from: pathNames.search.href,
  fromName: "a találatokhoz",
};


const Content = () => {

  const { isLoading, members } = useContext(SearchContext);

  const pager = usePager();

  return (
    <Suspense loading={isLoading} className="h-full">
      {members && members.length
        ? (
          <List className="p-24">
            <FuseAnimate delay={100}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="stretch">
                    {members.map(alumnus => (
                      <Grid item xs={12} sm={6} md={4} xl={3} key={alumnus.id} className="button">
                        <Link href={`${pathNames.alumnus.href}?from=${linkParams.from}&fromName=${linkParams.fromName}`}
                              as={pathNames.alumnus.as(alumnus.id)}
                              prefetch={false}
                        >
                          <a rel="noopener noreferrer" role="button" className="w-full h-full">
                            <AlumnusCard alumnus={alumnus}/>
                          </a>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center" className="pt-16">
                    <Pager {...pager}/>
                  </Grid>
                </Grid>
              </Grid>
            </FuseAnimate>
          </List>
        )
        : (
          <FuseAnimate delay={100}>
            <Grid container alignItems="center" justify="center" className="h-full min-h-128 px-16 sm:px-20">
              <Grid item>
                <Typography color="textSecondary" variant="h5" className="text-center text-16 sm:text-20 md:text-24">
                  {noResultsLabel}
                </Typography>
              </Grid>
            </Grid>
          </FuseAnimate>
        )}
    </Suspense>
  );
};

export default React.memo(Content);
