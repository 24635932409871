import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AlumnusContext } from "components/alumnus";
import StatusAlert from "components/alumnus/profile/StatusAlert";
import GroupmatesCard from "./GroupmatesCard";
import ContactCard from "./ContactCard";
import ProfessionalCard from "./ProfessionalCard";
import LeasureCard from "./LeasureCard";
import CityAndCountryCard from "./CityAndCountryCard";


const ProfileTab = props => {

  const { myProfile } = useContext(AlumnusContext);


  const stats = useMemo(() => props.alumnus.stats, [props.alumnus.stats]);


  return Boolean(props && props.alumnus) && (
    <Grid container spacing={3} className="max-w-2xl">

      {Boolean(myProfile) && (
        <Alert severity="info" className="w-full mx-12 mt-16 sm:mt-8 mb-8">
          <Typography variant="caption">Mivel ez a saját adatlapod, ezért számodra minden adat megjelenik. A többiek
            számára csak az adatvédelmi beállításaidnak megfelelő adatok láthatók.</Typography>
        </Alert>
      )}

      <StatusAlert alumnus={props.alumnus}/>

      <Grid item xs={12} className="md:flex max-w-2xl">

        {Boolean(stats.anyData) && (
          <div className="flex flex-col flex-1 md:pr-32">
            {Boolean(stats.anyContacts) && (
              <ContactCard {...props}/>
            )}
            {Boolean(stats.cooperation) && (
              <LeasureCard {...props}/>
            )}
            {Boolean(stats.anyProfessionals) && (
              <ProfessionalCard {...props}/>
            )}
            {Boolean(stats.hobby) && (
              <LeasureCard {...props} hobby/>
            )}
          </div>
        )}

        <div className={clsx("flex flex-col", stats.anyData && "md:w-320")}>
          <CityAndCountryCard {...props}/>
          <GroupmatesCard {...props}/>
        </div>

      </Grid>
    </Grid>
  );
};

export default React.memo(ProfileTab);
