import React from "react";
import { FusePageCarded } from "components/fuse";
import Tabs from "../Tabs";
import EditContent from "./EditContent";
import EditHeader from "./EditHeader";


const EditPage = () => (
  <FusePageCarded
    classes={{
      root: "pb-20 sm:pb-36",
      header: "min-h-224 h-224",
      toolbar: "px-16 sm:px-24",
      topBg: "h-288",
    }}
    header={(
      <EditHeader/>
    )}
    contentToolbar={(
      <Tabs/>
    )}
    content={(
      <EditContent/>
    )}
  />
);

export default React.memo(EditPage);
