import moment from "moment";
import { Check, Report, Warning } from "@material-ui/icons";


export const revisionStatuses = {
  upToDate: "upToDate",
  probably: "probably",
  outdated: "outdated",
};


const revisionPublicProps = {
  [revisionStatuses.upToDate]: {
    status: "Nagy valószínűséggel naprakész adatok.",
    severity: "success",
  },
  [revisionStatuses.probably]: {
    status: "Az adatok nem feltétlenül naprakészek.",
    description: "Az elmúlt 1 évben nem erősítette meg adatait (az elmúlt 3 évben viszont igen).",
    severity: "warning",
  },
  [revisionStatuses.outdated]: {
    status: "Az adatok elavultak lehetnek.",
    description: "Az elmúlt 3 évben nem erősítette meg adatait.",
    severity: "error",
  },
};


const revisionAdminProps = {
  [revisionStatuses.upToDate]: {
    label: "Megerősített adatok",
    color: "success",
    avatar: Check,
  },
  [revisionStatuses.probably]: {
    label: "Elmaradó adatmegerősítés",
    color: "warning",
    avatar: Warning,
  },
  [revisionStatuses.outdated]: {
    label: "Elmaradt adatmegerősítés",
    color: "danger",
    avatar: Report,
  },
};


const revisionStatus = ({ revision: { lastRevision } }) => {
  const safeLastRevision = moment(lastRevision || undefined).toDate();

  if (safeLastRevision > moment().subtract(1, "y").toDate()) return revisionStatuses.upToDate;
  if (safeLastRevision > moment().subtract(2, "y").toDate()) return revisionStatuses.probably;
  return revisionStatuses.outdated;
};


const revisionDate = ({ revision: { lastRevision } }) => moment(lastRevision).fromNow();


const adminProps = root => revisionAdminProps[revisionStatus(root)];


const publicProps = root => revisionPublicProps[revisionStatus(root)];


const alumnus_status = {
  revisionStatus,
  revisionDate,
  publicProps,
  adminProps,
};

export default alumnus_status;
