import { useCallback, useContext, useMemo } from "react";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { SearchContext } from "client/lib/providers/SearchProvider";
import { handlerWrapper } from "./helpers";


const usePager = () => {

  const { isLoading, outdatedPager, setPage, page, numPages, pageSizes, pageSize, setPageSize: _setPageSize } = useContext(SearchContext);


  const disabled = useMemo(() => Boolean(isLoading || outdatedPager), [isLoading, outdatedPager]);


  const setPageSize = useCallback(pageSize => handlerWrapper(() => {
    _setPageSize(pageSize);
    setPage(1);
  }), [_setPageSize, setPage]);


  const goToFirstPage = useCallback(() => handlerWrapper(() => {
    setPage(1)
  }), [setPage]);


  const goToLastPage = useCallback(() => handlerWrapper(() => {
    setPage(numPages)
  }), [numPages, setPage]);


  const pageDown = useCallback(() => page > 1 && handlerWrapper(() => {
    setPage(page - 1);
  }), [page, setPage]);


  const pageUp = useCallback(() => page < numPages && handlerWrapper(() => {
    setPage(page + 1);
  }), [numPages, page, setPage]);


  const onEnter = useCallback(() => emitUiStateEvent(uiStateEvents.search.updateStarted), []);


  return {
    page,
    setPage,
    numPages,
    pageSize,
    pageSizes,
    setPageSize,
    pageDown,
    pageUp,
    goToFirstPage,
    goToLastPage,
    onEnter,
    disabled,
  }

};

export default usePager;
