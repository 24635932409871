import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { GET_ALUMNUS_GROUP } from "client/store/alumnus/queries";
import { UPDATE_ALUMNUS_NEWSLETTER } from "client/store/alumnus/mutations";
import { fetchAlumnus } from "client/store/alumnus/resolvers/getters";
import { __typename } from "components/form/newsletter";


const statementId = "confirmedNews";


const useNewsStep = ({ stepStatements, toggleStatement, registerSubmitCb, ignoredSaveCbReturn }) => {

  const { personal: { alumnusId } } = useContext(SessionContext);


  const isEnd = useRef(false);


  const client = useApolloClient();

  const { loading: queryLoading, error: queryError, data: { members } = {} } = useQuery(GET_ALUMNUS_GROUP, {
    skip: !alumnusId,
    variables: {
      ids: [alumnusId]
    },
  });


  const { address, news } = useMemo(() => (!queryLoading && !queryError && members && members[0]) || {}
    , [queryLoading, queryError, members]);


  const error = useMemo(() => Boolean(queryError || !address || !news), [address, news, queryError]);


  const statement = useMemo(() => Boolean(stepStatements && stepStatements.includes(statementId))
    , [stepStatements]);


  const safeNews = useMemo(() => ({
    ...news,
    email: (news && news.email) || "",
  }), [news]);


  const saveNews = useCallback(async jobs => {
    const { id, ...changes } = (jobs[__typename] || [])[0] || {};
    if (!id) return;

    const { error } = await client.mutate({
      mutation: UPDATE_ALUMNUS_NEWSLETTER,
      variables: {
        id,
        changes,
      },
    });

    if (!error && !ignoredSaveCbReturn) await fetchAlumnus(alumnusId, client, true);

    return error;
  }, [alumnusId, client, ignoredSaveCbReturn]);


  const saveRevision = useCallback(async () => {
    const { error } = await backendFetch(backendServices.alumnus.revision);
    return error;
  }, []);


  const saveCb = useCallback(async ({ jobs, jobsCount }) => {
    if (jobsCount) {
      const newsError = await saveNews(jobs);
      if (newsError) return { error: newsError };
    }

    if (isEnd.current) {
      const backendError = await saveRevision();
      if (backendError) return { error: backendError };
    }

    return {
      noMessage: true,
      noStopLoading: true,
      ignore: Boolean(ignoredSaveCbReturn),
    };
  }, [ignoredSaveCbReturn, saveNews, saveRevision]);


  const toggleApprove = useCallback(() => toggleStatement(statementId), [toggleStatement]);


  const onSubmit = useCallback(backward => {
    isEnd.current = !backward;
    emitUiStateEvent(uiStateEvents.form.submitStarted);
  }, []);


  useEffect(() => {
    if (typeof registerSubmitCb === "function") registerSubmitCb(onSubmit);
  }, [registerSubmitCb, onSubmit]);


  return {
    queryLoading,
    error,
    address,
    news: safeNews,
    saveCb,
    statement,
    toggleApprove,
  };
};

export default useNewsStep;
