import { useCallback, useRef, useState } from "react";


const useFilter = ({ _update, _onFiltersChanged, filters }) => {

  const _filters = useRef(filters || {});


  const [filterValues, setFilterValues] = useState({});

  const [emptyFilters, setEmptyFilters] = useState(true);


  const getValue = useCallback(([filterKey, { value }]) => [filterKey, value], []);


  const getValues = useCallback(() => Object.fromEntries(Object.entries(_filters.current).map(getValue)), [getValue]);


  const isEmptyFilters = useCallback(() => Object.values(_filters.current).every(({ value }) => !value), []);


  const updateFilterValues = useCallback(() => {
    setFilterValues(getValues());
    setEmptyFilters(isEmptyFilters());
  }, [getValues, isEmptyFilters]);


  const setFilter = useCallback((filter, value) => {
    _filters.current = {
      ..._filters.current,
      [filter]: {
        ..._filters.current[filter],
        value,
      },
    };

    updateFilterValues();
    _update(_filters.current);
  }, [_update, updateFilterValues]);


  const purgeFilterEntry = useCallback(([filterKey, { testFn }]) => [filterKey, { testFn }], []);


  const purgeFilters = useCallback(() => Object.fromEntries(Object.entries(_filters.current).map(purgeFilterEntry)), [purgeFilterEntry]);


  const resetFilters = useCallback(noUpdate => {
    _filters.current = purgeFilters();

    setFilterValues(getValues());
    setEmptyFilters(!noUpdate ? isEmptyFilters() : false);

    if (!noUpdate) {
      _onFiltersChanged();
      _update(_filters.current);
    }
  }, [purgeFilters, getValues, _onFiltersChanged, isEmptyFilters, _update]);


  return {
    setFilter,
    resetFilters,
    filterValues,
    emptyFilters,
  };
};

export default useFilter;
