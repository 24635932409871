import React, { useState } from "react";
import { Button, Typography, Dialog, Icon, IconButton, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";


const _Transition = (props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
};


const Transition = React.forwardRef(_Transition);


const useStyles = makeStyles(theme => ({
  button: {
    position: "absolute",
    right: 0,
    bottom: 30,
    minWidth: 48,
    width: 48,
    height: 48,
    opacity: .9,
    padding: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 999,
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[500],
      opacity: 1
    }
  },
  "@keyframes rotating": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(360deg)"
    }
  },
  buttonIcon: {
    animation: "$rotating 3s linear infinite"
  },
  dialogPaper: {
    position: "fixed",
    width: 380,
    maxWidth: "90vw",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: 0,
    height: "100%",
    minHeight: "100%",
    bottom: 0,
    right: 0,
    margin: 0,
    zIndex: 1000,
    borderRadius: 0,
    [theme.breakpoints.up("sm")]: {
      width: 450,
    },
    [theme.breakpoints.up("md")]: {
      width: 600,
    },
    [theme.breakpoints.up("lg")]: {
      width: 750,
    },
    [theme.breakpoints.up("xl")]: {
      width: 900,
    },
  }
}));


const SettingsPanel = ({ settingsTitle, children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button id="fuse-settings" className={classes.button} variant="contained" onClick={handleOpen}>
        <Icon className={classes.buttonIcon}>settings</Icon>
      </Button>

      <Dialog
        TransitionComponent={Transition}
        aria-labelledby="settings-panel"
        aria-describedby="settings"
        open={open}
        keepMounted
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: classes.dialogPaper
        }}
      >
        <div className="p-24 pt-16 md:p-28 md:pt-20">
          <div className="flex flex-row items-center justify-between mb-28">
            <Typography variant="h6">{settingsTitle || "Tesztelési funkciók"}</Typography>
            <IconButton onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          {children}
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(SettingsPanel);
