import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UiMessages } from "config/messages";
import { safeFnOrPromise } from "common/helpers";
import { UiStateContext, uiStateEventEmitter, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { parsePanelKey } from "../helpers";
import { FormContext } from "./Form";


const usePanels = ({ addRecord, onErrorEntityStr, newRecordTypename, panelKey }) => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { requiredFields, formErrors, clearError, getValues, triggerValidation } = useContext(FormContext);


  const [activePanelKey, setActivePanelKey] = useState(panelKey || false);

  const [dirtyPanelKeys, setDirtyPanelKeys] = useState(panelKey ? [panelKey] : []);


  const panelPrefixedId = useRef(parsePanelKey(panelKey).prefixedId);

  const panelTypename = useRef(parsePanelKey(panelKey).__typename);


  const validateForm = useCallback(async () => {
    await triggerValidation();

    const prefixedId = panelPrefixedId.current;
    const __typename = panelTypename.current;

    const recordErrors = prefixedId && __typename && formErrors && formErrors[__typename] && formErrors[__typename][prefixedId];

    return Boolean(recordErrors && Object.keys(recordErrors).length);
  }, [formErrors, triggerValidation]);


  const validateField = useCallback(([field, value]) => {
    const required = (requiredFields[panelTypename.current] || []).includes(field);

    return required && !value && value !== false && value !== 0;
  }, [requiredFields]);


  const validateValues = useCallback(values => Object.entries(values || {}).some(validateField)
    , [validateField]);


  const validateRequiredFields = useCallback(() => {
    if (panelTypename.current && panelPrefixedId.current) {
      const { [panelPrefixedId.current]: values } = getValues()[panelTypename.current] || {};
      return validateValues(values);
    }
  }, [getValues, validateValues]);


  const validatePanel = useCallback(async () => {
    const invalidForm = await validateForm();

    if (validateRequiredFields()) {
      showMessage(UiMessages.form.panel.onMissingRequired(onErrorEntityStr));
      return true;
    }

    if (invalidForm) {
      showMessage(UiMessages.form.panel.onError(onErrorEntityStr));
      return true;
    }
  }, [validateForm, validateRequiredFields, showMessage, onErrorEntityStr]);


  const activatePanel = useCallback((__typename, prefixedId) => {
    panelTypename.current = __typename;
    panelPrefixedId.current = prefixedId;

    const panelKey = Boolean(__typename && prefixedId) && `${__typename}.${prefixedId}`;
    setActivePanelKey(panelKey);

    clearError();

    if (panelKey) setDirtyPanelKeys(keys => keys.includes(panelKey) ? keys : [...keys, panelKey]);
  }, [clearError]);


  const onPanelClick = useCallback(async (__typename, prefixedId) => {
    const invalid = await validatePanel();
    if (!activePanelKey || !invalid) activatePanel(__typename, prefixedId)
  }, [activatePanel, activePanelKey, validatePanel]);


  const onAddClick = useCallback(async () => {
    if (!newRecordTypename || await validatePanel()) return;

    const prefixedId = await safeFnOrPromise(addRecord)();

    if (prefixedId) activatePanel(newRecordTypename, prefixedId)
  }, [activatePanel, addRecord, newRecordTypename, validatePanel]);


  const onDataRefetched = useCallback(() => activatePanel(), [activatePanel]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.dataRefetched, onDataRefetched);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.dataRefetched, onDataRefetched);
    }
  }, [onDataRefetched]);


  return {
    activePanelKey,
    dirtyPanelKeys,
    onPanelClick,
    onAddClick,
    disableAdd: !newRecordTypename,
  }

};

export default usePanels;
