import { nolookalikes } from "nanoid-dictionary";
import { dbEnums } from "common/enums";


export const codeTypes = {
  ADMIN_ACTION: "ADMIN_ACTION",
  EMAIL_VERIFICATION: "EMAIL_VERIFICATION",
  PHONE_VERIFICATION: "PHONE_VERIFICATION",
  PSW_LINK: "PSW_LINK",
};


const defaultSettings = {
  codeAlphabet: nolookalikes,
  codeExpirationInMinutes: 180,
  codeLength: 5,
  verificationExpirationInMinutes: 24 * 60,
};


export const codeTypeConfig = {
  defaultSettings,
  [codeTypes.ADMIN_ACTION]: {
    codeExpirationInMinutes: 60,
    codeLength: 7,
    verificationExpirationInMinutes: 60,
  },
  [codeTypes.EMAIL_VERIFICATION]: {
    allowedRolesToSkip: [dbEnums.accountRoles.MANAGER],
    codeLength: 6,
  },
  [codeTypes.PHONE_VERIFICATION]: {
    codeExpirationInMinutes: 60,
    allowedRolesToSkip: [dbEnums.accountRoles.MANAGER],
    codeAlphabet: "123456789",
  },
  [codeTypes.PSW_LINK]: {
    codeAlphabet: "123456789",
  },
};
