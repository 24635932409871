import { customHeaderValue, headerNames, contentType } from "./config";
import { getSession, tokens } from "./session";


export const getSecurityHeaders = noContentType => {
  const headers = {
    [headerNames.custom]: customHeaderValue,
  };

  if (!noContentType) headers[headerNames.contentType] = contentType;
  if (tokens.cds) headers[headerNames.CDS] = tokens.cds;
  if (tokens.CSRFToken) headers[headerNames.CSRF] = tokens.CSRFToken;

  return headers;
};


// imported dynamically by config/routing.js
export const getSessionRoles = () => {
  const { account } = getSession();
  const { roles } = account || {};
  return roles || [];
};


export const isAuthenticated = () => {
  const { account } = getSession();
  const { id } = account || {};
  return Boolean(id);
};
