import { errors } from "common/errors";
import { authAPIMethods } from "../config";
import { getAuthCookies } from "../helpers";
import { query } from "../query";


const service = authAPIMethods.logout;


export const logout = async req => {
  const { error, data } = await query({ service, req });
  if (error) return { error };

  const { CSRFToken } = data;
  const __cookies = getAuthCookies(data);
  if (!CSRFToken || !__cookies) return errors.server.general.SERVER_ERROR;

  return {
    __cookies,
    __auth: {
      CSRFToken,
      account: {},
      issues: {},
      personal: {},
    },
  };
};
