import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import PageField from "./PageField";
import PageSteppers from "./PageSteppers";
import PageSizeField from "./PageSizeField";


const Pager = ({
                 noPageSizeField, className, page, numPages, pageSize, pageSizes, setPageSize, setPage, pageDown,
                 pageUp, goToFirstPage, goToLastPage, onEnter, onBlur, disabled
               }) => {


  const pageSizeComponent = useMemo(() => !noPageSizeField && (
    <Grid item>
      <PageSizeField pageSize={pageSize}
                     pageSizes={pageSizes}
                     setPageSize={setPageSize}
                     disabled={disabled}
                     className={clsx("min-w-80", numPages > 1 && "ml-16")}
      />
    </Grid>
  ), [disabled, noPageSizeField, numPages, pageSize, pageSizes, setPageSize]);


  const onPageChange = useCallback(event => setPage(event.target.value), [setPage]);


  return numPages < 2
    ? !noPageSizeField
      ? pageSizeComponent
      : (<div className="mb-12"/>)
    : (
      <Grid item>
        <Grid container spacing={0} alignItems="center" className={className}>
          <Grid item className="mr-16">
            <PageField page={page}
                       numPages={numPages}
                       onChange={onPageChange}
                       onEnter={onEnter}
                       onBlur={onBlur}
                       disabled={disabled}
            />
          </Grid>
          <PageSteppers page={page}
                        numPages={numPages}
                        onFirstPage={goToFirstPage}
                        onLastPage={goToLastPage}
                        onPageDown={pageDown}
                        onPageUp={pageUp}
                        disabled={disabled}
          />
          {pageSizeComponent}
        </Grid>
      </Grid>
    );
};

export default React.memo(Pager);
