import React from "react";
import { Typography } from "@material-ui/core";
import { maxRecordsCounts } from "config/form";


const Study = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon a <b>végzettségeidet és/vagy aktuális tanulmányaidat</b> oszthatod meg. Egy-egy képzést egy-egy
      bejegyzés létrehozásával adhatsz meg (legfeljebb {maxRecordsCounts.study} bejegyzés hozható
      létre).
    </Typography>
    <Typography className="my-20">
      <b>Az <span className="text-blue">intézmény, képzőhely</span>, illetve a <span className="text-blue">végzettség, szak, képzés</span> adatokat
        kötelező megadni.</b>
    </Typography>
    <Typography className="my-20">
      Megadhatod a képzés relevanciájának időtartományát is. Ennek kitöltése opcionális, viszont az időtartomány nem
      lehet alulról nyitott. Ha tehát megadod az időtartomány tetejét, vagyis a <span className="text-blue">befejezés évét</span>,
      akkor a <span className="text-blue">kezdés évét</span> is meg kell adnod (a <span className="text-blue">befejezés éve</span> bármikor
      üresen hagyható).
    </Typography>
    <Typography className="my-20">
      <b>A képzés aktualitását az időtartománytól függetlenül jelölheted meg</b>, a <span className="text-blue">jelenleg is végzem ezt a képzést</span> jelölőnégyzet
      bekapcsolásával. Ilyenkor az adatlapon a képzés mellett megjelenik az <i>Aktuális</i> felirat, függetlenül attól,
      hogy a relevancia időtartományát megadtad-e vagy milyen értékekkel adtad meg.
    </Typography>
    <Typography className="my-20">
      Amikor a <span className="text-blue">befejezés évét</span> megadod/módosítod, akkor
      a <span className="text-blue">jelenleg is végzem ezt a képzést</span> jelölőnégyzet <b>automatikusan
      kikapcsolt állapotba kerül</b> (hiszen ilyenkor múltbeliséget feltételezünk). Természetesen ezt
      a jelölőnégyzet újbóli bejelölésével felülbírálhatod.
    </Typography>
    <Typography className="my-20">
      A képzéshez kapcsolódóan egyéb információkat is megoszthatsz, így a képzéshez kapcsolódó <span
      className="text-blue">országot</span>, <span className="text-blue">települést</span> vagy <span
      className="text-blue">weboldalt</span>, illetve rövid <span className="text-blue">megjegyzést</span> is
      fűzhetsz a bejegyzéshez.
    </Typography>
    <Typography className="my-20">
      Az egyes képzésbejegyzések mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Az adott adatvédelmi beállítás értelemszerűen csak a megfelelő bejegyzésre
      vonatkozik. Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
    <Typography className="mt-20">
      Egy-egy képzésbejegyzést a <span className="text-blue">Törlés</span> gombra kattintva <b>törölhetsz</b>.
    </Typography>
  </>
);

export default React.memo(Study);
