import { UiMessages } from "config/messages";
import { devConsoleDebug } from "common/helpers";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { emitUiStateEventNextTick, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { setSession } from "./session";
import { validateMe } from "./load";
import { redirect } from "./redirect";
import { setLoaded, setLoading } from "./update";
import { isAuthenticated } from "./getters";
import { destroySessionStore } from "./store";


const onError = async () => {
  await validateMe();

  await setLoaded(true);

  emitUiStateEventNextTick(uiStateEvents.message.showMessage, UiMessages.auth.failedLogout);
};


const onCompleted = async () => {
  await setSession({ account: {} });
  await destroySessionStore();

  devConsoleDebug("onUnauthenticated by logout onCompleted");
  await redirect.onUnauthenticated();

  await setLoaded(true);
};


const fetchLogout = async () => {
  await setLoading();

  const { error } = await backendFetch(backendServices.auth.logout);

  if (error) {
    await onError();
  } else {
    await onCompleted();
  }
};


export const logout = async () => {
  const invalidMe = await validateMe();
  if (invalidMe) return emitUiStateEventNextTick(uiStateEvents.message.showMessage, UiMessages.auth.failedLogout);

  if (isAuthenticated()) await fetchLogout();
};
