import moment from "moment";
import { UiMessages } from "config/messages";
import { apiPaths } from "config/routing";
import { devConsoleDebug } from "common/helpers";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { emitUiStateEventNextTick, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { updateInitialized, updateIdentified, setLoaded } from "./update";
import { isAuthenticated } from "./getters";
import { clearSession, getSession, setSession } from "./session";
import { destroySessionStore, updateSessionStore } from "./store";
import { redirect } from "./redirect";


const scheduledWhoAmI = {
  scheduledId: null,
};


const graphQLHealthCheck = async () => {
  try {
    const response = await fetch(apiPaths.graphqlClientHealthcheckUri);

    return response.status === 200;

  } catch (e) {
    return false;
  }
};


// the difference between server time and client time in milliseconds
const getServerTimeGaps = async () => {
  const { serverTime } = getSession();

  const serverTimeGapInMs = !serverTime ? 0 : moment(serverTime).diff(moment(), "milliseconds");

  await setSession({ serverTimeGapInMs });
};


const init = async () => {
  const { initialized } = getSession();
  if (initialized) return true;

  const healthyGraphQL = await graphQLHealthCheck();
  if (!healthyGraphQL) {
    await updateInitialized(false);
    return false;
  }

  const { error } = await backendFetch(backendServices.auth.init);

  await getServerTimeGaps();

  const success = !error;
  await updateInitialized(success);
  return success;
};


export const validateMe = async () => {
  const { error } = await backendFetch(backendServices.auth.whoAmI);
  return error && { error };
};


const whoAmI = async () => {
  if (scheduledWhoAmI.scheduledId) clearTimeout(scheduledWhoAmI.scheduledId);

  const { identified } = getSession();
  if (identified) return true;

  const invalidMe = await validateMe();
  const { banned } = getSession();

  const success = !invalidMe || banned;
  await updateIdentified(success);
  return success;
};


const loadTerms = async () => {
  const { error, data: terms } = await backendFetch(backendServices.registration.getTerms);
  const success = !error && terms;

  if (success) await setSession({ terms });
  return success;
};


export const loadSession = async noStore => {
  if (!await init() || !await whoAmI() || !await loadTerms()) {
    return await setLoaded(false);
  }

  const requiredStoreClient = !noStore && isAuthenticated();
  const success = !requiredStoreClient || await updateSessionStore();

  await setLoaded(success);
};


const doReset = async () => {
  await destroySessionStore();

  devConsoleDebug("onUnauthenticated by doReset");
  await redirect.onUnauthenticated();

  await loadSession(true);
};


export const handleUnauthorized = async () => {
  const { banned } = getSession();
  if (banned) return;

  await clearSession();

  await backendFetch(backendServices.auth.logout);

  await doReset();

  emitUiStateEventNextTick(uiStateEvents.message.showMessage, UiMessages.auth.unauthorized);
};


export const handleExpiredSession = async () => {
  await clearSession();

  await doReset();

  emitUiStateEventNextTick(uiStateEvents.session.showExpiredSessionMessage);
};
