import gql from "graphql-tag";


export const SUBSCRIPTION_ALUMNUS_UPDATED = gql`
  subscription SubscriptionAlumnusUpdated($startDate: timestamptz!) {
    members: view_alumnus_updated(where: {updated: {_gt: $startDate}}) {
      id
      updated
    }
  }
`;
