import { useCallback, useState } from "react";


const defaultValues = {
  config: null,
  opened: false,
  toAnimate: false,
};


const useUiHelp = () => {

    const [config, setConfig] = useState(defaultValues.config);

    const [configToRestore, setConfigToRestore] = useState(defaultValues.config);

    const [opened, setOpened] = useState(defaultValues.opened);

    const [toAnimate, setToAnimate] = useState(defaultValues.toAnimate);


    const reset = useCallback(() => {
      setConfig(defaultValues.config);
      setConfigToRestore(defaultValues.config);
      setOpened(defaultValues.opened);
      setToAnimate(defaultValues.toAnimate);
    }, []);


    const checkConfig = useCallback(config => Boolean(config && config.content), []);


    const animateHelp = useCallback(() => setToAnimate(toAnimate => !toAnimate), []);


    const setHelp = useCallback(config => setConfig(checkConfig(config) ? config : defaultValues.config), [checkConfig]);


    const resetHelp = useCallback(() => setConfig(defaultValues.config), []);


    const backupConfig = useCallback(() => setConfigToRestore(config || defaultValues.config), [config]);


    const restoreConfig = useCallback(() => {
      setConfig(configToRestore || defaultValues.config);
      setConfigToRestore(defaultValues.config);
    }, [configToRestore]);


    const openHelp = useCallback(oneTimeConfig => {
      backupConfig();
      if (checkConfig(oneTimeConfig)) setConfig(oneTimeConfig);

      setOpened(true);
    }, [backupConfig, checkConfig]);


    const closeHelp = useCallback(() => {
      setOpened(false);
      restoreConfig();
    }, [restoreConfig]);


    return {
      help: {
        opened,
        config,
        toAnimate,
        animateHelp,
        setHelp,
        openHelp,
        closeHelp,
        resetHelp,
      },
      reset,
    };
  }
;

export default useUiHelp;
