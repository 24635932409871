import React from "react";
import { Typography } from "@material-ui/core";
import { maxRecordsCounts } from "config/form";


const Social = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon a <b>közösségi média</b> (pl. LinkedIn, Facebook stb.) és <b>egyéb webes elérhetőségeidet</b> (pl.
      saját weboldal) adhatod meg.</Typography>
    <Typography className="mt-20">
      Egy-egy elérhetőséget egy-egy bejegyzés létrehozásával adhatsz meg (legfeljebb {maxRecordsCounts.social} bejegyzés
      hozható létre), az alábbiak szerint:
    </Typography>
    <div>
      <ol>
        <li className="mb-12">A <b>közösségi média platformoknál</b> (pl. <i>LinkedIn</i>, <i>Facebook</i> stb.) <b>az
          adatlapod teljes webes hivatkozását (URL)</b> add meg! (Pl. a <span
          className="text-blue">&quot;https://facebook.com/gipszjakab&quot;</span> bejegyzés megfelelő, míg a <span
          className="text-red">&quot;gipszjakab&quot;</span> vagy a <span
          className="text-red">&quot;facebook.com/gipszjakab&quot;</span> nem.)
        </li>
        <li className="mb-12">A <b> weboldalaknál</b> szintén <b>teljes webes hivatkozást (URL)</b> add meg! (Pl.
          a <span className="text-blue">&quot;https://www.sajatweboldalam.hu&quot;</span> bejegyzés megfelelő, míg
          a <span className="text-red">&quot;www.sajatweboldalam.hu&quot;</span> nem.)
        </li>
        <li className="mb-12"><b>Skype elérhetőségedet</b> a <span
          className="text-blue">&quot;skype:gipszjakab&quot;</span> séma szerint add meg, ahol a <span
          className="text-blue">gipszjakab</span> a <i>Skype azonosítód</i>. (A <span
          className="text-blue">skype:</span> ne maradjon le az elejéről, valamint a kettőspont után ne kerüljön
          szóköz.)
        </li>
      </ol>
    </div>
    <Typography className="my-20">
      Az adatlapon a bejegyzések hivatkozásként jelennek meg, rendre egy-egy piktogrammal felsorolva (a legnépszerűbb
      közösségi média platformok a nekik megfelelő piktogrammal).</Typography>
    <Typography className="my-20">
      Az egyes bejegyzések mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Az adott adatvédelmi beállítás értelemszerűen csak a megfelelő bejegyzésre
      vonatkozik. Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
    <Typography className="mt-20">
      Egy-egy bejegyzést a <span className="text-blue">Törlés</span> gombra kattintva <b>törölhetsz</b>.
    </Typography>
  </>
);

export default React.memo(Social);
