import React, { useCallback, useContext } from "react";
import nextTick from "next-tick";
import moment from "moment";
import clsx from "clsx";
import { useMutation } from "@apollo/react-hooks";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { UiMessages } from "config/messages";
import { accent, safeFnOrPromise } from "common/helpers";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { DELETE_REVISION_REQUEST } from "client/store/revisionRequest";
import { ListContext } from "components/listPages";
import { useConfirm } from "components/common";


const getConfirmOptions = ({ required, created }) => ({
  title: "Adategyeztetési kör törlése",
  content: (
    <span>
      Biztosan törlöd {accent(created)}&nbsp;<b>{moment(created).format("L")}</b>&nbsp;dátummal
      létrehozott {clsx(required && "kötelező ")}adategyeztetési kört?
    </span>
  ),
  OKButton: "Igen, törlöm",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "inherit",
});


const DelButton = revisionRequest => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const { refetch } = useContext(ListContext);


  const confirm = useConfirm({
    confirmOptions: getConfirmOptions(revisionRequest),
  });


  const [doDelete] = useMutation(DELETE_REVISION_REQUEST, {
    variables: {
      id: revisionRequest.id
    },
    onError: () => showMessage(UiMessages.form.submit.saveError),
    onCompleted: async () => {
      await safeFnOrPromise(refetch)();

      nextTick(() => showMessage(UiMessages.specialAccounts.deleted));
    },
  });


  const onDelete = useCallback(() => confirm(doDelete), [confirm, doDelete]);


  return (
    <Tooltip title="Adategyeztetési kör törlése">
      <span>
        <IconButton size="small"
                    onClick={onDelete}
                    className="text-red"
        >
          <Icon>delete</Icon>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default React.memo(DelButton);
