export const idPrefix = "id_";

export const newRecordIdSuffix = "_new";


export const requirementMessages = {
  fake: "Érvénytelen adat",
  onlyLowerCase: "Nem lehet csupa kisbetűs",
  onlyUpperCase: "Nem lehet csupa nagybetűs",
  required: "Kötelező mező",
  confirmValue: entity => `A két ${entity || "érték"} nem egyezik`,
  nonIp: "Érvénytelen IP cím",
  password: {
    invalidChar: "A jelszó nem megengedett karaktert (pl. betűt, írásjelet stb.) tartalmaz",
    invalidFormat: "A jelszó nem felel meg a formai követelményeknek",
    sameAsCurrent: "Az újonnan megadott jelszó nem lehet azonos a jelenlegi jelszóval",
  },
  url: {
    invalid: "Teljes webes hivatkozást (URL) adj meg (pl. https://example.com)",
    whitespace: "A webes hivatkozás (URL) nem tartalmazhat szóközt",
  },
  yearInterval: {
    missingFromYear: "Ha megadtad a befejező évet, kérjük, a kezdő évet is add meg!",
    fromYear: "A befejezés nem lehet hamarabb, mint a kezdés",
    toYear: "A kezdés nem lehet később, mint a befejezés",
  },
  address: {
    domesticZip: "Hibásan megadott belföldi irányítószám",
  },
  email: {
    notUniquePerAlumnus: "Ezt az email címet már felvetted",
    invalidFormat: "Nem megfelelő email cím formátum",
  },
  phone: {
    notUniquePerAlumnus: "Ezt a telefonszámot már felvetted",
    invalidFormat: "Nem megfelelő telefonszám-formátum",
    domesticFormat: "A belföldi számot is nemzetközi formátumban kell megadni (pl. +36201234567)",
    onlyMobilePhone: "Csak mobilszám adható meg (SMS fogadóképes)",
  },
  social: {
    notUniquePerAlumnus: "Ezt a bejegyzést már felvetted",
    tooLong: maxLength => `Túl hosszú bejegyzés (legfeljebb ${maxLength || 0} karakter)`,
  },
  verification: {
    unverifiedEmail: "Email megerősítés szükséges",
    unverifiedPhone: "SMS kód megerősítés szükséges",
    notUniquePrimaryEmail: "Ezt az email címet egy másik alumnus használja",
    notUniquePrimaryPhone: "Ezt a számot egy másik alumnus használja",
    failedVerification: "Nem egyezik a kiküldött kóddal",
    missingVerficationCode: "Add meg a kiküldött kódot!",
    invalidVerificationCodeFormat: "Érvénytelen karaktert tartalmaz",
    invalidVerificationCodeLength: "A kód hossza nem megfelelő",
  },
};
