import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { useOpenDialog } from "components/listPages";
import EditRevisionRequest from "./EditRevisionRequest";


const EditButton = revisionRequest => {

  const edit = useOpenDialog({
    title: "Adategyeztetési kör módosítása",
    ContentComponent: (
      <EditRevisionRequest {...revisionRequest}/>
    ),
  });

  return (
    <Tooltip title="Adategyeztetési kör módosítása">
      <IconButton size="small"
                  onClick={edit}
      >
        <Icon>edit</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(EditButton);
