import { getService } from "./helpers";


export const alumnus = {
  isEmailVerified: getService("alumnus/isEmailVerified"),
  isPhoneVerified: getService("alumnus/isPhoneVerified"),
  isPrimaryEmailUnique: getService("alumnus/isPrimaryEmailUnique"),
  isPrimaryPhoneUnique: getService("alumnus/isPrimaryPhoneUnique"),
  revision: getService("alumnus/revision"),
  updateEmail: getService("alumnus/updateEmail"),
  updatePhone: getService("alumnus/updatePhone"),
};
