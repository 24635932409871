import React, { useEffect, useState } from "react";
import OutdatedBrowser from "./OutdatedBrowser";
import SplashScreen from "./SplashScreen";
import ModuleLoader from "./ModuleLoader";
import { checkBrowser } from "./checkBrowser";


const AppLoader = ({ children }) => {

  const [allowedPlatform, setAllowedPlatform] = useState(false);

  const checkPlatform = () => {
    const allowed = checkBrowser();
    setAllowedPlatform(allowed);
  };

  useEffect(checkPlatform);

  return (
    <ModuleLoader>
      <SplashScreen/>
      {
        allowedPlatform
          ? children
          : (
            <OutdatedBrowser/>
          )
      }
    </ModuleLoader>
  );
};

export default React.memo(AppLoader);
