export const defaultStatus = 500;


// never fails, returns undefined if appError !== { error: { ... } }
export const getSafeErrorStatus = appError => {
  try {
    if (!appError || typeof appError !== "object") return null;

    const { error } = appError;
    if (!error || typeof error !== "object") return null;

    const { status } = error;
    return status || defaultStatus;

  } catch (e) {
    return null;
  }
};
