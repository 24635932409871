import clsx from "clsx";
import moment from "moment";
import serializeJson from "serialize-javascript";
import { errors, isSameAppError } from "common/errors";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { getSession } from "client/lib/session";
import { errorLogLib } from "../errorLog";
import { isAuthService } from "./services";


const getBackend = ({ path }) => clsx(path).replace("/", ".");


const getSessionData = () => {
  const { account, banned, banExpiry, ip, userAgent: rawUserAgent } = getSession();
  const { browser, os, platform } = rawUserAgent || {};
  const userAgent = { browser, os, platform };

  const { id: accountId = null, roles: rawRoles } = account || {};
  const roles = rawRoles ? rawRoles.join(",") : null;

  return {
    accountId,
    roles,
    banned,
    banExpiry,
    ip,
    userAgent,
  };
};


const parseDetails = (service, status, error) => serializeJson({
  date: moment().format("L HH:mm Z"),
  backend: getBackend(service),
  ...error,
  status,
  ...getSessionData(),
});


const logError = (service, status, error) => {
  const details = parseDetails(service, status, error);

  errorLogLib("backendApi", "errorHandler", details);

  // no error message on ban
  if (error && error.banned) return;

  emitUiStateEvent(uiStateEvents.backend.apiError, details);
};


export const errorHandler = (service, status, error) => {
  // no log auth errors
  if (isAuthService(service)) return null;

  // no log bans
  if (isSameAppError({ error }, errors.server.general.BANNED)) return null;

  // no log error caused by invalid body data
  if (isSameAppError({ error }, errors.server.general.INVALID)) return null;

  logError(service, status, error);
};
