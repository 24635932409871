import { defaultStatus } from "./status";


const getError = (clientSide, service, code, data, status) => ({
  error: {
    service,
    code,
    clientSide: Boolean(clientSide),
    ...data,
    ...(status && { status }),
  },
});


export const getClientError = (service, code, data) => getError(true, service, code, data);


export const getServerError = (service, code, status, data) => getError(false, service, code, data, status || defaultStatus);
