export const headerNames = {
  CDS: "x-alumni-cds",
  CSRF: "x-csrf-token",
  custom: "X-Requested-With",
  contentType: "Content-Type",
};

export const customHeaderValue = "XMLHttpRequest";

export const contentType = "application/json";
