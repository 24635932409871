import { dbEnums } from "common/enums";
import { trimStr } from "common/helpers";
import { doMutation, getTagIds } from "client/store/helpers";
import { UPDATE_ALUMNUS_COOPERATION } from "client/store/alumnus";


const tagEntity = dbEnums.tagEntity.COOPERATION;


const normalizeRecord = async (client, { id, tags: rawTags, cooperation: rawCooperation, ...data }) => {
  const cooperation = trimStr(rawCooperation, true);
  const tags = await getTagIds(tagEntity, rawTags, client);

  return {
    ...data,
    id,
    cooperation,
    tags,
  };
};


const saveCooperation = async (_root, { record }, { client }) => {
  const { id, ...changes } = await normalizeRecord(client, record);

  await doMutation(client, UPDATE_ALUMNUS_COOPERATION, {
    id,
    changes,
  });
};

export default saveCooperation;
