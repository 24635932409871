import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete, createFilterOptions } from "@material-ui/lab";
import { Controller, FormContext, useField } from "components/form";
import { requirementMessages } from "../config";
import { getDefaultValueProp } from "../helpers";
import { useStyles } from "./Autocomplete";


const defaultFilterOptions = createFilterOptions();


const AutocompleteFreeSolo = ({
                                record, field, options, label, helperText, placeholder, className, textFieldProps,
                                requirements, required, defaultValue, fakeContentRegex, getOptionValue, minLength,
                                maxLength, variant = "outlined", ...restProps
                              }) => {


  const { setValue } = useContext(FormContext);


  const fakeContent = useCallback(value => (fakeContentRegex || /[\da-z]+/i).test(clsx(value)) || requirementMessages.fake
    , [fakeContentRegex]);


  const fieldRequirements = useMemo(() => !required
    ? requirements
    : {
      ...requirements,
      required,
      validate: {
        ...(requirements && requirements.validate),
        fakeContent,
      },
    }, [fakeContent, required, requirements]);


  const { error, name, helperTextOrError, rules } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const _getOptionValue = useCallback(option => typeof getOptionValue === "function"
    ? clsx(getOptionValue(option))
    : option && option.value
      ? clsx(option.value)
      : clsx(option)
    , [getOptionValue]);


  const onChange = useCallback((_args, option) => {
    const value = _getOptionValue(option);

    setValue(name, value);
    return value;
  }, [_getOptionValue, name, setValue]);


  const renderOption = useCallback(option => _getOptionValue(option), [_getOptionValue]);


  const renderInput = useCallback(params => (
    <Controller
      {...params}
      {...textFieldProps}
      {...getDefaultValueProp(defaultValue)}
      name={name}
      as={<TextField/>}
      rules={rules}
      error={error}
      required={Boolean(required)}
      label={label}
      variant={variant}
      fullWidth
      inputProps={{
        ...params.inputProps,
        maxLength: maxLength || 100,
        autoComplete: "disabled",
      }}
    />
  ), [defaultValue, error, label, maxLength, name, required, rules, textFieldProps, variant]);


  const filterOptions = useCallback((options, state) => {
    const input = clsx(state && state.inputValue);
    return (!minLength || input.length >= minLength)
      ? defaultFilterOptions(options, state)
      : [];
  }, [minLength]);


  const classes = useStyles();


  return (
    <FormControl variant={variant}
                 error={error}
                 className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}>
      <MuiAutocomplete
        {...restProps}
        options={options || []}
        onChange={onChange}
        defaultValue={record[field]}
        id={name}
        placeholder={clsx(placeholder)}
        freeSolo
        classes={{
          option: classes.option,
          noOptions: "p-0",
        }}
        filterOptions={filterOptions}
        getOptionLabel={_getOptionValue}
        noOptionsText={null}
        renderInput={renderInput}
        renderOption={renderOption}
        popupIcon={null}
        closeText="Bezár"
        clearText="Törlés"
        loadingText="Lista betöltése..."
      />
      {Boolean(helperTextOrError) && (
        <FormHelperText>{helperTextOrError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(AutocompleteFreeSolo);
