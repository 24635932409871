import React, { useMemo } from "react";
import moment from "moment";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { Suspense } from "components/common";
import Statement from "../Statement";
import EditContact from "./EditContact";
import useContactStep from "./useContactStep";


const ContactStep = ({ submitLoading, disableSubmit, enableSubmit, statementClassName, statementColor, noIssue, ...props }) => {

  const { queryLoading, error, lastRevision, countries, address, primaryEmail, primaryPhone, saveCb, statement, toggleApprove } = useContactStep(props);

  const revisionSince = useMemo(() => lastRevision && moment(lastRevision).fromNow(), [lastRevision]);

  return (
    <Suspense loading={submitLoading || queryLoading}
              className="py-32 md:py-48 lg:py-64"
    >
      {!error && (
        <div className="w-full">

          <Typography className="my-16 mb-24">
            A hatékony kapcsolattartás elősegítése végett célunk, hogy minél kevesebb kellemetlenség adódjon abból, hogy
            egy-egy alumnus adatlapon elavult elérhetőség vagy információ szerepeljen. Ennek érdekében arra kérünk,
            hogy&nbsp;
            <span className={clsx(noIssue && "font-bold")}>
              {Boolean(noIssue) && (<i>legalább évente&nbsp;</i>)}
              erősítsd meg kapcsolattartási adataidat!&nbsp;
              {Boolean(noIssue && revisionSince) && (
                <span>(Legutóbb <i>{revisionSince}</i> erősítetted meg adataidat.)</span>
              )}
            </span>
          </Typography>

          <Typography className="my-24">
            Legutóbb az alábbi elsődleges elérhetőségeket adtad meg. Az Alumni ezeken keresztül tudja felvenni veled a
            kapcsolatot. Egy-egy elérhetőség &ndash; az adminisztrátorokon kívül &ndash; csak azok számára lesz látható
            az adatlapodon, akik számára a megfelelő adatvédelmi beállítással láthatóvá teszed.
          </Typography>

          <Typography className="my-24">
            <i>Kérjük, ellenőrizd ezek aktualitását! Ha elavultak, aktualizáld őket!</i> Az adott
            elérhetőséget &ndash; beleértve a rá vonatkozó adatvédelmi beállítást &ndash; a megfelelő sorra kattintva
            tudod
            módosítani.
          </Typography>

          <EditContact countries={countries}
                       address={address}
                       primaryEmail={primaryEmail}
                       primaryPhone={primaryPhone}
                       saveCb={saveCb}
                       loading={submitLoading}
                       enableSubmit={enableSubmit}
                       disableSubmit={disableSubmit}
          />

          <Statement checked={statement}
                     onCheckCb={toggleApprove}
                     className={statementClassName}
                     color={statementColor}
                     title="Nyilatkozat a kapcsolattartási adatokról"
                     label="Megerősítem, hogy a fent megadott, hivatalos Alumni kapcsolattartási adataim valósak és aktuálisak."
          />

        </div>
      )}
    </Suspense>
  );
};

export default React.memo(ContactStep);
