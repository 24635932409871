import React from "react";
import clsx from "clsx";
import { AppBar, Hidden, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { FuseShortcuts } from "components/fuse";
import { SearchBar } from "components/search";
import { HelpButton } from "components/help";
import { NavbarMobileToggleButton } from "./navbar";
import UserMenu from "./UserMenu";


const useStyles = makeStyles(theme => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider
  }
}));


const ToolbarLayout = props => {

  const specialAccount = useForbidden([dbEnums.accountRoles.ALUMNUS])

  const classes = useStyles(props);

  return (
    <AppBar id="fuse-toolbar" className="flex relative z-10" color="default">
      <Toolbar className="p-0">

        <Hidden lgUp>
          <NavbarMobileToggleButton className="w-64 h-64 p-0"/>
          <div className={classes.separator}/>
        </Hidden>

        {!specialAccount && (
          <div className="flex flex-1">
            <Hidden mdDown>
              <FuseShortcuts className="px-16"/>
            </Hidden>
          </div>
        )}

        <div className={clsx("flex", specialAccount && "w-full justify-end")}>
          {!specialAccount && (
            <SearchBar/>
          )}

          <div className={classes.separator}/>

          <HelpButton/>

          <div className={classes.separator}/>

          <UserMenu/>

          <Hidden lgUp>
            <div className={classes.separator}/>
          </Hidden>
        </div>

      </Toolbar>
    </AppBar>
  );
};

export default React.memo(ToolbarLayout);
