import React from "react";
import * as PropTypes from "prop-types";
import { VelocityComponent } from "velocity-react";
import { isClientSide } from "common/helpers";


if (isClientSide()) require("velocity-animate/velocity.ui");


const _FuseAnimate = (props, ref) => {
  const children = React.cloneElement(props.children, {
    style: {
      ...props.children.style,
      visibility: "hidden"
    },
  });

  return (
    <VelocityComponent runOnMount={true} ref={ref} {...props}>
      {children}
    </VelocityComponent>
  )
};

const FuseAnimate = React.forwardRef(_FuseAnimate);

FuseAnimate.propTypes = {
  children: PropTypes.element.isRequired
};

FuseAnimate.defaultProps = {
  animation: "transition.fadeIn",
  runOnMount: true,
  targetQuerySelector: null,
  interruptBehavior: "stop",
  visibility: "visible",
  duration: 300,
  delay: 50,
  easing: [0.4, 0.0, 0.2, 1],
  display: null,
  setRef: undefined
};

export default React.memo(FuseAnimate);
