const getAddresses = ({ address, sAddress }) => {
  const primary = address && address.id && (address.city || address.address) ? 1 : 0;
  const secondary = sAddress && sAddress.id && (sAddress.city || sAddress.address) ? 1 : 0;

  return {
    address: primary,
    sAddress: secondary,
    addresses: primary + secondary,
  };
};


const getCooperation = ({ cooperation }) => {
  const { cooperation: description, discount, tagsW } = cooperation || {};
  const { tags } = tagsW || {};

  return description || discount || (tags && tags.length) ? 1 : 0;
};


const getHobby = ({ hobby }) => {
  const { hobby: description, tagsW } = hobby || {};
  const { tags } = tagsW || {};

  return description || (tags && tags.length) ? 1 : 0;
};


const getStats = ({ emails: _emails, phones: _phones, socials: _socials, studies: _studies, jobs: _jobs, ...alumnus }) => {
  const addresses = getAddresses(alumnus);
  const cooperation = getCooperation(alumnus);
  const hobby = getHobby(alumnus);

  const emails = 0 + (_emails && _emails.length);
  const phones = 0 + (_phones && _phones.length);
  const socials = 0 + (_socials && _socials.length);
  const studies = 0 + (_studies && _studies.length);
  const jobs = 0 + (_jobs && _jobs.length);

  return {
    ...addresses,
    emails,
    phones,
    socials,
    cooperation,
    hobby,
    studies,
    jobs,
  };
};


const isThereAnyContacts = rawStats => Boolean(rawStats.addresses || rawStats.emails || rawStats.phones || rawStats.socials);


const isThereAnyProfessionals = rawStats => Boolean(rawStats.studies || rawStats.jobs);


export const stats = alumnus => {
  const rawStats = getStats(alumnus);

  const anyContacts = isThereAnyContacts(rawStats);
  const anyProfessionals = isThereAnyProfessionals(rawStats);
  const anyData = Boolean(anyContacts || anyProfessionals || rawStats.hobby || rawStats.cooperation);

  return {
    ...rawStats,
    anyData,
    anyContacts,
    anyProfessionals,
  };
};
