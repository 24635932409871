import clsx from "clsx";
import { defaultRevisionReminderMaxEmailsPerCronJob } from "server/lib/restricted/config";


const getBooleanValue = envVar => Boolean(envVar && clsx(envVar).trim().toLowerCase() !== "false" && envVar !== 0);


const appVersion = clsx(process.env.SOFTWARE_VERSION).trim();


const baseUrl = clsx(process.env.ALUMNI_BASE_URI).trim();


const apiBaseUrl = clsx(process.env.NEXT_PUBLIC_ALUMNI_API_BASE_URI).trim();


const supportEmail = clsx(process.env.SUPPORT_EMAIL).trim();


const bugsnagApiKey = clsx(getBooleanValue(process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) && process.env.NEXT_PUBLIC_BUGSNAG_API_KEY).trim();


const bugsnagReporting = getBooleanValue(process.env.NEXT_PUBLIC_BUGSNAG_REPORTING);


const dryMode = getBooleanValue(process.env.DRY_MESSAGE_SENDING);


const secureSMTP = getBooleanValue(process.env.SMTP_SECURE);

const SMTPpool = getBooleanValue(process.env.SMTP_POOL);


const awsSES = getBooleanValue(process.env.AWS_SES);


const graduatesNoSSL = getBooleanValue(process.env.GRADUATES_NO_SSL);


const revisionReminder1 = getBooleanValue(process.env.REMINDER_1_YEAR);

const revisionReminder2 = getBooleanValue(process.env.REMINDER_2_YEARS);

const revisionReminder3 = getBooleanValue(process.env.REMINDER_3_YEARS);

const revisionReminder5 = getBooleanValue(process.env.REMINDER_5_YEARS);


const revisionReminderMaxEmailsPerCronJob = parseInt(clsx(process.env.REMINDER_MAX_EMAILS_PER_CRON_JOB).trim() || defaultRevisionReminderMaxEmailsPerCronJob);


const envVars = {
  appVersion,
  baseUrl,
  apiBaseUrl,
  supportEmail,
  bugsnagApiKey,
  bugsnagReporting,
  dryMode,
  secureSMTP,
  SMTPpool,
  awsSES,
  graduatesNoSSL,
  revisionReminder1,
  revisionReminder2,
  revisionReminder3,
  revisionReminder5,
  revisionReminderMaxEmailsPerCronJob,
};

export default envVars;
