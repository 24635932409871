import { dbEnums } from "common/enums";
import { withCapital } from "common/helpers";
import texts from "./texts";


export const discountClassName = "bg-red";


export const categories = [
  {
    id: dbEnums.searchCategories.ALL,
    relevanceId: "r",
    label: "Bármilyen adatot",
  },
  {
    id: dbEnums.searchCategories.NAME_GROUP,
    relevanceId: "ng",
    label: "Alumnus nevét",
    resultLabel: "Név",
    colorClassName: "text-blue",
    className: "bg-blue",
  },
  {
    id: dbEnums.searchCategories.FORM_TEACHER,
    relevanceId: "ft",
    label: "Osztályfőnök nevét",
    resultLabel: "Osztályfőnök",
    colorClassName: "text-yellow",
    className: "bg-yellow",
  },
  {
    id: dbEnums.searchCategories.CONTACT,
    relevanceId: "c",
    label: "Elérhetőséget",
    resultLabel: "Elérhetőség",
    colorClassName: "text-orange",
    className: "bg-orange",
  },
  {
    id: dbEnums.searchCategories.PROFESSIONAL,
    relevanceId: "p",
    label: "Szakmai adatokat",
    resultLabel: "Szakmai adatok",
    colorClassName: "text-green",
    className: "bg-green",
  },
  {
    id: dbEnums.searchCategories.DISCOUNT,
    relevanceId: "x",
    label: `${withCapital(texts.discountProviderNameAccusative)}`,
    resultLabel: withCapital(texts.discountProviderName),
    colorClassName: "text-red",
    className: discountClassName,
  },
  {
    id: dbEnums.searchCategories.HOBBY,
    relevanceId: "h",
    label: "Érdeklődési kört",
    resultLabel: "Érdeklődési kör",
    colorClassName: "text-purple",
    className: "bg-purple",
  },
];

