import clsx from "clsx";


export const infoURL = "https://browser-update.org/update-browser.html";


// they should support ES6
export const allowedPlatforms = {
  chrome: ">=56", // Sauce Labs live: 56 (2017-01)
  chromium: ">=76", // 76 (2019-07)
  edge: ">=15", // 15 (2017-03)
  firefox: ">=54", // 54 (2017-06)
  opera: ">=54", // 54 (2018-06)
  samsung_internet: ">=5.4", // 5.4 (2016-12)
  macOS: {
    safari: ">=10" // 10 (2016-09)
  },
  iOS: {
    safari: ">=10.2" // 10.2 (2016-09)
  },
};


const normalizeRule = rule => clsx(rule).replace(">=", "");


export const platformInfo = {
  chrome: {
    min: normalizeRule(allowedPlatforms.chrome),
    name: "Chrome",
  },
  chromium: {
    min: normalizeRule(allowedPlatforms.chromium),
    name: "Chromium",
  },
  edge: {
    min: normalizeRule(allowedPlatforms.edge),
    name: "Edge",
  },
  firefox: {
    min: normalizeRule(allowedPlatforms.firefox),
    name: "Firefox",
  },
  opera: {
    min: normalizeRule(allowedPlatforms.opera),
    name: "Opera",
  },
  safariMacOS: {
    min: normalizeRule(allowedPlatforms.macOS.safari),
    name: "macOS/Safari",
  },
  safariIOS: {
    min: normalizeRule(allowedPlatforms.iOS.safari),
    name: "iOS/Safari",
  },
};
