export const strLower = "a-z\u00DF-\u00F6\u00F8-\u00FF\u0151\u0171";

export const strUpper = "A-Z\u00C0-\u00D6\u00D8-\u00DE\u0150\u0170";

export const strNumeric = "0-9";

export const strSpecial = ".,!?:;\\-_@$\\\\/%^*()[\\]=+|&#~<> ";

export const strAllowed = `${strLower}${strUpper}${strNumeric}${strSpecial}`;

export const minLength = 8;

export const maxLength = 200;

export const allowedHelper = ". , ! ? : ; - _ @ $ \\ / % ^ * ( ) [ ] = + | & # ~ < >";

export const regexInvalidChar = new RegExp(`^[${strAllowed}]*$`);

export const regexFormat = new RegExp(`^(?=.*[${strUpper}].*[${strUpper}])(?=.*[${strLower}].*[${strLower}])(?=.*[${strNumeric}])(?=.*[${strSpecial}])[${strAllowed}]{${minLength},}$`);
