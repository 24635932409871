import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Snackbar, IconButton, Icon, SnackbarContent } from "@material-ui/core";
import { green, amber, blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const useStyles = makeStyles(theme => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: "#FFFFFF"
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark)
  },
  info: {
    backgroundColor: blue[600],
    color: "#FFFFFF"
  },
  warning: {
    backgroundColor: amber[700],
    color: "#FFFFFF"
  }
}));


const variantIcon = {
  success: "check_circle",
  warning: "warning",
  error: "error_outline",
  info: "info",
};


const FuseMessage = () => {

  const { message: { open, variant, message, anchorOriginHorizontal, anchorOriginVertical, autoHideDuration, hideMessage } } = useContext(UiStateContext);


  const onClose = useCallback(() => hideMessage(), [hideMessage]);


  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal
      }}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={onClose}
      classes={{
        root: classes.root
      }}
      ContentProps={{
        variant: "body2",
        headlineMapping: {
          body1: "div",
          body2: "div"
        }
      }}
    >
      <SnackbarContent
        classes={{
          message: "py-1",
        }}
        className={clsx(classes[variant])}
        message={
          <div className="flex items-center">
            {variantIcon[variant] && (
              <Icon className="mr-16" color="inherit">{variantIcon[variant]}</Icon>
            )}
            {message}
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className="ml-8"
              onClick={hideMessage}
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
        }
      />
    </Snackbar>
  );
};

export default React.memo(FuseMessage);
