import { mutationStatuses } from "server/lib/octopus/config";


export const defaultCache = {
  octopusState: {
    init: {
      status: mutationStatuses.uninitialized,
      startTime: null,
      endTime: null,
      error: null,
      listInfo: null,
      inProgress: false,
      __typename: "initOctopusState"
    },
    status: {
      status: mutationStatuses.uninitialized,
      startTime: null,
      endTime: null,
      error: null,
      stats: null,
      tasks: null,
      capacity: 0,
      primaryCount: 0,
      secondaryCount: 0,
      subscribedMembersCount: 0,
      inProgress: false,
      __typename: "statusOctopusState"
    },
    sync: {
      status: mutationStatuses.uninitialized,
      startTime: null,
      endTime: null,
      error: null,
      numTasks: 0,
      completedTasks: 0,
      failedTasks: 0,
      percent: 0,
      stop: null,
      inProgress: false,
      __typename: "syncOctopusState"
    },
    __typename: "octopusState",
  },
};
