import React from "react";
import { Typography } from "@material-ui/core";
import texts from "config/texts";
import { accent, withAccent, withCapital } from "common/helpers";


const Cooperation = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon <b>együttműködési lehetőségeket</b> vázolhatsz fel, valamint <b>kedvezményeket, segítséget ajánlhatsz
      fel</b> az alumnusoknak. Üzleti vagy bármilyen más területen. Az itt megadott információk kereshetővé válnak
      az <span className="text-blue">Alumnus keresőben</span>.
    </Typography>
    <Typography className="my-20">
      Az együttműködési lehetőségeket <b>kulcsszavakkal</b> is megadhatod. Ezek az adatlapodon címkékkel jelennek meg.
    </Typography>
    <Typography className="my-20">
      Ha {texts.discountNameAccusative} nyújtanál a diáktársaknak, akkor kapcsold be az erre vonatkozó
      jelölőnégyzetet! Ekkor egy speciális címke jelzi majd az adatlapodon, hogy {texts.discountProviderName} vagy.
      Egyúttal az adatlapod az <span
      className="text-blue">Alumnus keresőben</span> {accent(texts.discountProvidersName)} <span
      className="text-blue">{texts.discountProvidersName}</span> szűrővel kilistázott elemek között is megjelenik. <b>Bekapcsolás
      esetén adj meg legalább egy kulcsszót, egyúttal a leírás mezőben add meg a szükséges információkat a
      kedvezménynyújtás feltételeivel, részleteivel kapcsolatban!</b> Az <span
      className="text-blue">Alumnus keresőben</span> mind a kulcsszavak, mind pedig leírás alapján megtalálható
      leszel.&nbsp;{accent(texts.discountProvidersName, true)} <i>{texts.discountProvidersName}</i> szűrővel
      kilistázott találati kártyádon csak a kulcsszavak lesznek láthatók; a leírás az adatlapodon lesz olvasható.
    </Typography>
    <Typography className="my-20">
      <b>Az együttműködési lehetőségekkel és/vagy {withAccent(texts.discountName)} nyújtásával kapcsolatos,
        részletesebb információkhoz</b> az erre vonatkozó, többsoros szövegmező kitöltésével nyílik lehetőség. Ebben
      célszerű lehet webes hivatkozásokat is elhelyezni. Ahhoz, hogy az adatlapon e hivatkozások kattinthatók legyenek,
      mindig teljes URL-t adj meg! (Pl. a <span className="text-blue">https://example.com</span> kattintható lesz,
      a <span className="text-red">example.com</span> viszont nem.)
    </Typography>
    <Typography className="my-20">
      <b>{withCapital(texts.discountName)} nyújtása esetén a leírás mezőben feltétlenül add meg, hogy &ndash; a kártya
        felmutatása mellett &ndash; mire, milyen feltételekkel vonatkozik {withAccent(texts.discountName)}!</b> Ugyan
      az itt megadott leírás csak az adatlapodon lesz olvasható, az <span
      className="text-blue">Alumnus keresőben</span> e leírás tartalma alapján is megtalálható leszel.
    </Typography>
    <Typography className="my-20">
      Az itt megadott információk mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. <b>A láthatóság {withAccent(texts.discountName)} nyújtására is
      vonatkozik!</b> Pl. osztálytárs láthatóság beállítása esetén csak az osztálytársak fogják látni,
      hogy {texts.discountNameAccusative} nyújtasz.
    </Typography>
    <Typography className="mt-20">
      Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
  </>
);

export default React.memo(Cooperation);
