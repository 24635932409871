import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { uiStateEventEmitter, uiStateEvents } from "./events";


const emptyMessage = "";


const useUiApiErrorMessage = () => {

  const [message, setMessage] = useState(emptyMessage);


  const setApiError = useCallback(details => {
    const detailsStr = details && clsx(JSON.stringify(JSON.parse(details), null, 2));
    setMessage(detailsStr || emptyMessage);
  }, []);


  const reset = useCallback(() => setApiError(), [setApiError]);


  const showApiErrorMessage = useCallback(details => setApiError(details), [setApiError]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.backend.apiError, showApiErrorMessage);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.backend.apiError, showApiErrorMessage);
    }
  }, [showApiErrorMessage]);


  return {
    apiError: {
      opened: Boolean(message),
      hide: reset,
      message,
    },
    reset,
  };
};

export default useUiApiErrorMessage;
