import React from "react";
import { Typography } from "@material-ui/core";
import ErrorBase from "./ErrorBase";


const NotAllowed = ({ notFound }) => {

  const title = notFound ? 404 : 403;

  const description = notFound
    ? "A kért oldal nem található"
    : "A kért oldal megtekintése nem engedélyezett";

  return (
    <ErrorBase title={title}>
      <Typography variant="h5" color="textSecondary" className="mb-24">
        {description}
      </Typography>
    </ErrorBase>
  );
};

export default React.memo(NotAllowed);
