import React, { useMemo } from "react";
import moment from "moment";
import Link from "next/link";
import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { dbEnums } from "common/enums";
import { MiniChip } from "components/common";
import RowControl from "./RowControl";


const Row = regCode => {

  const { code, name, mem, expiry, safeGroup, formTeacher, note, regDate, alumnusId, alumnus } = regCode;

  const { safeGroup: alumnusSafeGroup, name: { fullName } = {} } = alumnus || {};


  const statusLabel = useMemo(() => regDate || alumnusId
    ? "Felhasznált kód"
    : !expiry
      ? "Bármeddig felhasználható"
      : moment(expiry) < moment()
        ? "Lejárt kód"
        : `Felhasználható: ${moment(expiry).format("YYYY.MM.DD")}-ig`
    , [alumnusId, expiry, regDate]);


  const statusColorClassName = useMemo(() => regDate || alumnusId
    ? "bg-blue"
    : !expiry
      ? "bg-green"
      : moment(expiry) < moment()
        ? "bg-red"
        : "bg-orange"
    , [alumnusId, expiry, regDate]);


  const memLabel = useMemo(() => mem === dbEnums.membership.TEACHER || mem === dbEnums.membership.HONORARY
    ? safeGroup
    : `${safeGroup} (of: ${formTeacher})`
    , [formTeacher, mem, safeGroup]);


  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <RowControl {...regCode}/>
      </Grid>
      <Grid item>
        <div className="sm:min-w-112">
          <Chip label={code}
                color="primary"
                size="small"
                variant="outlined"
          />
        </div>
      </Grid>
      <Grid item>
        <div className="sm:min-w-168">
          <Typography variant="caption">{name} részére</Typography>
        </div>
      </Grid>
      <Grid item>
        <Grid container spacing={1} className="sm:min-w-320">
          <Grid item>
            <MiniChip label={memLabel}
                      colorClassName="bg-teal"
            />
          </Grid>
          <Grid item>
            <MiniChip label={statusLabel}
                      colorClassName={statusColorClassName}
            />
          </Grid>
        </Grid>
      </Grid>
      {Boolean(alumnusId) && (
        <Grid item>
          <div className="sm:min-w-256">
            <Typography variant="caption">
              <span>A kóddal&nbsp;</span>
              <Link href={pathNames.alumnus.href} as={pathNames.alumnus.as(alumnusId)}>
                <Tooltip title="A regisztrációs kódot felhasználó alumnus">
                  <a className="cursor-pointer">{fullName}&nbsp;({alumnusSafeGroup})</a>
                </Tooltip>
              </Link>
              <span>&nbsp;regisztrált</span>
            </Typography>
          </div>
        </Grid>
      )}
      {Boolean(note) && (
        <Grid item>
          <Typography variant="caption">{note}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Row);
