import { removeMetaKeys, doMutation } from "client/store/helpers";
import { UPSERT_CARDS, DELETE_CARDS } from "client/store/alumnus";


const normalizeRecord = async (client, { id, ...card }, { alumnusId }) => ({
  alumnus__id: alumnusId,
  ...(id ? { id } : {}),
  ...card,
});


const normalizeRecords = async (client, { cards, ...args }) => {
  const normalizedRecords = [];

  for (let card of (cards || [])) {
    const normalizedRecord = await normalizeRecord(client, card, args);
    normalizedRecords.push(normalizedRecord);
  }

  return normalizedRecords;
};


const getRecords = (records, deletedIds) => (records || [])
  .filter(({ id }) => !(deletedIds || []).includes(id))
  .map(removeMetaKeys);


const saveCards = async (_root, { deletedIds, ...args }, { client }) => {
  const normalizedRecords = await normalizeRecords(client, args);

  const records = getRecords(normalizedRecords, deletedIds);
  if (records && records.length) await doMutation(client, UPSERT_CARDS, {
    records
  });

  if (deletedIds && deletedIds.length) await doMutation(client, DELETE_CARDS, {
    ids: deletedIds
  });
};

export default saveCards;
