import React from "react";
import { Icon, IconButton, Tooltip } from "@material-ui/core";
import { newRecordIdSuffix } from "components/form/config";
import { useOpenDialog } from "components/listPages";
import { __typename, _updateFields } from "./RevisionRequest";
import EditRevisionRequest from "./EditRevisionRequest";


const getEmptyRecord = () => ({
  id: `1${newRecordIdSuffix}`,
  [_updateFields.lastRevision]: new Date(),
  [_updateFields.required]: false,
  [_updateFields.note]: "",
  __typename,
});


const getContentComponent = () => (
  <EditRevisionRequest isNew {...getEmptyRecord()}/>
);


const AddButton = () => {

  const add = useOpenDialog({
    title: "Új adatellenőrzési kör hozzáadása",
    enabledOKButton: true,
    ContentComponent: getContentComponent(),
  });

  return (
    <Tooltip title="Új adatellenőrzési kör hozzáadása">
      <IconButton size="small"
                  onClick={add}
      >
        <Icon>add</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default React.memo(AddButton);
