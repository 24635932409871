import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { TextField } from "@material-ui/core";
import { requirementMessages } from "../config";
import useField from "./useField";


const Input = ({
                 record, field, requirements, required, componentRequired = required, minLength, maxLength,
                 fakeContentRegex, noOnlyLowerCase, noOnlyUpperCase, helperText, className, onlyNumbers,
                 textFieldClassName, label, variant = "outlined", ...restProps
               }) => {


  const fakeContent = useCallback(value => (fakeContentRegex || /[\da-z]+/i).test(clsx(value))
    || requirementMessages.fake
    , [fakeContentRegex]);


  const onlyLowerCase = useCallback(value => !noOnlyLowerCase
    || !clsx(value).trim()
    || clsx(value).trim().length < 3
    || clsx(value) !== clsx(value).toLowerCase()
    || requirementMessages.onlyLowerCase, [noOnlyLowerCase]);


  const onlyUpperCase = useCallback(value => !noOnlyUpperCase
    || !clsx(value).trim()
    || clsx(value).trim().length < 3
    || clsx(value) !== clsx(value).toUpperCase()
    || requirementMessages.onlyUpperCase, [noOnlyUpperCase]);


  const fieldRequirements = useMemo(() => ({
    ...requirements,
    required,
    validate: {
      ...(requirements && requirements.validate),
      ...(required ? { fakeContent } : {}),
      onlyLowerCase,
      onlyUpperCase,
    },
  }), [fakeContent, onlyLowerCase, onlyUpperCase, required, requirements]);


  const { error, name, helperTextOrError, register } = useField({
    field,
    record,
    helperText,
    fieldRequirements,
  });


  return (
    <div className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}>
      <TextField
        {...restProps}
        name={name}
        classes={{
          root: `w-full ${clsx(textFieldClassName)}`,
        }}
        variant={variant}
        label={label}
        inputProps={{
          "aria-label": label,
          maxLength: maxLength || 100,
          ...(minLength ? { minLength } : {}),
          ...(onlyNumbers ? { type: "number" } : {}),
        }}
        inputRef={register()}
        InputProps={{
          classes: {
            inputMultiline: "pt-1",
          },
        }}
        helperText={helperTextOrError}
        required={Boolean(componentRequired)}
        error={error}
      />
    </div>
  );
};

export default React.memo(Input);
