import React, { useContext } from "react";
import { Grid, Hidden, Icon, Paper } from "@material-ui/core";
import { Header as ListHeader, TextFilter, ListContext } from "components/listPages";
import { filterIds } from "./List";


const Header = props => {

  const { setFilter, filterValues } = useContext(ListContext);

  return (
    <ListHeader {...props}
                noPaper
                titleClassName="mb-32 sm:mb-40"
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Paper className="flex items-center h-48 sm:h-56 rounded-8 px-16 lg:px-20" elevation={1}>
            <Hidden xsDown>
              <Icon color="action" className="mr-16">search</Icon>
            </Hidden>
            <TextFilter filterId={filterIds.inName}
                        filterValue={filterValues[filterIds.inName]}
                        setFilter={setFilter}
                        placeholder="Név, osztály"
                        disableUnderline
                        className="w-full"
                        fullWidth
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className="flex items-center h-48 sm:h-56 rounded-8 px-16 lg:px-20" elevation={1}>
            <Hidden xsDown>
              <Icon color="action" className="mr-16">search</Icon>
            </Hidden>
            <TextFilter filterId={filterIds.inContact}
                        filterValue={filterValues[filterIds.inContact]}
                        setFilter={setFilter}
                        placeholder="Email, telefonszám, státusz"
                        disableUnderline
                        className="w-full"
                        fullWidth
            />
          </Paper>
        </Grid>
      </Grid>
    </ListHeader>
  );
};

export default React.memo(Header);
