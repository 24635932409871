import React, { useCallback, useEffect, useRef } from "react";
import clsx from "clsx";
import { TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
  pageInput: {
    textAlign: "right",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      "-moz-appearance": "textfield",
      margin: 0,
    },
  },
}));


const PageField = ({ page, numPages, onChange, onEnter, onBlur, disabled, className, textFieldClassName, spanClassName }) => {

  const ref = useRef();


  const safeOnEnter = useCallback(event => onEnter && event.keyCode === 13 && onEnter(), [onEnter]);


  const safeOnBlur = useCallback(() => onBlur && onBlur(), [onBlur]);


  useEffect(() => {
    const target = ref && ref.current;
    if (target && onEnter) {
      target.addEventListener("keydown", safeOnEnter, false);
      return () => {
        target.removeEventListener("keydown", safeOnEnter, false);
      }
    }
  }, [onEnter, safeOnEnter]);


  const classes = useStyles();


  return numPages > 1 && (
    <Grid container spacing={1} alignItems="center" className={className}>
      <Grid item>
        <TextField
          value={page || 1}
          onChange={onChange}
          onBlur={safeOnBlur}
          disabled={Boolean(disabled)}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={ref}
          inputProps={{
            className: `${classes.pageInput} text-13 sm:text-14`,
            min: 1,
            max: numPages,
          }}
          className={clsx("w-36", textFieldClassName)}
        />
      </Grid>
      <Grid item>
        <span className={clsx("text-13 sm:text-14", spanClassName)}>/&nbsp;{numPages}</span>
      </Grid>
    </Grid>
  );
};

export default React.memo(PageField);
