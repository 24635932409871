import { primaryListName, secondaryListName } from "server/lib/octopus/config";
import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { decoratedError, errors } from "common/errors";


const findListIdByName = (listName, lists) => {
  if (!listName || !lists) return null;
  const { id } = lists.find(({ name }) => name === listName) || {};
  return id;
};


const getListId = async (name, lists) => {
  const existingId = findListIdByName(name, lists);
  if (existingId) return { id: existingId };

  const { error, data } = await backendFetch(backendServices.octopus.createList, { name });
  return error ? { error } : data;
};


const getList = async (name, lists) => {
  const { error, id } = await getListId(name, lists);
  return error ? { error } : {
    id,
    name,
  };
};


const getPrimaryList = async lists => await getList(primaryListName, lists);


const getSecondaryList = async lists => await getList(secondaryListName, lists);


const initLists = async ({ lists }) => {
  try {
    const primaryList = await getPrimaryList(lists);
    if (primaryList.error) return primaryList;

    const secondaryList = await getSecondaryList(lists);
    if (secondaryList.error) return secondaryList;

    return {
      primaryList,
      secondaryList,
    };

  } catch (e) {
    const message = errorLogStore("octopus", "initLists", e);
    return decoratedError(errors.client.octopus.LIB_ERROR, { message });
  }
};

export default initLists;
