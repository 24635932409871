import { onTaskProcessed } from "./octopusState";
import { taskTypes, contactStatuses } from "server/lib/octopus/config";
import { UNSUBSCRIBE_ALUMNUS } from "client/store/alumnus";
import { errorLogStore } from "client/lib/errorLog";
import { backendFetch, backendServices } from "client/lib/backendApi";


const services = {
  [taskTypes.createContact]: backendServices.octopus.createContact,
  [taskTypes.updateContact]: backendServices.octopus.updateContact,
  [taskTypes.deleteContact]: backendServices.octopus.deleteContact,
};


const getCreateBody = ({ listId, email, first, last }) => listId && email && first && last && {
  listId,
  email,
  fields: { FirstName: first, LastName: last },
  status: contactStatuses.subscribed
};


const getDeleteBody = ({ listId, email }) => listId && email && {
  listId,
  email,
};


const makeRequest = async (client, { task, ...data }) => {
  const service = task && services[task];
  if (!service) return true;

  const bodyFactory = task === taskTypes.deleteContact ? getDeleteBody : getCreateBody;
  const body = bodyFactory(data);
  if (!body) return true;

  const { error } = await backendFetch(service, body);
  return Boolean(error);
};


const handleOctopusTask = makeRequest;


const handleAppTask = async (client, { task, id }) => {
  if (task === taskTypes.unsubcribeAlumnus) {
    const { error } = await client.mutate({
      mutation: UNSUBSCRIBE_ALUMNUS,
      variables: {
        id,
      },
    });
    return Boolean(error);
  }
};


const onFailure = client => onTaskProcessed(client, true) || null;


const processTask = async (client, task) => {
  try {

    if (!task) return onFailure(client) || null;

    const { task: taskType } = task;
    const handler = taskType === taskTypes.unsubcribeAlumnus ? handleAppTask : handleOctopusTask;

    const failed = await handler(client, task);
    return onTaskProcessed(client, failed) || null;

  } catch (e) {
    errorLogStore("octopus", "processTask", e);
    return onFailure(client) || null;
  }
};

export default processTask;
