import { dbEnums, labels } from "common/enums";


export const logActionDetails = {
  [dbEnums.action.REVISION]: {
    actionStr: labels.action[dbEnums.action.REVISION],
    icon: "playlist_add_check",
    iconColor: "secondary"
  },
  [dbEnums.action.UPDATE]: {
    actionStr: labels.action[dbEnums.action.UPDATE],
    icon: "edit",
    iconColor: "secondary"
  },
  [dbEnums.action.LOGIN]: {
    actionStr: labels.action[dbEnums.action.LOGIN],
    icon: "play_circle_filled_white",
    iconColor: "action"
  },
  [dbEnums.action.REGISTRATION]: {
    actionStr: labels.action[dbEnums.action.REGISTRATION],
    icon: "person_add",
    iconColor: "secondary"
  },
  [dbEnums.action.PSW_CHANGE]: {
    actionStr: labels.action[dbEnums.action.PSW_CHANGE],
    icon: "security",
    iconColor: "secondary"
  },
  [dbEnums.action.PSW_CHANGE_W_TOKEN]: {
    actionStr: labels.action[dbEnums.action.PSW_CHANGE_W_TOKEN],
    icon: "security",
    iconColor: "secondary"
  },
  [dbEnums.action.PSW_RESET]: {
    actionStr: labels.action[dbEnums.action.PSW_RESET],
    icon: "vpn_key",
    iconColor: "error"
  },
  [dbEnums.action.ACCOUNT_ST_CHANGE]: {
    actionStr: labels.action[dbEnums.action.ACCOUNT_ST_CHANGE],
    icon: "perm_identity",
    iconColor: "error"
  },
  [dbEnums.action.BANS_DELETED]: {
    actionStr: labels.action[dbEnums.action.BANS_DELETED],
    icon: "lock_open",
    iconColor: "secondary"
  },
  _default: {
    actionStr: "Ismeretlen művelet",
    icon: "help_outline",
    iconColor: "action"
  },
};


const details = ({ action }) => ({
  ...(logActionDetails[action] || logActionDetails._default),
});


const view_action_log = {
  details,
};

export default view_action_log;
