import React from "react";
import clsx from "clsx";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Label from "./Label";


const deleteButtonIcon = (
  <DeleteIcon color="action"/>
);


const DeletePanel = ({ disabled, onDelete, label, startIcon = deleteButtonIcon, xs = 12, md = 4, className, ...gridProps }) =>
  !disabled && (
    <Grid item xs={xs} md={md} {...gridProps}
          className={clsx("md:text-right", !clsx(className).includes("mb-") && "mb-32 md:mb-0", className)}>
      <Label label={label} align="right">
        <Tooltip title={label}>
          <Button
            variant="contained"
            color="default"
            size="large"
            className="bg-transparent hover:bg-transparent"
            startIcon={startIcon}
            onClick={onDelete}
          >
            Törlés
          </Button>
        </Tooltip>
      </Label>
    </Grid>
  );

export default React.memo(DeletePanel);
