import clsx from "clsx";
import serializeJson from "serialize-javascript";
import { createLogger, getFormattedCurrentTS, getDefaultFileOptions } from "../logger";
import { logServices } from "../config";


// only on server side
const winston = typeof window === "undefined" ? require("winston") : {};
const { format } = winston || {};


const log = (logger, level, { description = "", library = "", fn = "", details, error, err, e }) => {
  const message = `${description ? `${description} in ` : "In "}${fn}@${library}`;
  const info = {
    timestamp: new Date().toISOString(),
    level,
    message,
    fn,
    library,
  };
  if (description) info.description = description;
  if (details) info.details = details;
  const cErr = error || err || e;
  if (cErr) {
    info.errorMessage = clsx(cErr.message);
    info.errorStack = clsx(cErr.stack);
  }
  logger && logger.log(info);
  return message.trim();
};


const consoleTemplateFn = ({ level, message, errorMessage, errorStack, details }) =>
  `${getFormattedCurrentTS()} [${level}] ${message}${clsx(errorMessage && `\n  Error: ${errorMessage}`)}${clsx(errorStack && `\n  ${errorStack}`)}${clsx(details && `\n  Details: ${serializeJson(details)}`)}`;


const loggerConfig = {
  consoleOptions: {
    level: "error",
    format: format && format.printf(consoleTemplateFn),
  },
  fileOptions: getDefaultFileOptions(logServices.SERVER),
};


const logger = createLogger(logServices.SERVER, loggerConfig, log);

export default logger;
