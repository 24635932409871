import { useCallback, useEffect, useMemo, useState } from "react";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { emitUiStateEvent, uiStateEvents } from "client/lib/providers/UiStateProvider";
import { statementsConfig } from "./config";


const useTermsStep = ({ stepStatements, registerSubmitCb }) => {

  const [visibleTerms, setVisibleTerms] = useState(false);


  const statementsApproved = useMemo(() => !statementsConfig.some(({ id, required }) => required && !stepStatements.includes(id)
  ), [stepStatements]);


  const toggleVisibleTerms = useCallback(() => setVisibleTerms(visible => !visible), []);


  const onSubmit = useCallback(async () => {
    if (!statementsApproved) return emitUiStateEvent(uiStateEvents.form.submitFinished, {
      error: true,
      invalid: true,
    });

    const { error } = await backendFetch(backendServices.account.approveTerms);

    emitUiStateEvent(uiStateEvents.form.submitFinished, {
      success: !error,
      noMessage: true,
    });
  }, [statementsApproved]);


  useEffect(() => {
    if (typeof registerSubmitCb === "function") registerSubmitCb(onSubmit);
  }, [registerSubmitCb, onSubmit]);


  return {
    visibleTerms,
    toggleVisibleTerms,
  };

};

export default useTermsStep;
