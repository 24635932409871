import React from "react";
import { Typography, Icon } from "@material-ui/core";
import FlagIcon from "components/alumnus/FlagIcon";


const Address = ({ address, noIcon, noCountryIcon }) => (
  <div className="flex flex-row">
    {!noIcon && (
      <Icon className="text-16 mr-8" color="action">location_on</Icon>
    )}
    <Typography>
      <span className="mr-8">{address.full}</span>
      {!noCountryIcon && (
        <FlagIcon code={address.country} disableHun/>
      )}
    </Typography>
  </div>
);

export default React.memo(Address);
