import React, { useCallback, useContext } from "react";
import { Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UiMessages } from "config/messages";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import EditButton from "./EditButton";
import DelButton from "./DelButton";
import StatusButton from "./StatusButton";
import PswrLinkButton from "./PswrLinkButton";
import UnbanButton from "./UnbanButton";


const RowControl = specialAccount => {

  const { message: { showMessage } } = useContext(UiStateContext);

  const onCopy = useCallback(() => showMessage(UiMessages.meta.copiedToClipboard("A speciális fiók felhasználóneve")), [showMessage]);

  return (
    <Grid container spacing={1} alignItems="center" className="sm:min-w-256">
      <Grid item>
        <EditButton {...specialAccount}/>
      </Grid>
      <Grid item>
        <PswrLinkButton {...specialAccount}/>
      </Grid>
      <Grid item>
        <UnbanButton {...specialAccount}/>
      </Grid>
      <Grid item>
        <StatusButton {...specialAccount}/>
      </Grid>
      <Grid item>
        <DelButton {...specialAccount}/>
      </Grid>
      <Grid item>
        <CopyToClipboard text={specialAccount.username}
                         onCopy={onCopy}
        >
          <Tooltip title="Felhasználónév másolása a vágólapra">
            <IconButton size="small">
              <Icon>file_copy</Icon>
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      </Grid>
    </Grid>
  );
};

export default React.memo(RowControl);
