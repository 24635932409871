import React, { useCallback } from "react";
import clsx from "clsx";
import { MenuItem, Select, FormControl, Input } from "@material-ui/core";


const PageSizeField = ({ pageSize, pageSizes, setPageSize, disabled, className, selectClassName }) => {

  const onChange = useCallback(event => setPageSize(event.target.value), [setPageSize]);

  return pageSizes.length > 1 && (
    <FormControl className={clsx(className)}>
      <Select
        onChange={onChange}
        value={pageSize}
        disabled={disabled}
        input={<Input/>}
        inputProps={{
          className: "text-13 sm:text-14",
        }}
        className={clsx(selectClassName)}
        disableUnderline
      >
        {pageSizes.map(pageSize => (
          <MenuItem key={pageSize} value={pageSize}>
            <span className="text-14 py-6">{pageSize} sor</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(PageSizeField);
