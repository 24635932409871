import React, { useCallback, useEffect, useRef } from "react";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { emitUiStateEventNextTick, uiStateEvents } from "client/lib/providers/UiStateProvider";
import Layout from "components/layout";
import { helps } from "components/help";
import { FusePageCarded } from "components/fuse";
import { SidebarHeader, Header as ListHeader } from "components/listPages";
import SearchTextFilter from "./SearchTextFilter";
import Content from "./Content";
import Sidebar from "./Sidebar";
import Toolbar from "./Toolbar";


const SearchPage = () => {

  const pageLayout = useRef(null);

  const loaded = useRef(false);


  const forbidden = useForbidden([dbEnums.accountRoles.ALUMNUS]);


  const toggleSidebar = useCallback(() => pageLayout.current && pageLayout.current.toggleLeftSidebar(), []);


  useEffect(() => {
    if (!forbidden && !loaded.current) {
      loaded.current = true;
      emitUiStateEventNextTick(uiStateEvents.search.updateStarted);
    }
  }, [forbidden]);


  return (
    <Layout help={helps.search} forbidden={forbidden}>
      <FusePageCarded
        classes={{
          root: "w-full pb-20 sm:pb-36",
          header: "items-center min-h-72 h-72 sm:h-128 sm:min-h-128"
        }}
        header={
          <ListHeader toggleSidebar={toggleSidebar}
                      className="px-8 pr-16 lg:pl-20"
                      noIcon
          >
            <SearchTextFilter/>
          </ListHeader>
        }
        contentToolbar={
          <Toolbar/>
        }
        content={
          <Content/>
        }
        leftSidebarHeader={
          <SidebarHeader title="Alumnus kereső" icon="contacts"/>
        }
        leftSidebarContent={
          <Sidebar/>
        }
        ref={pageLayout}
      />
    </Layout>
  );
};

export default React.memo(SearchPage);
