import { fetchGroupMembers } from "client/store/group";
import { fetchAlumnus, getCachedAlumnus } from "./getAlumnus";


const alumnusWGroupmates = async (_root, { id, forcedRefetch }, { cache, getCacheKey, client }) => {
  const cachedAlumnus = getCachedAlumnus(id, cache, getCacheKey);

  // it should be fetched if it isn't in the cache
  const alumnus = (!forcedRefetch && cachedAlumnus) || await fetchAlumnus(id, client, forcedRefetch);

  // only prefetch groupmates, discard result set
  if (alumnus && !alumnus.special) await fetchGroupMembers(alumnus, client);

  return alumnus || null;
};

export default alumnusWGroupmates;
