import React, { useMemo } from "react";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@material-ui/core";
import { getDefaultValueProp } from "../helpers";
import Controller from "./Controller";
import useField from "./useField";


const RadioGroup = ({ record, field, required, defaultValue, options, label, helperText, className }) => {

  const fieldRequirements = useMemo(() => required && {
    required: required || true,
  }, [required]);


  const { name, rules, error, helperTextOrError } = useField({
    record,
    field,
    helperText,
    fieldRequirements,
  });


  const children = useMemo(() => Array.isArray(options) && options.map(option => {
    const { value, label, disabled, labelPlacement = "end" } = option || {};
    return (
      <FormControlLabel key={value} value={value} disabled={disabled} label={label} control={<Radio/>}
                        labelPlacement={labelPlacement}/>
    );
  }), [options]);


  return (
    <FormControl className={clsx("w-full", !clsx(className).includes("mb-") && "mb-32", className)}
                 component="fieldset"
                 error={error}
                 required>
      {Boolean(label) && (
        <FormLabel component="legend">{label}</FormLabel>
      )}
      {Boolean(children) && (
        <Controller
          {...getDefaultValueProp(defaultValue)}
          name={name}
          as={<MuiRadioGroup/>}
          rules={rules}
        >
          {children}
        </Controller>
      )}
      {Boolean(helperTextOrError) && (
        <FormHelperText>{helperTextOrError}</FormHelperText>
      )}
    </FormControl>
  );

};

export default React.memo(RadioGroup);
