import gql from "graphql-tag";


export const INIT = gql`
  mutation Init {
    octopusInit @client
  }
`;


export const REFRESH_STATUS = gql`
  mutation RefreshStatus {
    octopusRefreshStatus @client
  }
`;


export const SYNC = gql`
  mutation Sync {
    octopusSync @client
  }
`;


export const STOP_SYNC = gql`
  mutation StopSync {
    octopusStop @client
  }
`;
