import gql from "graphql-tag";


export const fragmentAlumnus = gql`
  fragment alumnus on alumnus {
    id
    year
    group: grp
    me: view_alumnus_restricted_me {
      privacy: default_privacy
    }
    special @client
    safeGroup @client
    accountStatus @client
    hiddenAccount @client
    stats @client
    name: alumnus_name {
      id
      last: last_name
      first: first_name
      pre: name_prefix
      suf: name_suffix
      nick: nickname
      fullName @client
    }
    img: alumnus_picture {
      id
      privacy
      fileId: file_id
      src @client
    }
    status: alumnus_status {
      regDate: reg_date
      me: view_alumnus_status_restricted_me {
        id
        lastUpdate: last_update
        lastLogin: last_login
      }
      revision: view_alumnus_status_last_revision {
        lastRevision: last_revision
      }
      revisionStatus @client
      revisionDate @client
      publicProps @client
      adminProps @client
    }
    address: alumnus_address {
      id
      privacy
      country: country_code
      cName: alumnus_address_country {
        n: name
      }
      countryName @client
      zip
      city
      address
      name: address_name
      full @client
    }
    sAddress: alumnus_secondary_address {
      id
      privacy
      isResidence: is_residence
      country: country_code
      cName: alumnus_secondary_address_country {
        n: name
      }
      countryName @client
      zip
      city
      address
      name: address_name
      full @client
    }
    residency @client
    hobby: alumnus_hobby {
      id
      privacy
      hobby
      tagsW: view_alumnus_hobby {
        tags
        str: tags_string
      }
      tags @client
      tagsString @client
    }
    cooperation: alumnus_cooperation {
      id
      privacy
      discount
      cooperation
      tagsW: view_alumnus_cooperation {
        tags
        str: tags_string
      }
      tags @client
      tagsString @client
    }
    emails (order_by: {is_primary: desc, created: asc}) {
      id
      privacy
      primary: is_primary
      email
      href @client
      note
      tagsW: view_email {
        tags
        str: tags_string
      }
      tags @client
    }
    primaryEmail @client
    phones (order_by: {is_primary: desc, created: asc}) {
      id
      privacy
      primary: is_primary
      phone
      parsed @client
      note
      tagsW: view_phone {
        tags
        str: tags_string
      }
      tags @client
    }
    primaryPhone @client
    studies(order_by: {current: desc, year_from: desc_nulls_first, year_to: desc_nulls_first}) {
      id
      privacy
      institution
      institutionFull @client
      degree
      current
      from: year_from
      to: year_to
      country: country_code
      cName: study_country {
        n: name
      }
      countryName @client
      city
      web
      note
    }
    jobs(order_by: {current: desc, year_from: desc_nulls_first, year_to: desc_nulls_first}) {
      id
      privacy
      company
      companyFull @client
      job
      current
      from: year_from
      to: year_to
      country: country_code
      cName: job_country {
        n: name
      }
      countryName @client
      city
      sector
      web
      note
    }
    socials (order_by: {site: asc}) {
      id
      privacy
      site
      account
      title @client
      icon @client
    }
    account {
      status
      student: view_account_resctricted_student {
        exp: status_expiry
      }
      manager: view_account_resctricted_manager {
        id
        username
        requiredTerms: required_terms
        lastTerms: last_terms
        roles
        note
        termsStatus @client
      }
      ban: view_account_ban {
        banned
        expiry
        services: banned_services
      }
      statusProps @client
    }
    org: alumnus_org {
      id
      formTeacher: form_teacher
      me: view_alumnus_org_restricted_me {
        mem: membership
        placeOfBirth: place_of_birth
        dateOfBirth: date_of_birth
        mothersName: mothers_name
        card
        gradName: grad_name
        membershipStr @client
        fullBirth @client
      }
      manager: view_alumnus_org_restricted_manager {
        note: admin_note
      }
    }
    news: alumnus_newsletter {
      id
      email: newsletter_email
      nws: subscription_newsletter
      magazine: subscription_magazine
    }
    nls: view_newsletter_subscription {
      id
      e: newsletter_email
      s: subscription_newsletter
    }
    cards(order_by: {date_of_issue: asc_nulls_last, card_number: asc_nulls_last, status: desc}) {
      id
      issueDate: date_of_issue
      status
      nr: card_number
      exp: expiry
    }
    extendedCards @client
  }
`;


export const GET_ALUMNUS_GROUP = gql`
  query GetAlumnusGroup($ids: [Int!], $groups: [String!]) {
    members: alumnus(where: {grp: {_in: $groups}, id: {_in: $ids}}, order_by: {alumnus_name: {last_name: asc, first_name: asc}}) {
      ...alumnus
    }
  }

  ${fragmentAlumnus}
`;
