import React, { useCallback, useContext, useMemo } from "react";
import clsx from "clsx";
import moment from "moment";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { Check, Lock, LockOpen } from "@material-ui/icons";
import { UiMessages } from "config/messages";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { useConfirm } from "components/common";
import { AlumnusContext } from "components/alumnus";
import Chip from "components/alumnus/Chip";
import { Label } from "components/form";
import { tooltipDisabled } from "./Status";


const confirmOptions = {
  title: "Automatikus kitiltások feloldása",
  content: "Biztosan feloldod valamennyi automatikus kitiltást és szolgáltatás-korlátozást?",
  OKButton: "Igen, feloldom",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
};


const Bans = ({ account: { id, ban }, verified, checkVerified }) => {


  const { message: { showMessage } } = useContext(UiStateContext);

  const { forcedReload } = useContext(AlumnusContext);


  const { banned, expiry, services } = useMemo(() => ban || {}, [ban]);


  const noBan = useMemo(() => !banned && !services, [banned, services]);


  const label = useMemo(() => noBan
    ? "Nincs kitiltás"
    : !banned
      ? clsx(services, "szolgáltatás-korlátozás")
      : expiry
        ? `Kitiltás eddig: ${moment(expiry).add(1, "minutes").format("L HH:mm")}`
        : "Határozatlan idejű kitiltás"
    , [banned, expiry, noBan, services]);


  const tooltip = useMemo(() => verified
    ? "Valamennyi automatikus kitiltás és szolgáltatás-korlátozás feloldása"
    : tooltipDisabled
    , [verified]);


  const confirm = useConfirm({
    confirmOptions,
  });


  const deleteAccountBan = useCallback(async () => {
    if (!await checkVerified()) return;

    const { error } = await backendFetch(backendServices.account.deleteAccountBan, { id });

    await forcedReload();

    showMessage(error ? UiMessages.form.submit.saveError : UiMessages.form.account.bansDeleted);
  }, [checkVerified, forcedReload, id, showMessage]);


  const onClick = useCallback(() => confirm(deleteAccountBan), [deleteAccountBan, confirm]);


  return (
    <Label label="Automatikus kitiltások"
           helperText={
             <span>A rendszer által eszközölt <i>kitiltás</i> alatt az alumnus nem fog tudni bejelentkezni.&nbsp;
               <i>Szolgáltatás-korlátozás</i> esetén bizonyos funkciókat (pl. jelszóváltoztatási link kérése stb.) nem
               fog tudni használni.</span>
           }
    >
      <Grid container spacing={4}>
        <Grid item>
          <Chip label={label}
                color={noBan ? "success" : "danger"}
                AvatarComponent={noBan ? Check : Lock}
          />
        </Grid>
        {!noBan && (
          <Tooltip title={tooltip}>
            <Grid item>
              <Button
                variant="outlined"
                className="text-red"
                startIcon={<LockOpen color={verified ? "error" : "disabled"}/>}
                disabled={!verified}
                onClick={onClick}
              >
                Feloldom a korlátozásokat
              </Button>
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </Label>
  );
};

export default React.memo(Bans);
