import React, { useContext } from "react";
import clsx from "clsx";
import { Icon, IconButton } from "@material-ui/core";
import { UiStateContext } from "client/lib/providers/UiStateProvider";


const NavbarFoldedToggleButton = ({ children, className }) => {

  const { navbar: { toggleFoldedNavbar } } = useContext(UiStateContext);

  return (
    <IconButton
      className={clsx(className)}
      onClick={toggleFoldedNavbar}
      color="inherit"
    >
      {children}
    </IconButton>
  );
};

NavbarFoldedToggleButton.defaultProps = {
  children: <Icon>menu</Icon>
};

export default React.memo(NavbarFoldedToggleButton);
