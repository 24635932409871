import gql from "graphql-tag";


export const UPDATE_ALUMNUS_NAME = gql`
  mutation UpdateAlumnusName($id: Int!, $changes: alumnus_name_set_input) {
    results: update_alumnus_name(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;
