import React from "react"
import clsx from "clsx";
import { Card, CardContent, Grid } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";
import { Scrollbars } from "../common";
import { useStyles } from "../styles";
import { Welcome } from "../unauthenticated";


const RightCardWrapper = ({ wrapperClassName, WelcomeComponent = Welcome, footer, children }) => {

  const classes = useStyles();

  return (
    <div className={clsx(classes.publicRoot, "w-full h-full")}>
      <Scrollbars right>
        <Grid container
              alignItems="stretch"
              justify="space-between"
              className={clsx("w-full h-full")}
        >
          <Grid item xs={12} md="auto"
                className="bg-transparent flex flex-col flex-grow-0 md:flex-shrink-0 md:flex-1 items-center md:items-start text-white p-16 pt-28 sm:pt-36 md:p-84 lg:p-112 text-center md:text-left md:justify-center md:h-full">
            <WelcomeComponent/>
          </Grid>

          <Grid item xs={12} md="auto">
            <div className="md:h-full pb-32 sm:pb-48 md:p-0">
              <FuseAnimate animation={{ translateX: [0, "100%"] }}>
                <Card
                  className={clsx("w-full mx-auto h-full md:w-400", !clsx(wrapperClassName).includes("max-") && "max-w-400", wrapperClassName)}
                  square
                >
                  <CardContent className="flex flex-col items-center justify-center p-28 md:p-48 md:pt-24 h-full">
                    {children}
                  </CardContent>
                </Card>
              </FuseAnimate>
            </div>
          </Grid>

          {Boolean(footer) && footer}

        </Grid>

      </Scrollbars>
    </div>
  );
};

export default React.memo(RightCardWrapper);
