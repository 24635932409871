import gql from "graphql-tag";


export const UPDATE_ALUMNUS_ORG = gql`
  mutation UpdateAlumnusOrg($id: Int!, $changes: alumnus_org_set_input) {
    results: update_alumnus_org(where: {id: {_eq: $id}}, _set: $changes) {
      affected_rows
    }
  }
`;
