import React from "react";
import { Typography } from "@material-ui/core";
import { maxRecordsCounts } from "config/form";


const Phone = () => (
  <>
    <Typography className="mb-20">
      Az Alumni az itt megadott <b>kapcsolattartási telefonszámon</b> tudja felvenni veled a kapcsolatot. Továbbá erre
      a telefonszámra érkeznek a <span className="text-blue">visszaigazoló kódokat tartalmazó SMS-ek</span> is (pl.
      jelszóváltoztatási link kérésekor).
    </Typography>
    <Typography className="my-20">
      Annak ellenőrzése végett, hogy biztosan nem történt elírás, a <i>kapcsolattartási
      telefonszám</i> megadása/változtatása SMS-ben kiküldött visszaigazoló kódokkal történik. A kód
      (újbóli) kiküldését a gombra kattintva kezdeményezheted. Az így kiküldött kódot <b>írd be a telefonszám alatti
      kód mezőbe</b>! A helyesen beírt vagy bemásolt kód <span className="text-blue">azonnal, automatikusan ellenőrzésre kerül</span>.
      Ha a beírt/bemásolt kód helyes, akkor a kód szövegmező <span className="text-blue">magától eltűnik</span>. Ha nem
      megfelelő a megadott kód, akkor a kód szövegmező alatt megjelenik az erre vonatkozó hibaüzenet.
    </Typography>
    <Typography className="my-20">
      A kapcsolattartási telefonszámon kívül <b>további telefonszámokat</b> is megoszthatsz az alumnusokkal
      (legfeljebb {maxRecordsCounts.phone} további telefonszám hozható létre). Ezek megadásakor/változtatásakor nincs
      szükség megerősítő kódra.
    </Typography>
    <Typography className="my-20">
      A telefonszámokhoz <b>megjegyzést írhatsz, valamint kulcsszavakat is megadhatsz</b>, ezzel közölve a telefonos
      kapcsolattartásra vonatkozó kéréseidet, körülményeket, tudnivalókat.
    </Typography>
    <Typography className="my-20">
      Az egyes telefonszámok mások számára való láthatóságáról az űrlap tetején lévő <span
      className="text-blue">adatvédelmi beállítással</span> rendelkezhetsz. Beállíthatod, hogy <i>minden
      alumnus</i>, <i>csak az évfolyamtársak</i>, <i>csak az osztálytársak</i>, vagy <i>csak az
      adminisztrátorok</i> láthassák. Az adott adatvédelmi beállítás értelemszerűen csak a megfelelő telefonszámra
      vonatkozik. Bejelentkezés nélkül, <b>a publikus webes felületen semmilyen adat nem látható</b>.
    </Typography>
    <Typography className="mt-20">
      Egy-egy telefonszámot a <span className="text-blue">Törlés</span> gombra kattintva <b>törölhetsz</b> (kivéve a
      kapcsolattartási telefonszámot, amely nem törölhető).
    </Typography>
  </>
);

export default React.memo(Phone);
