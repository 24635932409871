import bannedIpsWCountries from "./bannedIpsWCountries";
import deleteIpBans from "./deleteIpBans";


export const resolvers = {
  Query: {
    bannedIpsWCountries,
  },
  Mutation: {
    deleteIpBans,
  }
};
