import React, { useCallback, useContext, useEffect } from "react";
import nextTick from "next-tick";
import { useApolloClient } from "@apollo/react-hooks";
import { UiMessages } from "config/messages";
import { SAVE_CARD } from "client/store/card/mutations";
import { uiStateEventEmitter, uiStateEvents } from "client/lib/providers/uiState/events";
import { UiStateContext } from "client/lib/providers/UiStateProvider";
import { ListContext } from "components/listPages";
import { Form } from "components/form";
import { __typename, fieldAliases, requiredFields, updateFields } from "components/form/card";
import Card from "./Card";


const EditCard = card => {

  const { dialog: { closeDialog, enableOKButton }, message: { showMessage } } = useContext(UiStateContext);

  const { refetch, resetFilters } = useContext(ListContext)


  const client = useApolloClient();


  const saveCb = useCallback(async ({ jobs, jobsCount }) => {
    if (!jobsCount) return;

    const job = jobs[__typename][0];

    const { error } = await client.mutate({
      mutation: SAVE_CARD,
      variables: {
        card: job,
        alumnusId: card.alumnusId,
      },
    });

    if (!error) {
      await refetch();
      resetFilters();
    } else {
      showMessage(UiMessages.form.submit.saveError);
    }

    return {
      error,
      noMessage: true,
    };
  }, [card.alumnusId, client, refetch, resetFilters, showMessage]);


  const onSubmitFinished = useCallback(({ success }) => {
    if (!success) return enableOKButton()

    closeDialog();
    nextTick(() => showMessage(UiMessages.form.submit.saved));
  }, [closeDialog, enableOKButton, showMessage]);


  useEffect(() => {
    uiStateEventEmitter.on(uiStateEvents.form.submitFinished, onSubmitFinished);

    return () => {
      uiStateEventEmitter.off(uiStateEvents.form.submitFinished, onSubmitFinished);
    };
  }, [onSubmitFinished]);


  return (
    <Form className="w-full md:min-w-lg"
          records={[card]}
          updateFields={updateFields}
          requiredFields={requiredFields}
          fieldAliases={fieldAliases}
          saveCb={saveCb}
          enableSubmit={enableOKButton}
    >
      <Card {...card}/>
    </Form>
  );
};

export default React.memo(EditCard);
