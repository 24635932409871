import clsx from "clsx";


const texts = {
  cardName: clsx(process.env.NEXT_PUBLIC_TEXT_CARD_NAME).trim(),
  cardsName: clsx(process.env.NEXT_PUBLIC_TEXT_CARDS_NAME).trim(),
  discountName: clsx(process.env.NEXT_PUBLIC_TEXT_DISCOUNT_NAME).trim(),
  discountNameAccusative: clsx(process.env.NEXT_PUBLIC_TEXT_DISCOUNT_NAME_ACCUSATIVE).trim(),
  discountProviderName: clsx(process.env.NEXT_PUBLIC_TEXT_DISCOUNT_PROVIDER).trim(),
  discountProviderNameAccusative: clsx(process.env.NEXT_PUBLIC_TEXT_DISCOUNT_PROVIDER_ACCUSATIVE).trim(),
  discountProvidersName: clsx(process.env.NEXT_PUBLIC_TEXT_DISCOUNT_PROVIDERS).trim(),
  magazineName: clsx(process.env.NEXT_PUBLIC_TEXT_MAGAZINE).trim(),
  organization: clsx(process.env.NEXT_PUBLIC_TEXT_ORGANIZATION_FULL_NAME).trim(),
  retailerAccount: clsx(process.env.NEXT_PUBLIC_TEXT_RETAILER_ACCOUNT).trim(),
  school: clsx(process.env.NEXT_PUBLIC_TEXT_SCHOOL).trim(),
  schoolIn: clsx(process.env.NEXT_PUBLIC_TEXT_SCHOOL_IN).trim(),
  studentAccount: clsx(process.env.NEXT_PUBLIC_TEXT_STUDENT_ACCOUNT).trim(),
};

export default texts;
