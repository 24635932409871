import gql from "graphql-tag";
import { fragmentAlumnus } from "./alumnusGroup";


export const GET_GROUP_STATS = gql`
  query GetGroupStats($group: String!, $limit: Int = 5, $excludedIds: [Int!]) {
    groupmates: alumnus(where: {grp: {_eq: $group}, id: {_nin: $excludedIds}, account: {status: {_neq: "DELETED"}}}, order_by: {alumnus_status: {reg_date: desc}}, limit: $limit) {
      ...alumnus
    }
    alumni: alumnus_aggregate(where: {account: {status: {_neq: "DELETED"}}}) {
      stat: aggregate {
        count
      }
    }
    group: alumnus_aggregate(where: {grp: {_eq: $group}, id: {_nin: $excludedIds}}) {
      stat: aggregate {
        count
      }
    }
  }

  ${fragmentAlumnus}
`;
