import React from "react";


export const page = 0;

export const tab = 2;


export const confirmOptions = {
  title: "Ugrás a címekhez, mentés nélkül",
  content: (
    <span>A címekhez való ugrással <b>az itt végzett módosítások elvesznek</b>.
      Előbb célszerű elmenteni a módosításokat, majd ezután navigálni a címekhez. Mégis folytatod?
    </span>
  ),
  OKButton: "Igen, ugrás a címekhez",
  classNameOK: "text-red",
  cancelButton: "Mégsem",
  colorCancel: "secondary",
};
