import gql from "graphql-tag";


export const SAVE_SOCIALS = gql`
  mutation SaveSocials($socials: jsonb!, $deletedIds: [Int!], $alumnusId: Int!) {
    saveSocials(socials: $socials, deletedIds: $deletedIds, alumnusId: $alumnusId) @client
  }
`;


export const UPSERT_SOCIALS = gql`
  mutation UpsertSocials($records: [social_insert_input!]!) {
    results: insert_social(objects: $records, on_conflict: {constraint: social__pkey, update_columns: [privacy, site, account]}) {
      affected_rows
    }
  }
`;


export const DELETE_SOCIALS = gql`
  mutation DeleteSocials($ids: [Int!]!) {
    results: delete_social(where: {id: {_in: $ids}}) {
      affected_rows
    }
  }
`;
