import React from "react";
import clsx from "clsx";
import { dbEnums, labels } from "common/enums";
import { EnumField, Label } from "components/form";


const options = [
  {
    value: dbEnums.cardStatus.REQUESTED,
    label: labels.cardStatus[dbEnums.cardStatus.REQUESTED],
    icon: "priority_high",
    color: "error",
  },
  {
    value: dbEnums.cardStatus.PREPARATION,
    label: labels.cardStatus[dbEnums.cardStatus.PREPARATION],
    icon: "build",
    color: "primary",
  },
  {
    value: dbEnums.cardStatus.ACTIVE,
    label: labels.cardStatus[dbEnums.cardStatus.ACTIVE],
    icon: "check",
    color: "secondary",
  },
  {
    value: dbEnums.cardStatus.REVOKED,
    label: labels.cardStatus[dbEnums.cardStatus.REVOKED],
    icon: "pan_tool",
    color: "error",
  },
];


export const getStatus = status => options.find(({ value }) => status === value) || {};


const CardStatus = ({ record, className, ...props }) => (
  <Label label="Kártya státusza">
    <EnumField
      {...props}
      record={record}
      options={options}
      className={clsx(!clsx(className).includes("mb-") && "mb-32", className)}
    />
  </Label>
);

export default React.memo(CardStatus);
