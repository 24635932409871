import { useCallback, useEffect, useState } from "react";
import { backendServices } from "client/lib/backendApi";
import { useBackendService } from "components/form";
import { setCodeRequirements } from "components/form/email";


const useAdminVerification = ({ setVerified, verified, forbidden }) => {

  const [codeReqs, setCodeReqs] = useState({});


  const { loading, banned, banExpiryFormatted, fetch } = useBackendService();


  const _setCodeRequirements = useCallback((alphabet, length) => setCodeRequirements(setCodeReqs)(alphabet, length), []);


  const handleVerifiedResponse = useCallback((banned, error, { verified, alphabet, length }) => {
    const safeVerified = Boolean(!error && !banned && verified);
    setVerified(safeVerified);

    _setCodeRequirements(alphabet, length);

    return safeVerified;
  }, [_setCodeRequirements, setVerified]);


  const checkVerified = useCallback(async () => {
    const { banned, error, data } = await fetch(backendServices.verify.isAdminVerified);

    return handleVerifiedResponse(banned, error, data);
  }, [fetch, handleVerifiedResponse]);


  const onVerified = useCallback(() => setVerified(true), [setVerified]);


  useEffect(() => {
    if (!forbidden) checkVerified();
  }, [checkVerified, forbidden, verified]);


  return {
    checkVerified,
    onVerified,
    loading,
    banned,
    banExpiryFormatted,
    codeReqs,
    fetch,
  };
};

export default useAdminVerification;
