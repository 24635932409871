import { getService } from "./helpers";


export const verify = {
  admin: {
    send: getService("verify/adminAction/send"),
    verify: getService("verify/adminAction/verify"),
  },
  email: {
    send: getService("verify/email/send"),
    verify: getService("verify/email/verify"),
  },
  isAdminVerified: getService("verify/adminAction/isAdminVerified"),
  phone: {
    send: getService("verify/phone/send"),
    verify: getService("verify/phone/verify"),
  },
};
