import React from "react";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import { ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FuseNavVerticalCollapse from "./FuseNavVerticalCollapse";
import FuseNavVerticalItem from "./FuseNavVerticalItem";
import { minGroupCnt } from "./FuseNavigation";


const useStyles = makeStyles({
  item: {
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingRight: 12
  },
});

const FuseNavVerticalGroup = props => {

  const { item, nestedLevel, groupCnt } = props;

  const classes = useStyles(props);


  let paddingValue = 40 + (nestedLevel * 16);

  const listItemPadding = nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-24";

  return !item.disallowed && (
    <React.Fragment>

      {(nestedLevel > 0 || groupCnt >= minGroupCnt) && (
        <ListSubheader disableSticky={true}
                       className={clsx(classes.item, listItemPadding, "list-subheader flex items-center mt-6")}>
                <span className="list-subheader-text uppercase text-12">
                    {item.title}
                </span>
        </ListSubheader>
      )}

      {item.children && (
        <React.Fragment>
          {
            item.children.map(item => (

              <React.Fragment key={item.id}>

                {item.type === "group" && (
                  <NavVerticalGroup item={item} nestedLevel={nestedLevel} groupCnt={groupCnt}/>
                )}

                {item.type === "collapse" && (
                  <FuseNavVerticalCollapse item={item} nestedLevel={nestedLevel}/>
                )}

                {(!item.type || item.type === "item") && (
                  <FuseNavVerticalItem item={item} nestedLevel={nestedLevel}/>
                )}

              </React.Fragment>
            ))
          }
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

FuseNavVerticalGroup.propTypes = {
  item: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      children: PropTypes.array,
    })
};

FuseNavVerticalGroup.defaultProps = {};

const NavVerticalGroup = React.memo(FuseNavVerticalGroup);

export default NavVerticalGroup;
