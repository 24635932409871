import React from "react";
import { Typography } from "@material-ui/core";
import { codeTypeConfig, codeTypes } from "server/lib/verify/code/config";


const Account = () => (
  <>
    <Typography className="mb-20">
      Ezen a lapon az alumnus <b>felhasználói fiókjával</b> kapcsolatos adminisztrációt végezheted.
    </Typography>
    <Typography variant="subtitle1" className="mt-24">Jogosultság megerősítése</Typography>
    <Typography className="my-20">
      Mivel ezen a lapon kritikus fontosságú adminisztrátori feladatok végezhetők (fiókok törlése, letiltása stb.),
      ezért ide egy többlet biztonsági lépést építettünk be. Ahhoz, hogy ezen a lapon
      bármilyen <i>módosítási</i> műveletet végezhess, <b>előbb meg kell adnod a kapcsolattartási telefonszámodra érkező
      SMS megerősítő kódot</b>. A megerősítő kód megadását
      követően <b>{codeTypeConfig[codeTypes.ADMIN_ACTION].verificationExpirationInMinutes} percig leszel
      jogosult</b> itt &ndash; vagy bármely más lapon &ndash; kritikus adminisztrátori műveletek végrehajtására. Ennek
      lejártával új megerősítő kód szükséges.
    </Typography>
    <Typography variant="subtitle1" className="mt-24">Jelszóváltoztatási link</Typography>
    <Typography className="my-20">
      A <span className="text-blue">Link kiküldése</span> gombra kattintva <span className="text-blue">jelszóváltoztatási linket</span> küldhetünk
      ki az alumnus kapcsolattartási email címére. A linkre kattintva <b>új jelszó adható meg, a régi ismerete
      nélkül</b>. Egy link egyszer használható fel.
    </Typography>
    <Typography variant="subtitle1" className="mt-24">Fiók státusz</Typography>
    <Typography className="mt-20">
      Az alumnusi <b>felhasználói fiók státusza</b> háromféle lehet.
    </Typography>
    <ul>
      <li className="mb-12"><span className="text-blue">Aktív</span> státuszú fiók esetén az alumnus bejelentkezhet,
        egyúttal a többi alumnus megtekintheti az adatlapját.
      </li>
      <li className="mb-12"><span className="text-blue">Letiltott</span> fiók esetén a bejelentkezés az alumnus számára
        nem engedélyezett, ugyanakkor a többi alumnus megtekintheti az adatlapját.
      </li>
      <li className="mb-12"><span className="text-blue">Törölt</span> fiók esetén a bejelentkezés az alumnus számára nem
        engedélyezett, egyúttal az adatlapját <i>csak az adminisztrátorok</i> tekinthetik meg. <b>Az alumnus adatainak
          tényleges törlése a feltüntetett időpontban történik meg.</b>
      </li>
    </ul>
    <Typography className="my-20">
      Az <span className="text-blue">aktív</span> státuszú fiók &ndash; a felirat melletti gombra
      kattintva &ndash;&nbsp;<b>letiltható</b>, míg a <span className="text-blue">letiltott</span> státuszú
      fiók &ndash; ugyanott &ndash;&nbsp;<b>aktiválható</b>.
    </Typography>
    <Typography variant="subtitle1" className="mt-24">Automatikus kitiltások</Typography>
    <Typography className="mt-20">
      A rendszer <span className="text-blue">automatikus kitiltást</span> eszközöl, amennyiben a bejelentkezett
      felhasználó <b>sorozatosan megsérti a biztonsági protokollt</b> (pl. <i>a többször hibásan megadott visszaigazoló
      kód</i>). A kitiltás vonatkozhat:
    </Typography>
    <ul>
      <li className="mb-12"><b>A felhasználói fiókra</b>: ekkor a bejelentkezés az alumnus számára
        nem engedélyezett;
      </li>
      <li className="mb-12"><b>Egyes szolgáltatásokra</b> (pl. kapcsolattartási email cím megváltoztatása,
        jelszóváltoztatási link kérése stb.): ekkor az alumnus ugyan bejelentkezhet, de az adott szolgáltatás(ok) nem
        lesz(nek) elérhető(k) számára. (Ha pedig a letiltott szolgáltatás éppen a <i>bejelentkezés</i>, akkor
        bejelentkezni sem fog tudni.)
      </li>
    </ul>
    <Typography className="mt-20">
      Amennyiben a felhasználó kitiltásra kerül:
    </Typography>
    <ul>
      <li className="mb-12"><b>Meg kell várnia a kitiltás lejáratát</b>, amely <i>első alkalommal 2 perc</i>. Ezt
        követően <b>újrapróbálkozhat</b> a művelettel.
      </li>
      <li className="mb-12">Ha a következő alkalommal már <b>megfelelően végzi el a műveletet</b> (pl. <i>megfelelő
        visszaigazoló kódot ad meg</i>), akkor <b>a kitiltás automatikusan törlésre kerül</b>.
      </li>
      <li className="mb-12">Ha a <b>következő alkalommal is megsérti a biztonsági protokollt</b> (pl. <i>ismét rossz
        visszaigazoló kódot ad meg</i>), akkor <b>a kitiltás ideje progresszív jelleggel nő</b>:
        újabb <i>5</i>, <i>10</i>, <i>30 perc</i>, ezt követően <i>1</i>, <i>2</i>, <i>12</i>, <i>24</i>, végül
        pedig <i>48 óra</i>. A következő kitiltás már <i>határozatlan időre</i> szól.
      </li>
    </ul>
    <Typography className="mt-20">
      A kitiltásról a rendszer <b>minden esetben tájékoztatja az alumnust, beleértve a kitiltás határidejét is</b>.
      Számára két lehetőség adódik: vagy megvárja a kitiltás lejártát, vagy felveszi a kapcsolatot az Alumnival. Utóbbi
      esetben <b>az adminisztrátor ezen a lapon </b>&ndash; a <span
      className="text-blue">Feloldom a korlátozásokat</span> gombra kattintva &ndash; <b>tudja feloldani a
      kitiltás(oka)t</b>.
    </Typography>
  </>
);

export default React.memo(Account);
