import moment from "moment";
import { Check, HelpOutline, Lock, Delete } from "@material-ui/icons";
import { dbEnums, labels } from "common/enums";
import { dateStr } from "common/helpers";


const adminProps = {
  [dbEnums.accountStatus.ACTIVE]: {
    label: labels.accountStatus[dbEnums.accountStatus.ACTIVE],
    color: "success",
    avatar: Check,
  },
  [dbEnums.accountStatus.BLOCKED]: {
    label: labels.accountStatus[dbEnums.accountStatus.BLOCKED],
    shortLabel: "Letiltott",
    color: "danger",
    avatar: Lock,
  },
  [dbEnums.accountStatus.DELETED]: {
    label: labels.accountStatus[dbEnums.accountStatus.DELETED],
    shortLabel: "Törölt",
    color: "danger",
    avatar: Delete,
  },
  default: {
    label: "Ismeretlen státusz",
    avatar: HelpOutline,
  },
};


const statusProps = ({ status, student }) => {
  const { exp } = student || {};

  const expiryDate = dateStr(exp);
  const left = exp && moment(exp).fromNow();

  const expiry = expiryDate && `${expiryDate} (${left})`;

  const props = (status && adminProps[status]) || adminProps._default;

  return {
    ...props,
    expiry,
  };
};


const account = {
  statusProps,
};

export default account;
