import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { Card, Typography, CardContent, AppBar, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { categories, discountClassName } from "config/search";
import { searchCategories } from "common/enums/db";
import { SearchContext } from "client/lib/providers/SearchProvider";
import { MiniChip } from "components/common";
import { AlumnusAvatar } from "components/alumnus/common";
import NameTitle from "components/alumnus/NameTitle";


const useStyles = makeStyles(() => ({
  avatar: {
    right: 0,
    bottom: -38,
    background: "#fff",
    boxSizing: "content-box",
    borderRadius: "50% !important",
  },
  avatarImg: {
    borderRadius: "50% !important",
  },
  CardContent: {
    "&:last-child": {
      paddingBottom: 12,
    },
  },
  specialAppBar: {
    backgroundColor: "#bdbdbd",
  },
}));


const AlumnusCard = ({ alumnus }) => {

  const { category } = useContext(SearchContext);


  const classes = useStyles();


  const { relevances, safeGroup, special, cooperation } = alumnus;

  const { tagsW } = cooperation || {};

  const { tags } = tagsW || {};


  const discountFilter = useMemo(() => category === searchCategories.DISCOUNT, [category]);


  const filteredCategories = useMemo(() => categories.filter(({ relevanceId, id }) =>
    relevanceId !== "r" && ((relevances && relevances[relevanceId]) || (discountFilter && id === category)))
    , [category, discountFilter, relevances]);


  const categoriesEnabled = useMemo(() => Boolean(filteredCategories && filteredCategories.length), [filteredCategories]);


  const discountEnabled = useMemo(() => Boolean(discountFilter && tags && tags.length), [discountFilter, tags]);


  return (
    <Card elevation={1} className="w-full h-full min-h-128">
      <AppBar
        position="relative"
        elevation={0}
        className="user z-0 p-16"
      >
        <Grid container spacing={1} className="text-left">
          <Grid item xs={12}>
            <NameTitle alumnus={alumnus} className="fullname text-14"/>
          </Grid>
          <Grid item xs={12} container spacing={1} wrap="nowrap">
            {!special && (
              <Grid item>
                <FontAwesomeIcon className="text-14 text-white opacity-75" icon={faUsers} color="rgba(0, 0, 0, 0.4)"/>
              </Grid>
            )}
            <Grid item>
              <Typography className="text-13 pr-64 lg:pr-48 text-white opacity-75">{safeGroup}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={clsx("justify-center absolute p-8", classes.avatar)}>
          <AlumnusAvatar alumnus={alumnus}
                         avatarClassName={classes.avatarImg}
                         size={64}
                         rounded
                         defaultAvatar
                         noButton
          />
        </div>
      </AppBar>
      {(categoriesEnabled || discountEnabled) && (
        <CardContent className={clsx("text-left min-h-16 p-12 pr-72", classes.CardContent)}>
          <Grid container spacing={1} alignItems="center">
            {categoriesEnabled && filteredCategories.map(({ relevanceId, resultLabel, className }) => (
              <Grid item key={relevanceId}>
                <MiniChip label={resultLabel}
                          colorClassName={className}
                />
              </Grid>
            ))}
            {discountEnabled && tags.map(({ value, label }) => (
              <Grid item key={value}>
                <MiniChip label={label}
                          colorClassName={discountClassName}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default React.memo(AlumnusCard);
