import React, { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { GET_REVISION_REQUESTS } from "client/store/revisionRequest";
import { useList, ListPage, ListContext, Header } from "components/listPages";
import Row from "./Row";
import Toolbar from "./Toolbar";


const RevisionRequestsList = () => {

  const forbidden = useForbidden([dbEnums.accountRoles.MANAGER]);


  const { reload, ...list } = useList();


  const { refetch: refetchCards, error, loading } = useQuery(GET_REVISION_REQUESTS, {
    onCompleted: ({ requests } = {}) => requests && reload(requests),
  });


  const refetch = useCallback(async () => {
    const { data: { requests } = {} } = await refetchCards();
    if (requests) reload(requests);
  }, [refetchCards, reload]);


  const contextValue = {
    ...list,
    refetch,
  };


  return (
    <ListContext.Provider value={contextValue}>
      <ListPage forbidden={forbidden}
                loading={loading}
                error={error}
                title="Adategyeztetési körök"
                icon="notifications"
                HeaderComponent={Header}
                ToolbarComponent={Toolbar}
                RowComponent={Row}
                headerHeight={180}
                noFilters
      />
    </ListContext.Provider>
  );
};

export default React.memo(RevisionRequestsList);
