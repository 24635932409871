import React from "react";
import clsx from "clsx";
import Document, { Head, Main, NextScript } from "next/document";
import { ServerStyleSheets } from "@material-ui/core/styles";
import envVars from "config/envVars";
import { apiPaths } from "config/routing";


export const jssInsertionPointId = "jss-insertion-point";

export const legacyHideSplashId = "legacy-hide-splash";


class MyDocument extends Document {
  render() {
    const { csp } = this.props;

    return (
      <html lang="hu">
      <Head>
        <meta charSet="utf-8"/>
        <meta httpEquiv="Content-Security-Policy" content={csp}/>
        <meta name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"/>
        <meta name="robots" content="noindex, nofollow, noarchive"/>
        <meta name="googlebot" content="noindex, nofollow, noarchive"/>
        <meta name="Googlebot" content="noindex, nofollow, noarchive"/>
        <meta name="Googlebot-Image" content="noindex, nofollow, noarchive"/>
        <meta name="Googlebot-News" content="noindex, nofollow, noarchive"/>
        <meta name="AdsBot-Google" content="noindex, nofollow, noarchive"/>
        <meta name="AdsBot-Google-Mobile" content="noindex, nofollow, noarchive"/>
        <meta name="APIs-Google" content="noindex, nofollow, noarchive"/>
        <meta name="Mediapartners-Google" content="noindex, nofollow, noarchive"/>
        <meta name="AdsBot-Google-Mobile-Apps" content="noindex, nofollow, noarchive"/>
        <meta name="FeedFetcher-Google" content="noindex, nofollow, noarchive"/>
        <meta name="Google-Read-Aloud" content="noindex, nofollow, noarchive"/>
        <meta name="DuplexWeb-Google" content="noindex, nofollow, noarchive"/>
        <meta name="theme-color" content="#000000"/>
        <base href="/"/>
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico"/>
        <link href="/fonts/material-outline-icons/styles.css" rel="stylesheet"/>
        <link href="/fonts/meteocons/style.css" rel="stylesheet"/>
        <link href="/fonts/typeface-muli/styles.css" rel="stylesheet"/>
        <script id={legacyHideSplashId} src="/legacyHideSplash.js"/>
        <noscript id={jssInsertionPointId}/>
      </Head>
      <body>
      <noscript>
        <link href="/disabled-js/disabled-js.css" rel="stylesheet"/>
        <div id="no-js-alert">
          Az alkalmazás elindításához engedélyezd a JavaScriptet a böngésződben.<br/><br/>
          További információ: <a href="https://www.enable-javascript.com/hu/" rel="noopener noreferrer"
                                 target="_blank">https://www.enable-javascript.com/hu/</a>
        </div>
      </noscript>
      <Main/>
      <NextScript/>
      </body>
      </html>
    );
  }
}


const getCsp = () => {

  const connectUris = clsx(
    apiPaths.graphqlClientHealthcheckUri,
    apiPaths.graphqlHttpUri,
    apiPaths.graphqlWsUri,
    envVars.bugsnagApiKey && "https://*.bugsnag.com",
  );

  const devInline = clsx(process.env.DEV_MODE && "'unsafe-inline'");

  return `default-src 'self'; img-src 'self' blob:; style-src 'self' 'unsafe-inline'; connect-src 'self' ${connectUris}; script-src 'self' ${devInline}`;
};


MyDocument.getInitialProps = async ctx => {

  const sheets = new ServerStyleSheets();
  const originalRenderPage = ctx.renderPage;

  ctx.renderPage = () =>
    originalRenderPage({
      enhanceApp: App => props => sheets.collect(
        <App {...props} />
      ),
    });

  const initialProps = await Document.getInitialProps(ctx);

  const csp = getCsp();

  return {
    ...initialProps,
    csp,
    styles: (
      <React.Fragment>
        {sheets.getStyleElement()}
      </React.Fragment>
    ),
  };
};

export default MyDocument;
